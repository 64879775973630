import React from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  Autocomplete,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';

export type VirtualizedAutocompleteProps = {
  value: string | number;
  onChange: (value: string | number) => void;
  options: {
    label: string;
    value: string | number;
  }[];
  disabled?: boolean;
  hasError?: boolean;
  errorMsg?: string;
  nullable?: boolean;
};

/**
 * Based on the example in MUI documentation:
 * https://mui.com/material-ui/react-autocomplete/#virtualization
 *
 * Should be used when rendering large selection lists to improve performance.
 */
export default function VirtualizedAutocomplete({
  value,
  onChange,
  options,
  disabled,
  hasError,
  errorMsg,
  nullable,
}: VirtualizedAutocompleteProps) {
  const [error, setError] = React.useState(hasError);

  const selectedOption = options?.find((option) => option.value === value);

  return (
    <Autocomplete
      value={selectedOption}
      onChange={(_: React.SyntheticEvent, newValue) => {
        setError(false);
        onChange(newValue.value);
      }}
      options={
        nullable
          ? [{ value: null, label: 'Não selecionado' }, ...options]
          : options
      }
      fullWidth
      renderInput={(params) => (
        <TextField
          disabled={disabled}
          error={error}
          helperText={error ? (errorMsg ?? 'Valor inválido') : ''}
          inputProps={{
            ...(error
              ? {
                  endAdornment: <ErrorOutlineOutlinedIcon color="error" />,
                }
              : {}),
          }}
          {...params}
        />
      )}
      renderOption={(props, option, state) =>
        [props, option, state.index] as React.ReactNode
      }
      ListboxComponent={VirtualizedListbox}
    />
  );
}

const LISTBOX_PADDING = 8;

const VirtualizedListbox = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactElement<unknown>[] = [];
  (children as React.ReactElement<unknown>[]).forEach(
    (
      item: React.ReactElement<unknown> & {
        children?: React.ReactElement<unknown>[];
      },
    ) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    },
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactElement<unknown>) => {
    if (Object.prototype.hasOwnProperty.call(child, 'group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={15}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: unknown) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + 8,
  };

  const { key, ...optionProps } = dataSet[0];
  return (
    <MenuItem
      key={key}
      value={dataSet[1].value}
      {...optionProps}
      style={inlineStyle}
    >
      {dataSet[1].label}
    </MenuItem>
  );
}
