import {
  get,
  isArray,
  isBoolean,
  isNumber,
  isPlainObject,
  isString,
} from 'lodash';

import { ContractEntry, EventSourcingEvent } from '@octopus/api';

import { ContractFieldChange, FieldChangeFormatters } from './Fields';
import { IAppContext } from './types';

/**
 * Returns the list of properties that are changed by `event` in relation to a `contract`
 * i.e. what properties from the `contract` are changed when the `event` is applied to it.
 *
 * Deeply nested properties paths are returned as '/' separated names
 *
 * @example
 *   const contract: ContractEntry = {
 *     br: {
 *       pessoa: {},
 *       // other fields ommited for brevity
 *     }
 *   };
 *   const event: EventSourcingEvent = {
 *     payload: {
 *       br: {
 *         pessoa: { nmTrab: 'John' } },
 *         dependentes: [ { nmDep: 'Mary' }, { tpDep: 1 } ]
 *       }
 *   };
 *
 *   const changes = contractFieldChanges(contract, event);
 *
 *   console.assert(
 *     changes === [
 *       {
 *         path: 'br/pessoa/nmTrab',
 *         newData: 'Johnny',
 *         oldData: 'John'
 *       },
 *       {
 *         path: 'br/dependentes/0/nmDep',
 *         newData: 'Mary',
 *         oldData: undefined
 *       },
 *       {
 *         path: 'br/dependentes/1/tpDep',
 *         newData: 1,
 *         oldData: undefined
 *       },
 *     ]
 *   );
 *
 * @link
 *  If you wish to format the changes to the UI, use the {@link formatChangedFieldLabel} and {@link formatChangedFieldData} functions
 *
 */
export function contractFieldChanges(
  contract: ContractEntry,
  event: EventSourcingEvent,
): ContractFieldChange[] {
  return dataChanges(contract, event.payload, contract, event.payload);
}
type Rec = { [key: string]: any | Rec };
function dataChanges<T extends Rec>(
  baseSourceObject: T,
  baseEventPayload: T,
  oldData: T,
  newData: T,
  path?: string,
): ContractFieldChange[] {
  let changes: ContractFieldChange[] = [];
  if (isString(newData) || isNumber(newData) || isBoolean(newData)) {
    // disregard unchanged fields (sometimes the event may have unchanged fields)
    if (newData === oldData) {
      return [];
    }

    // manager change
    if (path === 'contractId') {
      return [
        {
          path,
          newData,
          oldData: baseSourceObject['orgStructure']?.['manager'],
        },
      ];
    }

    // internal transfer event: the path in contract is different than in the event
    if (path === 'destination/legalEntityId') {
      return [{ path, newData, oldData: baseSourceObject['legalEntityId'] }];
    }

    // company transfer: the path in contract is different than in the event
    // all properties come from the event
    if (path === 'br/novaMatricula') {
      return [
        {
          path,
          newData,
          oldData: get(baseEventPayload, 'old.br.matricula'),
        },
      ];
    }
    if (path === 'newLegalEntityId') {
      return [
        {
          path,
          newData,
          oldData: undefined,
        },
      ];
    }

    if ('newSalary' in baseEventPayload) {
      if (path === 'newSalary') {
        return [
          {
            path,
            newData,
            oldData: baseSourceObject['br']?.['remuneracao']?.['vrSalFx'],
          },
        ];
      }

      if (path) {
        return [
          {
            path,
            newData,
            oldData: undefined,
          },
        ];
      }
    }

    if (!path) {
      return []; // todo: é esse o comportamento esperado?
    }

    // native values
    return [{ path, oldData, newData }];
  }
  if (isPlainObject(newData)) {
    for (const [k, v] of Object.entries(newData)) {
      changes = changes.concat(
        dataChanges(
          baseSourceObject,
          baseEventPayload,
          (oldData ? oldData[k] : undefined) as Rec,
          v as Rec,
          path ? `${path}/${k}` : k,
        ),
      );
    }
  }
  if (isArray(newData)) {
    changes = changes.concat(
      newData.flatMap((d, i) =>
        dataChanges(
          baseSourceObject,
          baseEventPayload,
          oldData ? oldData[i] : undefined,
          d,
          path ? `${path}/${i}` : i.toString(),
        ),
      ),
    );
  }
  return changes;
}

function getFormatter(path: string) {
  const fieldIsDependente = path.includes('/dependentes/');
  if (!fieldIsDependente) {
    return FieldChangeFormatters[path] ?? FieldChangeFormatters.default;
  }
  const indexPath = path.replace(/\/\d+\//, '/*/');
  return FieldChangeFormatters[indexPath] ?? FieldChangeFormatters.default;
}

export function isChangedFieldHidden(path: string): boolean {
  const formatter = getFormatter(path);
  return !formatter || 'hidden' in formatter;
}

export function formatChangedFieldLabel(path: string): string | undefined {
  const formatter = getFormatter(path);
  if (!formatter || 'hidden' in formatter) return undefined;

  return typeof formatter.label === 'string'
    ? formatter.label
    : formatter.label(path);
}

export function formatChangedFieldData(
  path: string,
  data: string,
  appContext?: IAppContext,
): string | undefined {
  const formatter = getFormatter(path);
  if (!formatter || 'hidden' in formatter) return undefined;
  return formatter.data ? formatter.data(data, appContext) : data;
}
