import { MapEntries, Mapper } from './mapper';

const entries: MapEntries = [
  [1, 'acidenteDoencaRelacionadaAoTrabalho'],
  [3, 'acidenteDoencaNaoRelacionadaAoTrabalho'],
  [17, 'licencaMaternidade'],
  [18, 'licencaMaternidadeEmpresaCidada'],
  [19, 'licencaMaternidadeAbortoNaoCriminoso'],
  [20, 'licencaMaternidadeGuardaJudicial'],
  [35, 'licencaMaternidadeAtestadoMedico'],
];

export type MotivoAfastamentoEngine =
  | 'acidenteDoencaRelacionadaAoTrabalho'
  | 'acidenteDoencaNaoRelacionadaAoTrabalho'
  | 'licencaMaternidade'
  | 'licencaMaternidadeEmpresaCidada'
  | 'licencaMaternidadeAbortoNaoCriminoso'
  | 'licencaMaternidadeGuardaJudicial'
  | 'licencaMaternidadeAtestadoMedico';

export const MotivoAfastamentoEngine = Mapper.from(entries);
