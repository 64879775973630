import React from 'react';

import { LogoutOutlined } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

import UserAvatar from '../../../modules/components/UserAvatar';

export function SidebarFooter({
  username,
  pictureUrl,
  open,
  logout,
}: {
  username: string;
  pictureUrl: string | undefined;
  open: boolean;
  logout: () => void;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <Box
      onMouseOver={handleMenuOpen}
      onMouseLeave={handleMenuClose}
      data-testid="sidebar-footer"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        sx={(theme) => ({
          overflow: 'hidden',
          bottom: '56px',
          position: 'fixed',
          height: menuOpen ? '50px' : '0px',
          width: open ? '240px' : '0px',
          transition: theme.transitions.create('height', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        })}
      >
        <Button
          onClick={logout}
          sx={(theme) => ({
            height: '32px',
            mx: 1,
            padding: '0',
            border: '0',
            minWidth: '0',
            width: '100%',
            justifyContent: 'flex-start',
            backgroundColor: theme.palette.background.paper,
            '&:hover': {
              backgroundColor: theme.palette.strokes.light,
            },
            '&:active': {
              backgroundColor: theme.palette.strokes.heavy,
            },
          })}
          data-testid="sidebar-footer-logout-button"
        >
          <LogoutOutlined
            sx={(theme) => ({
              px: 1,
              color: theme.palette.text.secondary,
              height: '16px',
              width: '16px',
            })}
          />

          <Typography
            display="flex"
            alignItems="center"
            noWrap
            maxWidth="176px"
            variant="caption"
          >
            Sair
          </Typography>
        </Button>
      </Box>
      <Box display="flex" flexDirection="row">
        <UserAvatar
          name={username}
          pictureUrl={pictureUrl}
          showFullName={open}
          height="56px"
          avatarTextProps={{ fontWeight: 'bold' }}
          textProps={{ fontWeight: '500' }}
        />
      </Box>
    </Box>
  );
}
