import { fetchPostPayrollInputsConfiguration } from '@octopus/api';

export default function ({
  organizationId,
  companyId,
}: {
  organizationId: string;
  companyId: string;
}) {
  // const rubricaAjudaDeCusto = 'rubrica.1.eSocial1981';

  fetchPostPayrollInputsConfiguration({
    pathParams: {
      organizationId,
      companyId,
    },
    body: {
      valorDeServico: {
        id: 'serviceValue',
        type: 'rpa',
        label: 'Valor de serviço',
        target: 'rpa',
        payloadType: 'currency',
        tag: 'events',
      },
      dataDePagamento: {
        id: 'paymentDate',
        type: 'rpa',
        label: 'Data de pagamento',
        target: 'rpa',
        payloadType: 'date',
        tag: 'events',
      },
      aliquotaIss: {
        id: 'issRate',
        type: 'rpa',
        label: 'Aliquota do ISS',
        target: 'rpa',
        payloadType: 'percentage',
        tag: 'events',
      },
    },
  });
}
