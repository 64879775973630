import { useContext } from 'react';

import { formatCNPJ } from '@octopus/formatters';

import { AppContext } from '../../../../../app/context';
import { Record, RecordEntry } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type LocalDePrestacaoDeServicoData = {
  organizationId: string;
  companyId: string;
  legalEntityId: string;
  workerId: string;
};

export type LocalDePrestacaoDeServicoProps =
  BaseRecordProps<LocalDePrestacaoDeServicoData>;

export function LocalDePrestacaoDeServico(
  props: LocalDePrestacaoDeServicoProps,
) {
  const {
    data: { legalEntityId, workerId },
  } = props;

  const { editing, formData, updateData, editRecordProps } =
    useRecordEdit(props);

  const { appContext } = useContext(AppContext);

  const legalEntities = appContext?.company?.legalEntities;
  const legalEntity = legalEntities?.find(({ id }) => legalEntityId === id);

  return (
    <Record title="Local de prestação de serviço" edit={editRecordProps}>
      <RecordEntry
        label="Nome do contratante"
        isLoading={!legalEntity}
        edit={{
          editing,
          type: 'options',
          value: formData.legalEntityId,
          disabled: !legalEntities,
          options: legalEntities?.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
          onChange: (value) => {
            updateData((data) => ({
              ...data,
              legalEntityId: value,
            }));
          },
        }}
      >
        {legalEntity?.name}
      </RecordEntry>
      <RecordEntry
        label="Razão social do contratante"
        isLoading={!legalEntity}
        edit={{
          editing,
          type: 'options',
          value: formData.legalEntityId,
          options: legalEntities?.map(
            ({
              id,
              summary: {
                br: { razaoSocial },
              },
            }) => ({
              value: id,
              label: razaoSocial,
            }),
          ),
          disabled: true,
          onChange: (value) => {
            updateData((data) => ({
              ...data,
              legalEntityId: value,
            }));
          },
        }}
      >
        {legalEntity?.summary?.br?.razaoSocial}
      </RecordEntry>
      <RecordEntry
        label="CNPJ do contratante"
        isLoading={!legalEntity}
        edit={{
          editing,
          type: 'options',
          value: formData.legalEntityId,
          options: legalEntities?.map(
            ({
              id,
              summary: {
                br: { cnpj },
              },
            }) => ({
              value: id,
              label: cnpj,
            }),
          ),
          disabled: true,
          onChange: (value) => {
            updateData((data) => ({
              ...data,
              legalEntityId: value,
            }));
          },
        }}
      >
        {formatCNPJ(legalEntity?.summary?.br?.cnpj)}
      </RecordEntry>
      {editing && (
        <RecordEntry
          label="Matrícula na empresa"
          edit={{
            type: 'text',
            editing,
            disabled: legalEntityId === formData.legalEntityId,
            value: formData.workerId,
            onChange: (value) =>
              updateData((data) => ({
                ...data,
                workerId: value,
              })),
          }}
        >
          {workerId}
        </RecordEntry>
      )}
    </Record>
  );
}
