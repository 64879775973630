import { History as HistoryIcon } from '@mui/icons-material';
import {
  Box,
  Divider,
  Skeleton,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import {
  PayrollElement,
  PayrollEntry,
  PayrollPayslipEntry,
} from '@octopus/api';
import { formatMoney, formatPeriodDate } from '@octopus/formatters';

import { DisplayDiff } from './DisplayDiff';
import { ElementsTableItems } from './ElementsTableItems';

export function ElementsTable({
  payroll,
  comparisonPeriod,
  isLoadingComparisonPayroll,
  comparisonPayroll,
  summary,
  onClickElement,
  selectedElementId,
  selectorIconPosition = 'left',
  rpaMode,
}: {
  payroll: PayrollEntry | PayrollPayslipEntry;
  comparisonPeriod?: string;
  comparisonPayroll?: PayrollEntry;
  isLoadingComparisonPayroll?: boolean;
  summary: 'totalCost' | 'workerEarningsTotal' | 'workerDeductionsTotal';
  onClickElement: (element: PayrollElement) => void;
  selectedElementId?: string | undefined;
  selectorIconPosition?: 'left' | 'right';
  rpaMode?: boolean;
}) {
  const { total } = payroll.outputs?.[summary] || { total: '0' };
  const hasComparison = Boolean(comparisonPeriod);
  const comparisonTotal = comparisonPayroll?.outputs?.[summary].total;
  return (
    <Box
      component={'table'}
      width="100%"
      sx={{
        tableLayout: 'auto',
        borderCollapse: 'collapse',
        '& tr > td:nth-last-of-type(2):not([role="presentation"]), & tr > th:nth-last-of-type(2):not([role="presentation"])':
          {
            textAlign: 'left',
            '& > *': {
              textAlign: 'left',
            },
          },
      }}
    >
      {rpaMode ? (
        <RpaTableHeader
          hasComparison={hasComparison}
          comparisonPeriod={comparisonPeriod}
        />
      ) : (
        <TableHeader
          hasComparison={hasComparison}
          comparisonPeriod={comparisonPeriod}
        />
      )}
      <tbody>
        <ElementsTableItems
          summary={summary}
          onClickElement={onClickElement}
          selectedElementId={selectedElementId}
          selectorIconPosition={selectorIconPosition}
          comparisonPayroll={comparisonPayroll}
          isLoadingComparisonPayroll={isLoadingComparisonPayroll}
          comparisonPeriod={comparisonPeriod}
          payroll={payroll}
        />
        <tr>
          <td />
          <td colSpan={hasComparison ? 3 : 2}>
            <Divider sx={{ my: 1 }} />
          </td>
        </tr>
        <TotalBox
          total={total}
          isLoadingComparisonPayroll={isLoadingComparisonPayroll}
          comparisonTotal={comparisonTotal}
        />
      </tbody>
    </Box>
  );
}

export function TotalBox({
  total,
  isLoadingComparisonPayroll,
  comparisonTotal,
}: {
  total: string;
  isLoadingComparisonPayroll: boolean;
  comparisonTotal: string;
  sxProp?: SxProps<Theme>;
}) {
  return (
    <Box
      component="tr"
      sx={{
        '& > *': {
          pt: 2,
        },
      }}
    >
      <th align="left" style={{ width: '16px' }} role="presentation" />
      <td>
        <Typography variant="body2" fontWeight="bold">
          Total:
        </Typography>
      </td>
      <td>
        <Typography variant="body2" fontWeight="bold">
          {formatMoney(total)}
        </Typography>
      </td>
      {comparisonTotal && (
        <td>
          {isLoadingComparisonPayroll && (
            <Skeleton variant="rounded" width="100%" />
          )}
          {!isLoadingComparisonPayroll && (
            <DisplayDiff
              variant="caption"
              current={total}
              compare={comparisonTotal}
              withSign={false}
              withCurrency={false}
            />
          )}
        </td>
      )}
      <td />
    </Box>
  );
}

function TableHeader({
  hasComparison,
  comparisonPeriod,
}: {
  hasComparison: boolean;
  comparisonPeriod?: string;
}) {
  return (
    <thead>
      <tr
        style={{
          paddingBottom: '16px',
        }}
      >
        <th align="left" style={{ width: '16px' }} role="presentation" />
        <th align="left" style={{ width: hasComparison ? '40%' : '70%' }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Descrição
          </Typography>
        </th>
        <th align="left" style={{ width: '30%' }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Valor
          </Typography>
        </th>
        {hasComparison && (
          <th align="left" style={{ width: '30%' }}>
            <Box
              component={'span'}
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <HistoryIcon />
              <Typography
                variant="body2"
                sx={{ marginInlineStart: 0.5, fontWeight: 'bold' }}
              >
                {formatPeriodDate(comparisonPeriod, {
                  short: true,
                })}
              </Typography>
            </Box>
          </th>
        )}
        <th align="left" style={{ width: '16px' }} role="presentation" />
      </tr>
      <tr>
        <td />
        <td colSpan={hasComparison ? 3 : 2}>
          <Divider sx={{ my: 1 }} />
        </td>
      </tr>
    </thead>
  );
}

export function RpaTableHeader({
  hasComparison,
  comparisonPeriod,
}: {
  hasComparison: boolean;
  comparisonPeriod?: string;
}) {
  return (
    <thead>
      <tr
        style={{
          paddingBottom: '16px',
        }}
      >
        <th align="left" style={{ width: '16px' }} role="presentation" />
        <th align="left" style={{ width: hasComparison ? '40%' : '70%' }} />
        <th align="left" style={{ width: '30%' }} />
        {hasComparison && (
          <th align="left" style={{ width: '30%' }}>
            <Box
              component={'span'}
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <HistoryIcon />
              <Typography
                variant="body2"
                sx={{ marginInlineStart: 0.5, fontWeight: 'bold' }}
              >
                {formatPeriodDate(comparisonPeriod, {
                  short: true,
                })}
              </Typography>
            </Box>
          </th>
        )}
        <th align="left" style={{ width: '16px' }} role="presentation" />
      </tr>
    </thead>
  );
}
