import {
  AdmissionDraftEntry,
  AdmissionDraftInputFormStepEnum,
  AdmissionDraftUpdateInput,
} from '@octopus/api';
import { contractTypes } from '@octopus/contract-types';
import { getContractTypeFromWorkerCategory } from '@octopus/esocial/contracts';

import { getEffectiveDate } from '../../inputUtils';
import { AdmissionFormState } from '../../types';

import { cltMapper } from './cltMapper';
import { common } from './common';
import { pjMapper } from './pjMapper';

const getFormStateFromEntry = ({
  admissionDraftEntry,
}: {
  admissionDraftEntry: AdmissionDraftEntry;
}): AdmissionFormState => {
  if (!admissionDraftEntry) {
    return {};
  }

  let fields = {} as AdmissionFormState;
  const commonFields = common.getFormStateFromEntry({ admissionDraftEntry });

  switch (admissionDraftEntry.contractType) {
    case contractTypes.brClt: {
      const cltFields = cltMapper.getFormStateFromEntry({
        admissionDraftEntry,
      });
      fields = { ...commonFields, ...cltFields };
      break;
    }
    case contractTypes.brPj: {
      const pjFields = pjMapper.getFormStateFromEntry({ admissionDraftEntry });
      fields = { ...commonFields, ...pjFields };
      break;
    }
  }

  return fields;
};

const getUpdateInputFromFormState = ({
  formState,
  currentStepName,
}: {
  formState: AdmissionFormState;
  currentStepName: AdmissionDraftInputFormStepEnum;
}): AdmissionDraftUpdateInput => {
  const contractType = getContractTypeFromWorkerCategory(
    formState.workerCategory,
  );
  const body: AdmissionDraftUpdateInput = {
    contractType,
    effectiveStartDate: getEffectiveDate(),
  };

  const commonBody = common.getUpdateInputFromFormState({
    formState,
    currentStepName,
    body,
  });

  switch (contractType) {
    case contractTypes.brClt: {
      const cltBody = cltMapper.getUpdateInputFromFormState({
        formState,
        currentStepName,
        body,
      });
      return { ...commonBody, ...cltBody };
    }
    case contractTypes.brPj: {
      const pjBody = pjMapper.getUpdateInputFromFormState({
        formState,
        currentStepName,
        body,
      });
      return { ...commonBody, ...pjBody };
    }
  }
};

export { getUpdateInputFromFormState, getFormStateFromEntry };
