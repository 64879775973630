import { makeApi, Zodios, type ZodiosOptions } from '@zodios/core';
import { z } from 'zod';

type errorsIssue = {
  path: Array<string | number>;
  message: string;
  subIssues?: Array<errorsIssue | Array<errorsIssue>> | undefined;
};

const user = z
  .object({
    email: z.string(),
    emailVerified: z.boolean(),
    name: z.string(),
    sub: z.string(),
    organization: z.string(),
    userId: z.string().uuid(),
    personId: z.string().uuid().optional(),
  })
  .passthrough();
const credentials = z
  .object({ username: z.string(), password: z.string() })
  .passthrough();
const challenge = z
  .object({
    challenge: z
      .object({ name: z.string(), session: z.string() })
      .partial()
      .passthrough()
      .optional(),
  })
  .passthrough();
const token = z.object({ token: z.string() }).passthrough();
const answer = z
  .object({
    username: z.string(),
    answer: z
      .object({
        name: z.string(),
        session: z.string(),
        responses: z.record(z.string()),
      })
      .passthrough(),
  })
  .passthrough();
const changePasswordInput = z
  .object({ password: z.string(), newPassword: z.string() })
  .passthrough();
const startPasswordRecoveryInput = z
  .object({ username: z.string() })
  .passthrough();
const confirmPasswordRecoveryInput = z
  .object({ username: z.string(), code: z.string(), newPassword: z.string() })
  .passthrough();
const userInput = z
  .object({
    name: z.string().min(1).max(1024),
    email: z.string().email(),
    personId: z.string().uuid().optional(),
    draftId: z.string().uuid().optional(),
    organizationId: z.string().uuid().optional(),
    ssoOnly: z.boolean().optional(),
  })
  .passthrough();
const userEntry = z.object({ userId: z.string().uuid() }).passthrough();
const getUsersByIds_Body = z
  .object({
    required: z.unknown(),
    userIds: z.array(z.string()).min(1).max(50),
  })
  .partial()
  .passthrough();
const userData = z
  .object({
    userData: z
      .object({
        name: z.string().min(1).max(1024),
        email: z.string().email(),
        userId: z.string().uuid(),
        personId: z.string().uuid().optional(),
        draftId: z.string().uuid().optional(),
        organizationId: z.string().uuid().optional(),
      })
      .passthrough(),
  })
  .passthrough();
const userUpdate = z
  .object({
    newEmail: z.string().email(),
    newPersonId: z.string().uuid(),
    newName: z.string().min(1).max(1024),
  })
  .partial()
  .passthrough();
const email = z.object({ email: z.string().email() }).passthrough();
const paginatedResult = z
  .object({
    unfilteredTotal: z.number().optional(),
    total: z.number(),
    size: z.number(),
    page: z.number(),
  })
  .passthrough();
const auditableAPIEntry = z
  .object({
    active: z.boolean(),
    createdOn: z.string().datetime({ offset: true }),
    createdBy: z.string().uuid(),
    lastUpdatedOn: z.string().datetime({ offset: true }).optional(),
    lastUpdatedBy: z.string().uuid().optional(),
  })
  .passthrough();
const membershipTypes = z.enum([
  'internal',
  'external',
  'owner',
  'tako:support',
]);
const membershipSummary = auditableAPIEntry.and(
  z
    .object({
      organizationId: z.string().uuid(),
      membershipId: z.string().uuid(),
      userId: z.string().uuid(),
      type: membershipTypes,
    })
    .passthrough()
);
const membershipList = paginatedResult.and(
  z
    .object({ unfilteredTotal: z.number(), data: z.array(membershipSummary) })
    .partial()
    .passthrough()
);
const userLookupInput = z.object({ identifier: z.string() }).passthrough();
const userLookupEntry = z.object({ userId: z.string().uuid() }).passthrough();
const organizationSummary = auditableAPIEntry.and(
  z
    .object({ organizationId: z.string().uuid(), name: z.string() })
    .passthrough()
);
const organizationList = paginatedResult.and(
  z
    .object({ unfilteredTotal: z.number(), data: z.array(organizationSummary) })
    .partial()
    .passthrough()
);
const organizationSettings = z
  .object({
    ssoOnly: z.boolean(),
    webhookDeliveryEnabled: z.boolean(),
    webhookUrl: z.string(),
    sendOpsEmails: z.boolean(),
    enforceUniqueWorkerIdBy: z.enum(['organization', 'company', 'legalEntity']),
  })
  .partial()
  .passthrough();
const organizationInput = z
  .object({
    name: z.string(),
    logo: z.string().max(20000).optional(),
    logoUrl: z.string().optional(),
    settings: organizationSettings.optional(),
  })
  .passthrough();
const organizationEntry = auditableAPIEntry.and(
  z
    .object({
      organizationId: z.string().uuid(),
      name: z.string(),
      logo: z.string().optional(),
      logoUrl: z.string().optional(),
      settings: organizationSettings.optional(),
    })
    .passthrough()
);
const organizationUpdateInput = z
  .object({
    name: z.string(),
    logo: z.string().max(20000),
    logoUrl: z.string(),
    settings: organizationSettings,
  })
  .partial()
  .passthrough();
const membershipInput = z
  .object({ userId: z.string().uuid(), type: membershipTypes })
  .passthrough();
const membershipEntry = auditableAPIEntry.and(
  z
    .object({
      organizationId: z.string().uuid(),
      membershipId: z.string().uuid(),
      userId: z.string().uuid(),
      type: membershipTypes,
    })
    .passthrough()
);
const organizationPublicAssetsTypeEnum = z.literal('logo');
const organizationPublicAssetsInput = z
  .object({
    fileName: z.string().min(1).max(255),
    type: organizationPublicAssetsTypeEnum,
    contentType: z.enum(['image/jpeg', 'image/jpg', 'image/png']),
    contentLength: z.number().int(),
  })
  .passthrough();
const auditableVersionedEntry = auditableAPIEntry.and(
  z.object({ version: z.number() }).passthrough()
);
const organizationPublicAssetsEntry = auditableVersionedEntry.and(
  z
    .object({
      id: z.string().uuid(),
      organizationId: z.string().uuid(),
      status: z.enum(['waitingUpload', 'uploaded', 'deleted']),
      type: organizationPublicAssetsTypeEnum,
      fileName: z.string().min(1).max(255),
      contentType: z.string().min(1).max(255),
      contentLength: z.number().int(),
      deletedOn: z.string().datetime({ offset: true }).optional(),
      deletedBy: z.string().uuid().optional(),
      downloadUrl: z.string().optional(),
      uploadUrl: z.string().optional(),
    })
    .passthrough()
);
const BRClassificacaoTributaria = z.number();
const BREndereco = z
  .object({
    logradouro: z.string(),
    numero: z.string(),
    complemento: z.string().optional(),
    bairro: z.string(),
    cep: z.string().regex(/^[0-9]{5}-[0-9]{3}$/),
    municipio: z.string(),
    uf: z.string().min(2).max(2),
  })
  .passthrough();
const companyBRSummary = z
  .object({
    cnpj: z.string(),
    razaoSocial: z.string(),
    codClassTrib: BRClassificacaoTributaria.int().gte(1).lte(99),
    indOptRegEletron: z.boolean(),
    indConstr: z.boolean(),
    indCoop: z.boolean(),
    indDesFolha: z.boolean(),
    dtTrans11096: z.string().datetime({ offset: true }).optional(),
    dtIniValid: z.string().datetime({ offset: true }),
    dtFimValid: z.string().datetime({ offset: true }).optional(),
    endereco: BREndereco,
  })
  .passthrough();
const companySummary = auditableAPIEntry.and(
  z
    .object({
      organizationId: z.string().uuid(),
      companyId: z.string().uuid(),
      name: z.string().min(1).max(255),
      logo: z.string().optional(),
      countryCode: z.string().min(2).max(2),
      br: companyBRSummary.optional(),
    })
    .passthrough()
);
const companyList = paginatedResult.and(
  z
    .object({ unfilteredTotal: z.number(), data: z.array(companySummary) })
    .partial()
    .passthrough()
);
const companyBRInput = z
  .object({
    cnpj: z.string(),
    razaoSocial: z.string(),
    codClassTrib: BRClassificacaoTributaria.int().gte(1).lte(99),
    indOptRegEletron: z.boolean(),
    indConstr: z.boolean(),
    indCoop: z.boolean(),
    indDesFolha: z.boolean(),
    dtTrans11096: z.string().datetime({ offset: true }).optional(),
    dtIniValid: z.string().datetime({ offset: true }),
    dtFimValid: z.string().datetime({ offset: true }).optional(),
    endereco: BREndereco,
  })
  .passthrough();
const companyInput = z
  .object({
    name: z.string().min(1).max(255),
    logo: z.string().max(20000).optional(),
    countryCode: z.string().min(2).max(2),
    br: companyBRInput.optional(),
  })
  .passthrough();
const companyBREntry = z
  .object({
    cnpj: z.string(),
    razaoSocial: z.string(),
    codClassTrib: BRClassificacaoTributaria.int().gte(1).lte(99),
    indOptRegEletron: z.boolean(),
    indConstr: z.boolean(),
    indCoop: z.boolean(),
    indDesFolha: z.boolean(),
    dtTrans11096: z.string().datetime({ offset: true }).optional(),
    dtIniValid: z.string().datetime({ offset: true }),
    dtFimValid: z.string().datetime({ offset: true }).optional(),
    endereco: BREndereco,
  })
  .passthrough();
const companyEntry = auditableAPIEntry.and(
  z
    .object({
      organizationId: z.string().uuid(),
      companyId: z.string().uuid(),
      name: z.string().min(1).max(255),
      logo: z.string().optional(),
      countryCode: z.string().min(2).max(2),
      br: companyBREntry.optional(),
    })
    .passthrough()
);
const certificateInput = z
  .object({ certificate: z.string(), passphrase: z.string().max(255) })
  .passthrough();
const certificateEntry = z
  .object({
    id: z.string().uuid(),
    createdOn: z.string().datetime({ offset: true }),
    expiresOn: z.string().datetime({ offset: true }),
  })
  .passthrough();
const searchPagination = z
  .object({
    size: z.number().int().gte(1).lte(100),
    page: z.number().int().gte(0),
  })
  .partial()
  .passthrough();
const searchSorting = z
  .object({ field: z.string(), order: z.enum(['asc', 'desc']) })
  .partial()
  .passthrough();
const searchFilteringObjectInput = z.object({ not: z.string() }).passthrough();
const dateYYYYMM = z.string();
const dateYYYYMMDD = z.string();
const dateTime = z.string();
const searchFilteringRange = z
  .object({
    max: z.union([z.number(), dateYYYYMM, dateYYYYMMDD, dateTime]),
    min: z.union([z.number(), dateYYYYMM, dateYYYYMMDD, dateTime]),
  })
  .partial()
  .passthrough();
const searchFilteringInput = z
  .object({
    elements: z.record(
      z.array(z.union([z.string(), searchFilteringObjectInput]))
    ),
    arrays: z.record(
      z.array(z.union([z.string(), searchFilteringObjectInput]))
    ),
    ranges: z.record(searchFilteringRange),
    toggles: z.array(z.string()),
  })
  .partial()
  .passthrough();
const searchCountingInputCounters = z
  .object({ total: z.boolean(), byProp: z.record(z.array(z.string())) })
  .partial()
  .passthrough();
const searchCountingInput = z
  .object({
    filtered: searchCountingInputCounters,
    unfiltered: searchCountingInputCounters,
  })
  .partial()
  .passthrough();
const searchInput = z
  .object({
    query: z.string(),
    pagination: searchPagination,
    sorting: searchSorting,
    filtering: searchFilteringInput,
    counting: searchCountingInput,
  })
  .partial()
  .passthrough();
const searchCountingOutputCounters = z
  .object({
    counters: z
      .object({ total: z.number(), byProp: z.record(z.record(z.number())) })
      .partial()
      .passthrough(),
  })
  .passthrough();
const withMetadataResult = z
  .object({
    metadata: z
      .object({
        filtered: searchCountingOutputCounters,
        unfiltered: searchCountingOutputCounters,
        buckets: searchCountingOutputCounters,
      })
      .partial()
      .passthrough(),
  })
  .partial()
  .passthrough();
const datePeriodFormat = z.string();
const payrollTypes = z.enum([
  'monthly',
  'thirteenthFirst',
  'thirteenthSecond',
  'termination',
  'vacations',
  'advance',
  'complementary',
  'complementaryTermination',
  'provisions',
  'rpa',
]);
const payrollStatus = z.enum([
  'calculating',
  'calculationError',
  'open',
  'approving',
  'approved',
  'reconciled',
  'reconcilingError',
  'closingError',
  'closed',
  'archived',
]);
const payrollWorkerData = z
  .object({
    name: z.string(),
    employeeId: z.string(),
    salary: z.string(),
    hours: z.string(),
    admissionDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    dependentsQuantity: z.string(),
    location: z.object({ city: z.string(), state: z.string() }).passthrough(),
    jobTitle: z.string(),
    department: z.string(),
    hiringType: z.string(),
    br: z
      .object({
        mesBaseSindicato: z.number(),
        dependentes: z.array(
          z.object({ nmDep: z.string(), tpDep: z.number() }).passthrough()
        ),
      })
      .passthrough()
      .optional(),
  })
  .passthrough();
const payrollCalculationTotals = z
  .object({
    netPay: z.string(),
    totalCost: z.string(),
    workerEarningsTotal: z.string(),
    workerDeductionsTotal: z.string(),
  })
  .passthrough();
const payrollVacationsEntry = z
  .object({
    startDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    endDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    sellingVacations: z.boolean(),
    daysSold: z.number(),
    anticipate13th: z.boolean(),
    durationInDays: z.string(),
    paymentDeadline: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    accrualPeriod: z
      .object({
        startDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        endDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
      })
      .passthrough(),
  })
  .partial()
  .passthrough();
const payrollInputsTerminationReason = z.enum([
  'pedidoDeDemissao',
  'semJustaCausa',
  'comJustaCausa',
  'mutuoAcordo',
  'falecimento',
  'extincaoDeContratoDeExperiencia',
  'rescisaoAntecipadaDoContratoDeExperienciaPeloEmpregador',
  'rescisaoAntecipadaDoContratoDeExperienciaPeloEmpregado',
]);
const payrollInputsTerminationNoticePeriodNotWorkedReason = z.enum([
  'indenizado',
  'dispensado',
  'impedidoDeTrabalhar',
  'empregadoTemNovoEmprego',
]);
const payrollInputsBrInput = z
  .object({
    motivo: payrollInputsTerminationReason,
    comoLidarComAvisoPrevioNaoCumprido:
      payrollInputsTerminationNoticePeriodNotWorkedReason.optional(),
    multaFgts: z.string().optional(),
    diasRestantesNoContratoDeExperiencia: z.number().optional(),
  })
  .passthrough();
const payrollTerminationEntry = z
  .object({
    noticeDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    terminationDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    paymentDeadline: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    startedBy: z.enum(['employer', 'employee', 'both', 'other']),
    noticeIndemnityPayer: z.enum(['employer', 'employee', 'none']),
    noticeIndemnityType: z.enum([
      'indemnified',
      'deducted',
      'exempt',
      'worked',
      'other',
    ]),
    br: payrollInputsBrInput,
  })
  .partial()
  .passthrough();
const payrollSummary = z
  .object({
    organizationId: z.string().uuid(),
    companyId: z.string().uuid(),
    legalEntityId: z.string().uuid().optional(),
    period: datePeriodFormat.regex(/^\d{4}(\-(0[1-9]|1[012]))?$/),
    contractId: z.string().uuid(),
    personId: z.string().uuid(),
    type: payrollTypes,
    payrollId: z.string().uuid(),
    status: payrollStatus,
    statusIndex: z.number().int().optional(),
    date: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    workerData: payrollWorkerData,
    calculationTotals: payrollCalculationTotals.optional(),
    vacations: payrollVacationsEntry.optional(),
    termination: payrollTerminationEntry.optional(),
  })
  .passthrough();
const payrollList = paginatedResult
  .and(withMetadataResult)
  .and(z.object({ data: z.array(payrollSummary) }).passthrough());
const payrollConfigurationsBasisOverride = z
  .object({ includeIn: z.array(z.string()), excludeFrom: z.array(z.string()) })
  .partial()
  .passthrough();
const payrollConfigurationPaymentConfig = z
  .object({
    baseDay: z.number().gte(1).lte(31),
    baseMonth: z.enum(['current', 'next']),
  })
  .passthrough();
const BRPreferences = z
  .object({
    quantosDiasTemNoMes: z.enum(['sempre30Dias', 'diasReaisDoMes']),
    usarSempreDiasReaisDoMes: z.enum(['nunca', 'admissao']),
    proporcionalidadeAdiantamento: z.enum(['proporcional', 'integral']),
    proporcionalidadeDeDecimoTerceiroAteDezembroParaAdmitidosNoAno: z.enum([
      'sim',
      'nao',
      'avos',
      'ano',
    ]),
    pagarMediasEmFaltasJustificadas: z.enum(['sim', 'nao']),
    pagarMediasNaPrimeiraParcela13o: z.enum(['sim', 'nao']),
    subtrairFeriasDosMesesTrabalhadosParaMedias: z.enum(['sim', 'nao']),
    subtrairAfastamentosDosMesesTrabalhadosParaMedias: z.enum(['sim', 'nao']),
    descontarAjusteNegativoDe13o: z.enum(['sim', 'nao']),
  })
  .partial()
  .passthrough();
const payrollConfigurationInput = z
  .object({
    effectiveDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    modules: z.array(z.string()),
    basisOverrides: z.record(payrollConfigurationsBasisOverride).optional(),
    paymentConfig: payrollConfigurationPaymentConfig.optional(),
    brPreferences: BRPreferences.optional(),
  })
  .passthrough();
const payrollConfigurationEntry = auditableVersionedEntry
  .and(payrollConfigurationInput)
  .and(
    z
      .object({
        organizationId: z.string().uuid(),
        companyId: z.string().uuid(),
        paymentConfig: payrollConfigurationPaymentConfig,
      })
      .passthrough()
  );
const payrollConfigurationUpdate = z
  .object({
    modules: z.array(z.string()).optional(),
    basisOverrides: z.record(payrollConfigurationsBasisOverride).optional(),
    paymentConfig: payrollConfigurationPaymentConfig.optional(),
    brPreferences: BRPreferences.optional(),
    version: z.number().int(),
  })
  .passthrough();
const payrollHistoricalEventComissoes = z
  .object({
    type: z.literal('comissoes'),
    payload: z
      .object({
        total: z.string(),
        dsr: z.string(),
        lancamentoManual: z.string(),
      })
      .partial()
      .passthrough(),
  })
  .passthrough();
const payrollHistoricalEventDecimoTerceiro = z
  .object({
    type: z.literal('decimoTerceiro'),
    payload: z
      .object({ total: z.string(), irrf: z.string(), inss: z.string() })
      .partial()
      .passthrough(),
  })
  .passthrough();
const payrollHistoricalEventDsr = z
  .object({
    type: z.literal('dsr'),
    payload: z
      .object({
        diasTotaisEmTrabalho: z.number().int(),
        diasUteis: z.number().int(),
        diasDeDescansoRemunerado: z.number().int(),
        location: z
          .object({ state: z.string(), city: z.string() })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
  })
  .passthrough();
const payrollHistoricalEventFaltas = z
  .object({
    type: z.literal('faltasInjustificadas'),
    payload: z
      .object({ dias: z.array(z.string()), descontarDSR: z.boolean() })
      .partial()
      .passthrough(),
  })
  .passthrough();
const payrollHistoricalEventFerias = z
  .object({
    type: z.literal('ferias'),
    payload: z
      .object({
        tipo: z.enum(['individuais', 'coletivas']),
        dataDeInicio: z.string(),
        dataDeFim: z.string(),
        periodoAquisitivo: z
          .object({ dataDeInicio: z.string(), dataDeFim: z.string() })
          .partial()
          .passthrough(),
        diasDeFerias: z.string(),
        diasDeAbono: z.string(),
        pagamento: z
          .object({
            diasDeFerias: z.string(),
            tercoConstitucional: z.string(),
            abonoPecuniario: z.string(),
            tercoSobreAbonoPecuniario: z.string(),
            dobro: z.string(),
            tercoSobreDobro: z.string(),
          })
          .partial()
          .passthrough(),
        impostosRecolhidos: z
          .object({ provisaoDeInss: z.string() })
          .partial()
          .passthrough(),
        pagamentoPorMes: z.record(
          z.object({ proporcao: z.string() }).partial().passthrough()
        ),
        pagamentoDoDobroPorMes: z.record(
          z.object({ proporcao: z.string() }).partial().passthrough()
        ),
      })
      .partial()
      .passthrough(),
  })
  .passthrough();
const payrollHistoricalEventHoras = z
  .object({
    type: z.literal('horas'),
    payload: z
      .object({
        normais: z.string(),
        noturnas: z.string(),
        faltas: z.string(),
        faltasBancoDeHoras: z.string(),
        sobreaviso: z.string(),
        extras: z.record(z.string()),
        extrasNoturnas: z.record(z.string()),
        interjornada: z.record(z.string()),
        intrajornada: z.record(z.string()),
        extrasBancoDeHoras: z.record(z.string()),
      })
      .partial()
      .passthrough(),
  })
  .passthrough();
const payrollHistoricalEventInsuficienciaDeSaldo = z
  .object({
    type: z.literal('insuficienciaDeSaldo'),
    payload: z.object({ total: z.string() }).partial().passthrough(),
  })
  .passthrough();
const payrollHistoricalEventImpostoRecolhidoElement = z
  .object({
    id: z.string(),
    name: z.string(),
    amount: z.string(),
    type: z.enum(['earnings', 'deductions']),
  })
  .passthrough();
const payrollHistoricalEventImpostoRecolhido = z
  .object({
    total: z.string(),
    baseDeCalculo: z
      .object({
        itens: z.array(payrollHistoricalEventImpostoRecolhidoElement),
        deducoes: z.array(payrollHistoricalEventImpostoRecolhidoElement),
      })
      .partial()
      .passthrough(),
  })
  .passthrough();
const payrollHistoricalEventRecolhimentoDeImpostosMensais = z
  .object({
    type: z.literal('recolhimentoDeImpostos'),
    payload: z
      .object({
        tipoDeFolha: z.enum([
          'regularMensal',
          'ferias',
          'decimoTerceiro',
          'rescisao',
          'adiantamento',
          'complementar',
        ]),
        effectiveDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        dataDePagamento: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        inss: payrollHistoricalEventImpostoRecolhido,
        irrf: payrollHistoricalEventImpostoRecolhido,
      })
      .partial()
      .passthrough(),
  })
  .passthrough();
const payrollHistoricalEventDiasAfastados = z
  .object({
    type: z.literal('diasAfastados'),
    payload: z.object({ periodos: z.unknown() }).partial().passthrough(),
  })
  .passthrough();
const payrollHistoricalEventAdicionalDeInsalubridade = z
  .object({
    type: z.literal('adicionalDeInsalubridade'),
    payload: z
      .object({ total: z.string(), porcentagemDoAdicional: z.string() })
      .passthrough(),
  })
  .passthrough();
const payrollHistoricalEventAdicionalPorTempoDeServico = z
  .object({
    type: z.literal('adicionalPorTempoDeServico'),
    payload: z.object({ total: z.string() }).passthrough(),
  })
  .passthrough();
const payrollHistoricalEventPensaoAlimenticiaPaga = z
  .object({
    type: z.literal('pensaoAlimenticiaPaga'),
    payload: z
      .object({
        dataDePagamento: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        ferias: z.string().optional(),
        mensal: z.string().optional(),
        tipoDeFolha: z.string(),
        decimoTerceiro: z.string(),
        effectiveDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
      })
      .passthrough(),
  })
  .passthrough();
const payrollHistoricalEventInput = z
  .object({
    organizationId: z.string().uuid(),
    companyId: z.string().uuid(),
    legalEntityId: z.string().uuid().optional(),
    contractId: z.string().uuid(),
    period: z.string().min(4).max(7),
    date: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    type: payrollTypes,
    source: z.enum([
      'payrollEngine',
      'migration',
      'spreadsheets',
      'retool',
      'UNSAFE_OVERRIDE',
    ]),
    events: z.array(
      z.union([
        payrollHistoricalEventComissoes,
        payrollHistoricalEventDecimoTerceiro,
        payrollHistoricalEventDsr,
        payrollHistoricalEventFaltas,
        payrollHistoricalEventFerias,
        payrollHistoricalEventHoras,
        payrollHistoricalEventInsuficienciaDeSaldo,
        payrollHistoricalEventRecolhimentoDeImpostosMensais,
        payrollHistoricalEventDiasAfastados,
        payrollHistoricalEventAdicionalDeInsalubridade,
        payrollHistoricalEventAdicionalPorTempoDeServico,
        payrollHistoricalEventPensaoAlimenticiaPaga,
      ])
    ),
    eventsPriorToOverride: z
      .array(z.object({}).partial().passthrough())
      .optional(),
  })
  .passthrough();
const payrollHistoricalEventDelete = z
  .object({
    organizationId: z.string().uuid(),
    companyId: z.string().uuid(),
    contractId: z.string().uuid(),
    period: z.string().min(4).max(7),
    date: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    type: payrollTypes,
  })
  .passthrough();
const dateYYYY = z.string();
const period = z.union([dateYYYYMM, dateYYYY]);
const payrollPeriodStatus = z.enum([
  'open',
  'approving',
  'summarizing',
  'reconciling',
  'reconciled',
  'closing',
  'closed',
  'error',
]);
const payrollPeriodTotalizersEntityCount = z
  .object({ total: z.number().int(), byStatus: z.record(z.number().int()) })
  .passthrough();
const payrollPeriodTotalizersCounts = z
  .object({
    payrolls: payrollPeriodTotalizersEntityCount,
    payslips: payrollPeriodTotalizersEntityCount,
  })
  .passthrough();
const payrollPeriodTotalizersSection = z
  .object({ total: z.string(), elements: z.array(z.string()) })
  .passthrough();
const payrollPeriodTotalizersElementTotals = z
  .object({
    type: z.enum(['deductions', 'earnings']),
    id: z.string(),
    name: z.string(),
    amount: z.string(),
    workersCount: z.number().int().gte(0),
  })
  .passthrough();
const payrollPeriodTotalizersTotals = z
  .object({
    netPay: payrollPeriodTotalizersSection,
    totalCost: payrollPeriodTotalizersSection,
    workerEarningsTotal: payrollPeriodTotalizersSection,
    workerDeductionsTotal: payrollPeriodTotalizersSection,
    elements: z.record(payrollPeriodTotalizersElementTotals),
  })
  .passthrough();
const payrollPeriodTotalizers = z
  .object({
    counts: payrollPeriodTotalizersCounts,
    totals: payrollPeriodTotalizersTotals,
  })
  .passthrough();
const payrollPeriodBr = z
  .object({
    desoneracaoDeFolha: z
      .object({
        receitaNacionalDesonerada: z.string(),
        receitaNacionalNaoDesonerada: z.string(),
        receitaExportacao: z.string(),
      })
      .partial()
      .passthrough(),
  })
  .partial()
  .passthrough();
const payrollPeriodEntry = auditableVersionedEntry.and(
  z
    .object({
      organizationId: z.string().uuid(),
      companyId: z.string().uuid(),
      period: datePeriodFormat.regex(/^\d{4}(\-(0[1-9]|1[012]))?$/),
      status: payrollPeriodStatus,
      statusIndex: z.number().int(),
      errors: z.array(z.string()).optional(),
      totalizers: payrollPeriodTotalizers,
      br: payrollPeriodBr.optional(),
    })
    .passthrough()
);
const patchPeriod_Body = z
  .object({ br: payrollPeriodBr })
  .partial()
  .passthrough();
const payrollInputsEvent = z
  .object({ type: z.string(), payload: z.object({}).partial().passthrough() })
  .passthrough();
const payrollInputsElement = z
  .object({
    type: z.enum(['deductions', 'earnings']),
    id: z.string(),
    name: z.string(),
    amount: z.string(),
    meta: z
      .object({ includeIn: z.array(z.string()) })
      .partial()
      .passthrough()
      .optional(),
  })
  .passthrough();
const payrollRecord = z
  .object({ type: z.string(), payload: z.object({}).partial().passthrough() })
  .passthrough();
const payrollInputsAdvance = z
  .object({ percentage: z.number().gte(1).lte(100), paymentDate: z.string() })
  .passthrough();
const payrollInputsComplementary = z
  .object({
    paymentDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
  })
  .passthrough();
const esocialTpAcConv = z.enum([
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
]);
const esocialRemunSuc = z.enum(['S', 'N']);
const payrollInputsComplementaryTermination = z
  .object({
    paymentDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    br: z
      .object({
        tipoDoEvento: esocialTpAcConv,
        dataDoEvento: dateYYYYMMDD
          .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
          .optional(),
        descricaoDoEvento: z.string().min(1).max(255),
        periodoDaRemuneracaoRetroativa: dateYYYYMM
          .regex(/^\d{4}\-(0[1-9]|1[012])$/)
          .optional(),
        remuneradoPeloSucessor: esocialRemunSuc,
      })
      .passthrough(),
  })
  .passthrough();
const payrollInputsThirteenth = z
  .object({
    paymentDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
  })
  .passthrough();
const payrollInputsTermination = z
  .object({
    noticeDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    terminationDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    paymentDate: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    br: payrollInputsBrInput,
  })
  .passthrough();
const payrollInputsVacations = z
  .object({
    startDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    endDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    type: z.enum(['individual', 'group']).optional(),
    sellingVacations: z.boolean().optional(),
    daysSold: z.number().int().gte(0),
    anticipate13th: z.boolean(),
    paymentDate: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    accrualPeriod: z
      .object({
        startDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        endDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
      })
      .passthrough()
      .optional(),
    sequence: z.number(),
    vacationsBreakdown: z
      .array(
        z
          .object({
            date: dateYYYYMMDD.regex(
              /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
            ),
            ratios: z
              .object({ vacations: z.string(), paidLeave: z.string() })
              .passthrough(),
          })
          .passthrough()
      )
      .optional(),
  })
  .passthrough();
const payrollInputsRpa = z
  .object({
    paymentDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    serviceValue: z.string(),
    aliquotaISS: z.string(),
  })
  .passthrough();
const payrollInputsInputs = z
  .object({
    events: z.array(payrollInputsEvent),
    elements: z.array(payrollInputsElement),
    records: z.record(payrollRecord),
    advance: payrollInputsAdvance,
    complementary: payrollInputsComplementary,
    complementaryTermination: payrollInputsComplementaryTermination,
    thirteenth: payrollInputsThirteenth,
    termination: payrollInputsTermination,
    vacations: payrollInputsVacations,
    rpa: payrollInputsRpa,
  })
  .partial()
  .passthrough();
const payrollInput = z
  .object({
    inputs: payrollInputsInputs.optional(),
    type: payrollTypes,
    legalEntityId: z.string().uuid().optional(),
    period: datePeriodFormat.regex(/^\d{4}(\-(0[1-9]|1[012]))?$/),
    contractId: z.string().uuid(),
  })
  .passthrough();
const payrollOutputsSection = z
  .object({ total: z.string(), elements: z.array(z.string()) })
  .passthrough();
const payrollElementMetaElement = z
  .object({
    type: z.enum(['deductions', 'earnings']),
    id: z.string(),
    name: z.string(),
    amount: z.string(),
  })
  .passthrough();
const payrollElementMetaBasis = z
  .object({ elements: z.array(payrollElementMetaElement), total: z.string() })
  .passthrough();
const payrollElementMetaDescription = z
  .object({
    label: z.string(),
    value: z.string(),
    valueType: z.enum(['money', 'percentage', 'days', 'raw', 'hours']),
  })
  .passthrough();
const payrollElementMetaCompensation = z
  .object({
    items: z.array(
      z.object({ label: z.string(), value: z.string() }).passthrough()
    ),
    total: z.string(),
  })
  .passthrough();
const payrollElementMetaBody = z.union([
  z
    .object({
      type: z.literal('proportion'),
      descriptions: z.array(payrollElementMetaDescription),
      expanded: z.string(),
      compensation: payrollElementMetaCompensation.optional(),
    })
    .passthrough(),
  z
    .object({
      type: z.literal('flatTable'),
      brackets: z.array(
        z
          .object({
            lowerBound: z.string(),
            upperBound: z.string(),
            rate: z.string(),
            isRateEffective: z.boolean(),
            totalInBracket: z.string(),
            calculationHint: z.string(),
          })
          .passthrough()
      ),
      total: z.string(),
      compensation: payrollElementMetaCompensation.optional(),
    })
    .passthrough(),
  z
    .object({
      type: z.literal('progressive'),
      brackets: z.array(
        z
          .object({
            lowerBound: z.string(),
            upperBound: z.string(),
            rate: z.string(),
            totalInBracket: z.string(),
            calculationHint: z.string(),
          })
          .passthrough()
      ),
      total: z.string(),
      compensation: payrollElementMetaCompensation.optional(),
    })
    .passthrough(),
  z
    .object({
      type: z.literal('progressiveTableWithDeduction'),
      brackets: z.array(
        z
          .object({
            lowerBound: z.string(),
            upperBound: z.string(),
            rate: z.string(),
            deduction: z.string(),
            isRateEffective: z.boolean(),
            totalInBracket: z.string(),
            calculationHint: z.string(),
          })
          .passthrough()
      ),
      total: z.string(),
      compensation: payrollElementMetaCompensation.optional(),
    })
    .passthrough(),
]);
const payrollElementAlert = z
  .object({ type: z.enum(['info', 'warning']), message: z.string().optional() })
  .passthrough();
const payrollElementMeta = z
  .object({
    explanation: z
      .object({
        basis: payrollElementMetaBasis,
        body: payrollElementMetaBody.optional(),
        message: z
          .object({ title: z.string(), description: z.string() })
          .partial()
          .passthrough()
          .optional(),
      })
      .passthrough(),
    alert: payrollElementAlert,
  })
  .partial()
  .passthrough();
const payrollElement = z
  .object({
    type: z.enum(['deductions', 'earnings']),
    id: z.string(),
    name: z.string(),
    amount: z.string(),
    meta: payrollElementMeta.optional(),
  })
  .passthrough();
const payrollOutputsTermination = z
  .object({
    paymentDeadline: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    paymentDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    terminationReason: z.string(),
    terminationDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    noticeDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    startedBy: z.enum(['employer', 'employee', 'both', 'other']),
    noticeIndemnityPayer: z.enum(['employer', 'employee', 'none']),
    noticeIndemnityType: z
      .enum(['indemnified', 'deducted', 'exempt', 'worked', 'other'])
      .optional(),
    indemnityDaysPaid: z.number().int(),
    noticeProjectedDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
  })
  .passthrough();
const payrollOutputsVacations = z
  .object({
    durationInDays: z.string(),
    paymentDeadline: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    accrualPeriod: z
      .object({
        startDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        endDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
      })
      .passthrough(),
    concessivePeriod: z
      .object({
        startDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        endDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
      })
      .passthrough()
      .optional(),
  })
  .passthrough();
const payrollOutputsOutputs = z
  .object({
    calculationDate: z.string().datetime({ offset: true }),
    netPay: payrollOutputsSection,
    totalCost: payrollOutputsSection,
    workerEarningsTotal: payrollOutputsSection,
    workerDeductionsTotal: payrollOutputsSection,
    result: z.string().optional(),
    elements: z.record(payrollElement),
    records: z.record(payrollRecord),
    termination: payrollOutputsTermination.optional(),
    vacations: payrollOutputsVacations.optional(),
  })
  .passthrough();
const payrollEntry = auditableVersionedEntry
  .and(payrollInput)
  .and(
    z
      .object({
        organizationId: z.string().uuid(),
        companyId: z.string().uuid(),
        legalEntityId: z.string().uuid().optional(),
        personId: z.string().uuid(),
        period: datePeriodFormat.regex(/^\d{4}(\-(0[1-9]|1[012]))?$/),
        contractId: z.string().uuid(),
        type: payrollTypes,
        payrollId: z.string().uuid(),
        status: payrollStatus,
        statusIndex: z.number().int(),
        date: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        paymentDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        errors: z.array(z.string()).optional(),
        outputs: payrollOutputsOutputs.optional(),
        workerData: payrollWorkerData,
      })
      .passthrough()
  );
const payrollOrderInput = z
  .object({
    type: payrollTypes,
    period: datePeriodFormat.regex(/^\d{4}(\-(0[1-9]|1[012]))?$/),
    inputs: payrollInputsInputs.optional(),
    allCompanies: z.boolean().optional(),
    list: z
      .array(
        z
          .object({
            companyId: z.string().uuid(),
            legalEntityId: z.string().uuid().optional(),
          })
          .passthrough()
      )
      .optional(),
  })
  .passthrough();
const payrollOrder = payrollOrderInput.and(
  z
    .object({
      organizationId: z.string().uuid(),
      orderId: z.string().uuid(),
      parentOrderId: z.string().uuid().optional(),
      principal: z.string().uuid().optional(),
      status: z.enum(['waiting', 'dispatching', 'failed', 'dispatched']),
      errorCode: z.string().optional(),
      errorMessage: z.string().optional(),
    })
    .passthrough()
);
const payrollOrderExecution = z
  .object({
    organizationId: z.string().uuid(),
    orderId: z.string().uuid(),
    companyId: z.string().uuid(),
    legalEntityId: z.string().uuid(),
    totals: z
      .object({ contracts: z.number(), success: z.number(), fails: z.number() })
      .partial()
      .passthrough()
      .optional(),
    status: z.enum(['pending', 'processing', 'completed']),
  })
  .passthrough();
const payrollOrderExecutionOutput = auditableVersionedEntry.and(
  payrollOrderExecution
);
const payrollOrderResult = z
  .object({
    organizationId: z.string().uuid(),
    orderId: z.string().uuid(),
    companyId: z.string().uuid(),
    legalEntityId: z.string().uuid(),
    contractId: z.string().uuid(),
    contractType: z.string().optional(),
    contractPersonId: z.string().uuid().optional(),
    contractPersonName: z.string().optional(),
    contractPersonMatricula: z.string().optional(),
    success: z.boolean(),
    errorCode: z.string().optional(),
    errorMessage: z.string().optional(),
  })
  .passthrough();
const payrollOrderResultOutput =
  auditableVersionedEntry.and(payrollOrderResult);
const payrollOrderOutput = payrollOrderInput
  .and(auditableVersionedEntry)
  .and(payrollOrder)
  .and(
    z
      .object({
        executions: z.array(payrollOrderExecutionOutput),
        results: z.array(payrollOrderResultOutput),
      })
      .partial()
      .passthrough()
  );
const BRRubricTipo = z.enum([
  'provento',
  'desconto',
  'informativa',
  'informativaDedutora',
]);
const BRRubricNatureza = z.string();
const BRRubricCodIncCP = z.enum([
  'naoEBaseDeCalculo',
  'naoEBaseDeCalculoAcordosInternacionais',
  'baseDeCalculoMensal',
  'baseDeCalculo13Salario',
  'baseExclusivaEmpregadorMensal',
  'baseExclusivaEmpregador13Salario',
  'baseExclusivaSeguradoMensal',
  'baseExclusivaSegurado13Salario',
  'salarioMaternidadeMensalEmpregador',
  'salarioMaternidade13SalarioEmpregador',
  'salarioMaternidadeMensalINSS',
  'salarioMaternidade13SalarioINSS',
  'contribuicaoDescontadaSeguradoMensal',
  'contribuicaoDescontadaSegurado13Salario',
  'contribuicaoDescontadaSeguradoSEST',
  'contribuicaoDescontadaSeguradoSENAT',
  'salarioFamilia',
]);
const BRRubricCodIncIRRF = z.enum([
  'verbaTransitadaFolhaPagamento',
  'remuneracaoMensal',
  'decimoTerceiro',
  'ferias',
  'PLR',
  'retencaoRemuneracaoMensal',
  'retencao13Salario',
  'retencaoFerias',
  'retencaoPLR',
  'deducaoPSORemuneracaoMensal',
  'deducaoPSO13Salario',
  'deducaoPSOFerias',
  'deducaoPrevidenciaComplementarSalarioMensal',
  'deducaoPrevidenciaComplementar13Salario',
  'deducaoPrevidenciaComplementarFerias',
  'deducaoPensaoAlimenticiaRemuneracaoMensal',
  'deducaoPensaoAlimenticia13Salario',
  'deducaoPensaoAlimenticiaFerias',
  'deducaoPensaoAlimenticiaPLR',
  'deducaoFAPIRemuneracaoMensal',
  'deducaoFAPI13Salario',
  'deducaoFundacaoPrevidenciaComplementarRemuneracaoMensal',
  'deducaoFundacaoPrevidenciaComplementar13Salario',
  'deducaoFundacaoPrevidenciaComplementarFerias',
  'deducaoFAPIFerias',
  'deducaoPlanoPrivadoColetivoAssistenciaSaude',
  'parcelaIsenta65AnosRemuneracaoMensal',
  'parcelaIsenta65Anos13Salario',
  'diarias',
  'ajudaDeCusto',
  'indenizacaoRescisaoContrato',
  'abonoPecuniario',
  'rendimentoMolestiaGraveAcidenteServicoRemuneracaoMensal',
  'rendimentoMolestiaGraveAcidenteServico13Salario',
  'auxilioMoradia',
  'valorServicoTransportePassageirosCargas',
  'outrasIsencoes',
]);
const BRRubricCodIncFGTS = z.enum([
  'naoEBaseDeCalculo',
  'baseDeCalculoMensal',
  'baseDeCalculo13Salario',
  'baseDeCalculoAvisoPrevioIndenizado',
]);
const BRRubricProcesso = z
  .object({
    nrProc: z.string(),
    tpProc: z.enum(['administrativo', 'judicial']).optional(),
    extDecisao: z
      .enum([
        'contribuicaoPrevidenciariaPatronal',
        'contribuicaoPrevidenciariaPatronalEDescontadaDoSegurado',
      ])
      .optional(),
    codSusp: z.string().optional(),
  })
  .passthrough();
const BRRubric = z
  .object({
    tipo: BRRubricTipo,
    natureza: BRRubricNatureza,
    codIncCP: BRRubricCodIncCP,
    codIncIRRF: BRRubricCodIncIRRF,
    codIncFGTS: BRRubricCodIncFGTS,
    incideNoCustoTotal: z.boolean().optional(),
    processoFGTS: BRRubricProcesso.optional(),
    processoIRRF: BRRubricProcesso.optional(),
    processoCP: BRRubricProcesso.optional(),
    observacao: z.string().min(1).max(255).optional(),
  })
  .passthrough();
const rubricsTableRubric = z
  .object({
    id: z.string(),
    validFrom: dateYYYYMM.regex(/^\d{4}\-(0[1-9]|1[012])$/),
    validTo: dateYYYYMM.regex(/^\d{4}\-(0[1-9]|1[012])$/).optional(),
    name: z.string(),
    description: z.string(),
    versionNumber: z.number().int().optional(),
    br: BRRubric,
  })
  .passthrough();
const rubricsTableEntry = z
  .object({
    organizationId: z.string().uuid(),
    companyId: z.string().uuid(),
    tableId: z.string(),
    rubrics: z.record(rubricsTableRubric),
  })
  .passthrough();
const rubricsTableMap = z
  .object({ tables: z.record(rubricsTableEntry) })
  .passthrough();
const rubricInput = z
  .object({
    idempotencyKey: z.string().optional(),
    validFrom: dateYYYYMM.regex(/^\d{4}\-(0[1-9]|1[012])$/),
    validTo: dateYYYYMM.regex(/^\d{4}\-(0[1-9]|1[012])$/).optional(),
    name: z.string(),
    description: z.string().min(1).max(100),
    br: BRRubric,
  })
  .passthrough();
const rubricEntry = z
  .object({
    organizationId: z.string().uuid(),
    companyId: z.string().uuid(),
    tableId: z.string(),
    id: z.string(),
    validFrom: dateYYYYMM.regex(/^\d{4}\-(0[1-9]|1[012])$/),
    validTo: dateYYYYMM.regex(/^\d{4}\-(0[1-9]|1[012])$/).optional(),
    name: z.string(),
    description: z.string().min(1).max(100),
    br: BRRubric,
  })
  .passthrough();
const rubricPatch = z
  .object({
    validFrom: dateYYYYMM.regex(/^\d{4}\-(0[1-9]|1[012])$/),
    validTo: dateYYYYMM.regex(/^\d{4}\-(0[1-9]|1[012])$/).optional(),
    name: z.string(),
    description: z.string().min(1).max(100),
    br: BRRubric,
    versionNumber: z.number().int(),
  })
  .passthrough();
const payrollUpdate = z
  .object({ inputs: payrollInputsInputs.optional(), version: z.number().int() })
  .passthrough();
const payrollChangePaymentDateInput = z
  .object({
    paymentDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    version: z.number().optional(),
  })
  .passthrough();
const recalculatePayroll_Body = z
  .object({
    dangerouslySkipSafeguard: z.boolean().default(false),
    tolerance: z.number().default(0),
  })
  .partial()
  .passthrough();
const payrollPayslipStatus = z.enum(['approved', 'sent', 'archived']);
const payrollPayslipEntry = auditableVersionedEntry.and(
  z
    .object({
      organizationId: z.string().uuid(),
      companyId: z.string().uuid(),
      legalEntityId: z.string().uuid().optional(),
      payrollId: z.string().uuid(),
      period: datePeriodFormat.regex(/^\d{4}(\-(0[1-9]|1[012]))?$/),
      contractId: z.string().uuid(),
      personId: z.string().uuid(),
      type: payrollTypes,
      status: payrollPayslipStatus,
      date: dateYYYYMMDD.regex(
        /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
      ),
      workerData: payrollWorkerData,
      inputs: payrollInputsInputs.optional(),
      outputs: payrollOutputsOutputs,
      payrollVersion: z.number(),
      approvedBy: z.string(),
      approvedAt: z.string().datetime({ offset: true }),
      metadata: z
        .object({
          sentAt: z.string().datetime({ offset: true }),
          sentBy: z.string(),
        })
        .partial()
        .passthrough()
        .optional(),
    })
    .passthrough()
);
const payrollPayslipPdf = z
  .object({ fileName: z.string(), fileContent: z.string() })
  .passthrough();
const payrollPeriodSummaryStatus = z.enum([
  'open',
  'approved',
  'closed',
  'archived',
]);
const payrollPeriodTotalizersSummary = z
  .object({
    counts: payrollPeriodTotalizersCounts,
    totals: z
      .object({
        netPay: z.string(),
        totalCost: z.string(),
        workerEarningsTotal: z.string(),
        workerDeductionsTotal: z.string(),
      })
      .passthrough(),
  })
  .passthrough();
const payrollPeriodSummary = z
  .object({
    organizationId: z.string().uuid(),
    companyId: z.string().uuid(),
    period: datePeriodFormat.regex(/^\d{4}(\-(0[1-9]|1[012]))?$/),
    status: payrollPeriodSummaryStatus,
    statusIndex: z.number().int(),
    active: z.boolean(),
    type: payrollTypes,
    totalizers: payrollPeriodTotalizersSummary,
  })
  .passthrough();
const payrollPayslipSummary = z
  .object({
    organizationId: z.string().uuid(),
    companyId: z.string().uuid(),
    payrollId: z.string().uuid(),
    payrollVersion: z.number(),
    period: datePeriodFormat.regex(/^\d{4}(\-(0[1-9]|1[012]))?$/),
    contractId: z.string().uuid(),
    personId: z.string().uuid(),
    type: payrollTypes,
    status: payrollPayslipStatus,
    statusIndex: z.number(),
    date: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    netPay: z.string(),
  })
  .passthrough();
const payrollPayslipList = z
  .object({
    data: z.array(payrollPayslipSummary),
    total: z.number(),
    size: z.number(),
    page: z.number(),
  })
  .passthrough();
const payrollPeriodSummaryEntry = auditableVersionedEntry.and(
  z
    .object({
      organizationId: z.string().uuid(),
      companyId: z.string().uuid(),
      period: datePeriodFormat.regex(/^\d{4}(\-(0[1-9]|1[012]))?$/),
      status: payrollPeriodSummaryStatus,
      statusIndex: z.number().int(),
      active: z.boolean(),
      type: payrollTypes,
      totalizers: payrollPeriodTotalizers,
    })
    .passthrough()
);
const payrollInputsTypes = z.enum(['event', 'element', 'rpa']);
const payrollInputsTargets = z.enum(['employee', 'dependent', 'rpa']);
const payrollInputsPayloadTypes = z.enum([
  'currency',
  'hours',
  'days',
  'percentage',
  'date',
  'dateRange',
  'number',
]);
const payrollInputsTags = z.enum([
  'earnings',
  'deductions',
  'informative',
  'events',
]);
const payrollInputsEntry = z
  .object({
    id: z.string(),
    type: payrollInputsTypes,
    label: z.string(),
    target: payrollInputsTargets,
    payloadType: payrollInputsPayloadTypes,
    tag: payrollInputsTags,
  })
  .passthrough();
const payrollInputsPayload = z.record(payrollInputsEntry);
const payrollInputsConfig = auditableVersionedEntry.and(
  z.object({ payload: payrollInputsPayload }).passthrough()
);
const payrollInputsUpdate = z
  .object({
    payload: z.record(
      z
        .object({
          id: z.string(),
          type: payrollInputsTypes,
          label: z.string(),
          target: payrollInputsTargets,
          payloadType: payrollInputsPayloadTypes,
          tag: payrollInputsTags,
        })
        .passthrough()
        .nullable()
    ),
    version: z.number(),
  })
  .passthrough();
const payrollInputsPayroll = z
  .object({
    contractId: z.string().uuid(),
    personId: z.string().uuid(),
    name: z.string(),
    employeeId: z.string(),
    dependents: z
      .record(
        z
          .object({
            nmDep: z.string(),
            tpDep: z.number(),
            inputs: z.record(z.string()),
          })
          .passthrough()
      )
      .optional(),
    inputs: z.record(z.string()).optional(),
  })
  .passthrough();
const payrollInputsList = z
  .object({
    organizationId: z.string().uuid(),
    companyId: z.string().uuid(),
    period: datePeriodFormat.regex(/^\d{4}(\-(0[1-9]|1[012]))?$/),
    type: payrollTypes,
    payrolls: z.record(payrollInputsPayroll),
  })
  .passthrough();
const payrollInputsSubmissionValues = z.record(z.string().nullable());
const payrollInputsSubmissionInput = z.record(
  z
    .object({
      inputs: payrollInputsSubmissionValues,
      dependents: z.record(payrollInputsSubmissionValues),
    })
    .partial()
    .passthrough()
);
const payrollInputsSubmissionEntry = z
  .object({ submissionId: z.string().uuid() })
  .passthrough();
const payrollInputsSubmissionStatus = z.enum([
  'processing',
  'success',
  'failure',
]);
const payrollInputsSubmissionDetails = z
  .object({
    status: payrollInputsSubmissionStatus,
    message: z.string().optional(),
  })
  .passthrough();
const conditionOr = z
  .object({
    type: z.literal('or'),
    conditions: z.array(z.object({}).partial().passthrough()),
  })
  .passthrough();
const conditionAnd = z
  .object({
    type: z.literal('and'),
    conditions: z.array(z.object({}).partial().passthrough()),
  })
  .passthrough();
const conditionFieldValue = z.union([z.string(), z.number()]);
const conditionCheck = z
  .object({
    type: z.literal('check'),
    field: z.string(),
    operator: z.enum([
      'eq',
      'neq',
      'gt',
      'gte',
      'lt',
      'lte',
      'between',
      'in',
      'nin',
    ]),
    value: conditionFieldValue.optional(),
    maxValue: conditionFieldValue.optional(),
    minValue: conditionFieldValue.optional(),
    values: z.array(conditionFieldValue).optional(),
  })
  .passthrough();
const conditionAlways = z.object({ type: z.literal('always') }).passthrough();
const condition = z.union([
  conditionOr,
  conditionAnd,
  conditionCheck,
  conditionAlways,
]);
const notificationToolkitTemplates = z.enum([
  'simpleAlert',
  'simpleAlertUnregistered',
  'vacationsApprovalDeadline',
  'newVacationsAdmin',
  'newVacationsWorker',
  'accrualPeriodCloseToLimitDateWorker',
  'accrualPeriodCloseToLimitDateAdmin',
  'vacationsApprovedAdmin',
  'vacationsApprovedWorker',
  'vacationsRejectedWorker',
  'vacationsCanceledWorker',
  'vacationNotice',
  'probationPeriodEndDateAdmin',
  'probationPeriodExtensionDateAdmin',
  'admissionReviewAdmin',
  'admissionDeadlineWorker',
  'admissionReviewDeadlineAdmin',
  'admissionDeadlineAdmin',
  'admissionWelcomeWorker',
]);
const notificationRecipient = z.union([
  z
    .object({
      type: z.literal('user'),
      userId: z.string().uuid(),
      template: notificationToolkitTemplates,
    })
    .passthrough(),
  z
    .object({
      type: z.enum(['employee', 'new-hire', 'manager']),
      template: notificationToolkitTemplates,
    })
    .passthrough(),
  z
    .object({
      type: z.enum([
        'employee',
        'manager',
        'membership:owner',
        'membership:internal',
        'membership:tako:support',
      ]),
      template: notificationToolkitTemplates,
    })
    .passthrough(),
  z
    .object({
      type: z.literal('unregistered'),
      template: notificationToolkitTemplates,
      email: z.string(),
    })
    .passthrough(),
]);
const notificationAction = z
  .object({
    type: z.literal('email'),
    subject: z.string().optional(),
    body: z.string().optional(),
    recipient: notificationRecipient,
  })
  .passthrough();
const notificationTiming = z.union([
  z.object({ when: z.literal('immediately') }).passthrough(),
  z.object({ when: z.literal('at'), referenceField: z.string() }).passthrough(),
  z
    .object({
      when: z.enum(['before', 'after']),
      referenceField: z.string(),
      seconds: z.number(),
      days: z.number(),
    })
    .passthrough(),
]);
const notificationConfigurationInput = z
  .object({
    ruleId: z.string(),
    condition: condition,
    name: z.string(),
    description: z.string(),
    tags: z.array(z.string()),
    actions: z.array(notificationAction),
    timings: z.array(notificationTiming),
  })
  .passthrough();
const notificationConfiguration = z
  .object({
    organizationId: z.string().uuid(),
    ruleId: z.string(),
    resourceType: z.string(),
    name: z.string(),
    description: z.string(),
    tags: z.array(z.string()).optional(),
    condition: condition,
    actions: z.array(notificationAction),
    timings: z.array(notificationTiming),
  })
  .passthrough();
const notificationConfigurationsList = z.array(notificationConfiguration);
const notificationTest = z.array(
  z.object({ ruleId: z.string(), ruleName: z.string() }).passthrough()
);
const pjPaymentRequestInvoiceBrTaxes = z
  .object({
    inss: z.string(),
    irrf: z.string(),
    csll: z.string(),
    cofins: z.string(),
    pisPasep: z.string(),
    others: z.string(),
    iss: z.string(),
  })
  .partial()
  .passthrough();
const pjPaymentRequestInvoice = z
  .object({
    number: z.number(),
    fileUploaded: z.boolean().optional(),
    taxes: z
      .object({ br: pjPaymentRequestInvoiceBrTaxes })
      .partial()
      .passthrough()
      .optional(),
  })
  .passthrough();
const pjPaymentRequestInput = z
  .object({
    contractId: z.string().uuid().optional(),
    grossAmount: z.string(),
    period: datePeriodFormat.regex(/^\d{4}(\-(0[1-9]|1[012]))?$/),
    description: z.string().optional(),
    invoice: pjPaymentRequestInvoice.optional(),
  })
  .passthrough();
const pjContractorData = z
  .object({
    name: z.string(),
    jobTitle: z.string().optional(),
    department: z.string().optional(),
  })
  .passthrough();
const pjPaymentRequestEntry = auditableVersionedEntry.and(
  z
    .object({
      id: z.string().uuid(),
      organizationId: z.string().uuid(),
      companyId: z.string().uuid(),
      contractId: z.string().uuid(),
      grossAmount: z.string(),
      taxesAmount: z.string(),
      netAmount: z.string(),
      period: datePeriodFormat.regex(/^\d{4}(\-(0[1-9]|1[012]))?$/),
      status: z.enum(['created', 'rejected', 'approved', 'deleted']),
      description: z.string().optional(),
      invoice: pjPaymentRequestInvoice.optional(),
      contractorData: pjContractorData.optional(),
      metadata: z
        .object({
          approvedBy: z.string(),
          approvedOn: z.string().datetime({ offset: true }),
          rejectedBy: z.string(),
          rejectedOn: z.string().datetime({ offset: true }),
          rejectionReason: z.string(),
          deletedOn: z.string().datetime({ offset: true }),
          deletedBy: z.string(),
        })
        .partial()
        .passthrough()
        .optional(),
    })
    .passthrough()
);
const pjPaymentRequestSummary = z
  .object({
    id: z.string().uuid(),
    organizationId: z.string().uuid(),
    companyId: z.string().uuid(),
    contractId: z.string().uuid(),
    grossAmount: z.string(),
    netAmount: z.string(),
    taxesAmount: z.string().optional(),
    period: datePeriodFormat.regex(/^\d{4}(\-(0[1-9]|1[012]))?$/),
    contractorData: pjContractorData.optional(),
    invoice: pjPaymentRequestInvoice.optional(),
    approvedOn: z.string().datetime({ offset: true }).optional(),
    createdOn: z.string().datetime({ offset: true }),
    rejectedOn: z.string().datetime({ offset: true }).optional(),
    invoiceUploaded: z.boolean(),
    active: z.boolean().optional(),
    status: z.enum(['created', 'rejected', 'approved', 'deleted']),
  })
  .passthrough();
const pjPaymentRequestList = paginatedResult
  .and(withMetadataResult)
  .and(z.object({ data: z.array(pjPaymentRequestSummary) }).passthrough());
const pjPaymentRequestInvoiceUpdate = z
  .object({
    number: z.number(),
    fileUploaded: z.boolean(),
    taxes: z
      .object({ br: pjPaymentRequestInvoiceBrTaxes })
      .partial()
      .passthrough(),
  })
  .partial()
  .passthrough();
const pjPaymentRequestUpdate = z
  .object({
    version: z.number().int(),
    grossAmount: z.string().optional(),
    description: z.string().optional(),
    invoice: pjPaymentRequestInvoiceUpdate.optional(),
  })
  .passthrough();
const pjPaymentAttachmentEntry = auditableVersionedEntry.and(
  z
    .object({
      id: z.string().uuid(),
      organizationId: z.string().uuid(),
      companyId: z.string().uuid(),
      paymentRequestId: z.string().uuid(),
      status: z.enum(['waitingUpload', 'uploaded', 'deleted']),
      type: z.enum(['invoice', 'invoiceCancel']),
      fileName: z.string().min(1).max(255),
      contentType: z.string().min(1).max(255),
      contentLength: z.number().int(),
      deletedOn: z.string().datetime({ offset: true }).optional(),
      deletedBy: z.string().uuid().optional(),
      downloadUrl: z.string().optional(),
      uploadUrl: z.string().optional(),
    })
    .passthrough()
);
const pjPaymentAttachmentList = z.array(pjPaymentAttachmentEntry);
const pjPaymentAttachmentAllowedContentTypes = z.enum([
  'application/pdf',
  'image/jpeg',
  'image/jpg',
  'image/png',
]);
const pjPaymentAttachmentInput = z
  .object({
    fileName: z.string().min(1).max(255),
    type: z.enum(['invoice', 'invoiceCancel']),
    contentType: pjPaymentAttachmentAllowedContentTypes,
    contentLength: z.number().int(),
  })
  .passthrough();
const orgChartContract = z
  .object({ contractId: z.string().uuid() })
  .passthrough();
const contract_types = z.union([z.enum(['pj', 'clt']), z.string()]).optional();
const contractBRCltGeralCodigoCategoria = z.union([
  z.literal(101),
  z.literal(102),
  z.literal(103),
  z.literal(104),
  z.literal(105),
  z.literal(106),
  z.literal(107),
  z.literal(108),
  z.literal(111),
  z.literal(201),
  z.literal(202),
  z.literal(301),
  z.literal(302),
  z.literal(303),
  z.literal(304),
  z.literal(305),
  z.literal(306),
  z.literal(307),
  z.literal(308),
  z.literal(309),
  z.literal(310),
  z.literal(311),
  z.literal(312),
  z.literal(313),
  z.literal(401),
  z.literal(410),
  z.literal(501),
  z.literal(721),
  z.literal(722),
  z.literal(723),
  z.literal(731),
  z.literal(734),
  z.literal(738),
  z.literal(741),
  z.literal(751),
  z.literal(761),
  z.literal(771),
  z.literal(781),
  z.literal(901),
  z.literal(902),
  z.literal(903),
  z.literal(904),
  z.literal(906),
]);
const contractBRCltAutonomoCodigoCategoria = z.union([
  z.literal(701),
  z.literal(711),
  z.literal(712),
]);
const contractBRCltCodigoCategoria = z.union([
  contractBRCltGeralCodigoCategoria,
  contractBRCltAutonomoCodigoCategoria,
]);
const customFieldBaseProperties = z
  .object({
    name: z.string().min(1),
    label: z.string(),
    contractTypes: z
      .object({
        pj: z.boolean(),
        clt: z.union([z.boolean(), z.array(contractBRCltCodigoCategoria)]),
      })
      .passthrough(),
    visibility: z
      .object({
        app: z
          .object({
            contract: z.boolean(),
            admission: z.boolean(),
            'customer-api': z.boolean(),
          })
          .passthrough(),
        user: z
          .object({
            employee: z.boolean(),
            admin: z.boolean(),
            support: z.boolean(),
          })
          .passthrough(),
      })
      .passthrough(),
    admissionRegisteredBy: z
      .object({ worker: z.boolean(), admin: z.boolean() })
      .passthrough(),
  })
  .passthrough();
const customFieldSelectOption = z
  .object({ value: z.string(), label: z.string().optional() })
  .passthrough();
const customFieldDefinition = customFieldBaseProperties.and(
  z.union([
    z
      .object({ type: z.literal('text'), mask: z.string().optional() })
      .passthrough(),
    z.object({ type: z.literal('longtext') }).passthrough(),
    z.object({ type: z.literal('checkbox') }).passthrough(),
    z
      .object({
        type: z.literal('date'),
        startDate: dateYYYYMMDD
          .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
          .optional(),
        endDate: dateYYYYMMDD
          .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
          .optional(),
      })
      .passthrough(),
    z.object({ type: z.literal('link') }).passthrough(),
    z
      .object({
        type: z.literal('multiselect'),
        options: z.array(customFieldSelectOption).min(1).max(100),
      })
      .passthrough(),
    z
      .object({
        type: z.literal('number'),
        isInteger: z.boolean().optional(),
        min: z.number().optional(),
        max: z.number().optional(),
      })
      .passthrough(),
    z
      .object({
        type: z.literal('select'),
        options: z.array(customFieldSelectOption).min(1).max(100),
      })
      .passthrough(),
  ])
);
const customFieldEntry = customFieldDefinition
  .and(auditableVersionedEntry)
  .and(z.object({ organizationId: z.string().uuid() }).passthrough());
const customFieldUpdateBaseProperties = z
  .object({
    version: z.number(),
    label: z.string().optional(),
    contractTypes: z
      .object({
        pj: z.boolean(),
        clt: z.union([z.boolean(), z.array(contractBRCltCodigoCategoria)]),
      })
      .partial()
      .passthrough()
      .optional(),
    visibility: z
      .object({
        app: z
          .object({
            contract: z.boolean(),
            admission: z.boolean(),
            'customer-api': z.boolean().optional(),
          })
          .passthrough(),
        user: z
          .object({
            employee: z.boolean(),
            admin: z.boolean(),
            support: z.boolean(),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough()
      .optional(),
    admissionRegisteredBy: z
      .object({ worker: z.boolean(), admin: z.boolean() })
      .partial()
      .passthrough()
      .optional(),
  })
  .passthrough();
const customFieldUpdateInput = customFieldUpdateBaseProperties.and(
  z.union([
    z
      .object({
        startDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        endDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
      })
      .partial()
      .passthrough(),
    z.object({ mask: z.string() }).partial().passthrough(),
    z
      .object({ options: z.array(customFieldSelectOption).min(1).max(100) })
      .partial()
      .passthrough(),
    z
      .object({ isInteger: z.boolean(), min: z.number(), max: z.number() })
      .partial()
      .passthrough(),
    z
      .object({ options: z.array(customFieldSelectOption).min(1).max(100) })
      .partial()
      .passthrough(),
  ])
);
const workerProvisionsInput = z
  .object({
    balanceTransfers: z.record(
      z.array(
        z
          .object({
            type: z.enum(['deposit', 'withdrawal']),
            amount: z.string(),
            description: z.string(),
          })
          .passthrough()
      )
    ),
  })
  .passthrough();
const pjPaymentRequestApprove = z.object({ version: z.number() }).passthrough();
const pjPaymentRequestRejection = z
  .object({ reason: z.string(), version: z.number() })
  .passthrough();
const contractCustomFields = z.record(
  z.union([z.string(), z.number(), z.boolean(), z.array(z.string())])
);
const contractInputBody = z
  .object({
    companyId: z.string().uuid(),
    legalEntityId: z.string().uuid(),
    personId: z.string().uuid().optional(),
    workerId: z.string().min(1).max(30).optional(),
    customFields: contractCustomFields.optional(),
  })
  .passthrough();
const contractBRPjEntryPessoa = z
  .object({
    cpfTrab: z.string().min(11).max(11),
    nmTrab: z.string().min(2).max(70),
    nmSoc: z.string().min(2).max(70).optional(),
  })
  .passthrough();
const BRPaises = z.union([
  z.literal(8),
  z.literal(9),
  z.literal(13),
  z.literal(15),
  z.literal(17),
  z.literal(20),
  z.literal(23),
  z.literal(25),
  z.literal(31),
  z.literal(37),
  z.literal(40),
  z.literal(41),
  z.literal(42),
  z.literal(43),
  z.literal(47),
  z.literal(53),
  z.literal(59),
  z.literal(63),
  z.literal(64),
  z.literal(65),
  z.literal(69),
  z.literal(72),
  z.literal(73),
  z.literal(77),
  z.literal(80),
  z.literal(81),
  z.literal(83),
  z.literal(85),
  z.literal(87),
  z.literal(88),
  z.literal(90),
  z.literal(93),
  z.literal(97),
  z.literal(98),
  z.literal(99),
  z.literal(100),
  z.literal(101),
  z.literal(102),
  z.literal(105),
  z.literal(106),
  z.literal(108),
  z.literal(111),
  z.literal(115),
  z.literal(119),
  z.literal(127),
  z.literal(131),
  z.literal(137),
  z.literal(141),
  z.literal(145),
  z.literal(149),
  z.literal(150),
  z.literal(151),
  z.literal(152),
  z.literal(153),
  z.literal(154),
  z.literal(158),
  z.literal(160),
  z.literal(161),
  z.literal(163),
  z.literal(165),
  z.literal(169),
  z.literal(173),
  z.literal(177),
  z.literal(183),
  z.literal(187),
  z.literal(190),
  z.literal(193),
  z.literal(195),
  z.literal(196),
  z.literal(198),
  z.literal(199),
  z.literal(200),
  z.literal(229),
  z.literal(232),
  z.literal(235),
  z.literal(237),
  z.literal(239),
  z.literal(240),
  z.literal(243),
  z.literal(244),
  z.literal(245),
  z.literal(246),
  z.literal(247),
  z.literal(249),
  z.literal(251),
  z.literal(253),
  z.literal(255),
  z.literal(259),
  z.literal(263),
  z.literal(267),
  z.literal(271),
  z.literal(275),
  z.literal(281),
  z.literal(285),
  z.literal(289),
  z.literal(291),
  z.literal(292),
  z.literal(293),
  z.literal(297),
  z.literal(301),
  z.literal(305),
  z.literal(309),
  z.literal(313),
  z.literal(317),
  z.literal(321),
  z.literal(325),
  z.literal(329),
  z.literal(331),
  z.literal(334),
  z.literal(337),
  z.literal(341),
  z.literal(343),
  z.literal(345),
  z.literal(351),
  z.literal(355),
  z.literal(357),
  z.literal(358),
  z.literal(359),
  z.literal(361),
  z.literal(365),
  z.literal(367),
  z.literal(369),
  z.literal(372),
  z.literal(375),
  z.literal(379),
  z.literal(383),
  z.literal(386),
  z.literal(388),
  z.literal(391),
  z.literal(393),
  z.literal(395),
  z.literal(396),
  z.literal(399),
  z.literal(403),
  z.literal(411),
  z.literal(420),
  z.literal(423),
  z.literal(426),
  z.literal(427),
  z.literal(431),
  z.literal(434),
  z.literal(438),
  z.literal(440),
  z.literal(442),
  z.literal(445),
  z.literal(447),
  z.literal(449),
  z.literal(450),
  z.literal(452),
  z.literal(455),
  z.literal(458),
  z.literal(461),
  z.literal(464),
  z.literal(467),
  z.literal(472),
  z.literal(474),
  z.literal(476),
  z.literal(477),
  z.literal(485),
  z.literal(488),
  z.literal(489),
  z.literal(490),
  z.literal(493),
  z.literal(494),
  z.literal(495),
  z.literal(497),
  z.literal(498),
  z.literal(499),
  z.literal(501),
  z.literal(505),
  z.literal(507),
  z.literal(508),
  z.literal(511),
  z.literal(517),
  z.literal(521),
  z.literal(525),
  z.literal(528),
  z.literal(531),
  z.literal(535),
  z.literal(538),
  z.literal(542),
  z.literal(545),
  z.literal(548),
  z.literal(551),
  z.literal(556),
  z.literal(563),
  z.literal(566),
  z.literal(569),
  z.literal(573),
  z.literal(575),
  z.literal(576),
  z.literal(578),
  z.literal(580),
  z.literal(583),
  z.literal(586),
  z.literal(589),
  z.literal(593),
  z.literal(599),
  z.literal(603),
  z.literal(607),
  z.literal(611),
  z.literal(623),
  z.literal(625),
  z.literal(628),
  z.literal(640),
  z.literal(647),
  z.literal(660),
  z.literal(665),
  z.literal(670),
  z.literal(675),
  z.literal(676),
  z.literal(677),
  z.literal(678),
  z.literal(685),
  z.literal(687),
  z.literal(690),
  z.literal(691),
  z.literal(693),
  z.literal(695),
  z.literal(697),
  z.literal(698),
  z.literal(699),
  z.literal(700),
  z.literal(705),
  z.literal(710),
  z.literal(715),
  z.literal(720),
  z.literal(728),
  z.literal(731),
  z.literal(735),
  z.literal(737),
  z.literal(738),
  z.literal(741),
  z.literal(744),
  z.literal(748),
  z.literal(750),
  z.literal(754),
  z.literal(755),
  z.literal(756),
  z.literal(759),
  z.literal(760),
  z.literal(764),
  z.literal(767),
  z.literal(770),
  z.literal(772),
  z.literal(776),
  z.literal(780),
  z.literal(781),
  z.literal(782),
  z.literal(783),
  z.literal(785),
  z.literal(788),
  z.literal(790),
  z.literal(791),
  z.literal(795),
  z.literal(800),
  z.literal(805),
  z.literal(810),
  z.literal(815),
  z.literal(820),
  z.literal(823),
  z.literal(824),
  z.literal(827),
  z.literal(828),
  z.literal(831),
  z.literal(833),
  z.literal(840),
  z.literal(845),
  z.literal(847),
  z.literal(848),
  z.literal(850),
  z.literal(855),
  z.literal(858),
  z.literal(863),
  z.literal(866),
  z.literal(870),
  z.literal(873),
  z.literal(875),
  z.literal(888),
  z.literal(890),
  z.literal(895),
]);
const contractBRPjEntryNascimento = z
  .object({ dtNascto: z.string(), paisNascto: BRPaises, paisNac: BRPaises })
  .partial()
  .passthrough();
const contractBRPjEntryContato = z
  .object({
    fonePrinc: z.string().min(8).max(13),
    emailPrinc: z.string().min(6).max(60).email(),
  })
  .partial()
  .passthrough();
const contractBRContatoDeEmergencia = z
  .object({
    nome: z.string().min(1).max(255),
    telefone: z.string().min(8).max(13),
    relacao: z.string().min(1).max(255),
  })
  .passthrough();
const BRTipoLogradouro = z.enum([
  'A',
  'AC',
  'ACA',
  'ACL',
  'AD',
  'AE',
  'AER',
  'AL',
  'ALD',
  'AMD',
  'AME',
  'AN',
  'ANT',
  'ART',
  'AT',
  'ATL',
  'A V',
  'AV',
  'AVC',
  'AVM',
  'AVV',
  'BAL',
  'BC',
  'BCO',
  'BEL',
  'BL',
  'BLO',
  'BLS',
  'BLV',
  'BSQ',
  'BVD',
  'BX',
  'C',
  'CAL',
  'CAM',
  'CAN',
  'CH',
  'CHA',
  'CIC',
  'CIR',
  'CJ',
  'CJM',
  'CMP',
  'COL',
  'COM',
  'CON',
  'COND',
  'COR',
  'CPO',
  'CRG',
  'CTN',
  'DSC',
  'DSV',
  'DT',
  'EB',
  'EIM',
  'ENS',
  'ENT',
  'EQ',
  'ESC',
  'ESD',
  'ESE',
  'ESI',
  'ESL',
  'ESM',
  'ESP',
  'ESS',
  'EST',
  'ESV',
  'ETA',
  'ETC',
  'ETD',
  'ETN',
  'ETP',
  'ETT',
  'EVA',
  'EVD',
  'EX',
  'FAV',
  'FAZ',
  'FER',
  'FNT',
  'FRA',
  'FTE',
  'GAL',
  'GJA',
  'HAB',
  'IA',
  'IGP',
  'IND',
  'IOA',
  'JD',
  'JDE',
  'LD',
  'LGA',
  'LGO',
  'LOT',
  'LRG',
  'LT',
  'MER',
  'MNA',
  'MOD',
  'MRG',
  'MRO',
  'MTE',
  'NUC',
  'NUR',
  'O',
  'OUT',
  'PAR',
  'PAS',
  'PAT',
  'PC',
  'PCE',
  'PDA',
  'PDO',
  'PNT',
  'PR',
  'PRL',
  'PRM',
  'PRQ',
  'PRR',
  'PSA',
  'PSG',
  'PSP',
  'PSS',
  'PTE',
  'PTO',
  'Q',
  'QTA',
  'QTS',
  'R',
  'R I',
  'R L',
  'R P',
  'R V',
  'RAM',
  'RCR',
  'REC',
  'RER',
  'RES',
  'RET',
  'RLA',
  'RMP',
  'ROA',
  'ROD',
  'ROT',
  'RPE',
  'RPR',
  'RTN',
  'RTT',
  'SEG',
  'SIT',
  'SRV',
  'ST',
  'SUB',
  'TCH',
  'TER',
  'TR',
  'TRV',
  'TUN',
  'TV',
  'TVP',
  'TVV',
  'UNI',
  'V',
  'V C',
  'V L',
  'VAC',
  'VAL',
  'VCO',
  'VD',
  'V-E',
  'VER',
  'VEV',
  'VL',
  'VLA',
  'VLE',
  'VLT',
  'VPE',
  'VRT',
  'ZIG',
]);
const BREstados = z.enum([
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
]);
const contractBRPjAddress = z
  .object({
    tipo: z.literal('brasil'),
    tpLograd: BRTipoLogradouro.optional(),
    dscLograd: z.string().min(1).max(100),
    nrLograd: z.string().min(1).max(10).optional(),
    complemento: z.string().min(1).max(30).optional(),
    bairro: z.string().min(1).max(90).optional(),
    cep: z.string().min(8).max(8),
    codMunic: z.number().gte(1000000).lte(9999999),
    uf: BREstados,
  })
  .passthrough();
const contractBRPjEntryEndereco = z.union([
  contractBRPjAddress,
  z
    .object({
      tipo: z.literal('exterior'),
      paisResid: BRPaises,
      dscLograd: z.string().min(1).max(100),
      nrLograd: z.string().min(1).max(10),
      complemento: z.string().min(1).max(30).optional(),
      bairro: z.string().min(1).max(90).optional(),
      nmCid: z.string().min(2).max(50),
      codPostal: z.string().min(4).max(12).optional(),
    })
    .passthrough(),
]);
const contractBRPjEntryDependente = z
  .object({
    nmDep: z.string().min(2).max(70),
    dtNascto: z.string().optional(),
    cpfDep: z.string().min(11).max(11).optional(),
    sexoDep: z.enum(['M', 'F']).optional(),
    descricao: z.string().max(255).optional(),
  })
  .passthrough();
const contractBRPjEntryGestao = z
  .object({
    costCenterId: z.string(),
    nomeCentroCusto: z.string().min(1).max(99),
    codigoCentroCusto: z.string().min(1).max(50),
  })
  .partial()
  .passthrough();
const contractBRPjEntryPosicao = z
  .object({
    jobTitleId: z.string().uuid(),
    nmFuncao: z.string().min(1).max(100).optional(),
    departamento: z.string().min(1).max(100),
  })
  .passthrough();
const contractBRPjEntryPrestador = z
  .object({
    gestao: contractBRPjEntryGestao.optional(),
    posicao: contractBRPjEntryPosicao,
  })
  .passthrough();
const contractBRPjEntryEmpresa = z
  .object({
    razaoSocial: z.string().min(1).max(100),
    cnpj: z.string().min(14).max(14),
    cnae: z.string().min(7).max(7),
    enquadramentoTributario: z.enum(['simples', 'lucroPresumido']),
    endereco: contractBRPjAddress.optional(),
  })
  .passthrough();
const BRTipoPix = z.enum(['cpf', 'cnpj', 'email', 'celular', 'aleatoria']);
const contractBRPjEntryPagamento = z
  .object({
    honorarios: z.number(),
    periodicidade: z.unknown(),
    codigoBanco: z.string().min(1).max(14).optional(),
    agencia: z.string().min(1).max(10).optional(),
    conta: z.string().min(1).max(20).optional(),
    chavePix: z.string().min(5).max(99).optional(),
    tipoPix: BRTipoPix.optional(),
  })
  .passthrough();
const contractBRPjEntryContrato = z
  .object({
    dataAssinatura: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    renovacao: z.enum(['automatica', 'manual']).optional(),
    inicio: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    termino: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    validade: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
  })
  .passthrough();
const contractBRPjCreate = z
  .object({
    emailCorp: z.string().min(6).max(60).email().optional(),
    pessoa: contractBRPjEntryPessoa,
    nascimento: contractBRPjEntryNascimento.optional(),
    contato: contractBRPjEntryContato.optional(),
    contatoDeEmergencia: contractBRContatoDeEmergencia.optional(),
    endereco: contractBRPjEntryEndereco.optional(),
    dependentes: z.array(contractBRPjEntryDependente).max(99).optional(),
    prestador: contractBRPjEntryPrestador,
    empresa: contractBRPjEntryEmpresa,
    pagamento: contractBRPjEntryPagamento,
    contrato: contractBRPjEntryContrato,
  })
  .passthrough();
const contractBRCltSexo = z.enum(['M', 'F']);
const contractBRCltRacaCor = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
]);
const contractBRCltEstadoCivil = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
]);
const contractBRCltGrauInstrucao = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
  z.literal(6),
  z.literal(7),
  z.literal(8),
  z.literal(9),
  z.literal(10),
  z.literal(11),
  z.literal(12),
]);
const contractBRCltEntryPessoa = z
  .object({
    cpfTrab: z.string().min(11).max(11),
    nmTrab: z.string().min(2).max(70),
    nmSoc: z.string().min(2).max(70).optional(),
    nmMae: z.string().min(2).max(70).optional(),
    sexo: contractBRCltSexo,
    racaCor: contractBRCltRacaCor,
    estCiv: contractBRCltEstadoCivil.optional(),
    grauInstr: contractBRCltGrauInstrucao,
  })
  .passthrough();
const contractBRCltTempoResidencia = z.union([z.literal(1), z.literal(2)]);
const contractBRCltCondicaoIngresso = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
  z.literal(6),
  z.literal(7),
]);
const contractBRCltEntryNascimento = z
  .object({
    dtNascto: z.string(),
    paisNascto: BRPaises,
    paisNac: BRPaises,
    tmpResid: contractBRCltTempoResidencia.optional(),
    condIng: contractBRCltCondicaoIngresso.optional(),
  })
  .passthrough();
const contractBRCltAddress = z
  .object({
    tipo: z.literal('brasil'),
    tpLograd: BRTipoLogradouro.optional(),
    dscLograd: z.string().min(1).max(100),
    nrLograd: z.string().min(1).max(10).optional(),
    complemento: z.string().min(1).max(30).optional(),
    bairro: z.string().min(1).max(90).optional(),
    cep: z.string().min(8).max(8),
    codMunic: z.number().gte(1000000).lte(9999999),
    uf: BREstados,
  })
  .passthrough();
const contractBRCltForeignAddress = z
  .object({
    tipo: z.literal('exterior'),
    paisResid: BRPaises,
    dscLograd: z.string().min(1).max(100),
    nrLograd: z.string().min(1).max(10),
    complemento: z.string().min(1).max(30).optional(),
    bairro: z.string().min(1).max(90).optional(),
    nmCid: z.string().min(2).max(50),
    codPostal: z.string().min(4).max(12).optional(),
  })
  .passthrough();
const contractBRCltEntryEndereco = z.union([
  contractBRCltAddress,
  contractBRCltForeignAddress,
]);
const contractBRCltEntryDeficiencia = z
  .object({
    defFisica: z.boolean(),
    defVisual: z.boolean(),
    defAuditiva: z.boolean(),
    defMental: z.boolean(),
    defIntelectual: z.boolean(),
    reabReadap: z.boolean(),
    infoCota: z.boolean().optional(),
    observacao: z.string().min(1).max(255).optional(),
  })
  .passthrough();
const contractBRCltEntryContato = z
  .object({
    fonePrinc: z.string().min(8).max(13),
    emailPrinc: z.string().min(6).max(60).email(),
  })
  .partial()
  .passthrough();
const contractBRCltTipoDependente = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(6),
  z.literal(7),
  z.literal(9),
  z.literal(10),
  z.literal(11),
  z.literal(12),
  z.literal(99),
]);
const contractBRCltDependent = z
  .object({
    tpDep: contractBRCltTipoDependente,
    nmDep: z.string().min(2).max(70),
    dtNascto: z.string(),
    cpfDep: z.string().min(11).max(11),
    sexoDep: contractBRCltSexo,
    incTrab: z.boolean(),
    depIRRF: z.boolean(),
    depSF: z.boolean(),
    descrDep: z.string().optional(),
    id: z.string().uuid().optional(),
  })
  .passthrough();
const contractBRCltEntryTrabalho = z
  .object({
    codCateg: contractBRCltCodigoCategoria,
    jobTitleId: z.string().uuid(),
    nmFuncao: z.string().min(1).max(100).optional(),
    CBOFuncao: z.string().min(6).max(6).optional(),
    departamento: z.string().min(1).max(100),
  })
  .passthrough();
const contractBRCltNaturezaEstagio = z.enum(['O', 'N']);
const contractBRCltNivelEstagio = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(8),
  z.literal(9),
]);
const contractBRCltEntryEstagio = z
  .object({
    natEstagio: contractBRCltNaturezaEstagio,
    nivEstagio: contractBRCltNivelEstagio.optional(),
    areaAtuacao: z.string().min(1).max(100).optional(),
    dtPrevTerm: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    nrApol: z.string().min(1).max(30).optional(),
    cnpjAgntInteg: z.string().min(14).max(14).optional(),
    cpfSupervisor: z.string().min(11).max(11).optional(),
    instEnsino: z
      .object({ cnpjInstEnsino: z.string().min(14).max(14) })
      .passthrough(),
  })
  .passthrough();
const contractBRCltTipoRegimeTrabalhista = z.union([
  z.literal(1),
  z.literal(2),
]);
const contractBRCltTipoRegimePrevidenciario = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
]);
const contractBRCltEntryVinculo = z
  .object({
    matricula: z.string().min(1).max(30),
    tpRegTrab: contractBRCltTipoRegimeTrabalhista,
    tpRegPrev: contractBRCltTipoRegimePrevidenciario,
    emailCorp: z.string().min(6).max(60).email(),
    nrProcJud: z.string().min(20).max(20),
  })
  .partial()
  .passthrough();
const contractBRCltTipoAdmissao = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
  z.literal(6),
  z.literal(7),
]);
const contractBRCltIndAdmissao = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
]);
const contractBRCltTipoRegimeJornada = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
]);
const contractBRCltNaturezaAtividade = z.union([z.literal(1), z.literal(2)]);
const contractBRCltDataBase = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
  z.literal(6),
  z.literal(7),
  z.literal(8),
  z.literal(9),
  z.literal(10),
  z.literal(11),
  z.literal(12),
]);
const contractBRCltEntryRegime = z
  .object({
    dtAdm: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    tpAdmissao: contractBRCltTipoAdmissao.optional(),
    indAdmissao: contractBRCltIndAdmissao.optional(),
    nrProcTrab: z.string().min(20).max(20).optional(),
    tpRegJor: contractBRCltTipoRegimeJornada.optional(),
    natAtividade: contractBRCltNaturezaAtividade.optional(),
    dtBase: contractBRCltDataBase.optional(),
    cnpjSindCategProf: z.string().min(0).max(14).optional(),
    dtOpcFGTS: z.string().optional(),
  })
  .passthrough();
const contractBRCltTipoJornada = z.union([
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
  z.literal(6),
  z.literal(7),
  z.literal(9),
]);
const contractBRCltContratoRegimeParcial = z.union([
  z.literal(0),
  z.literal(1),
  z.literal(2),
  z.literal(3),
]);
const contractBRCltEntryJornada = z
  .object({
    qtdHrsSem: z.number().gte(0).lte(9999.99).optional(),
    tpJornada: contractBRCltTipoJornada,
    tmpParc: contractBRCltContratoRegimeParcial,
    horNoturno: z.boolean().optional(),
    descJorn: z.string().min(1).max(999),
  })
  .passthrough();
const contractBRCltUnidadeSalarioFixo = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
  z.literal(6),
  z.literal(7),
]);
const agreementTypes = z.enum(['acordoColetivo', 'convencao', 'dissidio']);
const contractBRCltEntryRemuneracao = z
  .object({
    vrSalFx: z.number().gte(0).lte(99999999999999.98).multipleOf(0.01),
    undSalFixo: contractBRCltUnidadeSalarioFixo,
    dscSalVar: z.string().min(1).max(999).optional(),
    acordoSalario: z
      .object({
        id: z.string().uuid(),
        data: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        tipo: agreementTypes,
        dataBase: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        periodoPagamentoDiferencaSalario: datePeriodFormat
          .regex(/^\d{4}(\-(0[1-9]|1[012]))?$/)
          .optional(),
        dataPagamentoRescisaoComplementar: dateYYYYMMDD
          .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
          .optional(),
        explicacaoReajuste: z.array(z.string()).optional(),
      })
      .passthrough()
      .optional(),
  })
  .passthrough();
const contractBRCltTipoContrato = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
]);
const contractBRCltEntryDuracao = z
  .object({
    tpContr: contractBRCltTipoContrato,
    dtTerm: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    dtPerExpTerm: z
      .string()
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .nullish(),
    dtPerExpProrTerm: z
      .string()
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .nullish(),
    clauAssec: z.boolean().optional(),
    objDet: z.string().min(1).max(255).optional(),
  })
  .passthrough();
const contractBRCltTipoInsc = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(5),
  z.literal(6),
]);
const contractBRCltEntrySucessao = z
  .object({
    tpInsc: contractBRCltTipoInsc,
    nrInsc: z.string().min(8).max(14),
    matricAnt: z.string().min(1).max(30).optional(),
    dtTransf: z.string(),
  })
  .passthrough();
const contractBRCltEntryPagamento = z
  .object({
    codigoBanco: z.string().min(1).max(14),
    nomeBanco: z.string().min(1).max(99),
    agencia: z.string().min(1).max(10),
    conta: z.string().min(1).max(20),
    chavePix: z.string().min(5).max(99),
    tipoPix: BRTipoPix,
  })
  .partial()
  .passthrough();
const contractBRCltEntryGestao = z
  .object({
    costCenterId: z.string(),
    nomeCentroCusto: z.string().min(1).max(99),
    codigoCentroCusto: z.string().min(1).max(50),
  })
  .partial()
  .passthrough();
const contractBRCltEntryAprendizDireto = z
  .object({
    indAprend: z.literal('direta'),
    cnpjEntQual: z.string().min(14).max(14),
    cnpjPrat: z.string().min(14).max(14).optional(),
  })
  .passthrough();
const contractBRCltEntryAprendizIndireto = z
  .object({
    indAprend: z.literal('indireta'),
    tpInsc: z.enum(['cpf', 'cnpj']),
    nrInsc: z.string().min(11).max(14),
    cnpjPrat: z.string().min(14).max(14).optional(),
  })
  .passthrough();
const contractBRCltEntryAprendiz = z.union([
  contractBRCltEntryAprendizDireto,
  contractBRCltEntryAprendizIndireto,
]);
const contractBRCltEsocialNrRecibo = z
  .object({ nrRecibo: z.string() })
  .passthrough();
const contractBRCltEntryOptions = z
  .object({ adiantamentoSalarial: z.boolean().nullable() })
  .partial()
  .passthrough();
const contractBRCltCreate = z
  .object({
    pessoa: contractBRCltEntryPessoa,
    nascimento: contractBRCltEntryNascimento,
    endereco: contractBRCltEntryEndereco,
    deficiencia: contractBRCltEntryDeficiencia,
    contato: contractBRCltEntryContato.optional(),
    contatoDeEmergencia: contractBRContatoDeEmergencia.optional(),
    dependentes: z.array(contractBRCltDependent).max(99).optional(),
    trabalho: contractBRCltEntryTrabalho,
    estagio: contractBRCltEntryEstagio.optional(),
    vinculo: contractBRCltEntryVinculo.optional(),
    regime: contractBRCltEntryRegime,
    jornada: contractBRCltEntryJornada.optional(),
    remuneracao: contractBRCltEntryRemuneracao.optional(),
    duracao: contractBRCltEntryDuracao.optional(),
    sucessao: contractBRCltEntrySucessao.optional(),
    pagamento: contractBRCltEntryPagamento.optional(),
    gestao: contractBRCltEntryGestao.optional(),
    aprendiz: contractBRCltEntryAprendiz.optional(),
    observacao: z.string().min(1).max(255).optional(),
    eSocial: contractBRCltEsocialNrRecibo.optional(),
    options: contractBRCltEntryOptions.optional(),
  })
  .passthrough();
const contractInput = contractInputBody.and(
  z.union([
    z
      .object({ contractType: z.literal('br:pj'), br: contractBRPjCreate })
      .passthrough(),
    z
      .object({ contractType: z.literal('br:clt'), br: contractBRCltCreate })
      .passthrough(),
  ])
);
const effectiveStartDate = z
  .object({
    effectiveStartDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
  })
  .passthrough();
const contractStatus = z.enum([
  'active',
  'onAdmission',
  'onLeave',
  'terminated',
  'cancelled',
]);
const contractOrgStructure = z
  .object({ manager: z.string().uuid(), reports: z.array(z.string()) })
  .partial()
  .passthrough();
const contractBRCltCodigoMotivoAfastamentoDoencaOuAcidente = z.union([
  z.literal(1),
  z.literal(3),
]);
const contractBRCltCodigoMotivoAfastamentoMaternidade = z.union([
  z.literal(17),
  z.literal(18),
  z.literal(19),
  z.literal(20),
  z.literal(35),
]);
const contractBRCltCodigoMotivoAfastamentoProrrogacaoMaternidade = z.union([
  z.literal(18),
  z.literal(35),
]);
const contractBRCltCodigoMotivoAfastamentoFeriasOuRecesso = z.literal(15);
const contractBRCltCodigoMotivoAfastamentoOutrosMotivos = z.union([
  z.literal(5),
  z.literal(6),
  z.literal(7),
  z.literal(8),
  z.literal(10),
  z.literal(11),
  z.literal(12),
  z.literal(13),
  z.literal(14),
  z.literal(16),
  z.literal(21),
  z.literal(22),
  z.literal(23),
  z.literal(24),
  z.literal(25),
  z.literal(26),
  z.literal(27),
  z.literal(28),
  z.literal(29),
  z.literal(30),
  z.literal(31),
  z.literal(33),
  z.literal(34),
  z.literal(36),
  z.literal(37),
  z.literal(38),
  z.literal(39),
  z.literal(40),
  z.literal(41),
  z.literal(42),
]);
const contractBRCltCodigoMotivoAfastamento = z.union([
  contractBRCltCodigoMotivoAfastamentoDoencaOuAcidente,
  contractBRCltCodigoMotivoAfastamentoMaternidade,
  contractBRCltCodigoMotivoAfastamentoProrrogacaoMaternidade,
  contractBRCltCodigoMotivoAfastamentoFeriasOuRecesso,
  contractBRCltCodigoMotivoAfastamentoOutrosMotivos,
]);
const contractBRCltSituacaoAfastado = z
  .object({
    tipo: z.literal('afastado'),
    dtIniAfast: z.string(),
    codMotAfast: contractBRCltCodigoMotivoAfastamento,
  })
  .passthrough();
const contractBRCltSituacaoAtivo = z
  .object({ tipo: z.literal('ativo') })
  .passthrough();
const contractBRCltSituacaoDesligado = z
  .object({ tipo: z.literal('desligado'), dtDeslig: z.string() })
  .passthrough();
const contractBRCltEntrySituacao = z.union([
  contractBRCltSituacaoAfastado,
  contractBRCltSituacaoAtivo,
  contractBRCltSituacaoDesligado,
]);
const contractBRCltEsocialStatus = z.enum(['success', 'pending', 'fail']);
const contractBRCltEsocialStatusEntry = z
  .object({
    status: contractBRCltEsocialStatus,
    statusBySequenceId: z.record(contractBRCltEsocialStatus),
  })
  .passthrough();
const contractBRCltEntry = z
  .object({
    pessoa: contractBRCltEntryPessoa,
    nascimento: contractBRCltEntryNascimento,
    endereco: contractBRCltEntryEndereco,
    deficiencia: contractBRCltEntryDeficiencia,
    contato: contractBRCltEntryContato.optional(),
    contatoDeEmergencia: contractBRContatoDeEmergencia.optional(),
    dependentes: z.array(contractBRCltDependent).max(99).optional(),
    situacao: contractBRCltEntrySituacao,
    trabalho: contractBRCltEntryTrabalho,
    estagio: contractBRCltEntryEstagio.optional(),
    vinculo: contractBRCltEntryVinculo.optional(),
    regime: contractBRCltEntryRegime,
    jornada: contractBRCltEntryJornada.optional(),
    remuneracao: contractBRCltEntryRemuneracao.optional(),
    duracao: contractBRCltEntryDuracao.optional(),
    sucessao: contractBRCltEntrySucessao.optional(),
    pagamento: contractBRCltEntryPagamento.optional(),
    gestao: contractBRCltEntryGestao.optional(),
    aprendiz: contractBRCltEntryAprendiz.optional(),
    eSocial: contractBRCltEsocialStatusEntry.optional(),
    observacao: z.string().min(1).max(255).optional(),
    options: contractBRCltEntryOptions.optional(),
  })
  .passthrough();
const contractBRPjEntry = z
  .object({
    emailCorp: z.string().min(6).max(60).email().optional(),
    pessoa: contractBRPjEntryPessoa,
    nascimento: contractBRPjEntryNascimento.optional(),
    contato: contractBRPjEntryContato.optional(),
    contatoDeEmergencia: contractBRContatoDeEmergencia.optional(),
    endereco: contractBRPjEntryEndereco.optional(),
    dependentes: z.array(contractBRPjEntryDependente).max(99).optional(),
    prestador: contractBRPjEntryPrestador,
    empresa: contractBRPjEntryEmpresa,
    pagamento: contractBRPjEntryPagamento,
    contrato: contractBRPjEntryContrato,
  })
  .passthrough();
const contractEntry = effectiveStartDate
  .and(auditableAPIEntry)
  .and(
    z
      .object({
        organizationId: z.string().uuid(),
        companyId: z.string().uuid(),
        personId: z.string().uuid(),
        legalEntityId: z.string().uuid(),
        contractId: z.string().uuid(),
        status: contractStatus,
        workerId: z.string().min(1).max(30).optional(),
        name: z.string(),
        orgStructure: contractOrgStructure,
        customFields: contractCustomFields.optional(),
        profilePicture: z
          .object({ pictureId: z.string(), pictureUrl: z.string() })
          .partial()
          .passthrough()
          .optional(),
      })
      .passthrough()
  )
  .and(
    z.union([
      z
        .object({ contractType: z.literal('br:clt'), br: contractBRCltEntry })
        .passthrough(),
      z
        .object({ contractType: z.literal('br:pj'), br: contractBRPjEntry })
        .passthrough(),
    ])
  );
const contractSummaryBody = z
  .object({
    organizationId: z.string(),
    contractId: z.string().uuid(),
    personId: z.string().uuid(),
    legalEntityId: z.string().optional(),
    companyId: z.string().uuid(),
    employeeId: z.string().min(1).max(30),
    matricula: z.string().min(1).max(30).optional(),
    name: z.string(),
    admissionDate: z.string(),
    status: contractStatus,
    department: z.string(),
    title: z.string(),
    workerCategory: z
      .enum([
        'clt:geral',
        'clt:autonomo',
        'clt:tsv',
        'clt:estagiario',
        'clt:jovemAprendiz',
        'pj',
      ])
      .optional(),
    titleName: z.string().optional(),
    manager: z.string().optional(),
    reports: z.array(z.string()).optional(),
    pictureId: z.string().optional(),
    pictureUrl: z.string().optional(),
    lastUpdatedOn: z.string().datetime({ offset: true }).optional(),
  })
  .passthrough();
const contractBRCltSummary = z
  .object({ eSocial: contractBRCltEsocialStatusEntry })
  .partial()
  .passthrough();
const contractSummary = contractSummaryBody.and(
  z.union([
    z.object({ contractType: z.literal('br:pj') }).passthrough(),
    z
      .object({
        contractType: z.literal('br:clt'),
        br: contractBRCltSummary.optional(),
      })
      .passthrough(),
  ])
);
const contractList = paginatedResult
  .and(withMetadataResult)
  .and(
    z
      .object({
        filters: z
          .object({
            contractTypes: z.array(
              z
                .object({ name: z.string(), count: z.number().int().gte(0) })
                .passthrough()
            ),
            departments: z.array(
              z
                .object({ name: z.string(), count: z.number().int().gte(0) })
                .passthrough()
            ),
            titles: z.array(
              z
                .object({
                  id: z.string(),
                  name: z.string(),
                  count: z.number().int().gte(0),
                })
                .passthrough()
            ),
          })
          .passthrough(),
        data: z.array(contractSummary),
      })
      .passthrough()
  );
const contractEsocialIssues_Body = z
  .object({
    pagination: searchPagination,
    sorting: searchSorting,
    filtering: searchFilteringInput,
  })
  .partial()
  .passthrough();
const effectiveDateBody = z
  .object({
    effectiveDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
  })
  .passthrough();
const contractBRContatoDeEmergenciaUpdate = z
  .object({
    nome: z.string().min(1).max(255).nullable(),
    telefone: z.string().min(8).max(13).nullable(),
    relacao: z.string().min(1).max(255).nullable(),
  })
  .partial()
  .passthrough();
const contractBRCltUpdate = z
  .object({
    pessoa: z
      .object({
        cpfTrab: z.string().min(11).max(11),
        nmTrab: z.string().min(2).max(70),
        nmSoc: z.string().min(2).max(70).nullable(),
        nmMae: z.string().min(2).max(70).nullable(),
        sexo: contractBRCltSexo,
        racaCor: contractBRCltRacaCor,
        estCiv: contractBRCltEstadoCivil.nullable(),
        grauInstr: contractBRCltGrauInstrucao,
      })
      .partial()
      .passthrough(),
    nascimento: z
      .object({
        dtNascto: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        paisNascto: BRPaises,
        paisNac: BRPaises,
        tmpResid: contractBRCltTempoResidencia.nullable(),
        condIng: contractBRCltCondicaoIngresso.nullable(),
      })
      .partial()
      .passthrough(),
    endereco: z.union([
      z
        .object({
          tipo: z.literal('brasil'),
          tpLograd: BRTipoLogradouro.nullable(),
          dscLograd: z.string().min(1).max(100),
          nrLograd: z.string().min(1).max(10),
          complemento: z.string().min(1).max(30).nullable(),
          bairro: z.string().min(1).max(90).nullable(),
          cep: z.string().min(8).max(8),
          codMunic: z.number().gte(1000000).lte(9999999),
          uf: BREstados,
        })
        .partial()
        .passthrough(),
      z
        .object({
          tipo: z.literal('exterior'),
          paisResid: BRPaises,
          dscLograd: z.string().min(1).max(100),
          nrLograd: z.string().min(1).max(10),
          complemento: z.string().min(1).max(30).nullable(),
          bairro: z.string().min(1).max(90).nullable(),
          nmCid: z.string().min(2).max(50),
          codPostal: z.string().min(4).max(12).nullable(),
        })
        .partial()
        .passthrough(),
    ]),
    deficiencia: z
      .object({
        defFisica: z.boolean(),
        defVisual: z.boolean(),
        defAuditiva: z.boolean(),
        defMental: z.boolean(),
        defIntelectual: z.boolean(),
        reabReadap: z.boolean(),
        infoCota: z.boolean().nullable(),
        observacao: z.string().min(1).max(255).nullable(),
      })
      .partial()
      .passthrough(),
    contato: z
      .object({
        fonePrinc: z.string().min(8).max(13).nullable(),
        emailPrinc: z.string().min(6).max(60).email().nullable(),
      })
      .partial()
      .passthrough()
      .nullable(),
    contatoDeEmergencia: contractBRContatoDeEmergenciaUpdate.nullable(),
    dependentes: z
      .array(
        z
          .object({
            tpDep: contractBRCltTipoDependente,
            nmDep: z.string().min(2).max(70),
            dtNascto: z.string(),
            cpfDep: z.string().min(11).max(11),
            sexoDep: contractBRCltSexo,
            incTrab: z.boolean(),
            depIRRF: z.boolean(),
            depSF: z.boolean(),
            descrDep: z.string().optional(),
          })
          .passthrough()
      )
      .max(99)
      .nullable(),
    trabalho: z
      .object({
        codCateg: contractBRCltCodigoCategoria,
        jobTitleId: z.string().uuid(),
        nmFuncao: z.string().min(1).max(100).nullable(),
        CBOFuncao: z.string().min(6).max(6).nullable(),
        departamento: z.string().min(1).max(100),
      })
      .partial()
      .passthrough(),
    estagio: z
      .object({
        natEstagio: contractBRCltNaturezaEstagio,
        nivEstagio: contractBRCltNivelEstagio.nullable(),
        areaAtuacao: z.string().min(1).max(100).nullable(),
        dtPrevTerm: z.string(),
        nrApol: z.string().min(1).max(30).nullable(),
        cnpjAgntInteg: z.string().min(14).max(14).nullable(),
        cpfSupervisor: z.string().min(11).max(11).nullable(),
        instEnsino: z
          .object({ cnpjInstEnsino: z.string().min(14).max(14) })
          .partial()
          .passthrough()
          .nullable(),
      })
      .partial()
      .passthrough()
      .nullable(),
    vinculo: z
      .object({
        tpRegTrab: contractBRCltTipoRegimeTrabalhista,
        tpRegPrev: contractBRCltTipoRegimePrevidenciario,
        emailCorp: z.string().min(6).max(60).email().nullable(),
        nrProcJud: z.string().min(20).max(20).nullable(),
      })
      .partial()
      .passthrough(),
    regime: z
      .object({
        dtAdm: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        tpAdmissao: contractBRCltTipoAdmissao,
        indAdmissao: contractBRCltIndAdmissao,
        nrProcTrab: z.string().min(20).max(20).nullable(),
        tpRegJor: contractBRCltTipoRegimeJornada,
        natAtividade: contractBRCltNaturezaAtividade,
        dtBase: contractBRCltDataBase.nullable(),
        cnpjSindCategProf: z.string().min(0).max(14).nullable(),
        dtOpcFGTS: z
          .string()
          .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
          .nullable(),
      })
      .partial()
      .passthrough(),
    jornada: z
      .object({
        qtdHrsSem: z.number().gte(0).lte(9999.99).nullable(),
        tpJornada: contractBRCltTipoJornada,
        tmpParc: contractBRCltContratoRegimeParcial,
        horNoturno: z.boolean().nullable(),
        descJorn: z.string().min(1).max(999),
      })
      .partial()
      .passthrough(),
    remuneracao: z
      .object({
        vrSalFx: z.number().gte(0).lte(99999999999999.98).multipleOf(0.01),
        undSalFixo: contractBRCltUnidadeSalarioFixo,
        dscSalVar: z.string().min(1).max(999).nullable(),
        acordoSalario: z
          .object({
            id: z.string().uuid(),
            data: dateYYYYMMDD.regex(
              /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
            ),
            tipo: agreementTypes,
            dataBase: dateYYYYMMDD.regex(
              /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
            ),
            periodoPagamentoDiferencaSalario: datePeriodFormat.regex(
              /^\d{4}(\-(0[1-9]|1[012]))?$/
            ),
            dataPagamentoRescisaoComplementar: dateYYYYMMDD.regex(
              /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
            ),
            explicacaoReajuste: z.array(z.string()),
          })
          .partial()
          .passthrough(),
      })
      .partial()
      .passthrough(),
    duracao: z
      .object({
        tpContr: contractBRCltTipoContrato,
        dtTerm: z
          .string()
          .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
          .nullable(),
        dtPerExpTerm: z
          .string()
          .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
          .nullable(),
        dtPerExpProrTerm: z
          .string()
          .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
          .nullable(),
        clauAssec: z.boolean().nullable(),
        objDet: z.string().min(1).max(255).nullable(),
      })
      .partial()
      .passthrough(),
    sucessao: z
      .object({
        tpInsc: contractBRCltTipoInsc,
        nrInsc: z.string().min(8).max(14),
        matricAnt: z.string().min(1).max(30).nullable(),
        dtTransf: z
          .string()
          .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
          .nullable(),
      })
      .partial()
      .passthrough()
      .nullable(),
    pagamento: z
      .object({
        codigoBanco: z.string().min(1).max(14).nullable(),
        nomeBanco: z.string().min(1).max(99).nullable(),
        agencia: z.string().min(1).max(10).nullable(),
        conta: z.string().min(1).max(20).nullable(),
        chavePix: z.string().min(5).max(99).nullable(),
        tipoPix: BRTipoPix.nullable(),
      })
      .partial()
      .passthrough()
      .nullable(),
    gestao: z
      .object({
        costCenterId: z.string().nullable(),
        nomeCentroCusto: z.string().min(1).max(99).nullable(),
        codigoCentroCusto: z.string().min(1).max(50).nullable(),
      })
      .partial()
      .passthrough()
      .nullable(),
    aprendiz: contractBRCltEntryAprendiz,
    observacao: z.string().min(1).max(255).nullable(),
    eSocial: contractBRCltEsocialNrRecibo,
    options: contractBRCltEntryOptions,
  })
  .partial()
  .passthrough();
const contractBRPjUpdateAddress = z
  .object({
    tipo: z.literal('brasil'),
    tpLograd: BRTipoLogradouro.nullable(),
    dscLograd: z.string().min(1).max(100),
    nrLograd: z.string().min(1).max(10).nullable(),
    complemento: z.string().min(1).max(30).nullable(),
    bairro: z.string().min(1).max(90).nullable(),
    cep: z.string().min(8).max(8),
    codMunic: z.number().gte(1000000).lte(9999999),
    uf: BREstados,
  })
  .partial()
  .passthrough();
const contractBRPjUpdatePrestador = z
  .object({
    gestao: z
      .object({
        costCenterId: z.string().nullable(),
        nomeCentroCusto: z.string().min(1).max(99).nullable(),
        codigoCentroCusto: z.string().min(1).max(50).nullable(),
      })
      .partial()
      .passthrough()
      .nullable(),
    posicao: z
      .object({
        jobTitleId: z.string().uuid(),
        nmFuncao: z.string().min(1).max(100).nullable(),
        departamento: z.string().min(1).max(100),
      })
      .partial()
      .passthrough(),
  })
  .partial()
  .passthrough();
const contractBRPjUpdateEmpresa = z
  .object({
    razaoSocial: z.string().min(1).max(100),
    cnpj: z.string().min(14).max(14),
    cnae: z.string().min(7).max(7),
    enquadramentoTributario: z.enum(['simples', 'lucroPresumido']),
    endereco: contractBRPjUpdateAddress.nullable(),
  })
  .partial()
  .passthrough();
const contractBRPjUpdatePagamento = z
  .object({
    honorarios: z.number(),
    periodicidade: z.unknown(),
    codigoBanco: z.string().min(1).max(14).nullable(),
    agencia: z.string().min(1).max(10).nullable(),
    conta: z.string().min(1).max(20).nullable(),
    chavePix: z.string().min(5).max(99).nullable(),
    tipoPix: BRTipoPix.nullable(),
  })
  .partial()
  .passthrough();
const contractBRPjUpdateContrato = z
  .object({
    dataAssinatura: z
      .string()
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .nullable(),
    renovacao: z.enum(['automatica', 'manual']),
    inicio: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    validade: z
      .string()
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .nullable(),
  })
  .partial()
  .passthrough();
const contractBRPjUpdate = z
  .object({
    emailCorp: z.string().min(6).max(60).email(),
    pessoa: z
      .object({
        cpfTrab: z.string().min(11).max(11),
        nmTrab: z.string().min(2).max(70),
        nmSoc: z.string().min(2).max(70).nullable(),
      })
      .partial()
      .passthrough(),
    nascimento: z
      .object({
        dtNascto: z
          .string()
          .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
          .nullable(),
        paisNascto: BRPaises.nullable(),
        paisNac: BRPaises.nullable(),
      })
      .partial()
      .passthrough()
      .nullable(),
    contato: z
      .object({
        fonePrinc: z.string().min(8).max(13).nullable(),
        emailPrinc: z.string().min(6).max(60).email().nullable(),
      })
      .partial()
      .passthrough()
      .nullable(),
    contatoDeEmergencia: contractBRContatoDeEmergenciaUpdate.nullable(),
    endereco: z
      .union([
        contractBRPjUpdateAddress,
        z
          .object({
            tipo: z.literal('exterior'),
            paisResid: BRPaises,
            dscLograd: z.string().min(1).max(100),
            nrLograd: z.string().min(1).max(10),
            complemento: z.string().min(1).max(30).nullable(),
            bairro: z.string().min(1).max(90).nullable(),
            nmCid: z.string().min(2).max(50),
            codPostal: z.string().min(4).max(12).nullable(),
          })
          .partial()
          .passthrough(),
      ])
      .nullable(),
    dependentes: z.array(contractBRPjEntryDependente).max(99).nullable(),
    prestador: contractBRPjUpdatePrestador,
    empresa: contractBRPjUpdateEmpresa,
    pagamento: contractBRPjUpdatePagamento,
    contrato: contractBRPjUpdateContrato,
  })
  .partial()
  .passthrough();
const contractUpdate = effectiveDateBody
  .and(
    z
      .object({
        workerId: z.string().min(1).max(30),
        customFields: z.record(
          z
            .union([z.string(), z.number(), z.boolean(), z.array(z.string())])
            .nullable()
        ),
      })
      .partial()
      .passthrough()
  )
  .and(
    z.union([
      z
        .object({ contractType: z.literal('br:clt'), br: contractBRCltUpdate })
        .passthrough(),
      z
        .object({ contractType: z.literal('br:pj'), br: contractBRPjUpdate })
        .passthrough(),
    ])
  );
const eventSourcingEvent = z
  .object({
    sequenceId: z.number().int(),
    recordDate: z.string().datetime({ offset: true }),
    effectiveDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    commandId: z.string().optional(),
    author: z.string(),
    type: z.string(),
    payload: z.object({}).partial().passthrough(),
    exclusiveResources: z.array(z.string()).optional(),
  })
  .passthrough();
const eventSourcingEventWithAuthor = eventSourcingEvent.and(
  z.object({ authorName: z.string() }).partial().passthrough()
);
const terminationDateBody = z
  .object({
    terminationDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
  })
  .passthrough();
const contractBrCltTermination = z
  .object({
    dataDeComunicacao: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    dataDeDesligamento: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    motivo: z.enum([
      'pedidoDeDemissao',
      'semJustaCausa',
      'comJustaCausa',
      'mutuoAcordo',
      'falecimento',
      'extincaoDeContratoDeExperiencia',
      'rescisaoAntecipadaDoContratoDeExperienciaPeloEmpregador',
      'rescisaoAntecipadaDoContratoDeExperienciaPeloEmpregado',
    ]),
    avisoPrevioIndenizado: z
      .object({
        fimDaProjecao: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        diasIndenizados: z.number(),
        quemPaga: z.enum(['ninguem', 'empregador', 'empregado']),
      })
      .passthrough(),
    eSocial: contractBRCltEsocialNrRecibo.optional(),
  })
  .passthrough();
const contractTermination = terminationDateBody.and(
  z.union([
    z
      .object({
        contractType: z.literal('br:clt'),
        br: contractBrCltTermination,
      })
      .passthrough(),
    z.object({ contractType: z.literal('br:pj') }).passthrough(),
  ])
);
const contractInternalTransfer = effectiveDateBody.and(
  z
    .object({
      contractType: z.enum(['br:clt', 'br:pj']),
      newWorkerId: z.string().optional(),
      destination: z.object({ legalEntityId: z.string().uuid() }).passthrough(),
    })
    .passthrough()
);
const companyTransferContract_Body = effectiveDateBody.and(
  z
    .object({
      newCompanyId: z.string().uuid(),
      newLegalEntityId: z.string().uuid(),
      newWorkerId: z.string().optional(),
      br: z
        .object({ novaMatricula: z.string() })
        .partial()
        .passthrough()
        .optional(),
    })
    .passthrough()
);
const contractVacationsBody = z
  .object({
    startDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    endDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
  })
  .passthrough();
const contractBrCltVacations = z
  .object({
    periodoAquisitivo: z
      .object({
        dataDeInicio: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        dataDeFim: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
      })
      .passthrough(),
    eSocial: contractBRCltEsocialNrRecibo.optional(),
  })
  .passthrough();
const contractBrPjVacations = z
  .object({ provisorio: z.string() })
  .passthrough();
const contractVacations = contractVacationsBody.and(
  z.union([
    z
      .object({ contractType: z.literal('br:clt'), br: contractBrCltVacations })
      .passthrough(),
    z
      .object({ contractType: z.literal('br:pj'), br: contractBrPjVacations })
      .passthrough(),
  ])
);
const contractProfilePictureInput = z
  .object({ length: z.number(), type: z.enum(['image/jpeg', 'image/png']) })
  .passthrough();
const contractProfilePictureResponse = z
  .object({
    pictureId: z.string(),
    uploadUrl: z.string(),
    uploadHeaders: z.record(z.string()),
  })
  .passthrough();
const contractBRCltEsocialStatusInput = z
  .object({ status: contractBRCltEsocialStatus, sequenceId: z.string() })
  .passthrough();
const contractBRCltPensaoAlimenticiaDivisaoPorDependente = z.record(
  z
    .object({
      tipo: z.literal('percentual').optional(),
      valor: z.string().optional(),
      dadosBancarios: contractBRCltEntryPagamento,
    })
    .passthrough()
);
const contractBRCltPensaoAlimenticiaDivisaoDoFGTS = z.union([
  z.object({ valorFixo: z.string() }).passthrough(),
  z.object({ percentual: z.string() }).passthrough(),
]);
const payrollRecurringEventBrPensaoAlimenticiaPagamentoFixo = z
  .object({ tipo: z.literal('valorFixo'), valor: z.string() })
  .passthrough();
const payrollRecurringEventBrPensaoAlimenticiaPagamentoPercentualDeSalarioMinimo =
  z
    .object({
      tipo: z.literal('percentual'),
      indicador: z.literal('salarioMinimo'),
      percentual: z.string(),
    })
    .passthrough();
const payrollRecurringEventBrPensaoAlimenticiaCustomizacaoDaBaseDeRendimentos =
  z
    .object({
      operacao: z.enum(['incluir', 'excluir']),
      matchRubricas: z
        .object({
          codigo: z.union([z.string(), z.array(z.string())]),
          natureza: z.union([BRRubricNatureza, z.array(BRRubricNatureza)]),
          tipo: z.union([BRRubricTipo, z.array(BRRubricTipo)]),
          codIncCP: z.union([BRRubricCodIncCP, z.array(BRRubricCodIncCP)]),
          codIncIRRF: z.union([
            BRRubricCodIncIRRF,
            z.array(BRRubricCodIncIRRF),
          ]),
          codIncFGTS: z.union([
            BRRubricCodIncFGTS,
            z.array(BRRubricCodIncFGTS),
          ]),
        })
        .partial()
        .passthrough(),
    })
    .passthrough();
const payrollRecurringEventBrPensaoAlimenticiaPagamentoPercentualDeRendimentos =
  z
    .object({
      tipo: z.literal('percentual'),
      indicador: z.enum(['rendimentosBrutos', 'rendimentosLiquidos']),
      percentual: z.string(),
      customizacaoDaBaseDeRendimentos: z.array(
        payrollRecurringEventBrPensaoAlimenticiaCustomizacaoDaBaseDeRendimentos
      ),
    })
    .passthrough();
const payrollRecurringEventBrPensaoAlimenticiaSentenca = z
  .object({
    baseDeRendimentos: z.enum(['mensal', 'ferias', 'decimoTerceiro']),
    pagamento: z.union([
      payrollRecurringEventBrPensaoAlimenticiaPagamentoFixo,
      payrollRecurringEventBrPensaoAlimenticiaPagamentoPercentualDeSalarioMinimo,
      payrollRecurringEventBrPensaoAlimenticiaPagamentoPercentualDeRendimentos,
    ]),
  })
  .passthrough();
const contractBRCltPensaoAlimenticiaInput = z
  .object({
    dataDeInicio: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    dataDeFim: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    status: z.enum(['ativo', 'suspenso']),
    divisaoPorDependente: contractBRCltPensaoAlimenticiaDivisaoPorDependente,
    divisaoDoFGTS: contractBRCltPensaoAlimenticiaDivisaoDoFGTS.optional(),
    sentencas: z.array(payrollRecurringEventBrPensaoAlimenticiaSentenca),
  })
  .passthrough();
const contractBRCltPensaoAlimenticiaEntry = auditableVersionedEntry.and(
  z
    .object({
      organizationId: z.string().uuid(),
      contractId: z.string().uuid(),
      eventId: z.string(),
      dataDeInicio: dateYYYYMMDD.regex(
        /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
      ),
      dataDeFim: dateYYYYMMDD
        .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
        .optional(),
      status: z.enum(['ativo', 'suspenso']),
      divisaoPorDependente: contractBRCltPensaoAlimenticiaDivisaoPorDependente,
      divisaoDoFGTS: contractBRCltPensaoAlimenticiaDivisaoDoFGTS.optional(),
    })
    .passthrough()
);
const contractBRCltPensaoAlimenticiaSearchInput = z
  .object({
    filtering: z
      .object({
        elements: z.object({ effectiveStartDate: z.string() }).passthrough(),
      })
      .passthrough(),
  })
  .passthrough();
const contractBRCltPensaoAlimenticiaSearchOutput = z
  .object({ data: z.array(contractBRCltPensaoAlimenticiaEntry) })
  .passthrough();
const contractBRCltGrauExp = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
]);
const contractBRCltInsalubridadeCore = z
  .object({ porcentagem: z.number().gte(0), grauExp: contractBRCltGrauExp })
  .passthrough();
const contractBRCltInsalubridadeInput = z
  .object({
    dataDeInicio: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    dataDeFim: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
  })
  .passthrough()
  .and(contractBRCltInsalubridadeCore);
const contractBRCltInsalubridadeEntry = auditableVersionedEntry
  .and(
    z
      .object({
        organizationId: z.string().uuid(),
        contractId: z.string().uuid(),
        insalubridadeId: z.string().uuid(),
        recurringEventId: z.string().uuid(),
        dataDeInicio: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        dataDeFim: dateYYYYMMDD
          .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
          .optional(),
        status: z.enum([
          'processandoCriacao',
          'processandoAtualizacao',
          'processandoRemocao',
          'ativo',
        ]),
      })
      .passthrough()
  )
  .and(contractBRCltInsalubridadeCore);
const contractBRCltInsalubridadeSearchInput = z
  .object({
    filtering: z
      .object({
        elements: z.object({ effectiveStartDate: z.string() }).passthrough(),
      })
      .passthrough(),
  })
  .passthrough();
const contractBRCltInsalubridadeSearchOutput = z
  .object({ data: z.array(contractBRCltInsalubridadeEntry) })
  .passthrough();
const contractSummaryList = paginatedResult.and(
  z.object({ data: z.array(contractSummary) }).passthrough()
);
const contractLeavesInputBody = z
  .object({
    startDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    endDate: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    comments: z.string().optional(),
  })
  .passthrough();
const contractBRCltLeavesInputDoencaOuAcidente = z
  .object({
    codMotAfast: contractBRCltCodigoMotivoAfastamentoDoencaOuAcidente,
    sameCauseGroupId: z.string().uuid().optional(),
    CID: z.string().optional(),
    eSocial: contractBRCltEsocialNrRecibo.optional(),
  })
  .passthrough();
const contractBRCltLeavesInputMaternidade = z
  .object({
    codMotAfast: contractBRCltCodigoMotivoAfastamentoMaternidade,
    eSocial: contractBRCltEsocialNrRecibo.optional(),
  })
  .passthrough();
const contractBRCltLeavesInputOutrosMotivos = z
  .object({
    codMotAfast: contractBRCltCodigoMotivoAfastamentoOutrosMotivos,
    eSocial: contractBRCltEsocialNrRecibo.optional(),
  })
  .passthrough();
const contractBRCltLeavesInput = z.union([
  contractBRCltLeavesInputDoencaOuAcidente,
  contractBRCltLeavesInputMaternidade,
  contractBRCltLeavesInputOutrosMotivos,
]);
const contractBRPjLeavesInput = z.object({}).partial().passthrough();
const contractLeavesInput = contractLeavesInputBody.and(
  z.union([
    z
      .object({
        contractType: z.literal('br:clt'),
        br: contractBRCltLeavesInput,
      })
      .passthrough(),
    z
      .object({ contractType: z.literal('br:pj'), br: contractBRPjLeavesInput })
      .passthrough(),
  ])
);
const contractLeavesCreationOutput = z
  .object({ leaveId: z.string() })
  .passthrough();
const contractLeavesEntryBody = z
  .object({
    organizationId: z.string().uuid(),
    contractId: z.string().uuid(),
    leaveId: z.string(),
    startDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    endDate: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    daysOfLeave: z.number().int().optional(),
    comments: z.string().optional(),
  })
  .passthrough();
const contractBRCltLeavesEntryDoencaOuAcidente = z
  .object({
    codMotAfast: contractBRCltCodigoMotivoAfastamentoDoencaOuAcidente,
    sameCauseGroupId: z.string().uuid(),
    availableJustifiedAbsences: z.number().int(),
    CID: z.string().optional(),
    eSocial: contractBRCltEsocialNrRecibo.optional(),
  })
  .passthrough();
const contractBRCltLeavesEntryMaternidade = z
  .object({
    codMotAfast: contractBRCltCodigoMotivoAfastamentoMaternidade,
    inicioDaLicenca: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    prorrogacao: z.boolean(),
    eSocial: contractBRCltEsocialNrRecibo.optional(),
  })
  .passthrough();
const contractBRCltLeavesEntryOutrosMotivos = z
  .object({
    codMotAfast: contractBRCltCodigoMotivoAfastamentoOutrosMotivos,
    eSocial: contractBRCltEsocialNrRecibo.optional(),
  })
  .passthrough();
const contractBRCltLeavesEntry = z.union([
  contractBRCltLeavesEntryDoencaOuAcidente,
  contractBRCltLeavesEntryMaternidade,
  contractBRCltLeavesEntryOutrosMotivos,
]);
const contractBRPjLeavesEntry = z.object({}).partial().passthrough();
const contractLeavesEntry = contractLeavesEntryBody.and(
  z.union([
    z
      .object({
        contractType: z.literal('br:clt'),
        br: contractBRCltLeavesEntry,
      })
      .passthrough(),
    z
      .object({ contractType: z.literal('br:pj'), br: contractBRPjLeavesEntry })
      .passthrough(),
  ])
);
const contractLeavesPatch = z
  .object({
    endDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    comments: z.string(),
    eSocial: contractBRCltEsocialNrRecibo,
  })
  .partial()
  .passthrough();
const contractLeavesDelete = z
  .object({ eSocial: contractBRCltEsocialNrRecibo })
  .partial()
  .passthrough();
const contractLeavesSummaryBody = z
  .object({
    organizationId: z.string().uuid(),
    contractId: z.string().uuid(),
    leaveId: z.string(),
    startDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    endDate: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    daysOfLeave: z.number().int().optional(),
  })
  .passthrough();
const contractBRCltLeavesSummaryDoencaOuAcidente = z
  .object({
    codMotAfast: contractBRCltCodigoMotivoAfastamentoDoencaOuAcidente,
    sameCauseGroupId: z.string().uuid(),
    availableJustifiedAbsences: z.number().int(),
    eSocial: contractBRCltEsocialNrRecibo.optional(),
  })
  .passthrough();
const contractBRCltLeavesSummaryMaternidade = z
  .object({
    codMotAfast: contractBRCltCodigoMotivoAfastamentoMaternidade,
    inicioDaLicenca: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    prorrogacao: z.boolean(),
    eSocial: contractBRCltEsocialNrRecibo.optional(),
  })
  .passthrough();
const contractBRCltLeavesSummaryOutrosMotivos = z
  .object({
    codMotAfast: contractBRCltCodigoMotivoAfastamentoOutrosMotivos,
    eSocial: contractBRCltEsocialNrRecibo.optional(),
  })
  .passthrough();
const contractBRCltLeavesSummary = z.union([
  contractBRCltLeavesSummaryDoencaOuAcidente,
  contractBRCltLeavesSummaryMaternidade,
  contractBRCltLeavesSummaryOutrosMotivos,
]);
const contractBRPjLeavesSummary = z.object({}).partial().passthrough();
const contractLeavesSummary = contractLeavesSummaryBody.and(
  z.union([
    z
      .object({
        contractType: z.literal('br:clt'),
        br: contractBRCltLeavesSummary,
      })
      .passthrough(),
    z
      .object({
        contractType: z.literal('br:pj'),
        br: contractBRPjLeavesSummary,
      })
      .passthrough(),
  ])
);
const contractLeavesList = paginatedResult.and(
  z
    .object({
      filters: z.object({ codMotAfast: z.array(z.string()) }).passthrough(),
      data: z.array(contractLeavesSummary),
    })
    .passthrough()
);
const payrollCheckIfEditableInput = z
  .object({
    startDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    endDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
  })
  .passthrough();
const payrollCheckIfEditableOutput = z
  .object({ canBeEdited: z.boolean() })
  .passthrough();
const payrollRecurringEventBaseInputDates = z
  .object({
    startDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    endDate: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
  })
  .passthrough();
const payrollRecurringEventBrInsalubridadeInput =
  payrollRecurringEventBaseInputDates.and(
    z
      .object({
        type: z.literal('br:insalubridade'),
        payload: z.object({ porcentagem: z.number().gte(1) }).passthrough(),
      })
      .passthrough()
  );
const payrollRecurringEventBrPensaoAlimenticiaInput =
  payrollRecurringEventBaseInputDates.and(
    z
      .object({
        type: z.literal('br:pensaoAlimenticia'),
        payload: z
          .object({
            sentencas: z.array(
              payrollRecurringEventBrPensaoAlimenticiaSentenca
            ),
          })
          .passthrough(),
      })
      .passthrough()
  );
const payrollRecurringEventInput = z.union([
  payrollRecurringEventBrInsalubridadeInput,
  payrollRecurringEventBrPensaoAlimenticiaInput,
]);
const payrollRecurirnEventBaseEntry = auditableVersionedEntry.and(
  z
    .object({
      endDate: dateYYYYMMDD.regex(
        /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
      ),
      organizationId: z.string().uuid(),
      contractId: z.string().uuid(),
      eventId: z.string().uuid(),
    })
    .passthrough()
);
const payrollRecurringEventBrInsalubridadeEntry =
  payrollRecurirnEventBaseEntry.and(payrollRecurringEventBrInsalubridadeInput);
const payrollRecurringEventBrPensaoAlimenticiaEntry =
  payrollRecurirnEventBaseEntry.and(
    payrollRecurringEventBrPensaoAlimenticiaInput
  );
const payrollRecurringEventEntry = z.union([
  payrollRecurringEventBrInsalubridadeEntry,
  payrollRecurringEventBrPensaoAlimenticiaEntry,
]);
const contractOrgStructureUpdateManager = effectiveDateBody.and(
  z
    .object({
      manager: z
        .object({ contractId: z.string().uuid() })
        .passthrough()
        .nullable(),
    })
    .passthrough()
);
const contractSalaryChange = effectiveDateBody
  .and(z.object({ newSalary: z.number() }).passthrough())
  .and(
    z.union([
      z
        .object({
          reason: z.enum(['promotion', 'salaryAdjustment']),
          description: z.string().min(1).max(2000).optional(),
        })
        .passthrough(),
      z
        .object({
          reason: z.literal('salaryAgreement'),
          salaryAgreement: z
            .object({
              id: z.string().uuid(),
              br: z
                .object({
                  data: dateYYYYMMDD.regex(
                    /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
                  ),
                  tipo: agreementTypes,
                  dataBase: dateYYYYMMDD.regex(
                    /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
                  ),
                  periodoPagamentoDiferencaSalario: datePeriodFormat
                    .regex(/^\d{4}(\-(0[1-9]|1[012]))?$/)
                    .optional(),
                  dataPagamentoRescisaoComplementar: dateYYYYMMDD
                    .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
                    .optional(),
                })
                .passthrough()
                .optional(),
            })
            .passthrough(),
        })
        .passthrough(),
    ])
  );
const contractSalaryChangeEventReference = z
  .object({ id: z.string(), type: z.string(), date: z.string() })
  .passthrough();
const jobTitleSummary = auditableAPIEntry.and(
  z
    .object({
      organizationId: z.string().uuid(),
      jobTitleId: z.string().uuid(),
      active: z.boolean(),
      name: z.string(),
      occupationCode: z.string().optional(),
    })
    .passthrough()
);
const jobTitleList = paginatedResult.and(
  z
    .object({ unfilteredTotal: z.number(), data: z.array(jobTitleSummary) })
    .partial()
    .passthrough()
);
const jobTitleInput = z
  .object({
    name: z.string().min(2),
    occupationCode: z.string().min(4).optional(),
  })
  .passthrough();
const jobTitleEntry = auditableAPIEntry.and(
  z
    .object({
      organizationId: z.string().uuid(),
      jobTitleId: z.string().uuid(),
      name: z.string(),
      occupationCode: z.string().optional(),
    })
    .passthrough()
);
const costCenterSummary = auditableVersionedEntry.and(
  z
    .object({
      organizationId: z.string().uuid(),
      costCenterId: z.string().uuid(),
      active: z.boolean(),
      name: z.string(),
      code: z.string().optional(),
    })
    .passthrough()
);
const costCenterList = paginatedResult.and(
  z
    .object({ unfilteredTotal: z.number(), data: z.array(costCenterSummary) })
    .partial()
    .passthrough()
);
const costCenterInput = z
  .object({ name: z.string().min(1), code: z.string().min(1).optional() })
  .passthrough();
const costCenterEntry = auditableVersionedEntry.and(
  z
    .object({
      organizationId: z.string().uuid(),
      costCenterId: z.string().uuid(),
      name: z.string(),
      code: z.string().optional(),
    })
    .passthrough()
);
const reportsDefinitionsSummary = z
  .object({
    id: z.string(),
    name: z.string(),
    description: z.string(),
    category: z.enum([
      'PEOPLE',
      'PAYROLL',
      'VACATIONS',
      'TERMINATIONS',
      'PAYMENTS',
      'CONTRACTOR',
    ]),
  })
  .passthrough();
const reportsDefinitionsList = z
  .object({ definitions: z.array(reportsDefinitionsSummary) })
  .passthrough();
const reportsInput = z
  .object({
    reportId: z.string(),
    parameters: z.object({}).partial().passthrough().optional(),
  })
  .passthrough();
const reportsOutput = z
  .object({ fileName: z.string(), fileContent: z.string() })
  .passthrough();
const reportsError = z
  .object({
    message: z.string(),
    component: z.string().optional(),
    issues: z
      .object({
        path: z.array(z.union([z.string(), z.number()])),
        message: z.string(),
      })
      .passthrough()
      .optional(),
  })
  .passthrough();
const formFieldSetDefinition = z
  .object({
    label: z.string(),
    fields: z.array(z.object({}).partial().passthrough()),
    name: z.string().optional(),
  })
  .passthrough();
const formUIType = z.enum([
  'TEXT',
  'TEXTAREA',
  'SELECT',
  'CHECKBOX',
  'RADIO',
  'TEXT_DATE_PICKER',
  'TEXT_DATE_PICKER_YEAR_MONTH',
  'SELECT_SEARCH_TAG_CLOUD',
]);
const formFieldOption = z
  .object({
    label: z.string(),
    value: z
      .union([
        z.string(),
        z.number(),
        z.array(z.string()),
        z.array(z.number()),
        z.null(),
      ])
      .optional(),
    selected: z.boolean().optional(),
    required: z.boolean().optional(),
  })
  .passthrough();
const formFieldNormalDefinition = z
  .object({
    label: z.string(),
    uiType: formUIType,
    name: z.string(),
    type: z.object({}).partial().passthrough(),
    options: z.array(formFieldOption).optional(),
  })
  .passthrough();
const formFieldBranchingOption = z
  .object({
    label: z.string(),
    fields: z.array(z.object({}).partial().passthrough()),
    selected: z.boolean().optional(),
  })
  .passthrough();
const formFieldBranchingDefinition = z
  .object({
    label: z.string(),
    uiType: formUIType,
    fieldsOptions: z.array(formFieldBranchingOption),
    name: z.string().optional(),
  })
  .passthrough();
const formFieldDefinition = z.union([
  formFieldSetDefinition,
  formFieldNormalDefinition,
  formFieldBranchingDefinition,
]);
const formDefinition = z.array(formFieldDefinition);
const reportsDefinitionsEntry = z
  .object({
    id: z.string(),
    name: z.string(),
    description: z.string(),
    category: z.enum([
      'PEOPLE',
      'PAYROLL',
      'VACATIONS',
      'TERMINATIONS',
      'PAYMENTS',
      'CONTRACTOR',
    ]),
    parameters: formDefinition,
    availableFormats: z.array(z.literal('pdf')),
  })
  .passthrough();
const terminationInfo = z
  .object({
    noticeDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    terminationDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    reason: z.string(),
    noticePayment: z.boolean(),
    noticePaymentEndDate: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
  })
  .passthrough();
const esocialPayrollPreviousPeriodInfo = z
  .object({
    tipoDoEvento: esocialTpAcConv,
    dataDoEvento: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    descricaoDoEvento: z.string().min(1).max(255),
    periodoDaRemuneracaoRetroativa: dateYYYYMM
      .regex(/^\d{4}\-(0[1-9]|1[012])$/)
      .optional(),
    remuneradoPeloSucessor: esocialRemunSuc,
  })
  .passthrough();
const workerReconciliationPayrollInputCustomInformation = z
  .object({
    evtRemun: z
      .object({
        infoInterm: z.object({ dia: z.number() }).passthrough(),
        infoComplCont: z
          .object({
            codCBO: z.string().min(6).max(6),
            natAtividade: contractBRCltNaturezaAtividade,
            qtdDiasTrab: z.number(),
          })
          .passthrough(),
      })
      .partial()
      .passthrough(),
  })
  .partial()
  .passthrough();
const esocialPayrollBrMultiplosVinculosInfo = z
  .object({
    tipoDescontoInss: z.enum([
      'descontoInssNaoAfetado',
      'descontoInssAfetado',
      'semDescontoInss',
    ]),
    vinculos: z.array(
      z
        .object({
          cnpjOuCpfEmpregador: z.string(),
          codCateg: contractBRCltCodigoCategoria,
          baseInssPago: z.string().regex(/^\d+(\.\d{1,2})?$/),
        })
        .passthrough()
    ),
  })
  .passthrough();
const esocialPayrollBrMetadadosDeRubricas = z
  .object({
    planoDeSaude: z.record(
      z
        .object({
          cnpjPlanoDeSaude: z
            .string()
            .min(14)
            .max(14)
            .regex(/^\d{14}$/),
          registroANS: z
            .string()
            .min(6)
            .max(6)
            .regex(/^\d{6}$/),
        })
        .passthrough()
    ),
    reembolsoMedico: z.record(
      z
        .object({
          origem: z.enum(['empregador', 'planoDeSaude']),
          cnpjPlanoDeSaude: z
            .string()
            .min(14)
            .max(14)
            .regex(/^\d{14}$/)
            .optional(),
          registroANS: z
            .string()
            .min(6)
            .max(6)
            .regex(/^\d{6}$/)
            .optional(),
        })
        .passthrough()
    ),
    previdencia: z.record(
      z
        .object({
          tipoPrevidencia: z.enum(['privada', 'fapi', 'funpresp']),
          cnpjEntidade: z.string(),
        })
        .passthrough()
    ),
    dependentes: z.record(
      z
        .object({ valorPorDependente: z.record(z.string()) })
        .partial()
        .passthrough()
    ),
  })
  .partial()
  .passthrough();
const workerReconciliationPayrollInput = z
  .object({
    version: z.number().optional(),
    personId: z.string().uuid(),
    contractId: z.string().uuid(),
    period: datePeriodFormat.regex(/^\d{4}(\-(0[1-9]|1[012]))?$/),
    paymentDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    payrollDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    type: z.string(),
    total: z.string(),
    elements: z
      .array(
        z
          .object({
            id: z.string(),
            tableId: z.string(),
            amount: z.string(),
            previousPeriod: dateYYYYMM
              .regex(/^\d{4}\-(0[1-9]|1[012])$/)
              .optional(),
          })
          .passthrough()
      )
      .min(1),
    terminationInfo: terminationInfo.optional(),
    previousPeriodInfo: esocialPayrollPreviousPeriodInfo.optional(),
    customInformation:
      workerReconciliationPayrollInputCustomInformation.optional(),
    br: z
      .object({
        multiplosVinculosInfo: esocialPayrollBrMultiplosVinculosInfo,
        metadadosDeRubricas: esocialPayrollBrMetadadosDeRubricas,
      })
      .partial()
      .passthrough()
      .optional(),
  })
  .passthrough();
const workerReconciliationInputCustomInformationReportingOverride = z
  .object({
    version: z.number().optional(),
    shouldByPassPayroll: z.boolean(),
    shouldByPassPayment: z.boolean(),
    shouldByPassTsvTerminationExtraPayroll: z.boolean(),
    newPaymentStatementId: z.string().optional(),
    pastPeriods: z
      .object({
        tpAcConv: z.string().regex(/^[A-J]{1}$/),
        dtAcConv: dateYYYYMMDD
          .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
          .optional(),
        dsc: z.string().min(1).max(255).optional(),
        period: dateYYYYMM.regex(/^\d{4}\-(0[1-9]|1[012])$/),
        remunSuc: esocialRemunSuc,
      })
      .passthrough()
      .optional(),
  })
  .passthrough();
const esocialEventType = z.enum([
  'S-1010',
  'S-1200',
  'S-1210',
  'S-1280',
  'S-1298',
  'S-1299',
  'S-1000',
  'S-1005',
  'S-1020',
  'S-2200',
  'S-2300',
  'S-3000',
  'S-2230',
  'S-2206',
  'S-2306',
  'S-2205',
  'S-2299',
  'S-2399',
  'S-5001',
  'S-5002',
  'S-5003',
  'S-5011',
  'S-5012',
  'S-5013',
]);
const customEventInput = z
  .object({
    idempotencyKey: z.string(),
    subjectId: z.object({}).partial().passthrough(),
    type: esocialEventType,
    content: z.string(),
    effectiveDate: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
  })
  .passthrough();
const esocialEventMessage = z
  .object({
    organizationId: z.string().uuid(),
    companyId: z.string().uuid(),
    messageId: z.string().uuid(),
    subject: z.enum([
      'contract',
      'leave',
      'vacations',
      'person',
      'service',
      'company',
      'people',
      'rubrica',
      'worker-payroll',
      'worker-payment',
      'e-social',
      'eventExclusion',
      'reconciliation',
      'termination',
      'custom',
    ]),
    type: z.enum([
      'create',
      'update',
      'updateAuxiliary',
      'rectify',
      'delete',
      'open',
      'close',
      'custom',
    ]),
    time: z.string().datetime({ offset: true }),
    ignorePrecedingEvents: z.boolean().optional(),
  })
  .passthrough();
const esocialBaseEvent = auditableAPIEntry.and(
  z
    .object({
      partitionKey: z.string(),
      organizationId: z.string().uuid(),
      companyId: z.string().uuid(),
      eventId: z.string(),
      message: esocialEventMessage,
      original: z.object({}).partial().passthrough().optional(),
    })
    .passthrough()
);
const esocialEventWaitingStatus = z.enum(['waiting', 'waitingFailed']);
const esocialWaitingEvent = esocialBaseEvent.and(
  z
    .object({
      status: esocialEventWaitingStatus,
      subjectId: z.object({}).partial().passthrough(),
      gsiSubjectKeySK: z.string(),
    })
    .passthrough()
);
const esocialEventNonWaitingStatus = z.enum([
  'accepted',
  'pending',
  'rejected',
]);
const esocialEventOccurrence = z
  .object({
    code: z.string(),
    description: z.string(),
    kind: z.string(),
    location: z.string().optional(),
  })
  .passthrough();
const esocialEventReplySummary = z
  .object({
    code: z.string(),
    message: z.string(),
    occurrences: z.array(esocialEventOccurrence),
  })
  .passthrough();
const esocialEventRequest = z
  .object({
    timestamp: z.string(),
    content: z.string(),
    acceptance: z
      .object({
        protocol: z.string(),
        content: z.string(),
        summary: esocialEventReplySummary,
      })
      .passthrough(),
  })
  .passthrough();
const esocialEventResponse = z
  .object({
    timestamp: z.string(),
    content: z.string(),
    status: z.string(),
    receipt: z.string().optional(),
    summary: esocialEventReplySummary,
  })
  .passthrough();
const esocialNonWaitingEvent = esocialBaseEvent.and(
  z
    .object({
      status: esocialEventNonWaitingStatus,
      type: esocialEventType,
      request: esocialEventRequest,
      response: esocialEventResponse.optional(),
      subjectId: z.object({}).partial().passthrough().optional(),
      gsiSubjectKeySK: z.string().optional(),
    })
    .passthrough()
);
const esocialEventEntry = z.union([
  esocialWaitingEvent,
  esocialNonWaitingEvent,
]);
const esocialEventDeleteInput = z
  .object({
    subjectId: z.object({}).partial().passthrough(),
    subjectKey: z.string(),
    period: dateYYYYMM.regex(/^\d{4}\-(0[1-9]|1[012])$/),
    personId: z.string().uuid(),
  })
  .partial()
  .passthrough();
const esocialEventSummary = z
  .object({
    organizationId: z.string().uuid(),
    companyId: z.string().uuid(),
    eventId: z.string(),
    status: z.string(),
    subjectId: z.object({}).partial().passthrough().optional(),
    type: z.string().optional(),
    createdOn: dateTime.datetime({ offset: true }),
    lastUpdatedOn: dateTime.datetime({ offset: true }),
  })
  .passthrough();
const esocialEventList = paginatedResult
  .and(withMetadataResult)
  .and(z.object({ data: z.array(esocialEventSummary) }).passthrough());
const esocialPostRubricEntry_Body = z
  .object({
    validFrom: dateYYYYMM.regex(/^\d{4}\-(0[1-9]|1[012])$/),
    validTo: dateYYYYMM.regex(/^\d{4}\-(0[1-9]|1[012])$/).optional(),
    description: z.string().min(1).max(100),
    action: z.enum(['create', 'update']),
    br: BRRubric,
    newValidInterval: z
      .object({
        validFrom: dateYYYYMM.regex(/^\d{4}\-(0[1-9]|1[012])$/),
        validTo: dateYYYYMM.regex(/^\d{4}\-(0[1-9]|1[012])$/).optional(),
      })
      .passthrough()
      .optional(),
  })
  .passthrough();
const reconciliationEntry = auditableVersionedEntry.and(
  z
    .object({
      organizationId: z.string().uuid(),
      companyId: z.string().uuid(),
      period: datePeriodFormat.regex(/^\d{4}(\-(0[1-9]|1[012]))?$/),
      status: z.string(),
      counts: z
        .object({
          workers: z.number(),
          payments: z.number(),
          payrolls: z.number(),
          workersByStatus: z.record(z.number()),
        })
        .passthrough(),
    })
    .passthrough()
);
const reconciliationInput = z
  .object({ period: datePeriodFormat.regex(/^\d{4}(\-(0[1-9]|1[012]))?$/) })
  .passthrough();
const workerReconciliationSearchInput = z
  .object({
    pagination: z
      .object({ size: z.number().int().gte(1).lte(100) })
      .partial()
      .passthrough(),
    filtering: z
      .object({
        elements: z
          .object({ status: z.string(), personId: z.array(z.string()) })
          .partial()
          .passthrough(),
      })
      .passthrough(),
  })
  .partial()
  .passthrough();
const workerReconciliationType = z.string();
const workerReconciliationSummary = z
  .object({
    organizationId: z.string().uuid(),
    companyId: z.string().uuid(),
    period: dateYYYYMM.regex(/^\d{4}\-(0[1-9]|1[012])$/),
    personId: z.string().uuid(),
    type: workerReconciliationType.regex(/^(yearly|monthly)$/),
    status: z.string(),
  })
  .passthrough();
const workerReconciliationList = z
  .object({
    total: z.number().int(),
    data: z.array(workerReconciliationSummary),
  })
  .passthrough();
const reconciliationError = z
  .object({ code: z.string(), type: z.string(), message: z.string() })
  .partial()
  .passthrough();
const workerReconciliationPayrollEntry = z
  .object({
    payrollId: z.string().uuid(),
    contractId: z.string().uuid(),
    type: z.string(),
    total: z.string(),
    elements: z.array(
      z
        .object({
          id: z.string().uuid(),
          tableId: z.string().uuid(),
          amount: z.string(),
        })
        .passthrough()
    ),
    terminationInfo: terminationInfo.optional(),
  })
  .passthrough();
const workerReconciliationPaymentEntry = z
  .object({
    payrollId: z.string().uuid(),
    payrollPeriod: dateYYYYMM.regex(/^\d{4}\-(0[1-9]|1[012])$/),
    contractId: z.string().uuid(),
    type: z.string(),
    total: z.string(),
    paidOn: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
  })
  .passthrough();
const workerReconciliationInputCustomInformation = z
  .object({
    evtPgtos: z
      .object({
        pgtoExt: z.array(
          z
            .object({
              ideDmDev: z.string(),
              paisResidExt: z.string(),
              infoPgtoExt: z
                .object({
                  indNIF: z.number(),
                  nifBenef: z.string().optional(),
                  frmTribut: z.string(),
                  endExt: z
                    .object({
                      endDscLograd: z.string(),
                      endNrLograd: z.string(),
                      endComplem: z.string(),
                      endBairro: z.string(),
                      endCidade: z.string(),
                      endEstado: z.string(),
                      endCodPostal: z.string(),
                      telef: z.string(),
                    })
                    .partial()
                    .passthrough()
                    .optional(),
                })
                .passthrough(),
            })
            .passthrough()
        ),
        infoProcRet: z.array(
          z
            .object({
              tpProcRet: z.number(),
              nrProcRet: z.string(),
              codSusp: z.number().optional(),
              infoValores: z
                .array(
                  z
                    .object({
                      indApuracao: z.number(),
                      vlrNRetido: z.number().optional(),
                      vlrDepJud: z.number().optional(),
                      vlrCmpAnoCal: z.number().optional(),
                      vlrCmpAnoAnt: z.number().optional(),
                      vlrRendSusp: z.number().optional(),
                      dedSusp: z
                        .array(
                          z
                            .object({
                              indTpDeducao: z.number(),
                              vlrDedSusp: z.number().optional(),
                              cnpjEntidPC: z.string().optional(),
                              vlrPatrocFunp: z.number().optional(),
                              benefPen: z
                                .array(
                                  z
                                    .object({
                                      cpfDep: z.string(),
                                      vlrDepenSusp: z.number(),
                                    })
                                    .passthrough()
                                )
                                .optional(),
                            })
                            .passthrough()
                        )
                        .optional(),
                    })
                    .passthrough()
                )
                .optional(),
            })
            .passthrough()
        ),
      })
      .partial()
      .passthrough(),
    reportingOverrides: z.record(
      workerReconciliationInputCustomInformationReportingOverride
    ),
  })
  .partial()
  .passthrough();
const workerReconciliationEntry = auditableVersionedEntry.and(
  z
    .object({
      organizationId: z.string().uuid(),
      companyId: z.string().uuid(),
      period: dateYYYYMM.regex(/^\d{4}\-(0[1-9]|1[012])$/),
      personId: z.string().uuid(),
      partitionKey: z.string(),
      type: workerReconciliationType.regex(/^(yearly|monthly)$/),
      status: z.string(),
      events: z.array(
        z
          .object({
            id: z.string().uuid(),
            receipt: z.string(),
            status: z.string(),
            reportedOn: z.string(),
            type: esocialEventType,
            errors: z.array(reconciliationError),
            removal: z
              .object({
                id: z.string().uuid(),
                receipt: z.string(),
                status: z.string(),
                reportedOn: z.string(),
                errors: z.array(reconciliationError),
              })
              .partial()
              .passthrough(),
          })
          .partial()
          .passthrough()
      ),
      payrolls: z.record(workerReconciliationPayrollEntry),
      payments: z.record(workerReconciliationPaymentEntry),
      customInformation: workerReconciliationInputCustomInformation.optional(),
    })
    .passthrough()
);
const spreadsheetFileType = z.enum([
  'contracts',
  'payroll',
  'thirteenth',
  'termination',
  'advance',
  'complementary',
  'complementaryTermination',
  'pjContracts',
  'historicalEvents',
  'internalTransfer',
  'overrideReporting',
  'rpa',
]);
const spreadsheetFileEntry = auditableAPIEntry.and(
  z
    .object({
      spreadsheetId: z.string().uuid().optional(),
      organizationId: z.string().uuid(),
      fileType: spreadsheetFileType,
      fileName: z.string().uuid(),
      rows: z.number(),
      status: z.enum(['processing', 'completed', 'failed']),
      logsVersion: z.string().optional(),
    })
    .passthrough()
);
const BRCnae = z.string();
const BRFpas = z.string();
const BRLegalEntity = z
  .object({
    nomeFantasia: z.string(),
    razaoSocial: z.string(),
    cnpj: z.string().regex(/^[0-9]{2}.[0-9]{3}.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/),
    classificacao: z.enum(['matriz', 'filial']).optional(),
    endereco: BREndereco.optional(),
    cnae: z
      .object({
        principal: BRCnae.min(7).max(7),
        secundarios: z.array(BRCnae).optional(),
      })
      .passthrough(),
    rat: z.record(z.number()),
    fap: z.number().gte(0.5).lte(2),
    lotacaoTributaria: z
      .object({
        tipo: z.string(),
        codigo: z.string(),
        fpas: BRFpas.min(3),
        terceiros: z
          .object({
            ativo: z.string().min(4).max(4),
            suspenso: z.string().min(4).max(4),
          })
          .partial()
          .passthrough()
          .optional(),
      })
      .passthrough(),
  })
  .passthrough();
const LegalEntitySummary = auditableAPIEntry.and(
  z
    .object({
      organizationId: z.string().uuid(),
      companyId: z.string().uuid(),
      legalEntityId: z.string().uuid(),
      countryCode: z.string().min(2).max(2),
      br: BRLegalEntity.optional(),
    })
    .passthrough()
);
const LegalEntityList = paginatedResult.and(
  z
    .object({ unfilteredTotal: z.number(), data: z.array(LegalEntitySummary) })
    .partial()
    .passthrough()
);
const LegalEntityPayload = effectiveStartDate.and(
  z
    .object({
      organizationId: z.string().uuid(),
      companyId: z.string().uuid(),
      countryCode: z.string().min(2).max(2),
      br: BRLegalEntity.optional(),
    })
    .passthrough()
);
const LegalEntityEntry = auditableAPIEntry
  .and(LegalEntityPayload)
  .and(z.object({ legalEntityId: z.string().uuid() }).passthrough());
const payrollCalculationOutputsTypeName = z.literal('vacationInputsPreview');
const queryPayrollCalculationOutputs_Body = payrollInput.and(
  z
    .object({ outputsType: payrollCalculationOutputsTypeName })
    .partial()
    .passthrough()
);
const payrollCalculationOutputsVacationsInputsPreviewBRInputs = z
  .object({
    dataDeInicio: z.string(),
    dataDeFim: z.string(),
    abonoPecuniario: z.boolean(),
    anteciparDecimoTerceiro: z.boolean(),
  })
  .passthrough();
const payrollCalculationOutputsVacationsInputsPreviewBRFeriasTotais = z
  .object({
    feriasQueTemDireito: z
      .object({
        dataDeInicio: z.string(),
        dataDeFim: z.string(),
        qtDiasDeFerias: z.number().int(),
      })
      .partial()
      .passthrough()
      .nullable(),
    feriasMaximo: z
      .object({
        dataDeInicio: z.string(),
        dataDeFim: z.string(),
        qtDiasDeFerias: z.number().int(),
      })
      .partial()
      .passthrough()
      .nullable(),
  })
  .partial()
  .passthrough();
const payrollCalculationOutputsVacationsInputsPreviewBRFeriasData = z
  .object({
    dataDeInicio: z.string(),
    dataDeFim: z.string(),
    dataLimiteParaPagamento: z.string(),
    qtDiasDeFerias: z.number().int(),
    qtDiasDeAbono: z.number().int(),
    qtDiasDeFeriasDisponiveis: z.number().int(),
    qtDiasDeAbonoDisponiveis: z.number().int(),
    qtDiasTotal: z.number().int(),
    qtDiasTotalDisponiveis: z.number().int(),
    qtDiasTotalDisponiveisAposGozo: z.number().int(),
    anoVigenteParaAdiantamentoDeDecimoTerceiro: z.string(),
    adiantouDecimoTerceiroNoAnoVigente: z.boolean(),
  })
  .partial()
  .passthrough();
const payrollCalculationOutputsVacationsInputsPreviewBRFeriasPorPeriodoAquisitivoItem =
  z
    .object({
      feriasQueTemDireito:
        payrollCalculationOutputsVacationsInputsPreviewBRFeriasData.nullable(),
      feriasMaximo:
        payrollCalculationOutputsVacationsInputsPreviewBRFeriasData.nullable(),
      periodoAquisitivo: z
        .object({
          dataDeInicio: z.string(),
          dataDeFim: z.string(),
          qtDiasDeFaltasInjustificadas: z.number().int(),
        })
        .partial()
        .passthrough(),
      periodoConcessivo: z
        .object({
          dataDeInicio: z.string(),
          dataDeFim: z.string(),
          dataLimiteParaConcessao: z.string(),
        })
        .partial()
        .passthrough(),
    })
    .partial()
    .passthrough();
const payrollCalculationOutputsVacationsInputsPreview = z
  .object({
    br: z
      .object({
        inputs: payrollCalculationOutputsVacationsInputsPreviewBRInputs,
        feriasTotais:
          payrollCalculationOutputsVacationsInputsPreviewBRFeriasTotais,
        feriasPorPeriodoAquisitivo: z.array(
          payrollCalculationOutputsVacationsInputsPreviewBRFeriasPorPeriodoAquisitivoItem
        ),
      })
      .passthrough(),
  })
  .passthrough();
const payrollCalculationOutputsByType = z
  .object({
    vacationInputsPreview: payrollCalculationOutputsVacationsInputsPreview,
  })
  .partial()
  .passthrough();
const admissionDraftStatus = z.enum([
  'none',
  'draft_created',
  'invite_sent',
  'user_created',
  'admission_partially_filled_admin',
  'admission_partially_filled_user',
  'admission_submitted',
  'admission_approved',
  'contract_created',
  'archived',
]);
const admissionDraftSummaryBody = z
  .object({
    organizationId: z.string(),
    legalEntityId: z.string(),
    companyId: z.string(),
    draftId: z.string(),
    contractId: z.string().optional(),
    draftStatus: admissionDraftStatus,
    active: z.boolean().optional(),
    contractType: z.string(),
    workerCategory: z.string(),
    name: z.string().optional(),
    email: z.string().email().optional(),
    admissionDate: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    inviteDate: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    limitDate: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    department: z.string().optional(),
    title: z.string().uuid().optional(),
    jobTitleName: z.string().optional(),
    allSteps: z.number(),
    doneSteps: z.number(),
    matricula: z.string().optional(),
    workerId: z.string().optional(),
  })
  .passthrough();
const admissionDraftSummary = admissionDraftSummaryBody.and(auditableAPIEntry);
const admissionDraftList = paginatedResult
  .and(withMetadataResult)
  .and(
    z
      .object({
        filters: z
          .object({
            contractTypes: z.array(
              z
                .object({ name: z.string(), count: z.number().int().gte(0) })
                .passthrough()
            ),
            draftStatuses: z
              .array(
                z
                  .object({ name: z.string(), count: z.number().int().gte(0) })
                  .passthrough()
              )
              .optional(),
            departments: z.array(
              z
                .object({ name: z.string(), count: z.number().int().gte(0) })
                .passthrough()
            ),
            titles: z.array(
              z
                .object({
                  id: z.string(),
                  name: z.string(),
                  count: z.number().int().gte(0),
                })
                .passthrough()
            ),
          })
          .passthrough(),
        data: z.array(admissionDraftSummary),
      })
      .passthrough()
  );
const admissionDraftPeopleInput = z
  .object({
    countryCode: z.string().min(2).max(2),
    br: z
      .object({
        pessoa: contractBRCltEntryPessoa,
        nascimento: contractBRCltEntryNascimento,
        endereco: contractBRCltEntryEndereco,
        deficiencia: contractBRCltEntryDeficiencia,
        contato: contractBRCltEntryContato,
        dependentes: z.array(contractBRCltDependent).max(99),
      })
      .partial()
      .passthrough()
      .optional(),
  })
  .passthrough();
const admissionDraftInputFormSectionEnum = z.enum([
  'payroll_data',
  'personal_data',
]);
const admissionDraftInputFormStepEnum = z.enum([
  'modalidade_contrato',
  'criacao_usuario',
  'profissional',
  'regime_trabalho',
  'jornada',
  'duracao',
  'sindicato',
  'remuneracao',
  'envio_convite',
  'dados_pessoais',
  'formacao_academica',
  'endereco_e_contatos',
  'deficiencias',
  'dependentes',
  'dados_bancarios',
  'foto_perfil',
  'documentos',
  'finaliza_admissao',
  'estagio_info',
  'pj_info',
  'dados_empresa',
  'custom_fields_worker',
  'custom_fields_admin',
  'custom_fields',
]);
const admissionDraftInputFormStep = z
  .object({
    section: admissionDraftInputFormSectionEnum,
    step: admissionDraftInputFormStepEnum,
    started: z.boolean().optional(),
    completed: z.boolean().optional(),
    reviewed: z.boolean().optional(),
  })
  .passthrough();
const admissionDraftInputFormSteps = z.record(admissionDraftInputFormStep);
const admissionDraftInputFormInfo = z
  .object({ formSections: z.record(admissionDraftInputFormSteps) })
  .passthrough();
const admissionType = z.enum(['admin_fills', 'send_invite']);
const admissionDraftInputBody = z
  .object({
    companyId: z.string().uuid(),
    legalEntityId: z.string().uuid(),
    workerCategory: z.string(),
    workerId: z.string().min(1).max(30).optional(),
    userId: z.string().uuid().optional(),
    person: admissionDraftPeopleInput.optional(),
    formInfo: admissionDraftInputFormInfo,
    admissionType: admissionType,
    approvalWorkflow: z.string().optional(),
    userData: userInput.optional(),
    customFields: contractCustomFields.optional(),
  })
  .passthrough();
const admissionDraftContractBRPjCreate = z
  .object({
    emailCorp: z.string().min(6).max(60).email(),
    pessoa: contractBRPjEntryPessoa,
    nascimento: contractBRPjEntryNascimento,
    contato: contractBRPjEntryContato,
    endereco: contractBRPjEntryEndereco,
    dependentes: z.array(contractBRPjEntryDependente).max(99),
    prestador: contractBRPjEntryPrestador,
    empresa: contractBRPjEntryEmpresa,
    pagamento: contractBRPjEntryPagamento,
    contrato: contractBRPjEntryContrato,
  })
  .partial()
  .passthrough();
const admissionDraftContractBRCltCreate = z
  .object({
    trabalho: contractBRCltEntryTrabalho,
    estagio: contractBRCltEntryEstagio,
    vinculo: contractBRCltEntryVinculo,
    regime: contractBRCltEntryRegime,
    jornada: contractBRCltEntryJornada,
    remuneracao: contractBRCltEntryRemuneracao,
    duracao: contractBRCltEntryDuracao,
    sucessao: contractBRCltEntrySucessao,
    pagamento: contractBRCltEntryPagamento,
    gestao: contractBRCltEntryGestao,
    aprendiz: contractBRCltEntryAprendiz,
    observacao: z.string().min(1).max(255),
  })
  .partial()
  .passthrough();
const admissionDraftInput = admissionDraftInputBody
  .and(effectiveStartDate)
  .and(
    z.union([
      z
        .object({
          contractType: z.literal('br:pj'),
          br: admissionDraftContractBRPjCreate,
        })
        .passthrough(),
      z
        .object({
          contractType: z.literal('br:clt'),
          br: admissionDraftContractBRCltCreate,
        })
        .passthrough(),
    ])
  );
const admissionDraftContractBRCltEntry = z
  .object({
    trabalho: contractBRCltEntryTrabalho,
    estagio: contractBRCltEntryEstagio,
    vinculo: contractBRCltEntryVinculo,
    regime: contractBRCltEntryRegime,
    jornada: contractBRCltEntryJornada,
    remuneracao: contractBRCltEntryRemuneracao,
    duracao: contractBRCltEntryDuracao,
    sucessao: contractBRCltEntrySucessao,
    pagamento: contractBRCltEntryPagamento,
    gestao: contractBRCltEntryGestao,
    aprendiz: contractBRCltEntryAprendiz,
    observacao: z.string().min(1).max(255),
  })
  .partial()
  .passthrough();
const admissionDraftContractBRPjEntry = z
  .object({
    emailCorp: z.string().min(6).max(60).email(),
    pessoa: contractBRPjEntryPessoa,
    nascimento: contractBRPjEntryNascimento,
    contato: contractBRPjEntryContato,
    endereco: contractBRPjEntryEndereco,
    dependentes: z.array(contractBRPjEntryDependente).max(99),
    prestador: contractBRPjEntryPrestador,
    empresa: contractBRPjEntryEmpresa,
    pagamento: contractBRPjEntryPagamento,
    contrato: contractBRPjEntryContrato,
  })
  .partial()
  .passthrough();
const admissionDraftEntry = effectiveStartDate
  .and(auditableAPIEntry)
  .and(
    z
      .object({
        organizationId: z.string().uuid(),
        companyId: z.string().uuid(),
        legalEntityId: z.string().uuid(),
        workerCategory: z.string(),
        draftId: z.string().uuid(),
        userId: z.string().uuid().optional(),
        userData: userInput.optional(),
        person: admissionDraftPeopleInput.optional(),
        draftStatus: admissionDraftStatus,
        workerId: z.string().min(1).max(30).optional(),
        formInfo: admissionDraftInputFormInfo,
        admissionType: admissionType,
        approvalWorkflow: z.string().optional(),
        contractId: z.string().uuid().optional(),
        inviteDate: z.string().datetime({ offset: true }).optional(),
        lastUpdatedByUsername: z.string().optional(),
        profilePicture: z
          .object({ pictureId: z.string(), pictureUrl: z.string() })
          .partial()
          .passthrough()
          .optional(),
      })
      .passthrough()
  )
  .and(
    z.union([
      z
        .object({
          contractType: z.literal('br:clt'),
          br: admissionDraftContractBRCltEntry,
        })
        .passthrough(),
      z
        .object({
          contractType: z.literal('br:pj'),
          br: admissionDraftContractBRPjEntry,
        })
        .passthrough(),
    ])
  )
  .and(
    z.object({ customFields: contractCustomFields }).partial().passthrough()
  );
const pessoa = z
  .object({
    cpfTrab: z.string().min(11).max(11),
    nmTrab: z.string().min(2).max(70),
    nmSoc: z.string().min(2).max(70).nullable(),
  })
  .partial()
  .passthrough();
const nascimento = z
  .object({
    dtNascto: z
      .string()
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .nullable(),
    paisNascto: BRPaises.nullable(),
    paisNac: BRPaises.nullable(),
  })
  .partial()
  .passthrough();
const contato = z
  .object({
    fonePrinc: z.string().min(8).max(13).nullable(),
    emailPrinc: z.string().min(6).max(60).email().nullable(),
  })
  .partial()
  .passthrough();
const endereco = z.union([
  contractBRPjUpdateAddress,
  z
    .object({
      tipo: z.literal('exterior'),
      paisResid: BRPaises,
      dscLograd: z.string().min(1).max(100),
      nrLograd: z.string().min(1).max(10),
      complemento: z.string().min(1).max(30).nullable(),
      bairro: z.string().min(1).max(90).nullable(),
      nmCid: z.string().min(2).max(50),
      codPostal: z.string().min(4).max(12).nullable(),
    })
    .partial()
    .passthrough(),
]);
const admissionDraftContractBRPjUpdate = z
  .object({
    emailCorp: z.string().min(6).max(60).email(),
    pessoa: pessoa,
    nascimento: nascimento.nullable(),
    contato: contato.nullable(),
    endereco: endereco.nullable(),
    dependentes: z.array(contractBRPjEntryDependente).max(99).nullable(),
    prestador: contractBRPjUpdatePrestador,
    empresa: contractBRPjUpdateEmpresa,
    pagamento: contractBRPjUpdatePagamento,
    contrato: contractBRPjUpdateContrato,
  })
  .partial()
  .passthrough();
const admissionDraftContractBRCltUpdate = z
  .object({
    trabalho: contractBRCltEntryTrabalho,
    estagio: contractBRCltEntryEstagio,
    vinculo: contractBRCltEntryVinculo,
    regime: contractBRCltEntryRegime,
    jornada: contractBRCltEntryJornada,
    remuneracao: contractBRCltEntryRemuneracao,
    duracao: contractBRCltEntryDuracao,
    sucessao: contractBRCltEntrySucessao,
    pagamento: contractBRCltEntryPagamento,
    gestao: contractBRCltEntryGestao,
    aprendiz: contractBRCltEntryAprendiz,
    observacao: z.string().min(1).max(255),
  })
  .partial()
  .passthrough();
const admissionDraftUpdateInput = z
  .object({
    legalEntityId: z.string().uuid(),
    workerId: z.string().min(1).max(30),
    userId: z.string().uuid(),
    workerCategory: z.string(),
    draftStatus: admissionDraftStatus,
    formInfo: admissionDraftInputFormInfo,
    person: admissionDraftPeopleInput,
    userData: userInput,
    admissionType: admissionType,
    approvalWorkflow: z.string(),
    contractId: z.string().uuid(),
    inviteDate: z.string().datetime({ offset: true }),
    customFields: contractCustomFields,
  })
  .partial()
  .passthrough()
  .and(effectiveStartDate)
  .and(
    z.union([
      z
        .object({
          contractType: z.literal('br:pj'),
          br: admissionDraftContractBRPjUpdate.optional(),
        })
        .passthrough(),
      z
        .object({
          contractType: z.literal('br:clt'),
          br: admissionDraftContractBRCltUpdate.optional(),
        })
        .passthrough(),
    ])
  );
const admissionDraftReviewInput = z
  .object({ contractType: z.enum(['br:clt', 'br:pj']) })
  .passthrough();
const admissionInviteInput = z
  .object({
    companyId: z.string().uuid(),
    workerName: z.string().min(1).max(1024),
    workerEmail: z.string().email(),
    draftId: z.string().uuid(),
    contractType: z.enum(['br:clt', 'br:pj']),
  })
  .passthrough();
const admissionDraftAttachmentType = z.enum([
  'rg',
  'cnh',
  'cpf',
  'titulo_eleitor',
  'certidao_nascimento',
  'certidao_casamento',
  'certificado_formacao',
  'passaporte',
  'comprovante_residencia',
  'cartao_cnpj',
  'contrato_social',
  'outro',
]);
const admissionDraftAttachmentAllowedContentType = z.enum([
  'application/pdf',
  'image/jpeg',
  'image/jpg',
  'image/png',
]);
const admissionDraftAttachmentInput = z
  .object({
    companyId: z.string().uuid(),
    fileName: z.string().min(1).max(255),
    type: admissionDraftAttachmentType,
    formStep: admissionDraftInputFormStepEnum,
    dependentId: z.string().uuid().optional(),
    contentType: admissionDraftAttachmentAllowedContentType,
    contentLength: z.number().int(),
  })
  .passthrough();
const admissionDraftAttachmentStatus = z.enum([
  'waitingUpload',
  'uploaded',
  'deleted',
]);
const admissionDraftAttachmentEntry = auditableVersionedEntry.and(
  z
    .object({
      id: z.string().uuid(),
      organizationId: z.string().uuid(),
      companyId: z.string().uuid(),
      draftId: z.string().uuid(),
      dependentId: z.string().uuid().optional(),
      status: admissionDraftAttachmentStatus,
      type: admissionDraftAttachmentType,
      formStep: admissionDraftInputFormStepEnum,
      fileName: z.string().min(1).max(255),
      contentType: z.string().min(1).max(255),
      contentLength: z.number().int(),
      deletedOn: z.string().datetime({ offset: true }).optional(),
      deletedBy: z.string().uuid().optional(),
      downloadUrl: z.string().optional(),
      uploadUrl: z.string().optional(),
    })
    .passthrough()
);
const admissionDraftAttachmentList = z.array(admissionDraftAttachmentEntry);
const vacationsConfigurationPaymentConfig = z
  .object({
    typeOfDays: z.enum(['calendar', 'banking']),
    amountOfDaysBeforeStart: z.number().gte(0),
    adminsAllowedToChange: z.boolean(),
    paymentWeekDays: z.array(
      z.enum(['monday', 'tuesday', 'wednesday', 'thursday', 'friday'])
    ),
    approvalDeadlineThresholdDays: z.number().gte(0),
  })
  .passthrough();
const vacationsExtraDaysForCollectiveHolidays = z
  .object({
    day: z.number().int().gte(1).lte(31),
    month: z.number().int().gte(1).lte(12),
    onlyWeekdays: z.boolean(),
    start: z.string(),
    end: z.string(),
  })
  .passthrough();
const vacationsConfigurationRule = z
  .object({ id: z.string(), enabled: z.boolean() })
  .passthrough();
const vacationsConfigurationEntry = z
  .object({
    organizationId: z.string().uuid(),
    membershipType: z.string(),
    paymentDateConfiguration: vacationsConfigurationPaymentConfig,
    extraDaysForCollectiveHolidays: z
      .object({
        nonDeductibleDays: z.array(vacationsExtraDaysForCollectiveHolidays),
      })
      .partial()
      .passthrough()
      .optional(),
    rules: z.record(vacationsConfigurationRule),
  })
  .passthrough();
const groupVacationsConfiguration = z
  .object({ rules: z.record(vacationsConfigurationRule) })
  .passthrough();
const vacationsConfigurationInput = z
  .object({
    paymentDateConfiguration: vacationsConfigurationPaymentConfig,
    daysBeforePaymentDateToCreatePayroll: z.number().optional(),
    extraDaysForCollectiveHolidays: z
      .object({
        nonDeductibleDays: z.array(vacationsExtraDaysForCollectiveHolidays),
      })
      .passthrough()
      .optional(),
    groupVacationsConfiguration: groupVacationsConfiguration,
    rules: z.record(z.record(vacationsConfigurationRule)),
  })
  .passthrough();
const vacationsConfigurationList = z
  .object({
    organizationId: z.string().uuid(),
    paymentDateConfiguration: vacationsConfigurationPaymentConfig,
    rules: z.record(z.record(vacationsConfigurationRule)),
  })
  .passthrough();
const groupVacationsContractInput = z
  .object({
    contractId: z.string().uuid(),
    daysSold: z.number().int(),
    doesNotHaveAvailableDaysPolicy: z
      .enum(['paidLeave', 'goBackToWork'])
      .optional(),
  })
  .passthrough();
const groupVacationsInput = z
  .object({
    startDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    endDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    contracts: z.array(groupVacationsContractInput),
    paymentDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    type: z.enum(['group', 'individual']),
  })
  .passthrough();
const groupVacationsEntry = z
  .object({
    organizationId: z.string().uuid(),
    id: z.string().uuid(),
    startDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    endDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    contracts: z.array(groupVacationsContractInput),
    status: z.enum(['creating', 'created', 'error']),
  })
  .passthrough();
const groupVacationsList = z.array(groupVacationsEntry);
const vacationsScheduleStatus = z.enum([
  'waitingApproval',
  'approved',
  'rejected',
  'canceled',
  'payrollCreated',
  'payrollApproved',
  'payrollArchived',
  'created',
  'contractTerminated',
]);
const vacationsScheduleAccrualPeriod = z
  .object({
    startDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    endDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    concessionPeriod: z
      .object({
        startDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        endDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
      })
      .passthrough(),
  })
  .passthrough();
const vacationsScheduleReviewEntry = z
  .object({
    action: z.enum(['approve', 'reject', 'terminate', 'cancel']),
    comment: z.string().optional(),
    userId: z.string().uuid(),
    userName: z.string(),
    date: z.string().datetime({ offset: true }),
  })
  .passthrough();
const vacationsWorkerData = z
  .object({
    name: z.string(),
    department: z.string(),
    location: z
      .object({ city: z.string(), state: z.string() })
      .passthrough()
      .optional(),
  })
  .passthrough();
const vacationsScheduleEntry = auditableVersionedEntry.and(
  z
    .object({
      organizationId: z.string().uuid(),
      contractId: z.string().uuid(),
      companyId: z.string().uuid(),
      legalEntityId: z.string().uuid(),
      sequence: z.number(),
      startDate: dateYYYYMMDD.regex(
        /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
      ),
      endDate: dateYYYYMMDD.regex(
        /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
      ),
      status: vacationsScheduleStatus,
      daysTaken: z.number().gte(0),
      daysSold: z.number().gte(0),
      thirteenthAdvance: z.boolean(),
      accrualPeriod: vacationsScheduleAccrualPeriod,
      review: vacationsScheduleReviewEntry.optional(),
      workerData: vacationsWorkerData,
      approvalDeadline: dateYYYYMMDD
        .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
        .optional(),
      payrollId: z.string().uuid().optional(),
      paymentDate: dateYYYYMMDD.regex(
        /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
      ),
      type: z.enum(['individual', 'group']),
      groupVacationsId: z.string().uuid().optional(),
      doesNotHaveAvailableDaysPolicy: z
        .enum(['goBackToWork', 'paidLeave'])
        .optional(),
    })
    .passthrough()
);
const vacationsAccrualPeriodSummary = z
  .object({
    id: z.string().uuid(),
    organizationId: z.string().uuid(),
    companyId: z.string().uuid(),
    contractId: z.string().uuid(),
    status: z.string(),
    workerData: vacationsWorkerData.optional(),
    totalDaysAvailable: z.number().optional(),
    totalDaysOverdue: z.number().optional(),
    currentVacationsAccrualPeriodStartDate: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    currentVacationsAccrualPeriodEndDate: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    limitDateToStartVacations: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    hasAvailableVacations: z.boolean().optional(),
    hasOverdueVacations: z.boolean().optional(),
    hasVacationsCloseToDueDate: z.boolean().optional(),
  })
  .passthrough();
const vacationsAccrualPeriodList = paginatedResult
  .and(withMetadataResult)
  .and(
    z.object({ data: z.array(vacationsAccrualPeriodSummary) }).passthrough()
  );
const vacationsScheduleSummary = z
  .object({
    id: z.string(),
    organizationId: z.string().uuid(),
    companyId: z.string().uuid(),
    contractId: z.string().uuid(),
    sequence: z.number(),
    startDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    endDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    status: vacationsScheduleStatus,
    daysTaken: z.number(),
    daysSold: z.number(),
    thirteenthAdvance: z.boolean(),
    hasDaysSold: z.boolean().optional(),
    accrualPeriod: vacationsScheduleAccrualPeriod,
    workerData: vacationsWorkerData,
    approvalDeadline: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    paymentDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    type: z.enum(['individual', 'group']),
  })
  .passthrough();
const vacationsScheduleList = paginatedResult
  .and(withMetadataResult)
  .and(z.object({ data: z.array(vacationsScheduleSummary) }).passthrough());
const vacationsAccrualPeriodEntry = z
  .object({
    startDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    endDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    absences: z.number(),
    thirteenthAdvanceByYear: z.array(z.string()),
    daysOnSickLeave: z.number().gte(0),
    daysTaken: z.number().gte(0),
    daysSold: z.number().gte(0),
    daysAvailable: z.number().gte(0),
    daysOverdue: z.number().gte(0),
    daysScheduled: z.number().gte(0),
    lost: z
      .object({ reason: z.literal('leaves') })
      .passthrough()
      .optional(),
    maximumAvailableDaysForTypeOfContract: z.number(),
    maximumAvailableDaysForWorker: z.number(),
    maximumAvailableDaysForSelling: z.number(),
    concessionPeriod: z
      .object({
        startDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        endDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
        limitDateToStartVacations: dateYYYYMMDD
          .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
          .optional(),
      })
      .passthrough()
      .optional(),
    fractionsAcquired: z.number(),
    daysAcquired: z.number(),
    shortenedByGroupVacations: z.boolean(),
  })
  .passthrough();
const contractVacationsAccrualPeriodsEntry = z
  .object({
    organizationId: z.string().uuid(),
    contractId: z.string().uuid(),
    companyId: z.string().uuid(),
    legalEntityId: z.string().uuid(),
    accrualPeriods: z.array(vacationsAccrualPeriodEntry),
    nextSequence: z.number(),
  })
  .passthrough();
const vacationsAccrualPeriodScheduledVacations = z
  .object({
    accrualPeriod: vacationsAccrualPeriodEntry,
    schedules: z.array(vacationsScheduleEntry),
    nextSequence: z.number(),
  })
  .passthrough();
const vacationsScheduleInput = z
  .object({
    startDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    endDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    sequence: z.number().gte(0),
    daysSold: z.number().gte(0),
    thirteenthAdvance: z.boolean(),
    accrualPeriodStart: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
    paymentDate: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
  })
  .passthrough();
const vacationsScheduleUpdate = z
  .object({
    startDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    endDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    daysSold: z.number().gte(0),
    thirteenthAdvance: z.boolean(),
    paymentDate: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
  })
  .partial()
  .passthrough();
const vacationsScheduleCancelInput = z
  .object({ version: z.number() })
  .passthrough();
const vacationsScheduleReviewInput = z
  .object({ version: z.number() })
  .passthrough()
  .and(
    z.union([
      z.object({ review: z.literal('approve') }).passthrough(),
      z
        .object({
          review: z.literal('reject'),
          reason: z.string().min(0).max(500),
        })
        .passthrough(),
      z
        .object({
          review: z.enum(['cancel', 'terminate']),
          reason: z.string().min(0).max(500).optional(),
        })
        .passthrough(),
    ])
  );
const vacationsIngestContractHistoryInput = z
  .object({ companyId: z.string().uuid() })
  .passthrough();
const vacationsIngestHistoryInput = z
  .object({
    organizationId: z.string().uuid(),
    companyId: z.string().uuid(),
    legalEntityId: z.string().uuid().optional(),
    contractId: z.string().uuid(),
    period: z.string().min(4).max(7),
    date: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    type: payrollTypes,
    source: z.enum(['migration', 'retool', 'spreadsheets', 'UNSAFE_OVERRIDE']),
    events: z.array(
      z.union([
        payrollHistoricalEventComissoes,
        payrollHistoricalEventDecimoTerceiro,
        payrollHistoricalEventDsr,
        payrollHistoricalEventFaltas,
        payrollHistoricalEventFerias,
        payrollHistoricalEventHoras,
        payrollHistoricalEventInsuficienciaDeSaldo,
        payrollHistoricalEventRecolhimentoDeImpostosMensais,
        payrollHistoricalEventDiasAfastados,
        payrollHistoricalEventAdicionalDeInsalubridade,
        payrollHistoricalEventAdicionalPorTempoDeServico,
        payrollHistoricalEventPensaoAlimenticiaPaga,
      ])
    ),
    eventsPriorToOverride: z
      .array(z.object({}).partial().passthrough())
      .optional(),
  })
  .passthrough();
const vacationsHistoryDeleteInput = z
  .object({
    organizationId: z.string().uuid(),
    companyId: z.string().uuid(),
    contractId: z.string().uuid(),
    period: z.string().min(4).max(7),
    date: dateYYYYMMDD.regex(
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    ),
    type: payrollTypes,
  })
  .passthrough();
const peopleLookupInput = z.object({ identifier: z.string() }).passthrough();
const peopleLookupEntry = z.object({ personId: z.string() }).passthrough();
const accountingEntriesMappingRules = z
  .object({
    rules: z.array(
      z
        .object({
          name: z.string().optional(),
          description: z.string().optional(),
          filters: z.array(
            z
              .object({
                contractId: z.array(z.string()),
                legalEntityId: z.array(z.string()),
                companyId: z.array(z.string()),
                costCenterId: z.array(z.string()),
              })
              .partial()
              .passthrough()
          ),
          mappings: z.array(
            z
              .object({
                description: z.string(),
                source: z.array(z.string()),
                credit: z.string(),
                debit: z.string(),
                allocation: z.string().optional(),
              })
              .passthrough()
          ),
        })
        .passthrough()
    ),
  })
  .passthrough();
const periodId = dateYYYYMM;
const accountingChartsOfAccounts = z
  .object({
    charts: z.record(
      z
        .object({
          id: z.string(),
          name: z.string(),
          accounts: z.record(
            z.object({ id: z.string(), name: z.string() }).passthrough()
          ),
        })
        .passthrough()
    ),
  })
  .passthrough();
const associationTypes = z.literal('workersAssociation');
const associationCreateBR = z
  .object({
    cnpj: z.string(),
    grau: z.literal('sindicato').optional(),
    codigo: z.string().optional(),
    grupo: z.enum(['trabalhador', 'empregador']).optional(),
    endereco: z
      .object({
        logradouro: z.string(),
        complemento: z.string().optional(),
        numero: z.string(),
        cidade: z.string(),
        estado: z.string().min(2).max(2),
      })
      .passthrough()
      .optional(),
    email: z.string().email().optional(),
    telefone: z.string().optional(),
    dtFimMandato: dateYYYYMMDD
      .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
      .optional(),
  })
  .passthrough();
const associationCreate = z
  .object({
    name: z.string().min(1).max(500),
    abbreviation: z.string().min(1).max(50).optional(),
    type: associationTypes,
    countryCode: z.string().min(2).max(2).optional(),
    br: associationCreateBR.optional(),
  })
  .passthrough();
const associationEntry = auditableVersionedEntry
  .and(associationCreate)
  .and(z.object({ id: z.string().uuid() }).passthrough());
const associationUpdate = z
  .object({
    version: z.number(),
    name: z.string().min(1).max(255).optional(),
    type: associationTypes.optional(),
    countryCode: z.string().min(2).max(2).optional(),
  })
  .passthrough();
const associationMemberCreate = z
  .object({
    organizationId: z.string().uuid(),
    companyId: z.string().uuid().optional(),
    associationId: z.string().uuid(),
  })
  .passthrough();
const associationMemberEntry = auditableVersionedEntry
  .and(associationMemberCreate)
  .and(
    z
      .object({ associationName: z.string(), associationCnpj: z.string() })
      .passthrough()
  );
const agreementRulesAttributeTypes = z.enum([
  'string',
  'number',
  'date',
  'table',
  'attribute',
]);
const agreementRulesAttribute = z
  .object({
    id: z.string(),
    name: z.string(),
    description: z.string().optional(),
    type: agreementRulesAttributeTypes,
  })
  .passthrough();
const agreementPeriod = z
  .object({ start: z.string(), end: z.string() })
  .passthrough();
const agreementRulesOperators = z.enum([
  'any',
  'equal',
  'has',
  'min',
  'max',
  'cap',
  'raise',
  'add',
  'subtract',
  'set',
]);
const agreementRulesExpression = z
  .object({ operator: agreementRulesOperators, content: z.string() })
  .passthrough();
const agreementRulesPreCondition = z
  .object({ attributeId: z.string(), expression: agreementRulesExpression })
  .passthrough();
const agreementRulesColumn = z
  .object({
    name: z.string(),
    attributeId: z.string(),
    preConditions: z.array(agreementRulesPreCondition).optional(),
  })
  .passthrough();
const agreementRulesCell = z.array(agreementRulesExpression);
const agreementRulesRow = z.array(agreementRulesCell);
const agreementRulesTable = z
  .object({
    name: z.string(),
    description: z.string().optional(),
    columns: z.array(agreementRulesColumn),
    rows: z.array(agreementRulesRow),
  })
  .passthrough();
const agreementCreate = z
  .object({
    description: z.string().min(1).max(255),
    agreementDate: z.string(),
    baseDate: z.string(),
    basePeriod: z
      .object({ start: z.string(), end: z.string() })
      .partial()
      .passthrough()
      .optional(),
    validityPeriod: agreementPeriod.optional(),
    retroactivePayment: z.boolean(),
    associationId: z.string().optional(),
    associationDetails: z
      .object({ cnpj: z.string().uuid(), name: z.string().min(1).max(255) })
      .partial()
      .passthrough()
      .optional(),
    countryCode: z.string().min(2).max(2),
    fileId: z.string().uuid().optional(),
    rules: z.array(agreementRulesTable).optional(),
    processNumber: z.string().min(1).max(255).optional(),
    type: agreementTypes,
  })
  .passthrough();
const agreementEntry = auditableVersionedEntry
  .and(agreementCreate)
  .and(z.object({ id: z.string().uuid() }).passthrough());
const agreementUpdate = z
  .object({
    version: z.number(),
    description: z.string().min(1).max(255).optional(),
    agreementDate: z.string().optional(),
    baseDate: z.string().optional(),
    basePeriod: z
      .object({ start: z.string(), end: z.string() })
      .partial()
      .passthrough()
      .optional(),
    validityPeriod: agreementPeriod.optional(),
    retroactivePayment: z.boolean().optional(),
    associationId: z.string().optional(),
    countryCode: z.string().min(2).max(2).optional(),
    fileId: z.string().uuid().optional(),
    processNumber: z.string().min(1).max(255).optional(),
    type: agreementTypes.optional(),
  })
  .passthrough();
const agreementExecutionCreate = z
  .object({
    retryOfId: z.string().uuid(),
    description: z.string().min(1).max(255),
    rules: z.array(agreementRulesTable),
    retroactivePayment: z
      .object({
        paymentPeriod: datePeriodFormat.regex(/^\d{4}(\-(0[1-9]|1[012]))?$/),
        complementaryTerminationPaymentDate: dateYYYYMMDD.regex(
          /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        ),
      })
      .partial()
      .passthrough(),
  })
  .partial()
  .passthrough();
const agreementExecutionState = z.enum([
  'dry_run_pending',
  'dry_run_starting',
  'dry_running',
  'dry_run_canceled',
  'dry_run_failed',
  'waiting_review',
  'apply_pending',
  'apply_starting',
  'applying',
  'apply_canceled',
  'apply_failed',
  'applied',
  'reversion_pending',
  'reversion_starting',
  'reverting',
  'reversion_canceled',
  'reversion_failed',
  'reverted',
  'removed',
]);
const agreementExecutionEntry = auditableVersionedEntry
  .and(agreementExecutionCreate)
  .and(
    z
      .object({
        agreementExecutionId: z.string().uuid(),
        organizationId: z.string().uuid(),
        agreementId: z.string().uuid(),
        state: agreementExecutionState,
        errorCode: z.string().min(1).max(100).optional(),
        errorMessage: z.string().min(1).max(255).optional(),
      })
      .passthrough()
  );
const agreementExecutionItemCreate = z
  .object({
    contract: z
      .object({
        id: z.string().uuid(),
        matricula: z.string().optional(),
        personId: z.string().optional(),
        personName: z.string().optional(),
      })
      .passthrough(),
  })
  .passthrough();
const agreementExecutionItemEntry = auditableVersionedEntry
  .and(agreementExecutionItemCreate)
  .and(
    z
      .object({
        agreementExecutionItemId: z.string().uuid(),
        agreementExecutionId: z.string().uuid(),
        agreementId: z.string().uuid(),
        organizationId: z.string().uuid(),
        state: agreementExecutionState,
        errorCode: z.string().min(1).max(100).optional(),
        errorMessage: z.string().min(1).max(255).optional(),
        manualChangeReason: z.string().min(1).max(2000).optional(),
        results: z.record(z.object({}).partial().passthrough()).optional(),
        patch: z
          .object({
            contractSalaryChange: contractSalaryChange.and(
              z
                .object({ events: z.array(contractSalaryChangeEventReference) })
                .partial()
                .passthrough()
            ),
          })
          .partial()
          .passthrough()
          .optional(),
      })
      .passthrough()
  );
const agreementExecutionItemUpdate = z
  .object({
    patch: z
      .object({
        contractSalaryChange: contractSalaryChange.and(
          z
            .object({ events: z.array(contractSalaryChangeEventReference) })
            .partial()
            .passthrough()
        ),
      })
      .partial()
      .passthrough()
      .optional(),
    manualChangeReason: z.string().max(2000).optional(),
    version: z.number(),
  })
  .passthrough();

export const schemas = {
  user,
  credentials,
  challenge,
  token,
  answer,
  changePasswordInput,
  startPasswordRecoveryInput,
  confirmPasswordRecoveryInput,
  userInput,
  userEntry,
  getUsersByIds_Body,
  userData,
  userUpdate,
  email,
  paginatedResult,
  auditableAPIEntry,
  membershipTypes,
  membershipSummary,
  membershipList,
  userLookupInput,
  userLookupEntry,
  organizationSummary,
  organizationList,
  organizationSettings,
  organizationInput,
  organizationEntry,
  organizationUpdateInput,
  membershipInput,
  membershipEntry,
  organizationPublicAssetsTypeEnum,
  organizationPublicAssetsInput,
  auditableVersionedEntry,
  organizationPublicAssetsEntry,
  BRClassificacaoTributaria,
  BREndereco,
  companyBRSummary,
  companySummary,
  companyList,
  companyBRInput,
  companyInput,
  companyBREntry,
  companyEntry,
  certificateInput,
  certificateEntry,
  searchPagination,
  searchSorting,
  searchFilteringObjectInput,
  dateYYYYMM,
  dateYYYYMMDD,
  dateTime,
  searchFilteringRange,
  searchFilteringInput,
  searchCountingInputCounters,
  searchCountingInput,
  searchInput,
  searchCountingOutputCounters,
  withMetadataResult,
  datePeriodFormat,
  payrollTypes,
  payrollStatus,
  payrollWorkerData,
  payrollCalculationTotals,
  payrollVacationsEntry,
  payrollInputsTerminationReason,
  payrollInputsTerminationNoticePeriodNotWorkedReason,
  payrollInputsBrInput,
  payrollTerminationEntry,
  payrollSummary,
  payrollList,
  payrollConfigurationsBasisOverride,
  payrollConfigurationPaymentConfig,
  BRPreferences,
  payrollConfigurationInput,
  payrollConfigurationEntry,
  payrollConfigurationUpdate,
  payrollHistoricalEventComissoes,
  payrollHistoricalEventDecimoTerceiro,
  payrollHistoricalEventDsr,
  payrollHistoricalEventFaltas,
  payrollHistoricalEventFerias,
  payrollHistoricalEventHoras,
  payrollHistoricalEventInsuficienciaDeSaldo,
  payrollHistoricalEventImpostoRecolhidoElement,
  payrollHistoricalEventImpostoRecolhido,
  payrollHistoricalEventRecolhimentoDeImpostosMensais,
  payrollHistoricalEventDiasAfastados,
  payrollHistoricalEventAdicionalDeInsalubridade,
  payrollHistoricalEventAdicionalPorTempoDeServico,
  payrollHistoricalEventPensaoAlimenticiaPaga,
  payrollHistoricalEventInput,
  payrollHistoricalEventDelete,
  dateYYYY,
  period,
  payrollPeriodStatus,
  payrollPeriodTotalizersEntityCount,
  payrollPeriodTotalizersCounts,
  payrollPeriodTotalizersSection,
  payrollPeriodTotalizersElementTotals,
  payrollPeriodTotalizersTotals,
  payrollPeriodTotalizers,
  payrollPeriodBr,
  payrollPeriodEntry,
  patchPeriod_Body,
  payrollInputsEvent,
  payrollInputsElement,
  payrollRecord,
  payrollInputsAdvance,
  payrollInputsComplementary,
  esocialTpAcConv,
  esocialRemunSuc,
  payrollInputsComplementaryTermination,
  payrollInputsThirteenth,
  payrollInputsTermination,
  payrollInputsVacations,
  payrollInputsRpa,
  payrollInputsInputs,
  payrollInput,
  payrollOutputsSection,
  payrollElementMetaElement,
  payrollElementMetaBasis,
  payrollElementMetaDescription,
  payrollElementMetaCompensation,
  payrollElementMetaBody,
  payrollElementAlert,
  payrollElementMeta,
  payrollElement,
  payrollOutputsTermination,
  payrollOutputsVacations,
  payrollOutputsOutputs,
  payrollEntry,
  payrollOrderInput,
  payrollOrder,
  payrollOrderExecution,
  payrollOrderExecutionOutput,
  payrollOrderResult,
  payrollOrderResultOutput,
  payrollOrderOutput,
  BRRubricTipo,
  BRRubricNatureza,
  BRRubricCodIncCP,
  BRRubricCodIncIRRF,
  BRRubricCodIncFGTS,
  BRRubricProcesso,
  BRRubric,
  rubricsTableRubric,
  rubricsTableEntry,
  rubricsTableMap,
  rubricInput,
  rubricEntry,
  rubricPatch,
  payrollUpdate,
  payrollChangePaymentDateInput,
  recalculatePayroll_Body,
  payrollPayslipStatus,
  payrollPayslipEntry,
  payrollPayslipPdf,
  payrollPeriodSummaryStatus,
  payrollPeriodTotalizersSummary,
  payrollPeriodSummary,
  payrollPayslipSummary,
  payrollPayslipList,
  payrollPeriodSummaryEntry,
  payrollInputsTypes,
  payrollInputsTargets,
  payrollInputsPayloadTypes,
  payrollInputsTags,
  payrollInputsEntry,
  payrollInputsPayload,
  payrollInputsConfig,
  payrollInputsUpdate,
  payrollInputsPayroll,
  payrollInputsList,
  payrollInputsSubmissionValues,
  payrollInputsSubmissionInput,
  payrollInputsSubmissionEntry,
  payrollInputsSubmissionStatus,
  payrollInputsSubmissionDetails,
  conditionOr,
  conditionAnd,
  conditionFieldValue,
  conditionCheck,
  conditionAlways,
  condition,
  notificationToolkitTemplates,
  notificationRecipient,
  notificationAction,
  notificationTiming,
  notificationConfigurationInput,
  notificationConfiguration,
  notificationConfigurationsList,
  notificationTest,
  pjPaymentRequestInvoiceBrTaxes,
  pjPaymentRequestInvoice,
  pjPaymentRequestInput,
  pjContractorData,
  pjPaymentRequestEntry,
  pjPaymentRequestSummary,
  pjPaymentRequestList,
  pjPaymentRequestInvoiceUpdate,
  pjPaymentRequestUpdate,
  pjPaymentAttachmentEntry,
  pjPaymentAttachmentList,
  pjPaymentAttachmentAllowedContentTypes,
  pjPaymentAttachmentInput,
  orgChartContract,
  contract_types,
  contractBRCltGeralCodigoCategoria,
  contractBRCltAutonomoCodigoCategoria,
  contractBRCltCodigoCategoria,
  customFieldBaseProperties,
  customFieldSelectOption,
  customFieldDefinition,
  customFieldEntry,
  customFieldUpdateBaseProperties,
  customFieldUpdateInput,
  workerProvisionsInput,
  pjPaymentRequestApprove,
  pjPaymentRequestRejection,
  contractCustomFields,
  contractInputBody,
  contractBRPjEntryPessoa,
  BRPaises,
  contractBRPjEntryNascimento,
  contractBRPjEntryContato,
  contractBRContatoDeEmergencia,
  BRTipoLogradouro,
  BREstados,
  contractBRPjAddress,
  contractBRPjEntryEndereco,
  contractBRPjEntryDependente,
  contractBRPjEntryGestao,
  contractBRPjEntryPosicao,
  contractBRPjEntryPrestador,
  contractBRPjEntryEmpresa,
  BRTipoPix,
  contractBRPjEntryPagamento,
  contractBRPjEntryContrato,
  contractBRPjCreate,
  contractBRCltSexo,
  contractBRCltRacaCor,
  contractBRCltEstadoCivil,
  contractBRCltGrauInstrucao,
  contractBRCltEntryPessoa,
  contractBRCltTempoResidencia,
  contractBRCltCondicaoIngresso,
  contractBRCltEntryNascimento,
  contractBRCltAddress,
  contractBRCltForeignAddress,
  contractBRCltEntryEndereco,
  contractBRCltEntryDeficiencia,
  contractBRCltEntryContato,
  contractBRCltTipoDependente,
  contractBRCltDependent,
  contractBRCltEntryTrabalho,
  contractBRCltNaturezaEstagio,
  contractBRCltNivelEstagio,
  contractBRCltEntryEstagio,
  contractBRCltTipoRegimeTrabalhista,
  contractBRCltTipoRegimePrevidenciario,
  contractBRCltEntryVinculo,
  contractBRCltTipoAdmissao,
  contractBRCltIndAdmissao,
  contractBRCltTipoRegimeJornada,
  contractBRCltNaturezaAtividade,
  contractBRCltDataBase,
  contractBRCltEntryRegime,
  contractBRCltTipoJornada,
  contractBRCltContratoRegimeParcial,
  contractBRCltEntryJornada,
  contractBRCltUnidadeSalarioFixo,
  agreementTypes,
  contractBRCltEntryRemuneracao,
  contractBRCltTipoContrato,
  contractBRCltEntryDuracao,
  contractBRCltTipoInsc,
  contractBRCltEntrySucessao,
  contractBRCltEntryPagamento,
  contractBRCltEntryGestao,
  contractBRCltEntryAprendizDireto,
  contractBRCltEntryAprendizIndireto,
  contractBRCltEntryAprendiz,
  contractBRCltEsocialNrRecibo,
  contractBRCltEntryOptions,
  contractBRCltCreate,
  contractInput,
  effectiveStartDate,
  contractStatus,
  contractOrgStructure,
  contractBRCltCodigoMotivoAfastamentoDoencaOuAcidente,
  contractBRCltCodigoMotivoAfastamentoMaternidade,
  contractBRCltCodigoMotivoAfastamentoProrrogacaoMaternidade,
  contractBRCltCodigoMotivoAfastamentoFeriasOuRecesso,
  contractBRCltCodigoMotivoAfastamentoOutrosMotivos,
  contractBRCltCodigoMotivoAfastamento,
  contractBRCltSituacaoAfastado,
  contractBRCltSituacaoAtivo,
  contractBRCltSituacaoDesligado,
  contractBRCltEntrySituacao,
  contractBRCltEsocialStatus,
  contractBRCltEsocialStatusEntry,
  contractBRCltEntry,
  contractBRPjEntry,
  contractEntry,
  contractSummaryBody,
  contractBRCltSummary,
  contractSummary,
  contractList,
  contractEsocialIssues_Body,
  effectiveDateBody,
  contractBRContatoDeEmergenciaUpdate,
  contractBRCltUpdate,
  contractBRPjUpdateAddress,
  contractBRPjUpdatePrestador,
  contractBRPjUpdateEmpresa,
  contractBRPjUpdatePagamento,
  contractBRPjUpdateContrato,
  contractBRPjUpdate,
  contractUpdate,
  eventSourcingEvent,
  eventSourcingEventWithAuthor,
  terminationDateBody,
  contractBrCltTermination,
  contractTermination,
  contractInternalTransfer,
  companyTransferContract_Body,
  contractVacationsBody,
  contractBrCltVacations,
  contractBrPjVacations,
  contractVacations,
  contractProfilePictureInput,
  contractProfilePictureResponse,
  contractBRCltEsocialStatusInput,
  contractBRCltPensaoAlimenticiaDivisaoPorDependente,
  contractBRCltPensaoAlimenticiaDivisaoDoFGTS,
  payrollRecurringEventBrPensaoAlimenticiaPagamentoFixo,
  payrollRecurringEventBrPensaoAlimenticiaPagamentoPercentualDeSalarioMinimo,
  payrollRecurringEventBrPensaoAlimenticiaCustomizacaoDaBaseDeRendimentos,
  payrollRecurringEventBrPensaoAlimenticiaPagamentoPercentualDeRendimentos,
  payrollRecurringEventBrPensaoAlimenticiaSentenca,
  contractBRCltPensaoAlimenticiaInput,
  contractBRCltPensaoAlimenticiaEntry,
  contractBRCltPensaoAlimenticiaSearchInput,
  contractBRCltPensaoAlimenticiaSearchOutput,
  contractBRCltGrauExp,
  contractBRCltInsalubridadeCore,
  contractBRCltInsalubridadeInput,
  contractBRCltInsalubridadeEntry,
  contractBRCltInsalubridadeSearchInput,
  contractBRCltInsalubridadeSearchOutput,
  contractSummaryList,
  contractLeavesInputBody,
  contractBRCltLeavesInputDoencaOuAcidente,
  contractBRCltLeavesInputMaternidade,
  contractBRCltLeavesInputOutrosMotivos,
  contractBRCltLeavesInput,
  contractBRPjLeavesInput,
  contractLeavesInput,
  contractLeavesCreationOutput,
  contractLeavesEntryBody,
  contractBRCltLeavesEntryDoencaOuAcidente,
  contractBRCltLeavesEntryMaternidade,
  contractBRCltLeavesEntryOutrosMotivos,
  contractBRCltLeavesEntry,
  contractBRPjLeavesEntry,
  contractLeavesEntry,
  contractLeavesPatch,
  contractLeavesDelete,
  contractLeavesSummaryBody,
  contractBRCltLeavesSummaryDoencaOuAcidente,
  contractBRCltLeavesSummaryMaternidade,
  contractBRCltLeavesSummaryOutrosMotivos,
  contractBRCltLeavesSummary,
  contractBRPjLeavesSummary,
  contractLeavesSummary,
  contractLeavesList,
  payrollCheckIfEditableInput,
  payrollCheckIfEditableOutput,
  payrollRecurringEventBaseInputDates,
  payrollRecurringEventBrInsalubridadeInput,
  payrollRecurringEventBrPensaoAlimenticiaInput,
  payrollRecurringEventInput,
  payrollRecurirnEventBaseEntry,
  payrollRecurringEventBrInsalubridadeEntry,
  payrollRecurringEventBrPensaoAlimenticiaEntry,
  payrollRecurringEventEntry,
  contractOrgStructureUpdateManager,
  contractSalaryChange,
  contractSalaryChangeEventReference,
  jobTitleSummary,
  jobTitleList,
  jobTitleInput,
  jobTitleEntry,
  costCenterSummary,
  costCenterList,
  costCenterInput,
  costCenterEntry,
  reportsDefinitionsSummary,
  reportsDefinitionsList,
  reportsInput,
  reportsOutput,
  reportsError,
  formFieldSetDefinition,
  formUIType,
  formFieldOption,
  formFieldNormalDefinition,
  formFieldBranchingOption,
  formFieldBranchingDefinition,
  formFieldDefinition,
  formDefinition,
  reportsDefinitionsEntry,
  terminationInfo,
  esocialPayrollPreviousPeriodInfo,
  workerReconciliationPayrollInputCustomInformation,
  esocialPayrollBrMultiplosVinculosInfo,
  esocialPayrollBrMetadadosDeRubricas,
  workerReconciliationPayrollInput,
  workerReconciliationInputCustomInformationReportingOverride,
  esocialEventType,
  customEventInput,
  esocialEventMessage,
  esocialBaseEvent,
  esocialEventWaitingStatus,
  esocialWaitingEvent,
  esocialEventNonWaitingStatus,
  esocialEventOccurrence,
  esocialEventReplySummary,
  esocialEventRequest,
  esocialEventResponse,
  esocialNonWaitingEvent,
  esocialEventEntry,
  esocialEventDeleteInput,
  esocialEventSummary,
  esocialEventList,
  esocialPostRubricEntry_Body,
  reconciliationEntry,
  reconciliationInput,
  workerReconciliationSearchInput,
  workerReconciliationType,
  workerReconciliationSummary,
  workerReconciliationList,
  reconciliationError,
  workerReconciliationPayrollEntry,
  workerReconciliationPaymentEntry,
  workerReconciliationInputCustomInformation,
  workerReconciliationEntry,
  spreadsheetFileType,
  spreadsheetFileEntry,
  BRCnae,
  BRFpas,
  BRLegalEntity,
  LegalEntitySummary,
  LegalEntityList,
  LegalEntityPayload,
  LegalEntityEntry,
  payrollCalculationOutputsTypeName,
  queryPayrollCalculationOutputs_Body,
  payrollCalculationOutputsVacationsInputsPreviewBRInputs,
  payrollCalculationOutputsVacationsInputsPreviewBRFeriasTotais,
  payrollCalculationOutputsVacationsInputsPreviewBRFeriasData,
  payrollCalculationOutputsVacationsInputsPreviewBRFeriasPorPeriodoAquisitivoItem,
  payrollCalculationOutputsVacationsInputsPreview,
  payrollCalculationOutputsByType,
  admissionDraftStatus,
  admissionDraftSummaryBody,
  admissionDraftSummary,
  admissionDraftList,
  admissionDraftPeopleInput,
  admissionDraftInputFormSectionEnum,
  admissionDraftInputFormStepEnum,
  admissionDraftInputFormStep,
  admissionDraftInputFormSteps,
  admissionDraftInputFormInfo,
  admissionType,
  admissionDraftInputBody,
  admissionDraftContractBRPjCreate,
  admissionDraftContractBRCltCreate,
  admissionDraftInput,
  admissionDraftContractBRCltEntry,
  admissionDraftContractBRPjEntry,
  admissionDraftEntry,
  pessoa,
  nascimento,
  contato,
  endereco,
  admissionDraftContractBRPjUpdate,
  admissionDraftContractBRCltUpdate,
  admissionDraftUpdateInput,
  admissionDraftReviewInput,
  admissionInviteInput,
  admissionDraftAttachmentType,
  admissionDraftAttachmentAllowedContentType,
  admissionDraftAttachmentInput,
  admissionDraftAttachmentStatus,
  admissionDraftAttachmentEntry,
  admissionDraftAttachmentList,
  vacationsConfigurationPaymentConfig,
  vacationsExtraDaysForCollectiveHolidays,
  vacationsConfigurationRule,
  vacationsConfigurationEntry,
  groupVacationsConfiguration,
  vacationsConfigurationInput,
  vacationsConfigurationList,
  groupVacationsContractInput,
  groupVacationsInput,
  groupVacationsEntry,
  groupVacationsList,
  vacationsScheduleStatus,
  vacationsScheduleAccrualPeriod,
  vacationsScheduleReviewEntry,
  vacationsWorkerData,
  vacationsScheduleEntry,
  vacationsAccrualPeriodSummary,
  vacationsAccrualPeriodList,
  vacationsScheduleSummary,
  vacationsScheduleList,
  vacationsAccrualPeriodEntry,
  contractVacationsAccrualPeriodsEntry,
  vacationsAccrualPeriodScheduledVacations,
  vacationsScheduleInput,
  vacationsScheduleUpdate,
  vacationsScheduleCancelInput,
  vacationsScheduleReviewInput,
  vacationsIngestContractHistoryInput,
  vacationsIngestHistoryInput,
  vacationsHistoryDeleteInput,
  peopleLookupInput,
  peopleLookupEntry,
  accountingEntriesMappingRules,
  periodId,
  accountingChartsOfAccounts,
  associationTypes,
  associationCreateBR,
  associationCreate,
  associationEntry,
  associationUpdate,
  associationMemberCreate,
  associationMemberEntry,
  agreementRulesAttributeTypes,
  agreementRulesAttribute,
  agreementPeriod,
  agreementRulesOperators,
  agreementRulesExpression,
  agreementRulesPreCondition,
  agreementRulesColumn,
  agreementRulesCell,
  agreementRulesRow,
  agreementRulesTable,
  agreementCreate,
  agreementEntry,
  agreementUpdate,
  agreementExecutionCreate,
  agreementExecutionState,
  agreementExecutionEntry,
  agreementExecutionItemCreate,
  agreementExecutionItemEntry,
  agreementExecutionItemUpdate,
};

const endpoints = makeApi([
  {
    method: 'post',
    path: '/api/agreements',
    alias: 'postAgreement',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: agreementCreate,
      },
    ],
    response: agreementEntry,
  },
  {
    method: 'get',
    path: '/api/agreements',
    alias: 'listAgreements',
    requestFormat: 'json',
    response: z.array(agreementEntry),
  },
  {
    method: 'get',
    path: '/api/agreements/:agreementId',
    alias: 'getAgreement',
    requestFormat: 'json',
    parameters: [
      {
        name: 'agreementId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: agreementEntry,
  },
  {
    method: 'put',
    path: '/api/agreements/:agreementId',
    alias: 'putAgreement',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: agreementUpdate,
      },
      {
        name: 'agreementId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: agreementEntry,
  },
  {
    method: 'delete',
    path: '/api/agreements/:agreementId',
    alias: 'deleteAgreement',
    requestFormat: 'json',
    parameters: [
      {
        name: 'agreementId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/agreements/:agreementId/executions',
    alias: 'listAgreementExecutions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'agreementId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.array(agreementExecutionEntry),
  },
  {
    method: 'get',
    path: '/api/agreements/rules/attributes',
    alias: 'listRuleAttributes',
    requestFormat: 'json',
    response: z.array(agreementRulesAttribute),
  },
  {
    method: 'post',
    path: '/api/associations',
    alias: 'postAssociation',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: associationCreate,
      },
    ],
    response: associationEntry,
  },
  {
    method: 'get',
    path: '/api/associations',
    alias: 'listAssociations',
    requestFormat: 'json',
    parameters: [
      {
        name: 'document',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'type',
        type: 'Query',
        schema: z.literal('workersAssociation').optional(),
      },
    ],
    response: z.array(associationEntry),
  },
  {
    method: 'get',
    path: '/api/associations/:associationId',
    alias: 'getAssociation',
    requestFormat: 'json',
    parameters: [
      {
        name: 'associationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: associationEntry,
  },
  {
    method: 'put',
    path: '/api/associations/:associationId',
    alias: 'putAssociation',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: associationUpdate,
      },
      {
        name: 'associationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: associationEntry,
  },
  {
    method: 'delete',
    path: '/api/associations/:associationId',
    alias: 'deleteAssociation',
    requestFormat: 'json',
    parameters: [
      {
        name: 'associationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/associations/:associationId/members',
    alias: 'listAssociationMembers',
    requestFormat: 'json',
    parameters: [
      {
        name: 'associationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.array(associationMemberEntry),
  },
  {
    method: 'post',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/custom-events',
    alias: 'postCustomEsocialEvent',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: customEventInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.object({ eventId: z.string() }).passthrough(),
  },
  {
    method: 'get',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/events/:eventId',
    alias: 'getEsocialEvent',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'eventId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: esocialEventEntry,
  },
  {
    method: 'delete',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/events/:eventId',
    alias: 'deleteEsocialEvent',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: esocialEventDeleteInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'eventId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: NaN,
        description: `OK`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/events/search',
    alias: 'searchEsocialEvents',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Search parameters`,
        type: 'Body',
        schema: searchInput.optional(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'eventId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: esocialEventList,
  },
  {
    method: 'put',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/payrolls/:payrollId',
    alias: 'putPayrollEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: workerReconciliationPayrollInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'delete',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/payrolls/:payrollId',
    alias: 'deletePayrollEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/payrolls/:payrollId/override-reporting',
    alias: 'updateWorkerReconciliationReportingOverride',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: workerReconciliationInputCustomInformationReportingOverride,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'delete',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/payrolls/:payrollId/override-reporting',
    alias: 'deleteWorkerReconciliationReportingOverride',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/payrolls/:payrollId/override-reporting',
    alias: 'getWorkerReconciliationReportingOverride',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: workerReconciliationInputCustomInformationReportingOverride,
  },
  {
    method: 'get',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/reconciliations/:period',
    alias: 'getReconciliationEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
    ],
    response: reconciliationEntry,
  },
  {
    method: 'put',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/reconciliations/:period',
    alias: 'putReconciliationEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: reconciliationInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
    ],
    response: reconciliationEntry,
  },
  {
    method: 'delete',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/reconciliations/:period',
    alias: 'deleteReconciliationEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/reconciliations/:period/cleanup',
    alias: 'cleanUpReconciliationEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/reconciliations/:period/close',
    alias: 'closeReconciliationEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/reconciliations/:period/complementaryInfo',
    alias: 'postReconciliationComplementaryInfo',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z
          .object({ percentualNaoDesoneradoDeCP: z.string() })
          .passthrough(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/reconciliations/:period/handleError',
    alias: 'handleErrorReconciliation',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/reconciliations/:period/open',
    alias: 'openReconciliationEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/reconciliations/:period/recalculate',
    alias: 'recalculateReconciliationSummary',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/reconciliations/:period/reconcile',
    alias: 'reconcileReconciliationEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/reconciliations/:period/workers/:personId',
    alias: 'getWorkerReconciliationEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
      {
        name: 'personId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: workerReconciliationEntry,
  },
  {
    method: 'put',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/reconciliations/:period/workers/:personId',
    alias: 'putWorkerReconciliationEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
      {
        name: 'personId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: workerReconciliationEntry,
  },
  {
    method: 'post',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/reconciliations/:period/workers/:personId/cleanup',
    alias: 'cleanUpWorkerReconciliationEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
      {
        name: 'personId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/reconciliations/:period/workers/:personId/handleError',
    alias: 'handleErrorWorkerReconciliation',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
      {
        name: 'personId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/reconciliations/:period/workers/:personId/reconcile',
    alias: 'reconcileWorkerReconciliationEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
      {
        name: 'personId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/reconciliations/:period/workers/:personId/reopen',
    alias: 'reopenWorkerReconciliation',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
      {
        name: 'personId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/reconciliations/:period/workers/search',
    alias: 'searchWorkerReconciliations',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: workerReconciliationSearchInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
    ],
    response: workerReconciliationList,
  },
  {
    method: 'post',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/rubrics-tables/:tableId/rubrics/:rubricId',
    alias: 'esocialPostRubricEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: esocialPostRubricEntry_Body,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'tableId',
        type: 'Path',
        schema: z.string().min(1).max(8),
      },
      {
        name: 'rubricId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/esocial/organizations/:organizationId/companies/:companyId/rubrics-tables/proprietary',
    alias: 'esocialRubricsProprietaryPost',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/organizations/',
    alias: 'getOrganizations',
    requestFormat: 'json',
    parameters: [
      {
        name: 'query',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sortField',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sortOrder',
        type: 'Query',
        schema: z.enum(['asc', 'desc']).optional(),
      },
      {
        name: 'elementFilters',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: organizationList,
  },
  {
    method: 'post',
    path: '/api/organizations/',
    alias: 'postOrganizations',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: organizationInput,
      },
    ],
    response: organizationEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId',
    alias: 'getOrganizationEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: organizationEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId',
    alias: 'putOrganizationEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: organizationUpdateInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: organizationEntry,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId',
    alias: 'deleteOrganizationEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/accounting/chartsOfAccounts',
    alias: 'putChartsOfAccounts',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Charts of accounts`,
        type: 'Body',
        schema: accountingChartsOfAccounts,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: accountingChartsOfAccounts,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/accounting/chartsOfAccounts',
    alias: 'getChartsOfAccounts',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: accountingChartsOfAccounts,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/accounting/entriesMappingRules/:periodId',
    alias: 'putEntriesMappingRules',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Entries mapping rules for a period`,
        type: 'Body',
        schema: accountingEntriesMappingRules,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: periodId,
      },
    ],
    response: accountingEntriesMappingRules,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/accounting/entriesMappingRules/:periodId',
    alias: 'getEntriesMappingRules',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: periodId,
      },
    ],
    response: accountingEntriesMappingRules,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/accounting/entriesMappingRules/:periodId/compile',
    alias: 'postEntriesMappingRulesCompile',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: periodId,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/activate',
    alias: 'activateOrganizationEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/agreements/:agreementId/executions',
    alias: 'postAgreementExecution',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: agreementExecutionCreate,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: agreementExecutionEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/agreements/:agreementId/executions/:agreementExecutionId',
    alias: 'getAgreementExecution',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementExecutionId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: agreementExecutionEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/agreements/:agreementId/executions/:agreementExecutionId/apply',
    alias: 'applyAgreementExecution',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementExecutionId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: agreementExecutionEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/agreements/:agreementId/executions/:agreementExecutionId/cancel',
    alias: 'cancelAgreementExecution',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementExecutionId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: agreementExecutionEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/agreements/:agreementId/executions/:agreementExecutionId/dryrun',
    alias: 'dryRunAgreementExecution',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementExecutionId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: agreementExecutionEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/agreements/:agreementId/executions/:agreementExecutionId/revert',
    alias: 'revertAgreementExecution',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementExecutionId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: agreementExecutionEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/agreements/:agreementId/executions/items',
    alias: 'listAgreementExecutionItems',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementExecutionId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.array(agreementExecutionItemEntry),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/agreements/:agreementId/executions/items/:agreementExecutionItemId',
    alias: 'getAgreementExecutionItem',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementExecutionId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementExecutionItemId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: agreementExecutionItemEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/agreements/:agreementId/executions/items/:agreementExecutionItemId',
    alias: 'putAgreementExecutionItem',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: agreementExecutionItemUpdate,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementExecutionId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementExecutionItemId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: agreementExecutionItemEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/agreements/:agreementId/executions/items/:agreementExecutionItemId/remove',
    alias: 'removeAgreementExecutionItem',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementExecutionId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementExecutionItemId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: agreementExecutionItemEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/agreements/:agreementId/executions/items/:agreementExecutionItemId/revert',
    alias: 'revertAgreementExecutionItem',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementExecutionId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'agreementExecutionItemId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: agreementExecutionItemEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/associations',
    alias: 'listOrganizationMemberAssociations',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.array(associationMemberEntry),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/associations/:associationId',
    alias: 'postOrganizationAssociationMember',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'associationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: associationMemberEntry,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/associations/:associationId',
    alias: 'deleteOrganizationAssociationMember',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'associationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: associationMemberEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies',
    alias: 'getAllCompanies',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'query',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sortField',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sortOrder',
        type: 'Query',
        schema: z.enum(['asc', 'desc']).optional(),
      },
      {
        name: 'rangeFilters',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'elementFilters',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: companyList,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies',
    alias: 'postCompany',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: companyInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: companyEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId',
    alias: 'getCompanyEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: companyEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/companies/:companyId',
    alias: 'putCompanyEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: companyInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: companyEntry,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/companies/:companyId',
    alias: 'deleteCompanyEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/activate',
    alias: 'activateCompanyEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/associations',
    alias: 'listCompanyMemberAssociations',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.array(associationMemberEntry),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/associations/:associationId',
    alias: 'postCompanyAssociationMember',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'associationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: associationMemberEntry,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/companies/:companyId/associations/:associationId',
    alias: 'deleteCompanyAssociationMember',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'associationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: associationMemberEntry,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/certificates',
    alias: 'postCertificate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: certificateInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: certificateEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/contracts/:contractId/payrolls/:payrollId/payslips/pdf',
    alias: 'getPayrollPayslipPdf',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: payrollPayslipPdf,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/deactivate',
    alias: 'deactivateCompanyEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/legal-entities',
    alias: 'getLegalEntities',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'query',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sortField',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sortOrder',
        type: 'Query',
        schema: z.enum(['asc', 'desc']).optional(),
      },
      {
        name: 'rangeFilters',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'elementFilters',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: LegalEntityList,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/legal-entities',
    alias: 'createLegalEntity',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: LegalEntityPayload,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: LegalEntityEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/legal-entities/:legalEntityId',
    alias: 'getLegalEntity',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'legalEntityId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'effectiveDate',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sequenceId',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: LegalEntityEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/companies/:companyId/legal-entities/:legalEntityId',
    alias: 'putLegalEntity',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: LegalEntityEntry,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'legalEntityId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: LegalEntityEntry,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/companies/:companyId/legal-entities/:legalEntityId',
    alias: 'deleteLegalEntity',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'legalEntityId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls',
    alias: 'postPayroll',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Payroll to create`,
        type: 'Body',
        schema: payrollInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: payrollEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/:payrollId',
    alias: 'getPayroll',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: payrollEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/:payrollId',
    alias: 'putPayroll',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Payroll to update`,
        type: 'Body',
        schema: payrollUpdate,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/:payrollId',
    alias: 'deletePayroll',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/:payrollId/approve',
    alias: 'approvePayroll',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 412,
        description: `There are pending non-regular payrolls for this employee`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `There are payrolls for this employee with date after the termination date. Please archive them first.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/:payrollId/archive',
    alias: 'archivePayroll',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 412,
        description: `Payroll is in a status that does not allow archiving`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/:payrollId/change-payment-date',
    alias: 'changePayrollPaymentDate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Payroll to change the payment`,
        type: 'Body',
        schema: payrollChangePaymentDateInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 412,
        description: `Payroll is in a status that does not allow changing the paymentDate`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/:payrollId/payslips',
    alias: 'getPayrollPayslip',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: payrollPayslipEntry,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/:payrollId/payslips/send',
    alias: 'sendPayrollPayslip',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/:payrollId/recalculate',
    alias: 'recalculatePayroll',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Payroll to recalculate`,
        type: 'Body',
        schema: recalculatePayroll_Body,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 409,
        description: `Conflict`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/:payrollId/reopen',
    alias: 'reopenPayroll',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Payroll to reopen`,
        type: 'Body',
        schema: payrollUpdate,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 412,
        description: `Payroll is in a status that does not allow reopening`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/:payrollId/restore',
    alias: 'restorePayroll',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Payroll to restore`,
        type: 'Body',
        schema: payrollUpdate,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 412,
        description: `Payroll is in a status that does not allow restoring`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/:payrollId/rollback-to-approved',
    alias: 'rollbackToApprovedPayroll',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 412,
        description: `Payroll is in a status that does not allow rollback to approved`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/calculation-outputs',
    alias: 'queryPayrollCalculationOutputs',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Payroll input to query calculation outputs from`,
        type: 'Body',
        schema: queryPayrollCalculationOutputs_Body,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: payrollOutputsOutputs.and(payrollCalculationOutputsByType),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/configurations',
    alias: 'getAllCompanyPayrollConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z
      .object({ data: z.array(payrollConfigurationEntry) })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/configurations',
    alias: 'postCompanyPayrollConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Configuration to create`,
        type: 'Body',
        schema: payrollConfigurationInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: payrollConfigurationEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/configurations/:effectiveDate',
    alias: 'getCompanyPayrollConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'effectiveDate',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: payrollConfigurationEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/configurations/:effectiveDate',
    alias: 'putCompanyPayrollConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Payroll to update`,
        type: 'Body',
        schema: payrollConfigurationUpdate,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'effectiveDate',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/historical-events',
    alias: 'postPayrollsHistoricalEvents',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: payrollHistoricalEventInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/historical-events',
    alias: 'deletePayrollsHistoricalEvents',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: payrollHistoricalEventDelete,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/historical-events',
    alias: 'getPayrollsHistoricalEvents',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Query',
        schema: z.string().uuid().optional(),
      },
    ],
    response: z.array(z.unknown()),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/inputs',
    alias: 'getPayrollInputsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: payrollInputsConfig,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/inputs',
    alias: 'postPayrollInputsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.record(payrollInputsEntry),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: payrollInputsConfig,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/inputs',
    alias: 'putPayrollInputsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.record(payrollInputsEntry),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: payrollInputsConfig,
  },
  {
    method: 'patch',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/inputs',
    alias: 'patchPayrollInputsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: payrollInputsUpdate,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: payrollInputsConfig,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/inputs/periods/:periodId/types/:payrollType',
    alias: 'getPayrollInputsForPeriod',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: period,
      },
      {
        name: 'payrollType',
        type: 'Path',
        schema: z.enum([
          'monthly',
          'thirteenthFirst',
          'thirteenthSecond',
          'termination',
          'vacations',
          'advance',
          'complementary',
          'complementaryTermination',
          'provisions',
          'rpa',
        ]),
      },
    ],
    response: payrollInputsList,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/inputs/periods/:periodId/types/:payrollType',
    alias: 'postPayrollInputsForPeriod',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: payrollInputsSubmissionInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: period,
      },
      {
        name: 'payrollType',
        type: 'Path',
        schema: z.enum([
          'monthly',
          'thirteenthFirst',
          'thirteenthSecond',
          'termination',
          'vacations',
          'advance',
          'complementary',
          'complementaryTermination',
          'provisions',
          'rpa',
        ]),
      },
    ],
    response: z.object({ submissionId: z.string().uuid() }).passthrough(),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/inputs/periods/:periodId/types/:payrollType/submissions/:submissionId',
    alias: 'getPayrollInputsSubmissionDetails',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: period,
      },
      {
        name: 'payrollType',
        type: 'Path',
        schema: z.enum([
          'monthly',
          'thirteenthFirst',
          'thirteenthSecond',
          'termination',
          'vacations',
          'advance',
          'complementary',
          'complementaryTermination',
          'provisions',
          'rpa',
        ]),
      },
      {
        name: 'submissionId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: payrollInputsSubmissionDetails,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/payslips/search',
    alias: 'searchAllPayslips',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Search parameters`,
        type: 'Body',
        schema: searchInput.optional(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: payrollPayslipList,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/periods/:period',
    alias: 'getPeriod',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
    ],
    response: payrollPeriodEntry,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/periods/:period',
    alias: 'postPeriod',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
    ],
    response: payrollPeriodEntry,
  },
  {
    method: 'patch',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/periods/:period',
    alias: 'patchPeriod',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: patchPeriod_Body,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'period',
        type: 'Path',
        schema: period,
      },
    ],
    response: payrollPeriodEntry,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/periods/:periodId/approve',
    alias: 'approvePeriod',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: period,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/periods/:periodId/close',
    alias: 'closePeriod',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: period,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/periods/:periodId/handleError',
    alias: 'handleErrorPeriod',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: period,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/periods/:periodId/recalculate',
    alias: 'recalculatePeriod',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: period,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/periods/:periodId/reopen',
    alias: 'reopenPeriod',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: period,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/periods/:periodId/types/:payrollType/approve',
    alias: 'approveAllPayrolls',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: period,
      },
      {
        name: 'payrollType',
        type: 'Path',
        schema: z.enum([
          'monthly',
          'thirteenthFirst',
          'thirteenthSecond',
          'termination',
          'vacations',
          'advance',
          'complementary',
          'complementaryTermination',
          'provisions',
          'rpa',
        ]),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/periods/:periodId/types/:payrollType/payslips/pdf',
    alias: 'getAllPayrollPayslipPdf',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: period,
      },
      {
        name: 'payrollType',
        type: 'Path',
        schema: z.enum([
          'monthly',
          'thirteenthFirst',
          'thirteenthSecond',
          'termination',
          'vacations',
          'advance',
          'complementary',
          'complementaryTermination',
          'provisions',
          'rpa',
        ]),
      },
    ],
    response: payrollPayslipPdf,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/periods/:periodId/types/:payrollType/payslips/send',
    alias: 'sendAllPayrollPayslip',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: period,
      },
      {
        name: 'payrollType',
        type: 'Path',
        schema: z.enum([
          'monthly',
          'thirteenthFirst',
          'thirteenthSecond',
          'termination',
          'vacations',
          'advance',
          'complementary',
          'complementaryTermination',
          'provisions',
          'rpa',
        ]),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/periods/:periodId/types/:payrollType/summary',
    alias: 'getPeriodSummary',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: period,
      },
      {
        name: 'payrollType',
        type: 'Path',
        schema: z.enum([
          'monthly',
          'thirteenthFirst',
          'thirteenthSecond',
          'termination',
          'vacations',
          'advance',
          'complementary',
          'complementaryTermination',
          'provisions',
          'rpa',
        ]),
      },
    ],
    response: payrollPeriodSummaryEntry,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/periods/summaries/search',
    alias: 'searchAllPeriodsSummaries',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Search parameters`,
        type: 'Body',
        schema: searchInput.optional(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z
      .object({
        total: z.number(),
        size: z.number(),
        page: z.number(),
        data: z.array(payrollPeriodSummary),
        metadata: z
          .object({
            countByStatus: z.record(z.number()),
            payrolls: z.record(z.number()).optional(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/recalculate',
    alias: 'recalculatePayrolls',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Payroll to recalculate`,
        type: 'Body',
        schema: z
          .object({ tolerance: z.number().default(0) })
          .partial()
          .passthrough(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/rubrics-tables',
    alias: 'postRubricTableEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: rubricsTableEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/rubrics-tables',
    alias: 'getAllRubricTables',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'effectiveDate',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: rubricsTableMap,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/rubrics-tables/:tableId/rubrics',
    alias: 'postRubricEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: rubricInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'tableId',
        type: 'Path',
        schema: z.string().min(1).max(8),
      },
      {
        name: 'x-idempotency-key',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: rubricEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/rubrics-tables/:tableId/rubrics/:rubricId',
    alias: 'putRubricEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: rubricInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'rubricId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'tableId',
        type: 'Path',
        schema: z.string().min(1).max(8),
      },
      {
        name: 'x-idempotency-key',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: rubricEntry,
  },
  {
    method: 'patch',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/rubrics-tables/:tableId/rubrics/:rubricId',
    alias: 'patchRubricEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: rubricPatch,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'rubricId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'tableId',
        type: 'Path',
        schema: z.string().min(1).max(8),
      },
      {
        name: 'x-idempotency-key',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: rubricEntry,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/payrolls/search',
    alias: 'searchAllPayrolls',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Search parameters`,
        type: 'Body',
        schema: searchInput.optional(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: payrollList,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/contracts/:contractId/payments',
    alias: 'postContractPaymentRequests',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The payment request to be created`,
        type: 'Body',
        schema: pjPaymentRequestInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: pjPaymentRequestEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/contracts/:contractId/payments/:paymentRequestId',
    alias: 'getContractPaymentRequest',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'paymentRequestId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: pjPaymentRequestEntry,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/contracts/:contractId/payments/:paymentRequestId',
    alias: 'deleteContractPaymentRequest',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The version of the payment request that will be deleted`,
        type: 'Body',
        schema: z.object({ version: z.number().int() }).passthrough(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'paymentRequestId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/contracts/:contractId/payments/:paymentRequestId',
    alias: 'putContractPaymentRequest',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The payment request to be updated`,
        type: 'Body',
        schema: pjPaymentRequestUpdate,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'paymentRequestId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: pjPaymentRequestEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/contracts/:contractId/payments/:paymentRequestId/attachments',
    alias: 'getContractAllPaymentRequestsAttachment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'paymentRequestId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.array(pjPaymentAttachmentEntry),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/contracts/:contractId/payments/:paymentRequestId/attachments',
    alias: 'postContractPaymentRequestsAttachment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The payment request to be created`,
        type: 'Body',
        schema: pjPaymentAttachmentInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'paymentRequestId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: pjPaymentAttachmentEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/contracts/:contractId/payments/:paymentRequestId/attachments/:attachmentId',
    alias: 'getContractPaymentRequestAttachment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'paymentRequestId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'attachmentId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: pjPaymentAttachmentEntry,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/contracts/:contractId/payments/:paymentRequestId/attachments/:attachmentId',
    alias: 'deleteContractPaymentRequestAttachment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'paymentRequestId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'attachmentId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/contracts/:contractId/payments/search',
    alias: 'searchByContractPjPaymentRequests',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Search parameters`,
        type: 'Body',
        schema: searchInput.optional(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: pjPaymentRequestList,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/notification-configurations',
    alias: 'postPjPaymentsNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: notificationConfigurationInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: notificationConfiguration,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/notification-configurations',
    alias: 'listPjPaymentsNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.array(notificationConfiguration),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/notification-configurations/:resourceType',
    alias: 'testPjPaymentsNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: notificationTest,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/notification-configurations/:resourceType/rules/:ruleId',
    alias: 'getPjPaymentsNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'ruleId',
        type: 'Path',
        schema: z.unknown(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/notification-configurations/:resourceType/rules/:ruleId',
    alias: 'updatePjPaymentsNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: notificationConfiguration,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'ruleId',
        type: 'Path',
        schema: z.unknown(),
      },
    ],
    response: notificationConfiguration,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/notification-configurations/:resourceType/rules/:ruleId',
    alias: 'deletePjPaymentsNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'ruleId',
        type: 'Path',
        schema: z.unknown(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/payments',
    alias: 'postPaymentRequests',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The payment request to be created`,
        type: 'Body',
        schema: pjPaymentRequestInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: pjPaymentRequestEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/payments/:paymentRequestId',
    alias: 'getPaymentRequest',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'paymentRequestId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: pjPaymentRequestEntry,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/payments/:paymentRequestId',
    alias: 'deletePaymentRequest',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The version of the payment request that will be deleted`,
        type: 'Body',
        schema: z.object({ version: z.number().int() }).passthrough(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'paymentRequestId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/payments/:paymentRequestId',
    alias: 'putPaymentRequest',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The payment request to be updated`,
        type: 'Body',
        schema: pjPaymentRequestUpdate,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'paymentRequestId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: pjPaymentRequestEntry,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/payments/:paymentRequestId/approve',
    alias: 'approvePaymentRequest',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The version of the payment request to be approved`,
        type: 'Body',
        schema: z.object({ version: z.number() }).passthrough(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'paymentRequestId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: pjPaymentRequestEntry,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/payments/:paymentRequestId/approve',
    alias: 'removeApprovalPaymentRequest',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The version of the payment request to have the approval removed`,
        type: 'Body',
        schema: z.object({ version: z.number() }).passthrough(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'paymentRequestId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/payments/:paymentRequestId/attachments',
    alias: 'getAllPaymentRequestsAttachment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'paymentRequestId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.array(pjPaymentAttachmentEntry),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/payments/:paymentRequestId/attachments',
    alias: 'postPaymentRequestsAttachment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The payment request to be created`,
        type: 'Body',
        schema: pjPaymentAttachmentInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'paymentRequestId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: pjPaymentAttachmentEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/payments/:paymentRequestId/attachments/:attachmentId',
    alias: 'getPaymentRequestAttachment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'paymentRequestId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'attachmentId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: pjPaymentAttachmentEntry,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/payments/:paymentRequestId/attachments/:attachmentId',
    alias: 'deletePaymentRequestAttachment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'paymentRequestId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'attachmentId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/payments/:paymentRequestId/reject',
    alias: 'rejectPaymentRequest',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The version and reason of rejection`,
        type: 'Body',
        schema: pjPaymentRequestRejection,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'paymentRequestId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: pjPaymentRequestEntry,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/companies/:companyId/pj-payments/payments/search',
    alias: 'searchAllPjPaymentRequests',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Search parameters`,
        type: 'Body',
        schema: searchInput.optional(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'companyId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: pjPaymentRequestList,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts',
    alias: 'createContract',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: contractEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/contracts/:contractId',
    alias: 'getContract',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'effectiveDate',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sequenceId',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'includeDeleted',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: contractEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/contracts/:contractId',
    alias: 'putContract',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractUpdate,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'x-idempotency-key',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/contracts/:contractId',
    alias: 'deleteContract',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/:contractId/br/clt/esocial',
    alias: 'contractEsocialStatus',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `eSocial status`,
        type: 'Body',
        schema: contractBRCltEsocialStatusInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/:contractId/br/insalubridades',
    alias: 'postContractBrInsalubridade',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractBRCltInsalubridadeInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'ignoreCheckIfEditablePayrolls',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: contractBRCltInsalubridadeEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/contracts/:contractId/br/insalubridades/:insalubridadeId',
    alias: 'putContractBrInsalubridade',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractBRCltInsalubridadeInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'insalubridadeId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'versionId',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'ignoreCheckIfEditablePayrolls',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/contracts/:contractId/br/insalubridades/:insalubridadeId',
    alias: 'deleteContractBrInsalubridade',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'insalubridadeId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'ignoreCheckIfEditablePayrolls',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/:contractId/br/insalubridades/search',
    alias: 'searchContractBrInsalubridade',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractBRCltInsalubridadeSearchInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: contractBRCltInsalubridadeSearchOutput,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/:contractId/br/pensoes-alimenticias',
    alias: 'postContractBrPensaoAlimenticia',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractBRCltPensaoAlimenticiaInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'ignoreCheckIfEditablePayrolls',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: contractBRCltPensaoAlimenticiaEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/contracts/:contractId/br/pensoes-alimenticias/:idPensaoAlimenticia',
    alias: 'putContractBrPensaoAlimenticia',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractBRCltPensaoAlimenticiaInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'eventId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'versionId',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'ignoreCheckIfEditablePayrolls',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/contracts/:contractId/br/pensoes-alimenticias/:idPensaoAlimenticia',
    alias: 'deleteContractBrPensaoAlimenticia',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'eventId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'versionId',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'ignoreCheckIfEditablePayrolls',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/:contractId/br/pensoes-alimenticias/search',
    alias: 'searchContractBrPensaoAlimenticia',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractBRCltPensaoAlimenticiaSearchInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: contractBRCltPensaoAlimenticiaSearchOutput,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/:contractId/companyTransfer',
    alias: 'companyTransferContract',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: companyTransferContract_Body,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/contracts/:contractId/events',
    alias: 'getContractEvents',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.array(eventSourcingEvent),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/contracts/:contractId/events-page',
    alias: 'getContractEventsPage',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'cursor',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.string().regex(/^\d+$/).optional().default('50'),
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.enum(['asc', 'desc']).optional().default('desc'),
      },
    ],
    response: z
      .object({
        data: z.array(eventSourcingEventWithAuthor),
        nextCursor: z.string(),
      })
      .partial()
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/contracts/:contractId/events/:eventId',
    alias: 'deleteContractEvent',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'eventId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/contracts/:contractId/events/:eventId',
    alias: 'getContractEvent',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'eventId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: eventSourcingEvent,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/:contractId/internalTransfer',
    alias: 'internalTransferContract',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractInternalTransfer,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/:contractId/leaves',
    alias: 'registerContractLeaves',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractLeavesInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.object({ leaveId: z.string() }).passthrough(),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/contracts/:contractId/leaves/:leaveId',
    alias: 'getContractLeave',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'leaveId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'effectiveDate',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sequenceId',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: contractLeavesEntry,
  },
  {
    method: 'patch',
    path: '/api/organizations/:organizationId/contracts/:contractId/leaves/:leaveId',
    alias: 'patchContractLeave',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractLeavesPatch,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'leaveId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/contracts/:contractId/leaves/:leaveId',
    alias: 'deleteContractLeave',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractLeavesDelete.optional(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'leaveId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/:contractId/managers',
    alias: 'contractsUpdateManager',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractOrgStructureUpdateManager,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'x-idempotency-key',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/:contractId/payrolls/check-if-editable',
    alias: 'postPayrollsCheckIfEditable',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: payrollCheckIfEditableInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.object({ canBeEdited: z.boolean() }).passthrough(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/:contractId/payrolls/recurring-events',
    alias: 'postPayrollRecurringEvent',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Recurring event to create`,
        type: 'Body',
        schema: payrollRecurringEventInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: payrollRecurringEventEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/contracts/:contractId/payrolls/recurring-events/:recurringEventId',
    alias: 'getPayrollRecurringEvent',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'recurringEventId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: payrollRecurringEventEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/contracts/:contractId/payrolls/recurring-events/:recurringEventId',
    alias: 'putPayrollRecurringEvent',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Recurring event to put`,
        type: 'Body',
        schema: payrollRecurringEventInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'recurringEventId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'versionId',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/contracts/:contractId/payrolls/recurring-events/:recurringEventId',
    alias: 'deletePayrollRecurringEvent',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'recurringEventId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'versionId',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/contracts/:contractId/payslips',
    alias: 'listSentPayslips',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: payrollPayslipList,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/contracts/:contractId/payslips/:payrollId',
    alias: 'getSentPayslip',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'payrollId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: payrollPayslipEntry,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/:contractId/profile-pictures',
    alias: 'uploadContractProfilePicture',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractProfilePictureInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: contractProfilePictureResponse,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/:contractId/salary-changes',
    alias: 'postSalaryChange',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractSalaryChange,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.array(contractSalaryChangeEventReference),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/:contractId/terminate',
    alias: 'terminateContract',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractTermination,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/:contractId/vacations',
    alias: 'registerContractVacations',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractVacations,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/br/clt/esocialIssues',
    alias: 'contractEsocialIssues',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Search parameters`,
        type: 'Body',
        schema: contractEsocialIssues_Body.optional(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: contractList,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/leaves/search',
    alias: 'searchAllLeaves',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Search parameters`,
        type: 'Body',
        schema: searchInput.optional(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: contractLeavesList,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/notification-configurations',
    alias: 'postContractsotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: notificationConfigurationInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: notificationConfiguration,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/contracts/notification-configurations',
    alias: 'listContractsNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.array(notificationConfiguration),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/notification-configurations/:resourceType',
    alias: 'testContractsNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: notificationTest,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/contracts/notification-configurations/:resourceType/rules/:ruleId',
    alias: 'getContractsNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'ruleId',
        type: 'Path',
        schema: z.unknown(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/contracts/notification-configurations/:resourceType/rules/:ruleId',
    alias: 'updateContractsNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: notificationConfiguration,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'ruleId',
        type: 'Path',
        schema: z.unknown(),
      },
    ],
    response: notificationConfiguration,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/contracts/people/:personId',
    alias: 'contractsPerson',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'personId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: contractSummaryList,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/contracts/search',
    alias: 'searchAllContracts',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Search parameters`,
        type: 'Body',
        schema: searchInput.optional(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: contractList,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/cost-centers',
    alias: 'postCostCenter',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: costCenterInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: costCenterEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/cost-centers/:costCenterId',
    alias: 'getCostCenterEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'costCenterId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: costCenterEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/cost-centers/:costCenterId',
    alias: 'putCostCenterEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: costCenterInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'costCenterId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: costCenterEntry,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/cost-centers/:costCenterId',
    alias: 'deleteCostCenterEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'costCenterId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/cost-centers/:costCenterId/activate',
    alias: 'activateCostCenterEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'costCenterId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/cost-centers/:costCenterId/deactivate',
    alias: 'deactivateCostCenterEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'costCenterId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/cost-centers/search',
    alias: 'searchAllCostCenters',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Search parameters`,
        type: 'Body',
        schema: searchInput.optional(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: costCenterList,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/custom-fields',
    alias: 'listCustomFields',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contract-types',
        type: 'Query',
        schema: contract_types,
      },
      {
        name: 'app-visibility',
        type: 'Query',
        schema: z.enum(['contract', 'admission', 'customer-api']).optional(),
      },
      {
        name: 'user-visibility',
        type: 'Query',
        schema: z.enum(['admin', 'employee']).optional(),
      },
      {
        name: 'admission-registered-by',
        type: 'Query',
        schema: z.enum(['admin', 'worker']).optional(),
      },
    ],
    response: z.array(customFieldEntry),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/custom-fields',
    alias: 'createCustomField',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: customFieldDefinition,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: customFieldEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/custom-fields/:fieldName',
    alias: 'updateCustomField',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: customFieldUpdateInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'fieldName',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: customFieldEntry,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/custom-fields/:fieldName',
    alias: 'deleteCustomField',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'fieldName',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/deactivate',
    alias: 'deactivateOrganizationEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/job-titles',
    alias: 'getAllJobTitles',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'query',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sortField',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sortOrder',
        type: 'Query',
        schema: z.enum(['asc', 'desc']).optional(),
      },
      {
        name: 'rangeFilters',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'elementFilters',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: jobTitleList,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/job-titles',
    alias: 'postJobTitle',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: jobTitleInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: jobTitleEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/job-titles/:jobTitleId',
    alias: 'getJobTitleEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'jobTitleId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: jobTitleEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/job-titles/:jobTitleId',
    alias: 'putJobTitleEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: jobTitleInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'jobTitleId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: jobTitleEntry,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/job-titles/:jobTitleId',
    alias: 'deleteJobTitleEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'jobTitleId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/job-titles/:jobTitleId/activate',
    alias: 'activateJobTitleEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'jobTitleId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/job-titles/:jobTitleId/deactivate',
    alias: 'deactivateJobTitleEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'jobTitleId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/membership',
    alias: 'getAllMembership',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'query',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sortField',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sortOrder',
        type: 'Query',
        schema: z.enum(['asc', 'desc']).optional(),
      },
      {
        name: 'rangeFilters',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'elementFilters',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: membershipList,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/membership',
    alias: 'postMembership',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: membershipInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: membershipEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/membership/:membershipId',
    alias: 'getMembershipEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'membershipId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: membershipEntry,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/membership/:membershipId',
    alias: 'deleteMembershipEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'membershipId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/membership/:membershipId/activate',
    alias: 'activateMembershipEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'membershipId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/membership/:membershipId/deactivate',
    alias: 'deactivateMembershipEntry',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'membershipId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/membership/user/:userId',
    alias: 'getMembershipByUserId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'userId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: membershipEntry,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/onboarding/admission/draft',
    alias: 'admissionDraftPost',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: admissionDraftInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'x-idempotency-key',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: admissionDraftEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/onboarding/admission/draft/:draftId',
    alias: 'getAdmissionDraft',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'draftId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: admissionDraftEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/onboarding/admission/draft/:draftId',
    alias: 'putAdmissionDraft',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: admissionDraftUpdateInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'draftId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'x-idempotency-key',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: admissionDraftEntry,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/onboarding/admission/draft/:draftId/approve',
    alias: 'approveAdmissionDraftReview',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: admissionDraftReviewInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'draftId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/onboarding/admission/draft/:draftId/archive',
    alias: 'archiveAdmissionDraft',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'draftId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/onboarding/admission/draft/:draftId/attachments',
    alias: 'postAdmissionDraftAttachment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: admissionDraftAttachmentInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'draftId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: admissionDraftAttachmentEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/onboarding/admission/draft/:draftId/attachments',
    alias: 'getAllAdmissionDraftAttachments',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'draftId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.array(admissionDraftAttachmentEntry),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/onboarding/admission/draft/:draftId/attachments/:attachmentId',
    alias: 'getAdmissionDraftAttachment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'draftId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'attachmentId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: admissionDraftAttachmentEntry,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/onboarding/admission/draft/:draftId/attachments/:attachmentId',
    alias: 'deleteAdmissionDraftAttachment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'draftId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'attachmentId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/onboarding/admission/draft/:draftId/profile-pictures',
    alias: 'uploadAdmissionProfilePicture',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: contractProfilePictureInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'draftId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: contractProfilePictureResponse,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/onboarding/admission/draft/:draftId/submit',
    alias: 'submitAdmissionDraftReview',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: admissionDraftReviewInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'draftId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/onboarding/admission/draft/search',
    alias: 'searchAdmissionDrafts',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: searchInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: admissionDraftList,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/onboarding/admission/draft/users/:userId',
    alias: 'getAdmissionDraftByUser',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'userId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: admissionDraftEntry,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/onboarding/admission/invite',
    alias: 'postAdmissionInvite',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: admissionInviteInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/onboarding/notification-configurations',
    alias: 'postAdmissionNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: notificationConfigurationInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: notificationConfiguration,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/onboarding/notification-configurations',
    alias: 'listAdmissionNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.array(notificationConfiguration),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/onboarding/notification-configurations/:resourceType',
    alias: 'testAdmissionNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: notificationTest,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/onboarding/notification-configurations/:resourceType/rules/:ruleId',
    alias: 'getAdmissionNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'ruleId',
        type: 'Path',
        schema: z.unknown(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/onboarding/notification-configurations/:resourceType/rules/:ruleId',
    alias: 'updateAdmissionNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: notificationConfiguration,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'ruleId',
        type: 'Path',
        schema: z.unknown(),
      },
    ],
    response: notificationConfiguration,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/org-chart/contracts/:contractId/cycle',
    alias: 'CheckCycleInOrgChart',
    description: `Checks whether or not adding the manager to the contract would create a cycle in the org chart.
Notice that this operation WILL NOT add/replace the manager, it simply checks.
`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ contractId: z.string().uuid() }).passthrough(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.object({ cycle: z.boolean() }).passthrough(),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/org-chart/contracts/:contractId/managers',
    alias: 'GetContractManager',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.object({ contractId: z.string().uuid() }).passthrough(),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/org-chart/contracts/:contractId/reports',
    alias: 'GetContractReports',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'directReports',
        type: 'Query',
        schema: z.enum(['true', 'false']).optional().default('true'),
      },
    ],
    response: z.array(orgChartContract),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/payrolls/orders',
    alias: 'getPayrollOrders',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'withExecutions',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'withResults',
        type: 'Query',
        schema: z.boolean().optional(),
      },
    ],
    response: z.array(payrollOrderOutput),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/payrolls/orders',
    alias: 'postPayrollOrder',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Payroll order to create`,
        type: 'Body',
        schema: payrollOrderInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'retry',
        type: 'Query',
        schema: z.boolean().optional(),
      },
    ],
    response: payrollOrderOutput,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/payrolls/orders/:orderId',
    alias: 'getPayrollOrder',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'orderId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'withExecutions',
        type: 'Query',
        schema: z.boolean().optional().default(false),
      },
      {
        name: 'withResults',
        type: 'Query',
        schema: z.boolean().optional().default(false),
      },
    ],
    response: payrollOrderOutput,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/provisions/contracts/:contractId/periods/:periodId',
    alias: 'putProvisionsPeriod',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: workerProvisionsInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: period,
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 404,
        description: `Provisions Period not found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Provisions Period is closed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/provisions/contracts/:contractId/periods/:periodId',
    alias: 'getProvisionsPeriod',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: period,
      },
    ],
    response: z
      .object({
        sharedElements: z.record(payrollElement),
        provisions: z.record(
          z
            .object({
              provisionedInPeriod: z.string(),
              deposits: z.string(),
              withdrawals: z.string(),
              paymentsForFruition: z.string(),
              paymentsForIndemnification: z.string(),
              paymentsReversal: z.string(),
              writeOffs: z.string(),
              adjustments: z.string(),
              oldProvisionedTotal: z.string(),
              newProvisionedTotal: z.string(),
              elements: z.record(payrollElement),
            })
            .passthrough()
        ),
      })
      .passthrough(),
    errors: [
      {
        status: 404,
        description: `Provisions Period not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/provisions/contracts/:contractId/periods/:periodId/close',
    alias: 'closeWorkerProvisionsPeriod',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: period,
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 404,
        description: `Provisions Period not found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Cannot close period that is calculating`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/provisions/periods/:periodId/close',
    alias: 'closeProvisionsPeriod',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'periodId',
        type: 'Path',
        schema: period,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/public-assets/upload',
    alias: 'handlePostPublicAssets',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: organizationPublicAssetsInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: organizationPublicAssetsEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/reports',
    alias: 'getReportDefinitions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: reportsDefinitionsList,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/reports',
    alias: 'generateReport',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: reportsInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: reportsOutput,
    errors: [
      {
        status: 400,
        description: `BAD_REQUEST`,
        schema: reportsError,
      },
    ],
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/reports/:reportId',
    alias: 'getReportDefinition',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'reportId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: reportsDefinitionsEntry,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/reports/pdf',
    alias: 'generatePdfReport',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: reportsInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: reportsOutput,
    errors: [
      {
        status: 400,
        description: `BAD_REQUEST`,
        schema: reportsError,
      },
    ],
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/spreadsheets',
    alias: 'getSpreadsheets',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'query',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sortField',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sortOrder',
        type: 'Query',
        schema: z.enum(['asc', 'desc']).optional(),
      },
      {
        name: 'elementFilters',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: z.array(spreadsheetFileEntry),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/vacations/accrual-periods/search',
    alias: 'searchVacationsAccrualPeriods',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Search parameters`,
        type: 'Body',
        schema: searchInput.optional(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: vacationsAccrualPeriodList,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/vacations/configuration',
    alias: 'getVacationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: vacationsConfigurationEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/vacations/configuration',
    alias: 'putVacationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: vacationsConfigurationInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: vacationsConfigurationEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/vacations/configuration/list',
    alias: 'listVacationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: vacationsConfigurationList,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/vacations/contracts/:contractId/accrual-periods',
    alias: 'getContractAccrualPeriods',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'effectiveDate',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'forceExecution',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: contractVacationsAccrualPeriodsEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/vacations/contracts/:contractId/accrual-periods/:startDate/schedules',
    alias: 'getContractAccrualPeriodsScheduledVacations',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'startDate',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: vacationsAccrualPeriodScheduledVacations,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/vacations/contracts/:contractId/configuration',
    alias: 'getVacationsConfigurationByContract',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: vacationsConfigurationEntry,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/vacations/contracts/:contractId/history/ingest-contract-history',
    alias: 'postVacationsIngestContractHistory',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ companyId: z.string().uuid() }).passthrough(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/vacations/contracts/:contractId/history/ingest-historical-events',
    alias: 'postVacationsIngestHistory',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: vacationsIngestHistoryInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/vacations/contracts/:contractId/history/ingest-historical-events',
    alias: 'deleteVacationsHistory',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: vacationsHistoryDeleteInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/vacations/contracts/:contractId/schedules',
    alias: 'postVacationsSchedule',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: vacationsScheduleInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: vacationsScheduleEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/vacations/contracts/:contractId/schedules',
    alias: 'getContractVacationsSchedule',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: vacationsScheduleList,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/vacations/contracts/:contractId/schedules/:sequence',
    alias: 'getVacationsSchedule',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'sequence',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: vacationsScheduleEntry,
  },
  {
    method: 'put',
    path: '/api/organizations/:organizationId/vacations/contracts/:contractId/schedules/:sequence',
    alias: 'putVacationsSchedule',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'sequence',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: vacationsScheduleUpdate,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/vacations/contracts/:contractId/schedules/:sequence',
    alias: 'deleteVacationsSchedule',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ version: z.number() }).passthrough(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'sequence',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: vacationsScheduleEntry,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/vacations/contracts/:contractId/schedules/:sequence/create-payroll',
    alias: 'postVacationsScheduleCreatePayroll',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'sequence',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: vacationsScheduleEntry,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/vacations/contracts/:contractId/schedules/:sequence/review',
    alias: 'postReviewVacationsSchedule',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: vacationsScheduleReviewInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'sequence',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: vacationsScheduleEntry,
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/vacations/contracts/:contractId/schedules/validate',
    alias: 'postValidateVacationsSchedule',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: vacationsScheduleInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'contractId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/vacations/group',
    alias: 'postGroupVacations',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: groupVacationsInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: groupVacationsEntry,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/vacations/group',
    alias: 'getGroupVacations',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.array(groupVacationsEntry),
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/vacations/group/:groupId',
    alias: 'getGroupVacationSchedules',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'groupId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.array(vacationsScheduleEntry),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/vacations/notification-configurations',
    alias: 'postVacationsotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: notificationConfigurationInput,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: notificationConfiguration,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/vacations/notification-configurations',
    alias: 'listVacationsNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.array(notificationConfiguration),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/vacations/notification-configurations/:resourceType',
    alias: 'testVacationsNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: notificationTest,
  },
  {
    method: 'get',
    path: '/api/organizations/:organizationId/vacations/notification-configurations/:resourceType/rules/:ruleId',
    alias: 'getVacationsNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'ruleId',
        type: 'Path',
        schema: z.unknown(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/vacations/notification-configurations/:resourceType/rules/:ruleId',
    alias: 'updateNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: notificationConfiguration,
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'ruleId',
        type: 'Path',
        schema: z.unknown(),
      },
    ],
    response: notificationConfiguration,
  },
  {
    method: 'delete',
    path: '/api/organizations/:organizationId/vacations/notification-configurations/:resourceType/rules/:ruleId',
    alias: 'deleteVacationsNotificationsConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'resourceType',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'ruleId',
        type: 'Path',
        schema: z.unknown(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/organizations/:organizationId/vacations/schedules/search',
    alias: 'searchAllScheduledVacations',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Search parameters`,
        type: 'Body',
        schema: searchInput.optional(),
      },
      {
        name: 'organizationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: vacationsScheduleList,
  },
  {
    method: 'post',
    path: '/api/people/lookup',
    alias: 'lookupPerson',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ identifier: z.string() }).passthrough(),
      },
    ],
    response: z.object({ personId: z.string() }).passthrough(),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/user-auth/identity',
    alias: 'getUserIdentity',
    description: `Retrieves user information from authentication token`,
    requestFormat: 'json',
    response: user,
    errors: [
      {
        status: 401,
        description: `Invalid credentials`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/user-auth/login',
    alias: 'login',
    description: `Can respond either with bearer tokens and setting a refresh cookie or with an authentication challenge.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: credentials,
      },
    ],
    response: z.union([challenge, token]),
    errors: [
      {
        status: 400,
        description: `Invalid input`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Invalid credentials`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/user-auth/login/challenge',
    alias: 'respondToChallenge',
    description: `Can respond either with bearer tokens and setting a refresh cookie or with another authentication challenge.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: answer,
      },
    ],
    response: z.union([challenge, token]),
    errors: [
      {
        status: 400,
        description: `Invalid input`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Invalid session`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/user-auth/login/token',
    alias: 'refreshToken',
    description: `Uses refresh cookie to retrieve a new bearer token, redirects to login in case of failure`,
    requestFormat: 'json',
    response: z.object({ token: z.string() }).passthrough(),
    errors: [
      {
        status: 401,
        description: `Invalid refresh token`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/user-auth/logout',
    alias: 'logout',
    description: `Invalidates refresh token and clears user cookies`,
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/user-auth/password',
    alias: 'changePassword',
    description: `Updates a user password`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: changePasswordInput,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/user-auth/password/recovery',
    alias: 'startPasswordRecovery',
    description: `Triggers a password recovery flow, which sends an email to the user that can be used to set a new password`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ username: z.string() }).passthrough(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/user-auth/password/recovery/code',
    alias: 'confirmPasswordRecovery',
    description: `Sets a new password for user using a recovery code received by email`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: confirmPasswordRecoveryInput,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/users',
    alias: 'createUser',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: userInput,
      },
    ],
    response: z.object({ userId: z.string().uuid() }).passthrough(),
  },
  {
    method: 'get',
    path: '/api/users/:userId',
    alias: 'getUserById',
    requestFormat: 'json',
    parameters: [
      {
        name: 'userId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: userData,
  },
  {
    method: 'put',
    path: '/api/users/:userId',
    alias: 'updateUser',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: userUpdate,
      },
      {
        name: 'userId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.object({ userId: z.string().uuid() }).passthrough(),
  },
  {
    method: 'get',
    path: '/api/users/:userId/membership',
    alias: 'getUserMembership',
    requestFormat: 'json',
    parameters: [
      {
        name: 'userId',
        type: 'Path',
        schema: z.string().uuid(),
      },
      {
        name: 'query',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sortField',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sortOrder',
        type: 'Query',
        schema: z.enum(['asc', 'desc']).optional(),
      },
      {
        name: 'rangeFilters',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'elementFilters',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: membershipList,
  },
  {
    method: 'post',
    path: '/api/users/data',
    alias: 'getUsersByIds',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: getUsersByIds_Body,
      },
    ],
    response: z.array(userData).min(1).max(50),
  },
  {
    method: 'post',
    path: '/api/users/disable-user-access',
    alias: 'disableUserAccess',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ email: z.string().email() }).passthrough(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/users/enable-user-access',
    alias: 'enableUserAccess',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ email: z.string().email() }).passthrough(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/users/lookup',
    alias: 'lookupUser',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ identifier: z.string() }).passthrough(),
      },
    ],
    response: z.object({ userId: z.string().uuid() }).passthrough(),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/users/people/:personId',
    alias: 'getUserByPersonId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'personId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.object({ userId: z.string().uuid() }).passthrough(),
  },
  {
    method: 'post',
    path: '/api/users/resend-creation-email',
    alias: 'resendUserCreationEmail',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ email: z.string().email() }).passthrough(),
      },
    ],
    response: z.void(),
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
