import { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import { OctopusLoading } from '@octopus/ui/design-system';

export type TakoProgressProps = {
  width?: string | number;
  height?: string | number;
  progress?: {
    current: number;
    total: number;
  };
  label?: {
    phrases: string[];
    cycleTimeInMs: number;
    completedPhrase?: string;
  };
};

export default function TakoProgress({
  width,
  height,
  progress,
  label,
}: TakoProgressProps) {
  const [currentPhrase, setCurrentPhrase] = useState<number>(-1);
  const [timeoutId, setTimeoutId] = useState<number>(-1);

  const handleChangePhrases = () => {
    setCurrentPhrase((i) => (i + 1) % label.phrases.length);
    setTimeoutId(
      setTimeout(handleChangePhrases, label.cycleTimeInMs) as unknown as number,
    );
    return () => clearTimeout(timeoutId);
  };

  useEffect(() => {
    if (label && currentPhrase === -1) {
      handleChangePhrases();
    }
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width={width}
      height={height}
      gap={3}
      data-testid="tako-progress"
    >
      <Box width="200px" height="200px" data-testid="octopus-loading">
        <OctopusLoading />
      </Box>
      {progress && (
        <Box height="20px" width="200px" data-testid="progress-bar">
          <LinearProgress
            variant="determinate"
            value={Math.max(
              0,
              Math.min(
                100,
                progress?.total ? (100 / progress.total) * progress.current : 0,
              ),
            )}
          />
        </Box>
      )}
      {label && label.phrases.length > 0 && (
        <Typography
          variant="subtitle1"
          color="text.primary"
          fontWeight="500"
          data-testid="progress-phrase"
        >
          {progress &&
          label.completedPhrase &&
          progress.current === progress.total
            ? label.completedPhrase
            : label.phrases[currentPhrase]}
        </Typography>
      )}
    </Box>
  );
}
