import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import { PeriodicidadePagamento } from '@octopus/contract-types';
import {
  BrasilAmountType,
  IFormDefinition,
  UI_TYPE,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { getOptionsFromMapper } from '../../inputUtils';
import { AdmissionFormState, FormStepDefinition } from '../../types';

const getFieldDefinition = ({
  formState,
}: {
  formState: AdmissionFormState;
}): IFormDefinition => {
  const { salary, periodicidadePagamento } = formState;

  return [
    {
      label: 'Valor base',
      type: BrasilAmountType,
      name: 'salary',
      uiType: UI_TYPE.TEXT_MONEY,
      placeholder: '0,00',
      value: salary || '',
    },
    // {
    //   label: 'Periodicidade para a base de cálculo',
    //   type: z.number().int(),
    //   name: 'unidadeBaseCalculo',
    //   uiType: UI_TYPE.SELECT,
    //   placeholder: 'Selecione o tipo de salário',
    //   options: getOptionsFromMapper({
    //     mapper: UnidadeSalarioFixo,
    //     currentValue: unidadeBaseCalculo,
    //   }),
    // },
    {
      label: 'Periodicidade de pagamento',
      type: z.string(),
      name: 'periodicidadePagamento',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione o tipo de salário',
      options: getOptionsFromMapper({
        mapper: PeriodicidadePagamento,
        currentValue: periodicidadePagamento,
        selectIfOnlyOne: true,
      }),
    },
  ];
};

export const getHonorariosFields = ({
  formState,
  remuneracaoFormSteps,
}: {
  formState: AdmissionFormState;
  remuneracaoFormSteps: AdmissionDraftInputFormStep;
}): FormStepDefinition => ({
  definition: getFieldDefinition({
    formState,
  }),
  options: {
    id: admissionDraftFormSteps.remuneracao,
    title: 'Honorários',
    completed: remuneracaoFormSteps?.completed,
    reviewed: remuneracaoFormSteps?.reviewed,
  },
});
