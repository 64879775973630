import { FieldMetadata, getInputProps } from '@conform-to/react';
import { MaskitoMask } from '@maskito/core';

import {
  IFieldSingleValueWithoutOptionsDefinition,
  parseFieldUiInfo,
} from '@octopus/libs/forms';

import { MaskitoOptionsBR } from '../Field/MaskitoOptions';

import { parseBranchingErrors } from './parseFormErrors';
import {
  TFieldDatePickerRenderProps,
  TFieldInputRenderProps,
  TFieldRenderProps,
} from './types';

export function parseFieldText(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldMetadata: FieldMetadata<any>,
  fieldDefinition: IFieldSingleValueWithoutOptionsDefinition,
  fieldsetName = '',
  payloadFormErrors: Record<string, string[]> = {},
): TFieldRenderProps & TFieldInputRenderProps {
  const inputProps = getInputProps(fieldMetadata, {
    type: 'text',
  });

  const errors = parseBranchingErrors({
    fieldMetadata,
    fieldDefinition,
    isInvalid: inputProps['aria-invalid'],
    fieldsetName,
    payloadFormErrors,
  });

  const currentValue =
    (fieldMetadata.dirty && fieldMetadata.value?.toString()) ||
    fieldDefinition.value?.toString() ||
    '';

  const fieldRenderProps: TFieldRenderProps & TFieldInputRenderProps = {
    uiType: parseFieldUiInfo(fieldDefinition),
    fieldsetName,
    errors: errors,
    hidden: fieldDefinition.hidden,
    props: {
      key: inputProps.id,
      mask: fieldDefinition.mask as MaskitoMask | keyof typeof MaskitoOptionsBR,
      sx: fieldDefinition.sx,
    },
    label: {
      textContent: fieldDefinition.label,
      props: {
        htmlFor: inputProps.id,
      },
    },
    input: {
      props: {
        ...inputProps,
        ...(currentValue && { defaultValue: currentValue }),
        placeholder: fieldDefinition.placeholder,
        info: fieldDefinition.info,
        disclaimerFromSelection: fieldDefinition.disclaimerFromSelection,
        hint: fieldDefinition.hint,
      },
    },
    extraProps:
      fieldDefinition?.extraProps as TFieldDatePickerRenderProps['extraProps'],
  };

  return fieldRenderProps;
}
