import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import {
  VacationsConfigurationEntry,
  fetchPostValidateVacationsSchedule,
} from '@octopus/api';

import { parseFormPayloadToPayrolInput } from '../adapters/parseFormPayloadToPayrolInput';

import { IVacationFormInputs } from './types';

export function useQueryVacationFormOutputs({
  inputs,
  configurations,
}: {
  inputs: IVacationFormInputs;
  configurations: VacationsConfigurationEntry;
}) {
  const startDateFromInputs = dayjs(inputs.startDate);
  const startDateApiFormatted = startDateFromInputs.format('YYYY-MM-DD');

  const queryKey = [
    inputs.organizationId,
    inputs.contractId,
    startDateApiFormatted,
    inputs.vacationDays ?? 0,
    inputs.daysSold ?? 0,
    inputs.thirteenthAdvance,
  ];

  const isEmpty = [
    inputs.organizationId,
    inputs.contractId,
    inputs.startDate,
    inputs.vacationDays,
    inputs.daysSold,
    inputs.thirteenthAdvance,
  ].some((prop) => prop === undefined);
  const enabled = !isEmpty && startDateFromInputs.isValid();

  const getCalculationOutputsResult = useQuery({
    queryKey,
    enabled,
    queryFn: () => {
      const payload = parseFormPayloadToPayrolInput(inputs, configurations);
      return fetchPostValidateVacationsSchedule(payload);
    },
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return getCalculationOutputsResult;
}
