import { ErrorBoundary } from 'react-error-boundary';

import dayjs from 'dayjs';
import { isNumber } from 'lodash';

import { Link } from '@mui/material';

import { ContractCustomFields, CustomFieldEntry } from '@octopus/api';
import { formatBooleanBR, formatDateBR } from '@octopus/formatters';

import { Record, RecordEntry, RecordGroup } from '../Record';

import { BaseRecordProps } from './common';
import { RecordEdit, useRecordEdit } from './useRecordEdit';

type CustomFieldsData = {
  customFields: ContractCustomFields | undefined;
};

export type CustomFieldsRecordProps = BaseRecordProps<CustomFieldsData> & {
  showExpandIcon?: boolean;
  fieldDefs: CustomFieldEntry[];
};

export function CustomFieldsRecord(props: CustomFieldsRecordProps) {
  const {
    data: { customFields },
    fieldDefs,
    showExpandIcon,
  } = props;

  const recordEdit = useRecordEdit<CustomFieldsData>(props);

  return (
    <RecordGroup title="Campos personalizados" showExpandIcon={showExpandIcon}>
      <Record title="Outros dados" edit={recordEdit.editRecordProps}>
        {fieldDefs.map((fieldDef) => (
          <ErrorBoundary
            key={fieldDef.name}
            fallback={null}
            onError={(err) =>
              console.error('Custom fields rendering error', err)
            }
          >
            <CustomFieldRecordEntry
              fieldDef={fieldDef}
              customFields={customFields}
              recordEdit={recordEdit}
            />
          </ErrorBoundary>
        ))}
      </Record>
    </RecordGroup>
  );
}

function CustomFieldRecordEntry({
  fieldDef,
  customFields,
  recordEdit,
}: {
  fieldDef: CustomFieldEntry;
  customFields: ContractCustomFields | undefined;
  recordEdit: RecordEdit<CustomFieldsData>;
}) {
  const { label, name } = fieldDef;
  const value = customFields[name];
  const formValue = recordEdit.formData.customFields[name];
  const onValueChange = (newValue: string | undefined) =>
    recordEdit.updateData((data) => {
      return {
        ...data,
        customFields: {
          ...data.customFields,
          [name]: newValue,
        },
      };
    });

  switch (fieldDef.type) {
    case 'text': {
      return (
        <RecordEntry
          label={label}
          edit={{
            editing: recordEdit.editing,
            type: 'text',
            onChange: onValueChange,
            value: formValue ? `${formValue}` : undefined,
            maskStr: fieldDef.mask,
          }}
        >
          {value as string | undefined}
        </RecordEntry>
      );
    }
    case 'longtext':
      return (
        <RecordEntry
          label={label}
          edit={{
            editing: recordEdit.editing,
            type: 'longtext',
            onChange: onValueChange,
            value: formValue ? `${formValue}` : undefined,
          }}
        >
          {value as string | undefined}
        </RecordEntry>
      );
    case 'checkbox':
      return (
        <RecordEntry
          label={label}
          edit={{
            editing: recordEdit.editing,
            type: 'check',
            onChange: (val) =>
              recordEdit.updateData((data) => ({
                ...data,
                customFields: {
                  ...data.customFields,
                  [name]: val,
                },
              })),
            value: (formValue as boolean | undefined) ?? false,
          }}
        >
          {formatBooleanBR(
            value !== undefined && typeof value === 'boolean' ? value : false,
          )}
        </RecordEntry>
      );
    case 'date': {
      let date: string | undefined;
      if (
        value !== undefined &&
        dayjs(value as string, 'YYYY-MM-DD').isValid()
      ) {
        date = value as string;
      }
      return (
        <RecordEntry
          label={label}
          edit={{
            editing: recordEdit.editing,
            type: 'date',
            onChange: onValueChange,
            value: formValue ? `${formValue}` : undefined,
            valueFormat: 'YYYY-MM-DD',
            startDate: fieldDef.startDate,
            endDate: fieldDef.endDate,
          }}
        >
          {date ? formatDateBR(date) : undefined}
        </RecordEntry>
      );
    }
    case 'link': {
      let url: string | undefined;
      try {
        if (value !== undefined) {
          url = new URL(value as string).href;
        } else {
          url = undefined;
        }
      } catch (e) {
        url = undefined;
      }
      return (
        <RecordEntry
          label={label}
          edit={{
            editing: recordEdit.editing,
            type: 'text',
            onChange: onValueChange,
            value: formValue !== undefined ? `${formValue}` : undefined,
          }}
        >
          {url ? (
            <Link
              variant="body2"
              color="text.primary"
              href={encodeURI(url)}
              target="_blank"
              overflow="hidden"
              textOverflow="ellipsis"
              noWrap
            >
              {url}
            </Link>
          ) : undefined}
        </RecordEntry>
      );
    }
    case 'multiselect':
      return (
        <RecordEntry
          label={label}
          edit={{
            editing: recordEdit.editing,
            type: 'multi-options',
            onChange: (vals) =>
              recordEdit.updateData((data) => ({
                ...data,
                customFields: {
                  ...data.customFields,
                  [name]: vals as string[],
                },
              })),
            values: formValue as string[],
            options: fieldDef.options.map(({ label, value }) => ({
              label: label ?? value,
              value,
            })),
          }}
        >
          {value !== undefined && Array.isArray(value)
            ? value
                .map(
                  (v) =>
                    fieldDef.options.find((o) => o.value === v)?.label ?? v,
                )
                .join(', ')
            : undefined}
        </RecordEntry>
      );
    case 'number':
      return (
        <RecordEntry
          label={label}
          edit={{
            editing: recordEdit.editing,
            type: 'number',
            onChange: (val) =>
              recordEdit.updateData((data) => ({
                ...data,
                customFields: {
                  ...data.customFields,
                  [name]: val,
                },
              })),
            value: formValue as number,
            isInteger: fieldDef.isInteger,
            min: fieldDef.min,
            max: fieldDef.max,
          }}
        >
          {value !== undefined && isNumber(value) ? `${value}` : undefined}
        </RecordEntry>
      );
    case 'select':
      return (
        <RecordEntry
          label={label}
          edit={{
            editing: recordEdit.editing,
            type: 'options',
            onChange: onValueChange,
            value: formValue ? (formValue as string) : undefined,
            options: fieldDef.options.map(({ label, value }) => ({
              label: label ?? value,
              value,
            })),
            nullable: true,
          }}
        >
          {value !== undefined
            ? (fieldDef.options.find((o) => o.value === value)?.label ??
              `${value}`)
            : undefined}
        </RecordEntry>
      );
    default:
      return (
        <RecordEntry
          label={label}
          edit={{
            editing: recordEdit.editing,
            type: 'text',
            onChange: onValueChange,
            value: formValue ? `${formValue}` : undefined,
          }}
        >
          {value ? `${value}` : undefined}
        </RecordEntry>
      );
  }
}
