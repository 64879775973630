import { ContractCustomFields } from '@octopus/api';

import { ContractEditError } from '../../../app/people/person/types';

export type RecordEditError = {
  path: string;
  message: string;
};

export type RecordEditProps<T> = {
  isEditable: boolean;
  onSave: (
    data: { update: T; workerId?: string; customFields?: ContractCustomFields },
    onSuccess: () => void,
    onError?: (err: ContractEditError) => void,
  ) => void;
};

export type BaseRecordProps<T, P = never> = {
  data: T;
  parameters?: P;
  edit?: RecordEditProps<T>;
  onSuccess?: () => void;
};

export type EditFormState<T> = {
  editing: boolean;
  data: T;
  fieldErrors?: { [field: string]: string };
};

export const booleanOptions = [
  { value: 'true', label: 'Sim' },
  { value: 'false', label: 'Não' },
];
