import { Close } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, IconButton, Typography } from '@mui/material';

import {
  CustomFieldEntry,
  EventSourcingEvent,
  useGetContract,
} from '@octopus/api';
import { formatDateBR } from '@octopus/formatters';

import { QueryResult } from '../../../../modules/types';
import { ContractDetailsProps } from '../types';

import { Loading } from './Loading';

type Controls = {
  onDismiss: () => void;
  hasNext: boolean;
  onNext: () => void;
  onPrevious: () => void;
  hasPrevious: boolean;
};
type ContractEventHistoryProjectionProps = {
  organizationId: string;
  contractId: string;
  event?: EventSourcingEvent;
  controls: Controls;
  projectionComponent: React.FC<ContractDetailsProps>;
  customFieldsQuery: QueryResult<CustomFieldEntry[]>;
};

export function ContractEventHistoryProjection(
  props: ContractEventHistoryProjectionProps,
) {
  return props.event ? (
    <ContractEventHistoryProjectionWithEvent {...props} />
  ) : null;
}

function ContractEventHistoryProjectionWithEvent({
  organizationId,
  contractId,
  event,
  controls,
  projectionComponent: Projection,
  customFieldsQuery,
}: ContractEventHistoryProjectionProps) {
  const { sequenceId, effectiveDate } = event;

  const query = useGetContract({
    pathParams: { organizationId, contractId },
    queryParams: { sequenceId: sequenceId.toString(), effectiveDate },
  });

  return (
    <>
      <ProjecionHeader event={event} controls={controls} />
      <Box sx={{ px: 5, pb: 15 }}>
        {query.isLoading ? (
          <Loading skeletons={3} />
        ) : (
          <Projection
            contractQuery={query}
            isEditable={false}
            historyEnabled={false}
            showTitle={false}
            customFieldsQuery={customFieldsQuery}
          />
        )}
      </Box>
    </>
  );
}

function ProjecionHeader({
  event,
  controls: { onDismiss, hasNext, onNext, hasPrevious, onPrevious },
}: {
  event: EventSourcingEvent;
  controls: Controls;
}) {
  const { effectiveDate } = event;
  return (
    <Box sx={{ pt: 5, pb: 2, height: '32px' }}>
      <Box
        sx={{ px: 5 }}
        gap={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Typography
          color="textSecondary"
          variant="body2"
          sx={{ width: '100%' }}
        >
          Dados em {formatDateBR(effectiveDate)}
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <IconButton
            onClick={onPrevious}
            disabled={!hasPrevious}
            sx={(theme) => ({
              py: 0,
              px: 0.5,
              height: '32px',
              backgroundColor: theme.palette.background.paper,
              borderRadius: '8px 0 0 8px',
              border: `1px solid ${theme.palette.strokes.light}`,
              borderRight: hasNext
                ? `0.5px solid ${theme.palette.strokes.light}`
                : undefined,
            })}
          >
            <ExpandLessIcon />
          </IconButton>
          <IconButton
            disabled={!hasNext}
            onClick={onNext}
            sx={(theme) => ({
              py: 0,
              px: 0.5,
              height: '32px',
              backgroundColor: theme.palette.background.paper,
              borderRadius: '0 8px 8px 0',
              border: `1px solid ${theme.palette.strokes.light}`,
              borderLeft: `0.5px solid ${theme.palette.strokes.light}`,
            })}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
        <IconButton
          sx={{
            py: 0.5,
            px: 0.5,
            width: '32px',
            height: '32px',
            borderRadius: '4px',
          }}
          onClick={onDismiss}
        >
          <Close sx={{ fontSize: '24px' }} />
        </IconButton>
      </Box>
    </Box>
  );
}
