import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { setAuthToken } from '@octopus/api';
import { WorkerCategory } from '@octopus/contract-types';

import { logoutUser } from '../../../modules/login';
import { LoadingScene } from '../../admission/components/AdmissionDraftLoadingScene';

import { NewPasswordChallenge } from './NewPasswordChallenge';
import { WelcomeNewEmployee } from './WelcomeNewEmployee';

export type newPasswordType = 'challenge' | 'forgot-password';

export function Signup() {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const companyName = searchParams.get('c');
  const workerCategory = searchParams.get('wc');
  const logoUrl = decodeURIComponent(searchParams.get('l'));
  const username = location.search.match(/u=([^&]*)/i)![1];

  const [welcomeCompleted, setWelcomeCompleted] = useState<boolean>();
  const [logedout, setLogedout] = useState<boolean>();

  logoutUser().then(() => {
    setLogedout(true);
  });

  const navigate = useNavigate();

  const onChangePasswordSuccess = (token: string) => {
    setAuthToken(token);
    navigate('/admissions/signup-success');
  };

  if (!logedout) {
    return <LoadingScene />;
  }

  return welcomeCompleted ? (
    <Box>
      <NewPasswordChallenge
        username={username}
        onSuccess={onChangePasswordSuccess}
      />
    </Box>
  ) : (
    <WelcomeNewEmployee
      companyName={companyName}
      logoUrl={logoUrl}
      setCompleted={() => setWelcomeCompleted(true)}
      workerCategory={workerCategory as WorkerCategory}
    />
  );
}
