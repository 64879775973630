import React from 'react';

import dayjs from 'dayjs';

import { Box, Button, Divider, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { ContractEntry } from '@octopus/api';
import {
  DataGrid,
  GridValueGetterParams,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { ExpandableTypography } from '../../ExpandableTypography';
import UserAvatar from '../../UserAvatar';

import { AddReportInfo } from './types';

export function AddReportsReview({
  newManagerContract,
  reportsToAdd,
  effectiveDate,
  setEffectiveDate,
  onConfirm,
  onCancel,
}: {
  newManagerContract: ContractEntry;
  reportsToAdd: AddReportInfo[];
  effectiveDate: string;
  setEffectiveDate: (dt: string) => void;
  onConfirm: () => void;
  onCancel: () => void;
}) {
  const { sortingProps, paginationProps } = useDataGrid({
    pagination: {
      rowsPerPageOptions: [5, 25, 50, 100],
    },
  });

  const minDate = dayjs().startOf('month').subtract(1, 'month');
  const maxDate = dayjs().startOf('month').add(1, 'month').endOf('month');

  const reportsToList = reportsToAdd
    .slice(paginationProps.page * paginationProps.rowsPerPage)
    .slice(0, paginationProps.rowsPerPage);

  return (
    <>
      <Box display="flex" flexDirection="column" gap={4} mb={16}>
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="h5" fontWeight="700">
            Adicionar liderado
          </Typography>
          <Typography variant="body2" fontWeight="400">
            Abaixo você vê um resumo dos colaboradores selecionados para
            inclusão na gestão de{' '}
            <Typography variant="body2" component="span" fontWeight="700">
              {newManagerContract.br.pessoa.nmTrab}
            </Typography>
            .
          </Typography>
          <DataGrid
            sortingProps={sortingProps}
            paginationProps={paginationProps}
            totalRowCount={reportsToAdd.length}
            getRowId={(row) => row.reportContractId}
            rows={reportsToList}
            columns={[
              {
                field: 'worker',
                headerName: 'Colaborador',
                renderHeader: (params) => {
                  return <Box ml={2}>{params.field}</Box>;
                },
                flex: 1,
                valueGetter: (params: GridValueGetterParams) => {
                  return params.row.reportName;
                },
                renderCell: ({ value, row }) => {
                  return (
                    <UserAvatar
                      name={value}
                      pictureUrl={row.reportProfileUrl}
                      expandNameOnHover={true}
                      sx={{
                        '--UserAvatar-name-max-width': '12.5em',
                      }}
                    />
                  );
                },
              },
              {
                field: 'oldManager',
                headerName: 'Gestor atual',
                valueGetter: (params: GridValueGetterParams) => {
                  return params.row.oldManagerName;
                },
                renderCell: ({ value }) => {
                  return value ? (
                    <ExpandableTypography fontWeight="500">
                      {value}
                    </ExpandableTypography>
                  ) : (
                    <ExpandableTypography fontWeight="400">
                      Sem gestor
                    </ExpandableTypography>
                  );
                },
              },
              {
                field: 'newManager',
                headerName: 'Novo gestor',
                valueGetter: () => newManagerContract.br.pessoa.nmTrab,
                renderCell: ({ value }) => {
                  return <ExpandableTypography>{value}</ExpandableTypography>;
                },
              },
            ]}
            emptyMessage="Nenhuma mudança selecionada."
          />
        </Box>
        <Divider />
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="body1">
            As mudanças devem começar a ter efeito a partir de qual data?
          </Typography>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="pt-br"
          >
            <DatePicker
              defaultValue={dayjs(effectiveDate)}
              sx={{ width: '100%' }}
              onChange={(v) => setEffectiveDate(v.format('YYYY-MM-DD'))}
              minDate={minDate}
              maxDate={maxDate}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box
        display="flex"
        gap={1}
        py={1}
        px={3}
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'end',
          borderTop: `1px solid ${theme.palette.strokes.light}`,
          background: 'rgba(247, 247, 247, 0.90)',
          'backdrop-filter': 'blur(4px)',
          position: 'fixed',
          bottom: 0,
          right: 0,
          left: 0,
        })}
      >
        <Button color="secondary" size="large" onClick={onCancel}>
          Voltar
        </Button>
        <Button color="primaryAlt" size="large" onClick={onConfirm}>
          Confirmar
        </Button>
      </Box>
    </>
  );
}
