import { useEffect, useState } from 'react';

import { RecordProps } from '../Record';

import { BaseRecordProps, EditFormState } from './common';

export type RecordEdit<T extends Record<string, unknown>> = {
  editing: boolean;
  formData: T;
  updateData: (updater: (data: T) => T) => void;
  editRecordProps: RecordProps['edit'];
  hasError: (field: string) => boolean;
  error: (field: string) => string | undefined;
};

export function useRecordEdit<T extends Record<string, unknown>>({
  edit,
  data: initialState,
  onSuccess,
}: BaseRecordProps<T, unknown>): RecordEdit<T> {
  const [formState, setFormState] = useState<EditFormState<T>>({
    editing: false,
    data: initialState,
  });

  const setEdit = (edit: boolean) =>
    setFormState((state) => ({ ...state, editing: edit }));

  const updateData = (updater: (data: T) => T) =>
    setFormState((state) => ({ ...state, data: updater(state.data) }));

  const resetState = () => setFormState({ editing: false, data: initialState });

  const initialStateHash = JSON.stringify(initialState);

  useEffect(() => {
    resetState();
  }, [initialStateHash]);

  return {
    editing: formState.editing,
    formData: formState.data,
    updateData,
    editRecordProps: {
      isEditable: edit?.isEditable,
      isEditing: formState.editing,
      onEdit: () => setEdit(true),
      onCancel: (callback) => {
        callback?.();
        resetState();
      },
      onSave: (callback) => {
        edit?.onSave(
          { update: formState.data },
          () => {
            callback?.();
            resetState();
            onSuccess?.();
          },
          (error) => {
            setFormState((state) => ({ ...state, fieldErrors: error.fields }));
          },
        );
      },
    },
    hasError: (field) => !!formState.fieldErrors?.[field],
    error: (field) => formState.fieldErrors?.[field],
  };
}
