import React, { useState } from 'react';

import { useMaskito } from '@maskito/react';

import { InfoOutlined } from '@mui/icons-material';
import { Alert, OutlinedInput } from '@mui/material';

import { UI_TYPE } from '@octopus/libs/forms';

import { TFieldInputRenderProps, TFieldRenderProps } from '../parseField/types';

import { ErrorWarningIcon } from './commons/ErrorWarningIcon';
import { FieldWrapper } from './commons/FieldWrapper';
import { MaskitoOptionsBR } from './MaskitoOptions';

type FieldTextProps = {
  field: TFieldRenderProps & TFieldInputRenderProps;
};

type NamedMask = keyof typeof MaskitoOptionsBR;
const maskNames = new Set(Object.keys(MaskitoOptionsBR));
const isNamedMask = (mask: string): mask is NamedMask => maskNames.has(mask);

export function FieldText(props: FieldTextProps) {
  const [focused, setFocused] = useState(false);
  const { field } = props;

  const hasError = field.errors?.length > 0;

  const mask = field.props.mask;

  const options = !mask
    ? null
    : typeof mask === 'string' && isNamedMask(mask)
      ? MaskitoOptionsBR[mask]
      : { mask };

  const maskito = useMaskito({
    options,
  });

  const { disclaimerFromSelection } = field.input.props;

  return (
    <FieldWrapper field={field} sx={field.props.sx} focused={focused}>
      <OutlinedInput
        fullWidth
        onAnimationStart={null}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        inputProps={field.input.props}
        disabled={field.input.props.disabled}
        autoComplete="off"
        error={hasError}
        endAdornment={<ErrorWarningIcon hasError={hasError} />}
        ref={maskito}
      />
      {disclaimerFromSelection && (
        <Alert
          severity="info"
          icon={<InfoOutlined fontSize="medium" />}
          sx={{ mt: 2, whiteSpace: 'preserve' }}
        >
          {disclaimerFromSelection('')}
        </Alert>
      )}
    </FieldWrapper>
  );
}

FieldText.uiType = UI_TYPE.TEXT;
FieldText.canRender = (
  field: TFieldRenderProps,
): field is FieldTextProps['field'] => {
  return (
    'input' in field &&
    field.input.props.type !== 'checkbox' &&
    field.input.props.type !== 'radio'
  );
};
