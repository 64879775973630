import { RefObject, useMemo } from 'react';

import { IconIdBadge2 } from '@tabler/icons-react';

import { Box } from '@mui/material';

import { ContractEntry, CustomFieldEntry } from '@octopus/api';

import {
  SidePanel,
  SidePanelMenu,
  SidePanelMenuItem,
  SidePanelMenuSubItem,
} from '../../modules/components/people/SidePanel';
import { QueryResult } from '../../modules/types';

import { PersonalDataInfo } from './PersonalDataInfo';

export type PersonSidePanelProps = {
  refs: {
    pageRef: RefObject<Element>;
    personalDetailsRef: RefObject<Element>;
    contractDetailsRef: RefObject<Element>;
    customFieldsDetailsRef: RefObject<Element>;
  };
  contractQuery: QueryResult<ContractEntry>;
  customFieldsQuery: QueryResult<CustomFieldEntry[]>;
};

export function PersonalDataSidePanel({
  refs,
  contractQuery,
  customFieldsQuery,
}: PersonSidePanelProps) {
  const name = useMemo(() => {
    return (
      contractQuery.data?.br?.pessoa.nmSoc ??
      contractQuery.data?.br?.pessoa.nmTrab ??
      ''
    );
  }, [contractQuery.data]);
  const scrollToRef = (ref: RefObject<Element>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const { isLoading } = contractQuery;
  const {
    pageRef,
    personalDetailsRef,
    contractDetailsRef,
    customFieldsDetailsRef,
  } = refs;
  return (
    <Box data-testid="person-side-panel">
      <SidePanel
        isLoading={contractQuery.isLoading}
        name={name}
        InfoCard={<PersonalDataInfo contractQuery={contractQuery} />}
        Menu={
          !isLoading &&
          contractQuery.data && (
            <SidePanelMenu>
              <SidePanelMenuItem
                Icon={IconIdBadge2}
                selected={true}
                title="Meus dados"
                onClick={() => scrollToRef(pageRef)}
                testId="person-page-button"
              >
                <SidePanelMenuSubItem
                  title="Dados Pessoais"
                  onClick={() => scrollToRef(personalDetailsRef)}
                  testId="person-data-button"
                />
                <SidePanelMenuSubItem
                  hide={isLoading}
                  title="Dados Contratuais"
                  onClick={() => scrollToRef(contractDetailsRef)}
                  testId="contract-data-button"
                />
                {!customFieldsQuery.isLoading &&
                  customFieldsQuery.data?.length > 0 && (
                    <SidePanelMenuSubItem
                      title="Campos personalizados"
                      onClick={() => scrollToRef(customFieldsDetailsRef)}
                      testId="custom-fields-data-button"
                    />
                  )}
              </SidePanelMenuItem>
            </SidePanelMenu>
          )
        }
      />
    </Box>
  );
}
