import { useContext } from 'react';

import {
  ContractBRCltEntryGestao,
  ContractBRCltEntryTrabalho,
  ContractBRCltEntryVinculo,
} from '@octopus/api';
import { CBOs } from '@octopus/esocial/mapper';
import { formatCBO } from '@octopus/formatters';

import { AppContext } from '../../../../../app/context';
import { Record, RecordEntry } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type InformacoesProfissionaisRecordData = {
  organizationId: string;
  workerId: string;
  vinculo: ContractBRCltEntryVinculo;
  trabalho: ContractBRCltEntryTrabalho;
  gestao: ContractBRCltEntryGestao | undefined;
};

export type InformacoesProfissionaisRecordProps =
  BaseRecordProps<InformacoesProfissionaisRecordData>;

export function InformacoesProfissionaisRecord(
  props: InformacoesProfissionaisRecordProps,
) {
  const { workerId, vinculo, trabalho, gestao } = props.data;
  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit(props);
  const { appContext } = useContext(AppContext);

  const setFormField = (
    key: keyof InformacoesProfissionaisRecordData,
    value: string,
  ) =>
    updateData((data) => ({
      ...data,
      [key]: value,
    }));

  const setFormGroupField = <
    G extends keyof Omit<
      InformacoesProfissionaisRecordData,
      'workerId' | 'organizationId'
    >,
  >(
    group: G,
    key: keyof InformacoesProfissionaisRecordData[G],
    value: string,
  ) =>
    updateData((data) => ({
      ...data,
      [group]: {
        ...data[group],
        [key]: value,
      },
    }));

  const costCenters = appContext?.company?.costCenters;
  const costCenter = costCenters?.find(({ id }) => gestao?.costCenterId === id);

  const jobTitles = appContext?.company?.jobTitles;
  const jobTitle = jobTitles?.find(({ id }) => trabalho.jobTitleId === id);
  const cboCargo = CBOs.getByCode(jobTitle?.summary?.occupationCode);
  const editCargo = jobTitles?.find(
    ({ id }) => formData.trabalho?.jobTitleId === id,
  );
  const editCBO = CBOs.getByCode(editCargo?.summary?.occupationCode);
  return (
    <Record title="Informações profissionais" edit={editRecordProps}>
      <RecordEntry
        label="Matrícula na empresa"
        edit={{
          type: 'text',
          editing,
          value: formData.workerId,
          onChange: (value) => setFormField('workerId', value),
          hasError: hasError('workerId'),
        }}
      >
        {workerId}
      </RecordEntry>

      <RecordEntry
        label="Matrícula no eSocial"
        edit={{
          type: 'text',
          editing,
          value: formData.vinculo.matricula,
          disabled: true,
          onChange: () => undefined,
          hasError: hasError('br/vinculo/matricula'),
        }}
      >
        {vinculo.matricula}
      </RecordEntry>

      <RecordEntry
        label="Cargo"
        isLoading={!jobTitle}
        edit={{
          type: 'options',
          disabled: !jobTitles,
          editing,
          value: formData.trabalho.jobTitleId,
          options: jobTitles
            ?.filter(({ summary: { active } }) => active)
            ?.filter(({ summary: { occupationCode } }) => !!occupationCode)
            ?.map(({ id, summary: { name } }) => ({
              value: id,
              label: name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),

          onChange: (value) =>
            setFormGroupField('trabalho', 'jobTitleId', value as string),
          hasError: hasError('br/trabalho/jobTitleId'),
        }}
      >
        {jobTitle?.summary?.name}
      </RecordEntry>

      <RecordEntry
        label="CBO do cargo"
        isLoading={!jobTitle}
        edit={{
          editing,
          type: 'text',
          value:
            formatCBO(editCargo?.summary.occupationCode) +
            (editCBO ? ` - ${editCBO}` : ''),
          disabled: true,
          onChange: () => {
            //noop
          },
        }}
      >
        {formatCBO(jobTitle?.summary?.occupationCode) +
          (cboCargo ? ` - ${cboCargo}` : '')}
      </RecordEntry>

      <RecordEntry
        label="Função"
        edit={{
          editing,
          type: 'options',
          options: [
            { label: 'N/A', value: null },
            ...CBOs.codes()
              .sort()
              .map((code) => ({
                label: `${formatCBO(code as string)} - ${CBOs.getByCode(code)}`,
                value: code,
              })),
          ],
          value: formData.trabalho.CBOFuncao ?? null,
          onChange: (code) => {
            updateData((data) => ({
              ...data,
              trabalho: {
                ...data.trabalho,
                CBOFuncao: code as string,
                nmFuncao: code ? CBOs.getByCode(code) : null,
              },
            }));
          },
        }}
      >
        {trabalho.CBOFuncao
          ? `${formatCBO(trabalho.CBOFuncao as string)} - ${CBOs.getByCode(
              trabalho.CBOFuncao,
            )}`
          : undefined}
      </RecordEntry>

      <RecordEntry
        label="Departamento"
        edit={{
          type: 'text',
          editing,
          value: formData.trabalho.departamento,
          onChange: (value) =>
            setFormGroupField('trabalho', 'departamento', value),
          hasError: hasError('br/trabalho/departamento'),
        }}
      >
        {trabalho.departamento}
      </RecordEntry>

      <RecordEntry
        label="Centro de custo"
        isLoading={!costCenters}
        edit={{
          type: 'options',
          editing,
          value: formData.gestao?.costCenterId,
          options: [
            { label: 'N/A', value: null },
            ...(costCenters
              ?.map(({ id, name }) => ({
                label: name,
                value: id,
              }))
              ?.sort((a, b) => a.label.localeCompare(b.label)) ?? []),
          ],
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              gestao: {
                ...data.gestao,
                costCenterId: value as string,
                nomeCentroCusto:
                  costCenters?.find(({ id }) => id === value)?.summary?.name ??
                  '',
                codigoCentroCusto:
                  costCenters?.find(({ id }) => id === value)?.summary?.code ??
                  '',
              },
            })),
          hasError: hasError('br/gestao/costCenterId'),
        }}
      >
        {!gestao?.costCenterId || !costCenter ? undefined : costCenter.name}
      </RecordEntry>

      <RecordEntry
        label="Email corporativo"
        edit={{
          type: 'text',
          editing,
          value: formData.vinculo.emailCorp,
          onChange: (value) => setFormGroupField('vinculo', 'emailCorp', value),
          hasError: hasError('br/vinculo/emailCorp'),
        }}
      >
        {vinculo.emailCorp}
      </RecordEntry>
    </Record>
  );
}
