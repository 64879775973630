import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import { z } from 'zod';

import { DateYYYYMMDD } from '@octopus/api';

const dateYearMonthRegex = /^\d{4}-\d{2}-\d{2}$/;

const parseDate = (val: string) =>
  LocalDate.parse(val, DateTimeFormatter.ofPattern('yyyy-MM-dd'));

export const dateYearMonthSchema = z
  .string()
  .regex(dateYearMonthRegex)
  .refine(
    (val) => {
      try {
        parseDate(val);
        return true;
      } catch (_) {
        return false;
      }
    },
    {
      params: {
        i18n: { key: 'form_invalid_date' },
      },
    },
  );

export const optionalDateYearMonthSchema = z
  .string()
  .regex(dateYearMonthRegex)
  .optional();

export const dateYearMonthMinMaxSchema = ({
  min,
  max,
}: {
  min?: DateYYYYMMDD;
  max?: DateYYYYMMDD;
}) =>
  z
    .string()
    .regex(dateYearMonthRegex)
    .refine(
      (val) => {
        try {
          const date = parseDate(val);
          const minDate = min ? parseDate(min) : undefined;
          const maxDate = max ? parseDate(max) : undefined;

          return (
            (!minDate || date.isAfter(minDate) || date.isEqual(minDate)) &&
            (!maxDate || date.isBefore(maxDate) || date.isEqual(maxDate))
          );
        } catch (_) {
          return false;
        }
      },
      {
        params: {
          i18n: { key: 'form_invalid_date' },
        },
      },
    );
