import React from 'react';

import { useQuery } from '@tanstack/react-query';

import { Box, Skeleton } from '@mui/material';

import { SearchInput, fetchSearchAllContracts } from '@octopus/api';

import { CountProps, Counts } from '../../modules/components/Counts';

export function PeopleCount({
  organizationId,
  companyId,
}: {
  organizationId: string;
  companyId: string;
}) {
  const queryTotals = useQuery({
    queryKey: ['getContractTotals', { organizationId, companyId }],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const searchFilter: SearchInput = {
        counting: {
          filtered: {
            total: true,
            byProp: {
              contractType: ['br:clt', 'br:pj'],
              workerCategory: ['clt:autonomo'],
            },
          },
        },
        filtering: {
          elements: {
            companyId: [companyId],
            status: [{ not: 'terminated' }],
          },
        },
      };

      const totals = await fetchSearchAllContracts({
        pathParams: {
          organizationId,
        },
        body: searchFilter,
      });

      const counts: CountProps = {};
      counts['all'] = totals?.metadata?.filtered?.counters?.total ?? 0;

      if (counts['all'] === 0) {
        return counts;
      }

      Object.entries({
        ...totals.metadata.filtered.counters.byProp['contractType'],
        ...totals.metadata.filtered.counters.byProp['workerCategory'],
      }).forEach(([key, value]) => {
        counts[key] = value;
      });
      // Remove autonomos from clt count
      counts['br:clt'] = counts['br:clt'] - (counts['clt:autonomo'] ?? 0);
      if (
        counts['all'] === counts['br:pj'] ||
        counts['all'] === counts['br:clt'] ||
        counts['all'] === counts['br:clt|clt:autonomo']
      ) {
        return counts;
      }

      Object.entries(counts).forEach(([key, value]) => {
        counts[key] = value;
      });

      return counts;
    },
    enabled: !!organizationId && !!companyId,
  });

  if (queryTotals.isLoading || queryTotals.isFetching) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        px={0.5}
        paddingTop={3}
        pb={2}
        width={'40%'}
      >
        <Skeleton variant="rounded" width={'100%'} height={'40px'} />
      </Box>
    );
  }

  return (
    <Box
      px={0.5}
      paddingTop={3}
      pb={2}
      width={'60%'}
      display="flex"
      data-testid={'people-counts'}
    >
      <Counts props={getContractTypeLabels(queryTotals.data)} />
    </Box>
  );
}

const contractLabels = {
  all: 'Total de pessoas',
  'br:clt': 'Colaboradores',
  'br:pj': 'Prestadores de serviço',
  'clt:autonomo': 'Autônomos',
} as const;

function getContractTypeLabels(counts: CountProps): CountProps {
  return Object.entries(counts).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [contractLabels[key as keyof typeof contractLabels]]: value,
    };
  }, {} as CountProps);
}
