import React from 'react';

import {
  AdmissionDraftInputFormInfo,
  AdmissionDraftInputFormSteps,
} from '@octopus/api';
import { GrauInstrucao } from '@octopus/esocial/mapper';
import { z } from '@octopus/i18n';
import { IFormDefinition, UI_TYPE } from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { AdmissionReviewDependent } from '../../../review/AdmissionReviewDependents';
import { AddNewDependent } from '../../dependentes/NewAdmissionDependents';
import { NewAdmissionDocuments } from '../../documents/NewAdmissionDocuments';
import { NewProfilePicture } from '../../pictures/NewProfilePicture';
import {
  getDadosBancariosFields,
  getDeficienciaFields,
} from '../fieldDefinitions';
import { getDadosPessoaisFields } from '../fieldDefinitions/dadosPessoais';
import { getEnderecoEContatoFields } from '../fieldDefinitions/enderecoEContatos';
import { getOptionsFromMapper } from '../inputUtils';
import {
  AdmissionFormState,
  AdmissionFormSteps,
  FormStepDefinition,
} from '../types';

import { getCustomFieldsStepDefinition } from './customFields';

export const getWorkerSteps = async ({
  organizationId,
  formState,
  formSteps,
  finalizaAdmissao,
  isPj,
}: {
  organizationId: string;
  formState: AdmissionFormState;
  formSteps: AdmissionDraftInputFormSteps;
  finalizaAdmissao: FormStepDefinition;
  isPj: boolean;
}): Promise<AdmissionFormSteps> => {
  const { grauInstr } = formState;
  const dados_pessoais = getDadosPessoaisFields({
    formState,
    isPj,
    dadosPessoaisFormSteps: formSteps[admissionDraftFormSteps.dados_pessoais],
  });

  const formacao_academica = {
    definition: [
      {
        label: 'Escolaridade',
        type: z.number().int(),
        name: 'grauInstr',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione',
        options: getOptionsFromMapper({
          mapper: GrauInstrucao,
          currentValue: grauInstr,
        }),
      },
    ],
    options: {
      id: admissionDraftFormSteps.formacao_academica,
      title: 'Formação acadêmica',
      completed:
        formSteps[admissionDraftFormSteps.formacao_academica]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.formacao_academica]?.reviewed,
    },
  };

  const pessoa_com_deficiencia = getDeficienciaFields({
    formState,
    deficienciaFormSteps: formSteps[admissionDraftFormSteps.deficiencias],
  });

  const dados_bancarios = getDadosBancariosFields({
    formState,
    dadosBancariosFormSteps: formSteps[admissionDraftFormSteps.dados_bancarios],
  });

  const documents_section = {
    definition: [] as IFormDefinition,
    options: {
      getCustomComponent: ({
        updateDisabled,
        isLoading,
        formState,
        stepDefinition,
        onSubmitCallback,
        goToNextStep,
        goToPreviousStep,
        organizationId,
        companyId,
        draftId,
        submitLabel,
      }: {
        updateDisabled: boolean;
        isLoading: boolean;
        formState: AdmissionFormState;
        stepDefinition: FormStepDefinition;
        goToNextStep: () => void;
        goToPreviousStep: () => void;
        onSubmitCallback: (newFormState: AdmissionFormState) => void;
        organizationId: string;
        companyId: string;
        draftId: string;
        submitLabel: string;
      }): React.ReactElement => {
        if (!formState || !stepDefinition) {
          return null;
        }
        return (
          <NewAdmissionDocuments
            disabled={updateDisabled}
            isLoading={isLoading}
            formState={formState}
            stepDefinition={stepDefinition}
            onSubmitCallback={onSubmitCallback}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            organizationId={organizationId}
            companyId={companyId}
            draftId={draftId}
            submitLabel={submitLabel}
            workerCategory={formState.workerCategory}
          />
        );
      },
      id: admissionDraftFormSteps.documentos,
      title: 'Documentos',
      completed: formSteps[admissionDraftFormSteps.documentos]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.documentos]?.reviewed,
    },
  };

  const profile_picture_section = {
    definition: [] as IFormDefinition,
    options: {
      getCustomComponent: ({
        updateDisabled,
        isLoading,
        formState,
        stepDefinition,
        goToNextStep,
        goToPreviousStep,
        organizationId,
        companyId,
        draftId,
        submitLabel,
      }: {
        updateDisabled: boolean;
        isLoading: boolean;
        formState: AdmissionFormState;
        stepDefinition: FormStepDefinition;
        goToNextStep: () => void;
        goToPreviousStep: () => void;
        organizationId: string;
        companyId: string;
        draftId: string;
        submitLabel: string;
      }): React.ReactElement => {
        if (!formState || !stepDefinition) {
          return null;
        }
        return (
          <NewProfilePicture
            disabled={updateDisabled}
            isLoading={isLoading}
            formState={formState}
            stepDefinition={stepDefinition}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            organizationId={organizationId}
            companyId={companyId}
            draftId={draftId}
            submitLabel={submitLabel}
            workerCategory={formState.workerCategory}
            profilePictureId={formState.profilePictureId}
          />
        );
      },
      id: admissionDraftFormSteps.foto_perfil,
      title: 'Foto de perfil',
      completed: formSteps[admissionDraftFormSteps.foto_perfil]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.foto_perfil]?.reviewed,
    },
  };

  const dependentes_section = {
    definition: [] as IFormDefinition,
    options: {
      getCustomComponent: ({
        updateDisabled,
        isLoading,
        formState,
        stepDefinition,
        onSubmit,
        goToPreviousStep,
        organizationId,
        companyId,
        draftId,
        submitLabel,
      }: {
        updateDisabled: boolean;
        isLoading: boolean;
        formState: AdmissionFormState;
        onSubmit: ({ extraData }: { extraData?: Record<string, any> }) => void;
        stepDefinition: FormStepDefinition;
        goToPreviousStep: () => void;
        organizationId: string;
        companyId: string;
        draftId: string;
        submitLabel: string;
      }): React.ReactElement => {
        if (!formState || !stepDefinition) {
          return null;
        }
        return (
          <AddNewDependent
            disabled={updateDisabled}
            isLoading={isLoading}
            formState={formState}
            stepDefinition={stepDefinition}
            onSubmit={onSubmit}
            goToPreviousStep={goToPreviousStep}
            organizationId={organizationId}
            companyId={companyId}
            draftId={draftId}
            submitLabel={submitLabel}
          />
        );
      },
      id: admissionDraftFormSteps.dependentes,
      title: 'Dependentes',
      completed: formSteps[admissionDraftFormSteps.dependentes]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.dependentes]?.reviewed,
    },
  };

  const endereco_e_contatos = getEnderecoEContatoFields({
    formState,
    enderecoFormSteps: formSteps[admissionDraftFormSteps.endereco_e_contatos],
  });

  const custom_fields_worker = await getCustomFieldsStepDefinition({
    filledBy: 'worker',
    organizationId,
    formState,
    formSteps,
  });

  return {
    dados_pessoais,
    formacao_academica,
    endereco_e_contatos,
    deficiencias: pessoa_com_deficiencia,
    dependentes: dependentes_section,
    dados_bancarios,
    ...(custom_fields_worker && { custom_fields_worker }),
    foto_perfil: profile_picture_section,
    documentos: documents_section,
    ...(finalizaAdmissao && { finaliza_admissao: finalizaAdmissao }),
  };
};

export const getReviewDependentStep = ({
  fieldStepsState,
  dependentId,
}: {
  fieldStepsState: AdmissionDraftInputFormInfo;
  dependentId: string;
}): FormStepDefinition => {
  const formSteps = fieldStepsState?.formSections?.['personal_data'] ?? {};
  return {
    definition: [] as IFormDefinition,
    options: {
      getCustomComponent: ({
        formState,
        stepDefinition,
        onSubmitCallback,
        organizationId,
        companyId,
        draftId,
        formId,
      }: {
        updateDisabled: boolean;
        isLoading: boolean;
        formState: AdmissionFormState;
        stepDefinition: FormStepDefinition;
        onSubmitCallback: (newFormState: AdmissionFormState) => void;
        organizationId: string;
        companyId: string;
        draftId: string;
        formId?: string;
      }): React.ReactElement => {
        if (!formState || !stepDefinition) {
          return null;
        }
        return (
          <AdmissionReviewDependent
            formState={formState}
            stepDefinition={stepDefinition}
            onSubmitCallback={onSubmitCallback}
            organizationId={organizationId}
            companyId={companyId}
            draftId={draftId}
            formId={formId}
            dependentId={dependentId}
          />
        );
      },
      id: admissionDraftFormSteps.dependentes,
      title: 'Dependentes',
      dependentId,
      completed: formSteps[admissionDraftFormSteps.dependentes]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.dependentes]?.reviewed,
    },
  };
};

export const getAdminSteps = async ({
  organizationId,
  formState,
  formSteps,
}: {
  organizationId: string;
  formState: AdmissionFormState;
  formSteps: AdmissionDraftInputFormSteps;
}): Promise<AdmissionFormSteps> => {
  const custom_fields_admin = await getCustomFieldsStepDefinition({
    filledBy: 'admin',
    organizationId,
    formState,
    formSteps,
  });

  return {
    custom_fields_admin,
  };
};
