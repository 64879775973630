import { formatDateBR } from '@octopus/formatters';

import WorkerCategoryTag from '../../../../modules/components/contracts/WorkerCategoryTag';
import { CompanyContext } from '../../../../modules/types';
import { AdmissionFormState } from '../form/types';

const getSummaryFields = ({
  formState,
  companyContext,
}: {
  formState: AdmissionFormState;
  companyContext: CompanyContext;
}) => {
  return [
    {
      label: 'Nome completo',
      value: formState.nome ?? formState.user_name,
    },
    {
      label: 'Data de início',
      value: formatDateBR(formState.dataInicio),
    },
    {
      label: 'Tipo de contratação',
      value: <WorkerCategoryTag workerCategory={formState.workerCategory} />,
    },
    {
      label: 'Cargo',
      value:
        companyContext?.jobTitles?.find((j) => j.id === formState.cargo)
          ?.name ?? formState.cargo,
    },
    {
      label: 'Departamento',
      value: formState.departamento,
    },
    {
      label: 'Centro de custo',
      value:
        companyContext?.costCenters?.find(
          (cc) => cc.id === formState.centro_de_custo,
        )?.name ?? formState.centro_de_custo,
    },
    {
      label: 'Honorários',
      value: `R$ ${formState.salary}`,
    },
    {
      label: 'Email pessoal',
      value: formState.user_email,
    },
    {
      label: 'Contratante',
      value:
        companyContext?.legalEntities?.find(
          (le) => le.id === formState.legalEntity,
        )?.name ?? formState.legalEntity,
    },
  ];
};

export const pjSummaryFields = {
  getSummaryFields,
};
