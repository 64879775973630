import React, { useRef, useState } from 'react';

import dayjs from 'dayjs';
import { isFunction } from 'lodash';

import { ChevronLeft, ExpandLess, ExpandMore } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  Popover,
  Typography,
} from '@mui/material';

import {
  CustomFieldEntry,
  useDeleteContractEvent,
  useGetContract,
} from '@octopus/api';
import { ContractFieldChange, formatDateBR } from '@octopus/formatters';
import { Tag } from '@octopus/ui/design-system';

import { QueryResult } from '../../../../modules/types';
import { ContractDetailsProps } from '../types';

import { ContractEventHistoryProjection } from './ContractEventsHistoryProjection';
import { Loading } from './Loading';
import { ContractHistoryEvent, Utils } from './utils';

type EventSelection = {
  event: ContractHistoryEvent;
  index: number;
};
type OnEventSelected = (selection: EventSelection) => void;
type OnEventCanceled = (event: ContractHistoryEvent) => void;

type ContractEventsHistoryProps = {
  organizationId: string;
  contractId: string;
  personName: string;
  onDismiss: () => void;
  projectionComponent: React.FC<ContractDetailsProps>;
  events: ContractHistoryEvent[];
  onEventCanceled: OnEventCanceled;
  customFieldsQuery: QueryResult<CustomFieldEntry[]>;
};

export function ContractEventsHistory({
  events,
  organizationId,
  contractId,
  personName,
  onDismiss,
  projectionComponent,
  onEventCanceled,
  customFieldsQuery,
}: ContractEventsHistoryProps) {
  const [selectedEvent, setSelectedEvent] = useState<EventSelection>({
    event: undefined,
    index: undefined,
  });
  const [projectionIsOpen, setProjectionIsOpen] = useState<boolean>(false);

  const projectEvent = (selection: EventSelection) => {
    setProjectionIsOpen(true);
    setSelectedEvent(selection);
  };

  const onPrevious = () => {
    const currentIndex = selectedEvent.index;
    setSelectedEvent({
      event: events[currentIndex - 1],
      index: currentIndex - 1,
    });
  };

  const onNext = () => {
    const currentIndex = selectedEvent.index;
    setSelectedEvent({
      event: events[currentIndex + 1],
      index: currentIndex + 1,
    });
  };

  const dismissProjectionPanel = () => {
    setProjectionIsOpen(false);
    setSelectedEvent({ event: undefined, index: undefined });
  };

  return (
    <Box display="flex" alignContent="stretch" height="100%">
      <SecondaryDrawer
        isOpen={projectionIsOpen && !!selectedEvent.event}
        width="736px"
      >
        <ContractEventHistoryProjection
          projectionComponent={projectionComponent}
          event={selectedEvent.event}
          organizationId={organizationId}
          contractId={contractId}
          controls={{
            onDismiss: dismissProjectionPanel,
            hasPrevious: selectedEvent.index > 0,
            hasNext: selectedEvent.index < events.length - 1,
            onPrevious,
            onNext,
          }}
          customFieldsQuery={customFieldsQuery}
        />
      </SecondaryDrawer>
      <ContractEventsTimelinePanel
        organizationId={organizationId}
        contractId={contractId}
        projectedEventIndex={selectedEvent.index}
        events={events}
        onDismiss={onDismiss}
        personName={personName}
        onEventSelected={projectEvent}
        onEventCanceled={onEventCanceled}
      />
    </Box>
  );
}

function SecondaryDrawer({
  children,
  isOpen,
  width,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  width: string;
}) {
  return (
    <Box
      height="100%"
      zIndex={1}
      sx={{
        opacity: isOpen ? 1 : 0,
        width: isOpen ? width : '0px',
        transition: 'all 0.2s',
        backgroundColor: 'background.default',
        overflowY: 'overlay',
        scrollbarWidth: 'thin',
      }}
    >
      {children}
    </Box>
  );
}

function ContractEventsTimelinePanel({
  organizationId,
  contractId,
  events,
  personName,
  onDismiss,
  onEventSelected,
  projectedEventIndex,
  onEventCanceled,
}: {
  organizationId: string;
  contractId: string;
  events: ContractHistoryEvent[];
  personName: string;
  onDismiss: () => void;
  onEventSelected: OnEventSelected;
  projectedEventIndex?: number;
  onEventCanceled: OnEventCanceled;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '560px',
        overflowY: 'overlay',
        scrollbarWidth: 'thin',
      }}
    >
      <ContractHistoryPanelHeader
        personName={personName}
        onDismiss={onDismiss}
      />
      <Box sx={{ pt: 1, px: 7 }}>
        {
          <ContractHistoryEventsTimeline
            organizationId={organizationId}
            contractId={contractId}
            events={events}
            onEventSelected={onEventSelected}
            projectedEventIndex={projectedEventIndex}
            onEventCanceled={onEventCanceled}
          />
        }
      </Box>
    </Box>
  );
}

function ContractHistoryEventsTimeline({
  organizationId,
  contractId,
  events,
  onEventSelected,
  projectedEventIndex,
  onEventCanceled,
}: {
  organizationId: string;
  contractId: string;
  events: ContractHistoryEvent[];
  onEventSelected: OnEventSelected;
  projectedEventIndex?: number;
  onEventCanceled: OnEventCanceled;
}) {
  const now = dayjs();
  const currentEventIndex = events.findIndex(
    (event) =>
      (dayjs(event.effectiveDate).isSame(now, 'day') ||
        dayjs(event.effectiveDate).isBefore(now, 'day')) &&
      !event.canceled,
  );

  return (
    <Box display="flex" flexDirection="row" textAlign="left">
      <Timeline
        position="right"
        sx={{
          p: 0,
          mt: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {events.map((event, i) => (
          <ContractEventItem
            organizationId={organizationId}
            contractId={contractId}
            isProjected={projectedEventIndex === i}
            key={event.sequenceId}
            eventIndex={i}
            event={event}
            isCurrent={i === currentEventIndex}
            nextEvent={events[i + 1]}
            previousEvent={events[i - 1]}
            onEventSelected={onEventSelected}
            onEventCanceled={onEventCanceled}
          />
        ))}
      </Timeline>
    </Box>
  );
}

function ContractEventItem({
  organizationId,
  contractId,
  event,
  eventIndex,
  nextEvent,
  previousEvent,
  isCurrent,
  onEventSelected,
  isProjected,
  onEventCanceled,
}: {
  organizationId: string;
  contractId: string;
  event: ContractHistoryEvent;
  eventIndex: number;
  nextEvent?: ContractHistoryEvent;
  previousEvent?: ContractHistoryEvent;
  isCurrent: boolean;
  authorName?: string;
  onEventSelected: OnEventSelected;
  isProjected: boolean;
  onEventCanceled: OnEventCanceled;
}) {
  const { isHovered, hoveredStyle, hoverEventHandlers } =
    Utils.Hooks.useHoverBackground();

  const [diffShowing, setDiffShowing] = useState<boolean>(false);
  const toggleDiff = () => setDiffShowing((state) => !state);

  const isSelected = isHovered || isProjected || diffShowing;

  return (
    <TimelineItem
      sx={(theme) => ({
        ...hoveredStyle(theme),
        backgroundColor: isProjected
          ? theme.palette.background.primary
          : isSelected
            ? Utils.Styles.getHoveredBackgoundColor(theme)
            : undefined,
        cursor: isHovered ? 'pointer' : undefined,
        borderRadius: '12px',
        pr: 1,
        mb: 0.5,
      })}
      onClick={() => {
        if (!isHovered) {
          return;
        }
        onEventSelected({
          event,
          index: eventIndex,
        });
      }}
    >
      {isSelected ? (
        <IconButton
          size="small"
          sx={{
            pl: 0.5,
            pr: 0,
            mx: 0,
            '&.MuiButtonBase-root:hover': {
              bgcolor: 'transparent',
            },
          }}
        >
          <ChevronLeft
            color={isProjected ? 'primary' : undefined}
            sx={{ height: '12px', width: '12px', px: 0, mx: 0 }}
          />
        </IconButton>
      ) : null}

      <TimelineSeparator
        sx={{
          pr: 2,
          pl: isSelected ? 0 : 2,
        }}
      >
        <ContractEventTimelineNode
          event={event}
          previousEvent={previousEvent}
          nextEvent={nextEvent}
          isCurrent={isCurrent}
        />
      </TimelineSeparator>
      <TimelineContent
        {...hoverEventHandlers}
        sx={{
          px: 0,
          pt: 2,
          pb: 2.5,
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
          gap={2}
        >
          <Box display="flex" flexDirection="column" gap={0.5}>
            {event.canceled ? (
              <Typography
                variant="caption"
                sx={(theme) => ({
                  color: Utils.Styles.getGrayscaleLighter(theme),
                })}
              >
                <strong>Evento excluído</strong> -{' '}
                {formatDateBR(event.recordDate)}
              </Typography>
            ) : (
              <>
                <Typography variant="body2">
                  <strong>{Utils.Events.getEventActionName(event.type)}</strong>{' '}
                  - {formatDateBR(event.effectiveDate)}
                </Typography>

                <Typography variant="caption" color="textSecondary">
                  Por <strong>{event.authorName}</strong> em{' '}
                  {formatDateBR(event.recordDate)}
                </Typography>
              </>
            )}
          </Box>

          <Box gap={0.5} display="flex">
            {diffShowing ? (
              <>
                <IconButton
                  sx={{ borderRadius: 1, p: 0.5 }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleDiff();
                  }}
                >
                  <ExpandLess fontSize="small" />
                </IconButton>
                <EventActionsMenu
                  organizationId={organizationId}
                  contractId={contractId}
                  event={event}
                  onEventCanceled={onEventCanceled}
                />
              </>
            ) : isHovered && nextEvent ? (
              <>
                <IconButton
                  sx={{ borderRadius: 1, p: 0.5 }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleDiff();
                  }}
                >
                  <ExpandMore fontSize="small" />
                </IconButton>
                <EventActionsMenu
                  organizationId={organizationId}
                  contractId={contractId}
                  event={event}
                  onEventCanceled={onEventCanceled}
                />
              </>
            ) : undefined}
            <ContractEventTitleTag
              isCurrent={isCurrent}
              event={event}
              isSelected={isSelected}
            />
          </Box>
        </Box>
        <ContractEventFieldsDiff
          organizationId={organizationId}
          contractId={contractId}
          event={event}
          nextEvent={nextEvent}
          showing={diffShowing}
        />
      </TimelineContent>
    </TimelineItem>
  );
}

function EventActionsMenu({
  organizationId,
  contractId,
  event,
  onEventCanceled,
}: {
  organizationId: string;
  contractId: string;
  event: ContractHistoryEvent;
  onEventCanceled: OnEventCanceled;
}) {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const close = () => setMenuIsOpen(false);
  const open = () => setMenuIsOpen(true);
  const menuRef = useRef(null);

  if (!Utils.Events.canCancelEvent(event)) {
    return null;
  }

  return (
    <>
      <IconButton
        ref={menuRef}
        sx={{ borderRadius: 1, p: 0.5 }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          open();
        }}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Popover
        open={menuIsOpen}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <CancelEventMenuItem
          organizationId={organizationId}
          contractId={contractId}
          event={event}
          onCancel={close}
          onConfirm={() => {
            close();
            onEventCanceled(event);
          }}
        />
      </Popover>
    </>
  );
}

function CancelEventMenuItem({
  organizationId,
  contractId,
  event,
  onCancel,
  onConfirm,
}: {
  organizationId: string;
  contractId: string;
  event: ContractHistoryEvent;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const { isLoading: cancelEventLoading, mutateAsync } =
    useDeleteContractEvent();
  const cancelEvent = () =>
    mutateAsync({
      pathParams: {
        organizationId,
        contractId,
        eventId: event.sequenceId.toString(),
      },
    });

  const { hoveredStyle, hoverEventHandlers } = Utils.Hooks.useHoverBackground();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  return (
    <>
      <Box
        display="flex"
        {...hoverEventHandlers}
        sx={{
          height: '32px',
          width: '220px',
          py: 1,
          px: 0,
        }}
      >
        <Link
          component="button"
          underline="none"
          textAlign="left"
          sx={(theme) => ({
            ...hoveredStyle(theme),
            padding: 0,
            width: '100%',
          })}
          onClick={openDialog}
        >
          <Typography variant="body2" color="error" sx={{ px: 2, py: 0.5 }}>
            Cancelar evento
          </Typography>
        </Link>
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        PaperProps={{ sx: { minWidth: '600px' } }}
      >
        <DialogTitle sx={{ pt: 5 }}>
          <Box sx={{ px: 3 }}>Cancelar evento</Box>
        </DialogTitle>
        <DialogContent>
          {cancelEventLoading ? (
            <Loading />
          ) : (
            <Box sx={{ px: 3 }}>
              <Typography color="textPrimary" variant="body1" fontWeight="bold">
                Ao confirmar, o que acontece em seguida:
              </Typography>
              <List
                sx={{
                  pt: 2,
                  pl: 2,
                  pb: 3,
                  listStyleType: 'disc',
                }}
              >
                <ListItem disableGutters sx={{ py: 0, display: 'list-item' }}>
                  <Box>
                    <Typography variant="body1" fontWeight="500">
                      O evento será excluído do histórico do colaborador no
                      eSocial
                    </Typography>
                    <Typography variant="caption">
                      Você ainda verá o histórico em nosso sistema.
                    </Typography>
                  </Box>
                </ListItem>
              </List>
              <Alert severity="error" icon={<ErrorIcon />}>
                Atenção, esta ação não pode ser desfeita.
              </Alert>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={cancelEventLoading}
            color="secondary"
            size="large"
            onClick={() => {
              closeDialog();
              onCancel();
            }}
          >
            Cancelar
          </Button>
          <Button
            disabled={cancelEventLoading}
            color="primaryAlt"
            size="large"
            onClick={() => {
              cancelEvent().then(() => {
                closeDialog();
                onConfirm();
              });
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

type ContractEventFieldsDiffProps = {
  organizationId: string;
  contractId: string;
  showing: boolean;
  event: ContractHistoryEvent;
  nextEvent?: ContractHistoryEvent;
};

function ContractEventFieldsDiff({
  organizationId,
  contractId,
  showing,
  event,
  nextEvent,
}: ContractEventFieldsDiffProps) {
  const {
    isError,
    isLoading,
    data: projection,
  } = useGetContract(
    {
      pathParams: {
        organizationId,
        contractId,
      },
      queryParams: {
        sequenceId: nextEvent?.sequenceId.toString(),
        effectiveDate: nextEvent?.effectiveDate,
      },
    },
    {
      enabled: !!nextEvent && showing,
    },
  );

  if (!nextEvent || !showing) {
    return null;
  }
  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return null;
  }

  const changes = Utils.Events.contractFieldChanges(projection, event).filter(
    ({ path }) => !Utils.Events.isChangedFieldHidden(path),
  );

  return (
    <>
      {changes.map(({ path, oldData, newData }, i) => (
        <Box>
          <ContractEventPropDiff
            organizationId={organizationId}
            contractId={contractId}
            path={path}
            oldData={oldData}
            newData={newData}
          />
          {i === changes.length - 1 ? null : (
            <Box sx={{ pl: 2, pr: 1 }}>
              <Divider component="li"></Divider>
            </Box>
          )}
        </Box>
      ))}
    </>
  );
}

type ContractEventPropDiffProps = ContractFieldChange & {
  organizationId: string;
  contractId: string;
};

function ContractEventPropDiff(props: ContractEventPropDiffProps) {
  const { label, formattedOldData, formattedNewData } =
    Utils.Hooks.useFormattedDiffValues(props);

  return (
    <Box sx={{ py: 2, px: 2 }} gap={0.5} display="flex" flexDirection="column">
      <Typography variant="caption" color="textSecondary" fontWeight="bold">
        {label}
      </Typography>
      {!formattedOldData ? null : (
        <Box gap={2} display="flex" flexDirection="row">
          <LightGreyText width="40px">de</LightGreyText>
          <LightGreyText>{formattedOldData}</LightGreyText>
        </Box>
      )}
      <Box gap={2} display="flex" flexDirection="row">
        <LightGreyText width="40px">para</LightGreyText>
        <Typography variant="caption" fontWeight="bold">
          {formattedNewData}
        </Typography>
      </Box>
    </Box>
  );
}

function LightGreyText({
  children,
  width = undefined,
}: {
  children: string | JSX.Element | JSX.Element[];
  width?: string;
}) {
  return (
    <Typography
      variant="caption"
      sx={(theme) => ({
        width,
        color: theme.palette.strokes.heavy,
      })}
    >
      {children}
    </Typography>
  );
}

function ContractEventTitleTag({
  isCurrent,
  isSelected,
  event,
}: {
  isCurrent: boolean;
  isSelected: boolean;
  event: ContractHistoryEvent;
}) {
  const isScheduled = Utils.Events.eventIsScheduled(event);

  if (!isScheduled && !isCurrent) {
    return null;
  }

  const [title, color]: [string, 'info' | 'default'] = isScheduled
    ? ['Agendada', 'default']
    : ['Atual', 'info'];

  return (
    <Tag
      color={color}
      emphasis={isSelected ? 'high' : undefined}
      borderRadius="small"
      slotProps={{
        typography: { variant: 'caption' },
      }}
    >
      {title}
    </Tag>
  );
}

function ContractEventTimelineNode({
  isCurrent,
  event,
  nextEvent,
  previousEvent,
}: {
  isCurrent: boolean;
  event: ContractHistoryEvent;
  nextEvent?: ContractHistoryEvent;
  previousEvent?: ContractHistoryEvent;
}) {
  const isScheduled = Utils.Events.eventIsScheduled(event);
  const isFirst = !previousEvent;
  const isLast = !nextEvent;

  const baseTimelineDotStyle = {
    width: '2px',
    height: '2px',
    padding: 0,
    mb: 0,
    mt: 0,
  };

  let dotMuiColor = undefined;
  let dotVariant = 'outlined';
  let dotBorderColor = Utils.Styles.getPrimaryLighter;
  let separatorColor = Utils.Styles.getPrimaryLighter;

  if (event.canceled) {
    dotBorderColor = Utils.Styles.getBackGroundColor;
    separatorColor = Utils.Styles.getBackGroundColor;
  } else if (isScheduled) {
    dotBorderColor = undefined;
    separatorColor = undefined;
  } else if (isCurrent) {
    dotMuiColor = 'primary';
    dotVariant = 'filled';
    dotBorderColor = undefined;
  }

  const EventTimelineDot = (
    <TimelineDot
      color={dotMuiColor as 'primary' | undefined}
      variant={dotVariant as 'filled' | 'outlined'}
      sx={(theme) => ({
        ...baseTimelineDotStyle,
        borderColor: dotBorderColor ? dotBorderColor(theme) : dotBorderColor,
      })}
    />
  );

  const EventTimelineConnectorBottom = isLast ? null : (
    <TimelineConnector
      sx={(theme) => ({
        height: isFirst ? undefined : 25,
        mb: -0.5,
        backgroundColor: separatorColor
          ? separatorColor(theme)
          : separatorColor,
      })}
    />
  );

  const topConnectorColor = isFirst
    ? undefined
    : event.canceled
      ? Utils.Styles.getBackGroundColor
      : previousEvent && Utils.Events.eventIsScheduled(previousEvent)
        ? Utils.Styles.getGrayscaleLighter
        : Utils.Styles.getPrimaryLighter;

  const EventTimelineConnectorTop = (
    <Box
      sx={(theme) => ({
        height: '24px',
        width: '2px',
        backgroundColor: isFunction(topConnectorColor)
          ? topConnectorColor(theme)
          : topConnectorColor,
      })}
    ></Box>
  );

  return (
    <>
      {EventTimelineConnectorTop}
      {EventTimelineDot}
      {EventTimelineConnectorBottom}
    </>
  );
}

function ContractHistoryPanelHeader({
  personName,
  onDismiss,
}: {
  personName: string;
  onDismiss: () => void;
}) {
  return (
    <Box
      gap={2}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'top',
        justifyContent: 'space-between',
        pt: 5,
        pb: 2,
        pr: '50px',
        pl: '50px',
      }}
    >
      <Box display="flex" flexDirection="column" textAlign="left">
        <Typography variant="body1" color="textSecondary">
          {personName}
        </Typography>
        <Typography variant="h6" gap={1}>
          Histórico de alterações
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" textAlign="right">
        <Button
          color="secondary"
          size="small"
          onClick={() => {
            onDismiss();
          }}
          sx={{
            borderColor: 'transparent',
            minWidth: '32px',
            minHeight: '32px',
            height: '32px',
            width: '32px',
          }}
        >
          <Close sx={{ fontSize: '24px', padding: 0.5 }} />
        </Button>
      </Box>
    </Box>
  );
}
