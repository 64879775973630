import { useEffect, useRef, useState } from 'react';

import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react';

import { Box, ClickAwayListener } from '@mui/material';

import {
  // PayrollInputsConfig,
  // PayrollInputsList,
  SearchInput,
  useSearchAllContracts,
} from '@octopus/api';
import { contractStatuses, contractTypes } from '@octopus/contract-types';

import UserAvatar from '../../../../modules/components/UserAvatar';
import { useAutoSizeTable } from '../../[period]/[type]/inputs/useAutoSizeTable';
// import { useSubmissionState } from '../../[period]/[type]/inputs/useSubmissionState';

export type InputsTableProps = {
  organizationId: string;
  companyId: string;
  // data: PayrollInputsList;
  // config: PayrollInputsConfig;
};

function InputRpaTable({
  organizationId,
  companyId,
  // data: payrollList,
  // config,
}: InputsTableProps) {
  const gridRef = useRef<AgGridReact>();
  useAutoSizeTable({ gridRef, show: true });
  const { mutate, data: autonomoContracts } = useSearchAllContracts();
  const [searchTerm, _] = useState<string | undefined>();
  // const { employees, hasPayrollBeenEdited, getEditCount, getSubmissionInput } =
  //   useSubmissionState(config, payrollList);
  useEffect(() => {
    if (organizationId) {
      mutate({
        pathParams: {
          organizationId,
        },
        body: {
          ...(searchTerm ? { query: searchTerm } : {}),
          filtering: {
            elements: {
              workerCategory: ['clt:autonomo'],
              status: [{ not: contractStatuses.terminated }],
              contractType: [contractTypes.brClt],
              companyId: [companyId],
            },
          },
          sorting: {
            field: 'name',
            order: 'asc',
          },
        } as SearchInput,
      });
    }
  }, [organizationId, searchTerm, mutate, companyId]);

  return (
    <ClickAwayListener
      onClickAway={() => gridRef.current?.api?.clearCellSelection()}
    >
      <Box className="ag-theme-quartz" height="100%" width="100%">
        <AgGridReact
          columnDefs={[
            {
              valueGetter: (props) => props.data.name,
              headerName: 'Colaborador',
              editable: true,
              cellRenderer: (props: CustomCellRendererProps) => {
                return (
                  <UserAvatar
                    name={props?.value}
                    expandNameOnHover={true}
                    sx={{
                      '--UserAvatar-name-max-width': '12.5em',
                    }}
                  />
                );
              },
              cellEditor: 'agRichSelectCellEditor',
              cellEditorParams: {
                values: autonomoContracts?.data?.map(
                  (contract) => contract.name,
                ),
                valueListMaxHeight: '220px',
                cellRenderer: (props: CustomCellRendererProps) => {
                  return (
                    <UserAvatar
                      name={props?.value}
                      expandNameOnHover={true}
                      sx={{
                        '--UserAvatar-name-max-width': '12.5em',
                      }}
                    />
                  );
                },
              },
            },
            {
              field: 'serviceValue',
              headerName: 'Valor do serviço',
              editable: true,
            },
            { field: 'ISSRate', headerName: 'Aliquota de ISS', editable: true },
            {
              field: 'paymentDate',
              headerName: 'Data de pagamento',
              editable: true,
            },
          ]}
          rowData={[
            {
              name: 'test',
              serviceValue: '21',
              ISSRate: '0.02',
              paymentDate: '12/12/1995',
            },
          ]}
          keepDetailRows
          ref={gridRef}
        />
      </Box>
    </ClickAwayListener>
  );
}

export default InputRpaTable;
