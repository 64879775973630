import { WorkerCategory } from '@octopus/contract-types';
import {
  addressAttachmentTypes,
  dependentAttachmentTypes,
  grauInstrpersonalAttachmentTypes,
  personalAttachmentTypes,
} from '@octopus/onboarding-types';

import { DocumentSectionsType } from './types';

const cltDocumentSections: DocumentSectionsType = {
  dados_pessoais: {
    required: true,
    title: 'Documento de identificação',
    description: 'Preferencialmente RG ou CNH que contenha o numero do CPF.',
    allowedTypes: personalAttachmentTypes,
  },
  endereco_e_contatos: {
    required: true,
    title: 'Comprovante de residência',
    description:
      'Conta de luz, água, gás, telefone fixo ou móvel, internet ou TV a cabo são aceitos.',
    allowedTypes: addressAttachmentTypes,
  },
  formacao_academica: {
    required: false,
    title: 'Certificado de formação',
    description:
      'Caso queira, você pode anexar aqui o comprovante de certificado de formação.',
    allowedTypes: grauInstrpersonalAttachmentTypes,
  },
};

const pjDocumentSections: DocumentSectionsType = {
  dados_pessoais: {
    required: true,
    title: 'Documento de identificação',
    description: 'Preferencialmente RG ou CNH que contenha o numero do CPF.',
    allowedTypes: personalAttachmentTypes,
  },
  endereco_e_contatos: {
    required: true,
    title: 'Comprovante de residência',
    description:
      'Conta de luz, água, gás, telefone fixo ou móvel, internet ou TV a cabo são aceitos.',
    allowedTypes: addressAttachmentTypes,
  },
  dados_empresa: {
    required: true,
    title: 'Cartão CNPJ',
    description:
      'Acesse o site da Receita Federal e consulte o Cartão CNPJ. Basta inserir o número do CNPJ da sua empresa.',
    allowedTypes: ['cartao_cnpj'],
  },
  pj_info: {
    required: true,
    title: 'Contrato Social - Certidão de Inteiro Teor',
    description:
      'Acesse o site da junta comercial do seu estado para obter a Certidão de Inteiro Teor.',
    allowedTypes: ['contrato_social'],
  },
};

const cltDocumentReviewSections: DocumentSectionsType = {
  ...cltDocumentSections,
  dependentes: {
    required: true,
    title: 'Dependente',
    description: '',
    allowedTypes: dependentAttachmentTypes,
  },
};

const pjDocumentReviewSections: DocumentSectionsType = {
  ...pjDocumentSections,
  dependentes: {
    required: true,
    title: 'Dependente',
    description: '',
    allowedTypes: dependentAttachmentTypes,
  },
};

export const getDocumentSections = (
  workerCategory: WorkerCategory,
): DocumentSectionsType => {
  return workerCategory === 'pj' ? pjDocumentSections : cltDocumentSections;
};

export const getDocumentReviewSections = (
  workerCategory: WorkerCategory,
): DocumentSectionsType => {
  return workerCategory === 'pj'
    ? pjDocumentReviewSections
    : cltDocumentReviewSections;
};
