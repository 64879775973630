import { Mapper } from './mapper';

export const TipoRegimeTrabalho = Mapper.from([
  [
    1,
    'CLT - Consolidação das Leis de Trabalho e legislações trabalhistas específicas',
  ],
  [
    2,
    'Estatutário/legislações específicas (servidor temporário, militar, agente político, etc.)',
  ],
]);
