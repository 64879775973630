import { SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { UI_TYPE } from '@octopus/libs/forms';

import { TFieldInputRenderProps, TFieldRenderProps } from '../parseField/types';

type FieldCheckboxProps = {
  field: TFieldRenderProps & TFieldInputRenderProps;
  sx?: SxProps<Theme>;
};

export function FieldCheckbox(props: FieldCheckboxProps) {
  const { field } = props;

  return (
    <span {...field.props} style={{ display: 'inline-block' }}>
      <input {...field.input.props} />
      <label
        {...field.label.props}
        style={{ display: 'inline-block', marginLeft: 1.5 }}
      >
        <Typography
          component="label"
          variant="body2"
          sx={{ cursor: 'pointer', pl: 0.5 }}
          tabIndex={0}
          htmlFor={field.label.props.htmlFor}
        >
          {field.label.textContent}
        </Typography>
      </label>
    </span>
  );
}

FieldCheckbox.uiType = UI_TYPE.CHECKBOX;
FieldCheckbox.canRender = (
  field: TFieldRenderProps,
): field is FieldCheckboxProps['field'] => {
  return 'input' in field && field.input.props.type === 'checkbox';
};
