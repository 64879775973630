import { AdmissionDraftEntry, DateYYYYMMDD } from '@octopus/api';
import { WorkerCategory, contractTypes } from '@octopus/contract-types';
import { isTrabalhadorAutonomo } from '@octopus/esocial/contracts';

export const getAdmissionDate = (
  admissionDraft: AdmissionDraftEntry,
): DateYYYYMMDD | undefined => {
  const { contractType, br } = admissionDraft;
  if (
    br &&
    contractType === contractTypes.brClt &&
    (!br.trabalho || !isTrabalhadorAutonomo(br.trabalho))
  ) {
    return br.regime?.dtAdm;
  }

  if (br && contractType === contractTypes.brPj) {
    return br.contrato?.inicio;
  }

  return '';
};

export const getAdmissionJobTitleId = (
  admissionDraft: AdmissionDraftEntry,
): string | undefined => {
  const { contractType, br } = admissionDraft;

  if (contractType === contractTypes.brClt) {
    return br?.trabalho?.jobTitleId;
  }

  if (contractType === contractTypes.brPj) {
    return br?.prestador?.posicao?.jobTitleId;
  }

  return '';
};

export const getAdmissionEmail = (
  admissionDraft: AdmissionDraftEntry,
): string | undefined => {
  const { contractType, br, userData } = admissionDraft;
  if (contractType === contractTypes.brClt) {
    return br?.vinculo?.emailCorp ?? userData?.email;
  }

  if (contractType === contractTypes.brPj) {
    return br?.emailCorp ?? userData?.email;
  }

  return '';
};

export const getAdmissionDepartment = (
  admissionDraft: AdmissionDraftEntry,
): string | undefined => {
  const { contractType, br } = admissionDraft;
  if (contractType === contractTypes.brClt) {
    return br?.trabalho?.departamento;
  }

  if (contractType === contractTypes.brPj) {
    return br?.prestador?.posicao?.departamento;
  }

  return '';
};

export const getAdmissionCostCenterId = (
  admissionDraft: AdmissionDraftEntry,
): string | undefined => {
  const { contractType, br } = admissionDraft;
  if (contractType === contractTypes.brClt) {
    return br?.gestao?.costCenterId;
  }

  if (contractType === contractTypes.brPj) {
    return br?.prestador?.gestao?.costCenterId;
  }

  return '';
};

export const getAdmissionTitle = (workerCategory: WorkerCategory): string => {
  return workerCategory === 'pj' ? 'contratação' : 'admissão';
};
