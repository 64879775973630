import { z } from 'zod';

export const nomeSocialSchema = z
  .object({
    nomeSocial: z.string().optional(),
    nomeSocialHidden: z.string().optional(),
  })
  .superRefine((formState, ctx) => {
    const { nomeSocial, nomeSocialHidden } = formState || {};

    if (nomeSocialHidden) {
      return;
    }

    if (!nomeSocial) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Obrigatório',
        path: ['nomeSocial'],
      });
    }

    return;
  });
