import { useState } from 'react';

import { Icon } from '@tabler/icons-react';

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Typography,
  TypographyProps,
} from '@mui/material';

import { getAvatarColor } from '../../utils';

export type UserAvatarProps = {
  name: string;
  pictureUrl?: string;
  showFullName?: boolean;
  size?: number;
  avatarProps?: {
    border?: string;
    ml?: number;
    mr?: number;
    my?: number;
    color?: string;
  } & AvatarProps;
  avatarTextProps?: {
    fontSize?: string;
    fontWeight?: string;
  } & TypographyProps;
  textProps?: TypographyProps;
  expandNameOnHover?: boolean;
  onClick?: () => void;
  AvatarHoverIcon?: Icon;
} & BoxProps;

export default function UserAvatar({
  name,
  pictureUrl,
  showFullName = true,
  size = 24,
  avatarProps,
  avatarTextProps,
  textProps,
  expandNameOnHover = false,
  onClick,
  AvatarHoverIcon,
  ...props
}: UserAvatarProps) {
  const [avatarHover, setAvatarHover] = useState(false);
  const {
    border = '0.5px solid rgba(0, 0, 0, 0.07)',
    ml = 2,
    mr = 1.5,
    my = 0.75,
    color,
    ...restAvatarProps
  } = avatarProps || {};
  const { fontWeight, fontSize, ...restAvatarTextProps } =
    avatarTextProps || {};
  return (
    <Box display="flex" flexDirection="row" alignItems="center" {...props}>
      <Box
        onMouseEnter={() => setAvatarHover(true)}
        onMouseLeave={() => setAvatarHover(false)}
        onClick={onClick}
        sx={{
          width: `${size}px`,
          height: `${size}px`,
          my,
          mr,
          ml,
          boxSizing: 'border-box',
          borderRadius: '100%',
          ...(onClick && { cursor: 'pointer' }),
        }}
      >
        {onClick && AvatarHoverIcon && avatarHover && (
          <Box
            width={`${size + 4}px`}
            height={`${size + 4}px`}
            position="absolute"
            zIndex={25}
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="rgba(97, 97, 97, 0.4)"
            borderRadius="100%"
          >
            <AvatarHoverIcon size={size / 2.5} color="#FFFFFF" stroke={2.5} />
          </Box>
        )}
        <Avatar
          src={pictureUrl}
          sx={(theme) => ({
            width: '100%',
            height: `100%`,
            backgroundColor: color ? color : getAvatarColor(name, theme),
            border,
            zIndex: 20,
          })}
          {...restAvatarProps}
        >
          <Typography
            variant="caption"
            sx={(theme) => ({
              fontWeight: fontWeight
                ? fontWeight
                : theme.typography.caption.fontWeight,
              fontSize: fontSize ? fontSize : theme.typography.caption.fontSize,
            })}
            {...restAvatarTextProps}
          >
            {name.length > 0 ? name.charAt(0).toUpperCase() : 'O'}
          </Typography>
        </Avatar>
      </Box>
      {showFullName && (
        <Typography
          component={'span'}
          variant="body2"
          {...textProps}
          sx={{
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            maxWidth: 'var(--UserAvatar-name-max-width, auto)',
            position: 'relative',
            ...(expandNameOnHover && {
              '&:hover .ellipsisName': {
                opacity: 0,
              },
              '&:hover .fullName': {
                px: 0.5,
                backgroundColor: 'text.primary',
                color: 'background.paper',
                borderRadius: 0.5,
                boxShadow:
                  '0px 4px 16px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.04)',
                contain: 'paint',
                opacity: 1,
                zIndex: 1000,
                userSelect: 'unset',
              },
            }),
          }}
        >
          <Box
            component={'span'}
            className="ellipsisName"
            sx={{
              display: 'inline-block',
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {name}
          </Box>
          <Box
            component={'span'}
            className="fullName"
            sx={{
              display: 'inline-block',
              opacity: 0,
              zIndex: -1,
              userSelect: 'none',
              position: 'absolute',
            }}
          >
            {name}
          </Box>
        </Typography>
      )}
    </Box>
  );
}
