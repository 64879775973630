import { groupBy } from 'lodash';

import { Mapper } from './mapper';
import { Municipios } from './municipios';
import { UFs } from './uf';

/**
 * Map of `Municipio` mappers.
 *
 * You first index into it using a `Estado`'s (state)
 * code to get a handle to a mapper
 * that maps it's `Municipios` (cities).
 *
 * @example
 *   const state = Estados.codes()[0];
 *   const mapper = MunicipiosByEstado[state];
 *   const allOfTheStatesCities = mapper.codes().map(
 *     (code) => mapper.getByCode(code)
 *   );
 *
 */
const MunicipiosByEstado: Record<string, Mapper> = {};

const getEstadoCodeByMunicipioCode = (code: number): number | undefined =>
  code != null ? parseInt(`${code}`.slice(0, 2)) : undefined;

const getEstadoByMunicipioCode = (code: number): string | undefined => {
  const estadoCode = getEstadoCodeByMunicipioCode(code);
  return UFs.getByCode(estadoCode);
};

const municipiosByCode: Record<string, [number, string][]> = groupBy(
  Municipios.entries() as [number, string][],
  ([code, _value]) => getEstadoCodeByMunicipioCode(code),
);

for (const [code, uf] of UFs.entries()) {
  const matchingMunicipios = municipiosByCode[code] as [
    string | number,
    string,
  ][];
  MunicipiosByEstado[uf] = Mapper.from(matchingMunicipios);
}

export { MunicipiosByEstado, getEstadoByMunicipioCode };
