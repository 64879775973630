import { Mapper } from './mapper';

export const UnidadeSalarioFixo = Mapper.from([
  [1, 'Por hora'],
  [2, 'Por dia'],
  [3, 'Por semana'],
  [4, 'Por quinzena'],
  [5, 'Por mês'],
  [6, 'Por tarefa'],
  [7, 'Não aplicável - Salário exclusivamente variável'],
]);
