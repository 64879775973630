import React, { useEffect, useState } from 'react';

import Cancel from '@mui/icons-material/Cancel';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import {
  PayrollTypes,
  useSendAllPayrollPayslip,
  useSendPayrollPayslip,
} from '@octopus/api';
import { payrollTypes } from '@octopus/payroll-types';
import { OctopusLoading } from '@octopus/ui/design-system';

import SendIcon from '../../../assets/send.svg';
import { useSnackbar } from '../../hooks/useSnackbar';

type SendPayslipsRenderProps = {
  Title?: React.ElementType;
  payrollType?: PayrollTypes;
};

function useSendPayslips({
  organizationId,
  companyId,
}: {
  organizationId: string;
  companyId: string;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [periodInfo, setPeriodInfo] = useState<
    | {
        periodId: string;
        payrollType: PayrollTypes;
      }
    | undefined
  >(undefined);
  const { mutate, isLoading, isSuccess, isError, error, reset } =
    useSendAllPayrollPayslip();

  const sendPayslips = ({
    periodId,
    payrollType,
  }: {
    periodId: string;
    payrollType: PayrollTypes;
  }) => {
    setIsModalOpen(true);
    setPeriodInfo({ periodId, payrollType });
  };

  const confirmSendPayslips = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    mutate({
      pathParams: {
        organizationId,
        companyId,
        periodId: periodInfo?.periodId || '',
        payrollType: periodInfo?.payrollType || 'monthly',
      },
    });
  };

  const closeModal = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setIsModalOpen(false);
    setTimeout(() => {
      // this changes the state of the modal to the initial state
      // so that the next time it opens it will be in the initial state
      // and not in the last state it was in. the setTimeout is needed
      // because the modal has an animation and we need to wait for it to finish
      // before changing the state.
      reset();
    }, 500);
  };

  return {
    sendPayslipsProps: {
      isModalOpen,
      closeModal,
      confirmSendPayslips,

      isSuccess,
      isLoading,
      isError,
      error,
    },

    sendPayslips,
    SendPayslipsComponent: SendPayslips,
  };
}

export function useSendPayslip({
  organizationId,
  companyId,
}: {
  organizationId: string;
  companyId: string;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [payrollId, setPayrollId] = useState<string>(undefined);
  const { mutate, isLoading, isSuccess, isError, error, reset } =
    useSendPayrollPayslip();

  const sendPayslips = ({ payrollId }: { payrollId: string }) => {
    setPayrollId(payrollId);
    setIsModalOpen(true);
  };

  const confirmSendPayslips = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    mutate({
      pathParams: {
        organizationId,
        companyId,
        payrollId,
      },
    });
  };

  const closeModal = (event: React.MouseEvent<HTMLElement> | undefined) => {
    event?.stopPropagation();

    setIsModalOpen(false);
    setTimeout(() => {
      // this changes the state of the modal to the initial state
      // so that the next time it opens it will be in the initial state
      // and not in the last state it was in. the setTimeout is needed
      // because the modal has an animation and we need to wait for it to finish
      // before changing the state.
      reset();
    }, 500);
  };

  return {
    sendPayslipsProps: {
      isModalOpen,
      closeModal,
      confirmSendPayslips,

      isSuccess,
      isLoading,
      isError,
      error,
    },

    sendPayslips,
    SendPayslipsComponent: SendPayslips,
  };
}

function SendPayslips({
  isModalOpen,
  closeModal,
  confirmSendPayslips,
  isLoading,
  isSuccess,
  isError,
  error,
  Title = () => <> Envio de holerites </>,
  payrollType = payrollTypes.monthly,
}: ReturnType<typeof useSendPayslips>['sendPayslipsProps'] &
  SendPayslipsRenderProps) {
  const showSnackbar = useSnackbar().showSnackbar;
  useEffect(() => {
    if (isSuccess) {
      if (payrollType === payrollTypes.rpa) {
        showSnackbar({
          isOpen: true,
          Message: 'Os RPAs serão disponibilizados em instantes',
          StartAdornment: <Box component="img" src={SendIcon} />,
          autoHideDuration: 5000,
          hasCloseAction: true,
        });
        closeModal(undefined);
      } else {
        window.location.reload();
      }
    }
  }, [isSuccess]);
  let NewTitle = Title;
  let bullets = [
    [
      'Disponibilização dos Holerites',
      'Colaboradores terão acesso aos documentos no sistema, disponíveis para download.',
    ],
    [
      'Notificação por Email',
      'Colaboradores serão notificados sobre a disponibilidade dos documentos.',
    ],
  ];
  let loadingMessage = 'Enviando holerites, aguarde alguns segundos...';
  if (payrollType === payrollTypes.rpa) {
    NewTitle = () => <> Enviar RPAs </>;
    bullets = [
      [
        'Disponibilização dos RPAs',
        'Os autônomos terão acesso aos documentos no sistema, disponíveis também para downlaod.',
      ],
    ];
    loadingMessage = 'Enviando RPAs, aguarde alguns segundos...';
  }
  const initialScene = (
    <>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'flex-start',
            mb: 0,
          }}
        >
          <Typography variant="h1">
            <NewTitle />
          </Typography>
          <Typography variant="body1" fontWeight="bold">
            Ao confirmar, o que acontece em seguida:
          </Typography>
          <Box
            component="ul"
            sx={{
              pl: 3,
              m: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignContent: 'flex-start',
              gap: 2,
            }}
            data-testid="send-payslips-modal"
          >
            {bullets.map(([main, secondary]) => (
              <li key={main}>
                <Typography variant="body1">{main}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {secondary}
                </Typography>
              </li>
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          color="secondary"
          onClick={closeModal}
          data-testid="cancel-send-payslips-button"
        >
          Cancelar
        </Button>
        <Button
          color="primaryAlt"
          size="large"
          onClick={confirmSendPayslips}
          data-testid="confirm-send-payslips-button"
        >
          Confirmar
        </Button>
      </DialogActions>
    </>
  );

  const loadingScene = (
    <DialogContent>
      <Box
        py={10}
        px={16}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box width="100px">
          <OctopusLoading />
        </Box>
        <Box pt={2}>
          <Typography variant="h4" textAlign="center">
            {loadingMessage}
          </Typography>
        </Box>
      </Box>
    </DialogContent>
  );

  const errorScene = (
    <>
      <DialogContent>
        <Box display="flex" alignItems="center" gap={1.8} pb={3}>
          <Cancel fontSize="huge" color="error" />
          <Typography variant="h1">Erro no envio</Typography>
        </Box>
        <Typography variant="body1">
          Não foi possível enviar todos os holerites, certifique-se de que não
          existem pendências e tente novamente.
        </Typography>
        {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
      </DialogContent>

      <DialogActions>
        <Button size="large" sx={{ minWidth: '140px' }} onClick={closeModal}>
          Ok
        </Button>
      </DialogActions>
    </>
  );

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      onClick={(event) => event.stopPropagation()}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
    >
      {(() => {
        if (isLoading) {
          return loadingScene;
        }

        if (isError) {
          return errorScene;
        }

        if (isSuccess) {
          return null;
        }

        return initialScene;
      })()}
    </Dialog>
  );
}

export { useSendPayslips };
