import { AuditableVersionedEntry } from '@octopus/api';

import { VacationsType } from './common';

export type GroupVacationsKeyParameters = {
  organizationId: string;
  id: string;
};

export type GroupVacations = AuditableVersionedEntry & {
  partitionKey: string;
  sortKey: string;

  organizationId: string;

  id: string;

  // YYYY, generated from the startDate
  period: string;

  startDate: string;
  endDate: string;
  paymentDate: string;
  contracts: ContractGroupVacations[];

  status: GroupVacationStatus;

  type: VacationsType;
};

export type CreateGroupVacationsInput = {
  organizationId: string;
  startDate: string;
  endDate: string;
  paymentDate: string;
  contracts: ContractGroupVacations[];
  type: VacationsType;
};

export type CreateGroupScheduleInput = {
  organizationId: string;
  startDate: string;
  endDate: string;

  paymentDate: string;

  contractId: string;
  doesNotHaveAvailableDaysPolicy?: DoesNotHaveAvailableDaysPolicy;
  daysSold: number;

  groupVacationsId: string;
  type: VacationsType;
};

export type GroupVacationsKeys = {
  partitionKey: {
    organizationId: string;
  };

  sortKey: {
    id: string;
  };
};

export type GroupVacationsCreatePayload = Omit<
  GroupVacations,
  keyof GroupVacationsKeys | keyof AuditableVersionedEntry
>;

export type GroupVacationsUpdatePayload = Partial<GroupVacationsCreatePayload>;

export type ContractGroupVacations = {
  contractId: string;
  doesNotHaveAvailableDaysPolicy?: DoesNotHaveAvailableDaysPolicy;
  daysSold: number;
};

export const doesNotHaveAvailableDaysPolicies = {
  paidLeave: 'paidLeave',
  goBackToWork: 'goBackToWork',
} as const;

export const groupVacationsStatuses = {
  creating: 'creating',
  created: 'created',
  error: 'error',
} as const;

export type DoesNotHaveAvailableDaysPolicy =
  keyof typeof doesNotHaveAvailableDaysPolicies;

export type GroupVacationStatus = keyof typeof groupVacationsStatuses;
