import dayjs from 'dayjs';

import { Mapper as ContractMapper } from '@octopus/contract-types';
import { Mapper } from '@octopus/esocial/mapper';

import { TFieldSelectRenderProps } from '../../../../modules/form/parseField/types';
import { formatOptionalDayjsDate } from '../../../../modules/formatters';
import { BaseContext } from '../../../../modules/types';

import { SelectOption } from './types';

const getOptionsFromList = ({
  list,
  currentValue,
  enabledKeys = null,
}: {
  list: Record<string, string>;
  currentValue: string;
  enabledKeys?: Set<string | number>;
}): SelectOption[] => {
  return Object.entries(list)
    .map(([key, value]) => ({
      value: key || null,
      selected: `${currentValue}` === `${key}`,
      label: value,
    }))
    .filter(
      (item) =>
        item.value != null && (!enabledKeys || enabledKeys.has(item.value)),
    )
    .sort((a, b) => a.label.localeCompare(b.label));
};

const getOptionsFromMapper = ({
  mapper,
  currentValue,
  enabledKeys = null,
  showKey = false,
  selectIfOnlyOne = false,
}: {
  mapper: Mapper | ContractMapper;
  currentValue: string;
  enabledKeys?: Set<string | number>;
  showKey?: boolean;
  selectIfOnlyOne?: boolean;
}): SelectOption[] => {
  const entries = mapper.entries();
  const hasOnlyOneOption =
    (entries?.length === 1 || enabledKeys?.size === 1) && selectIfOnlyOne;

  return entries
    .map(([key, label]) => ({
      value:
        mapper.findByName(label) !== undefined
          ? `${mapper.findByName(label)}`
          : null,
      selected:
        hasOnlyOneOption || `${currentValue}` === `${mapper.findByName(label)}`,
      label: showKey ? `${label} - ${key}` : label,
    }))
    .filter(
      (item) =>
        item.value != null && (!enabledKeys || enabledKeys.has(item.value)),
    )
    .sort((a, b) => a.label.localeCompare(b.label));
};

const getAutocompleteOptionsFromMapper = ({
  mapper,
  filter,
  fieldName,
  textContentFormatter,
}: {
  mapper: Mapper;
  filter: string;
  fieldName: string;
  textContentFormatter?: ({
    key,
    value,
  }: {
    key: string | number;
    value: string;
  }) => string;
}): TFieldSelectRenderProps['select']['options'] => {
  const reg = new RegExp(
    filter
      .replace(/(\(\D\))*/gm, '')
      .split(' ')
      .join('.*?[ ]'),
    'i',
  );

  const options = mapper.entries();
  const filtered = options.filter(
    ([key, val]) =>
      val.toLocaleLowerCase().match(reg) ||
      `${key}`.toLocaleLowerCase().match(reg),
  );

  const result = filtered.length > 0 ? filtered : options.slice(0, 20);
  const formatted = result
    .sort((a, b) => a[1].localeCompare(b[1]))
    .map(([key, value]) => {
      return {
        textContent: textContentFormatter
          ? textContentFormatter({ key, value })
          : value,
        content: {
          [fieldName]: `${key}`,
        },
        props: {
          key: `${key}`,
          value: `${key}`,
        },
      };
    });

  return formatted;
};

const getAutocompleteOptionsFromList = ({
  list,
  filter,
  fieldName,
}: {
  list: { key: string; value: string }[];
  filter: string;
  fieldName: string;
}): TFieldSelectRenderProps['select']['options'] => {
  const reg = new RegExp(
    filter
      .replace(/(\(\D\))*/gm, '')
      .split(' ')
      .join('.*?[ ]'),
    'i',
  );

  const filtered = list.filter(
    ({ key, value }) =>
      value.toLocaleLowerCase().match(reg) ||
      key.toLocaleLowerCase().match(reg),
  );

  const result = filtered.length > 0 ? filtered : list.slice(0, 20);
  return result
    .sort((a, b) => a.value.localeCompare(b.value))
    .map(({ key, value }) => ({
      textContent: value,
      content: {
        [fieldName]: `${key}`,
      },
      props: {
        key: key,
        value: key,
      },
    }));
};

const getOptionsFromCompanyContext = ({
  baseContext,
  currentValue,
  selectIfOnlyOne = false,
}: {
  baseContext: BaseContext<unknown>[];
  currentValue: string;
  selectIfOnlyOne?: boolean;
}): SelectOption[] => {
  if (!baseContext) {
    return [
      {
        value: '',
        label: 'Carregando informações...',
        disabled: true,
        selected: false,
      },
    ];
  }

  const hasOnlyOneOption = baseContext.length === 1 && selectIfOnlyOne;

  return baseContext
    .map(({ id, name }) => ({
      value: id,
      selected: hasOnlyOneOption || `${currentValue}` === `${id}`,
      label: name,
    }))
    .filter((item) => item.value != null)
    .sort((a, b) => a.label.localeCompare(b.label));
};

const getEffectiveDate = () => {
  const currentDate = new Date();
  return formatOptionalDayjsDate({
    date: dayjs(currentDate),
    format: 'YYYY-MM-DD',
  });
};

export {
  getEffectiveDate,
  getOptionsFromList,
  getOptionsFromMapper,
  getOptionsFromCompanyContext,
  getAutocompleteOptionsFromMapper,
  getAutocompleteOptionsFromList,
};
