import { WorkerCategory } from '@octopus/contract-types';
import { Parentesco, Sexo } from '@octopus/esocial/mapper';
import { z } from '@octopus/i18n';
import {
  UI_TYPE,
  cpfSchema,
  nonEmptyString,
  validDependentBirthDateSchema,
} from '@octopus/libs/forms';

import { getOptionsFromMapper } from '../../form/inputUtils';

import { parentescoSchema } from './fieldDefinitions/parentescoSchema';
import { Dependent, extractParentescoType } from './types';

export const getDefinition = (
  dependente: Dependent,
  workerCategory: WorkerCategory,
) =>
  [
    {
      label: 'Nome',
      type: nonEmptyString,
      name: 'nome',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Maria de Lurdes',
      value: dependente?.nome || '',
    },
    {
      label: 'Parentesco',
      type: parentescoSchema,
      name: 'parentesco',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione',
      fieldsOptions: getOptionsFromMapper({
        mapper: Parentesco,
        currentValue: extractParentescoType(dependente),
      }).map((option) => {
        return {
          ...option,
          fields: [
            ...(option.value === '99'
              ? [
                  {
                    label: 'Descrição do parentesco',
                    type: nonEmptyString,
                    name: 'descrDep',
                    uiType: UI_TYPE.TEXT,
                    placeholder: 'Descreva o parentesco',
                    value: dependente?.parentesco?.descrDep || '',
                  },
                ]
              : []),
            {
              label: '-',
              type: z.number(),
              name: `type_${option.value}`,
              value: parseInt(option.value),
              uiType: UI_TYPE.TEXT,
              hidden: true,
            },
          ],
        };
      }),
    },
    {
      label: 'Data de nascimento',
      type: validDependentBirthDateSchema,
      name: 'dtNascto',
      uiType: UI_TYPE.DATE_PICKER,
      placeholder: '00/00/0000',
      value: dependente?.dtNascto,
    },
    {
      label: 'CPF',
      type: cpfSchema,
      name: 'cpf',
      uiType: UI_TYPE.TEXT,
      placeholder: '000.000.000-00',
      value: dependente?.cpf || '',
      mask: 'cpf',
    },
    {
      label: 'Sexo',
      type: nonEmptyString,
      name: 'sexo',
      uiType: UI_TYPE.SELECT,
      options: getOptionsFromMapper({
        mapper: Sexo,
        currentValue: dependente?.sexo,
      }),
    },
    workerCategory !== 'pj' && {
      label: 'Possui incapacidade física ou mental para trabalho?',
      type: nonEmptyString,
      uiType: UI_TYPE.SELECT,
      name: 'incTrab',
      options: [
        {
          label: 'Sim',
          value: 'true',
          selected: dependente?.incTrab === 'true',
        },
        {
          label: 'Não',
          value: 'false',
          selected: dependente?.incTrab === 'false',
        },
      ],
    },
    workerCategory !== 'pj' && {
      label: 'Considerado(a) nos cálculos de:',
      type: z.array(z.string()),
      name: 'calculos',
      uiType: UI_TYPE.SELECT_CHECKBOX_LIST,
      options: [
        {
          label: 'IRRF - Imposto de renda',
          value: 'calcIRRF',
          selected: dependente?.calculos?.indexOf('calcIRRF') >= 0,
        },
        workerCategory !== 'clt:estagiario' && {
          label: 'SF - Salário Família',
          value: 'calcSF',
          selected: dependente?.calculos?.indexOf('calcSF') >= 0,
        },
      ],
    },
  ].filter((item) => item);
