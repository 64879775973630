import { useParams } from 'react-router-dom';

import { Box, Container, Divider, Skeleton, Typography } from '@mui/material';

import { useGetPayrollPayslip } from '@octopus/api';

import { BackButton } from '../../../../../../modules/components/BackButton';
import Payslip from '../../../../../../modules/components/payrolls/Payslip';
import { PayslipActionsMenu } from '../../../../../../modules/components/payslips/ActionsMenu';
import { DataFetching } from '../../../../../../modules/dataFetching';

export function PayslipPage({
  organizationId,
  companyId,
}: {
  organizationId: string | undefined;
  companyId: string | undefined;
}) {
  const { payrollId } = useParams<{
    payrollId: string;
  }>();

  const useFetch = () => {
    return useGetPayrollPayslip(
      {
        pathParams: {
          organizationId: organizationId!,
          companyId: companyId!,
          payrollId: payrollId!,
        },
      },
      {
        enabled: !!organizationId && !!companyId && !!payrollId,
      },
    );
  };

  return (
    <>
      <BackButton />
      <Container maxWidth="md">
        <Container sx={{ padding: '72px 80px' }}>
          <DataFetching
            useHook={useFetch}
            Loading={() => (
              <Box pt={12}>
                <Typography variant="h3" color="text.primary">
                  <Skeleton width={200} />
                </Typography>
                <Typography variant="h1" color="text.primary">
                  <Skeleton width="100%" />
                </Typography>
                <Skeleton width={200} />
                <Skeleton width={100} />
                <Divider sx={{ my: 2 }} />
                <Skeleton variant="rounded" height={300} />
                <Divider sx={{ my: 2 }} />
                <Skeleton variant="rounded" height={300} />
              </Box>
            )}
            Data={({ data }) =>
              data && (
                <Payslip
                  payslip={data}
                  actionMenu={() => PayslipActionsMenu({ payslip: data })}
                />
              )
            }
          />
        </Container>
      </Container>
    </>
  );
}
