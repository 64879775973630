import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import { Cancel } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { fetchGetPeriod } from '@octopus/api';
import { OctopusLoading } from '@octopus/ui/design-system';

export function SelectPeriodModal({
  organizationId,
  companyId,
  close,
}: {
  organizationId: string;
  companyId: string;
  close: () => void;
}) {
  const [dateSelected, setDateSelected] = useState<dayjs.Dayjs | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const navigate = useNavigate();
  const confirm = () => {
    if (!dateSelected) {
      return;
    }
    setIsLoading(true);
    fetchGetPeriod({
      pathParams: {
        organizationId,
        companyId,
        period: dateSelected?.format('YYYY-MM'),
      },
    })
      .then(({ status }) => {
        if (status !== 'open') {
          setIsError(true);
        }
        setIsLoading(false);
        navigate(`/autonomos/${dateSelected?.format('YYYY-MM')}/rpa`);
        close();
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <LoadingScene />;
  }
  if (isError) {
    return <ErrorScene close={close} />;
  }
  return (
    <InitialScene
      date={dateSelected}
      setDate={setDateSelected}
      confirm={confirm}
      close={close}
    />
  );
}

function InitialScene({
  close,
  date,
  setDate,
  confirm,
}: {
  close: () => void;
  date: dayjs.Dayjs | null;
  setDate: (date: dayjs.Dayjs | null) => void;
  confirm: () => void;
}) {
  return (
    <Box width={'600px'}>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'flex-start',
            mb: 0,
            width: '100%',
          }}
        >
          <Typography variant="h5" fontSize="24px" fontWeight={700}>
            Selecionar Competência
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{ width: '100%' }}
          >
            <Typography variant="body1">
              Os novos pagamentos serão referentes a qual competência?
            </Typography>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <DatePicker
                value={date}
                onChange={setDate}
                format="MM/YYYY"
                views={['year', 'month']}
                openTo="month"
                disableHighlightToday={true}
                slotProps={{
                  textField: {
                    placeholder: 'Selecione o mês',
                  },
                }}
                sx={{
                  width: '100%',
                }}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'background.paper',
            color: 'text.primary',
            width: '140px',
            height: '40px',
            px: 4,
            ':hover': {
              backgroundColor: 'strokes.light',
            },
          }}
          size="large"
          onClick={() => {
            close();
          }}
          data-testid="cancel-approve-payroll-button"
        >
          Cancelar
        </Button>
        <Button
          color="primaryAlt"
          variant="contained"
          size="large"
          sx={{
            width: '140px',
            height: '40px',
          }}
          onClick={confirm}
          data-testid="confirm-approve-payroll-button"
        >
          Confirmar
        </Button>
      </DialogActions>
    </Box>
  );
}

function ErrorScene({ close }: { close: () => void }) {
  return (
    <>
      <DialogContent>
        <Box display="flex" alignItems="center" gap={1.8} pb={3}>
          <Cancel fontSize="huge" color="error" />
          <Typography variant="h1">Erro de validação</Typography>
        </Box>
        <Typography variant="body1">
          Não é possivel lançar RPAs nesse periodo, certifique-se de que o
          periodo da competência não está fechado.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button size="large" sx={{ minWidth: '140px' }} onClick={close}>
          Ok
        </Button>
      </DialogActions>
    </>
  );
}

function LoadingScene() {
  return (
    <DialogContent>
      <Box
        py={10}
        px={16}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box width="100px">
          <OctopusLoading />
        </Box>
        <Box pt={2}>
          <Typography variant="h4" textAlign="center">
            Validando a competência, aguarde alguns segundos...
          </Typography>
        </Box>
      </Box>
    </DialogContent>
  );
}
