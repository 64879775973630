import { Mapper } from './mapper';

export const GrauInstrucao = Mapper.from([
  [
    1,
    'Analfabeto, inclusive o que, embora tenha recebido instrução, não se alfabetizou',
  ],
  [
    2,
    'Até o 5º ano incompleto do ensino fundamental (antiga 4ª série) ou que se tenha alfabetizado sem ter frequentado escola regular',
  ],
  [3, '5º ano completo do ensino fundamental'],
  [
    4,
    'Do 6º ao 9º ano do ensino fundamental incompleto (antiga 5ª a 8ª série)',
  ],
  [5, 'Ensino fundamental completo'],
  [6, 'Ensino médio incompleto'],
  [7, 'Ensino médio completo'],
  [8, 'Educação superior incompleta'],
  [9, 'Educação superior completa'],
  [10, 'Pós-graduação completa'],
  [11, 'Mestrado completo'],
  [12, 'Doutorado completo'],
]);
