import {
  FieldMetadata,
  getCollectionProps,
  getFieldsetProps,
} from '@conform-to/react';

import {
  IFieldCollectionValueWithOptionsDefinition,
  UI_TYPE,
  parseFieldUiInfo,
} from '@octopus/libs/forms';

import { parseBranchingErrors } from './parseFormErrors';
import {
  TFieldRenderProps,
  TFieldSelectRenderProps,
  TFieldsetRenderProps,
} from './types';

export function parseFieldCheckboxCloud(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldMetadata: FieldMetadata<any>,
  fieldDefinition: IFieldCollectionValueWithOptionsDefinition,
  fieldsetName = '',
  payloadFormErrors: Record<string, string[]> = {},
): TFieldRenderProps & TFieldsetRenderProps {
  const checkboxFieldsetProps = getFieldsetProps(fieldMetadata);
  const errors = parseBranchingErrors({
    fieldMetadata,
    fieldDefinition,
    isInvalid: checkboxFieldsetProps['aria-invalid'],
    fieldsetName,
    payloadFormErrors,
  });

  const labelByOptionValue = fieldDefinition.options.reduce(
    (labelByOptionValue, option) => {
      labelByOptionValue[`${option.value.toString()}`] = option.label;
      return labelByOptionValue;
    },
    {} as Record<string, string>,
  );

  const { dirty, value } = fieldMetadata;
  const selectedValues = value != null && Array.isArray(value) ? value : [];

  const childFieldsRenderProps = getCollectionProps(fieldMetadata, {
    type: 'checkbox',
    options: fieldDefinition.options.map(
      (option) => `${option.value.toString()}`,
    ),
  }).map((checkBoxProps, idx) => {
    const optionDefinition = fieldDefinition.options[idx];
    const currentValue =
      dirty && selectedValues?.indexOf(checkBoxProps.value) >= 0 ? true : false;

    const fieldRenderProps: TFieldRenderProps = {
      uiType: UI_TYPE.CHECKBOX_CARD,
      fieldsetName: optionDefinition.groupLabel || '',
      props: {
        key: checkBoxProps.id,
      },
      label: {
        textContent: labelByOptionValue[checkBoxProps.value],
        props: {
          htmlFor: checkBoxProps.id,
        },
      },
      input: {
        props: {
          ...checkBoxProps,
          required: checkBoxProps.required || optionDefinition.required,
          defaultChecked: dirty ? currentValue : optionDefinition.selected,
        },
      },
    };

    return fieldRenderProps;
  });

  const fieldRenderProps: TFieldRenderProps & TFieldsetRenderProps = {
    uiType: parseFieldUiInfo(fieldDefinition) || UI_TYPE.FIELDSET,
    fieldsetName: '',
    errors,
    props: {
      key: checkboxFieldsetProps.id,
      id: checkboxFieldsetProps.id,
      name: checkboxFieldsetProps.name,
      form: checkboxFieldsetProps.form,
    },
    label: {
      textContent: fieldDefinition.label,
      props: {
        htmlFor: checkboxFieldsetProps.id,
      },
    },
    fields: childFieldsRenderProps,
    extraProps:
      fieldDefinition?.extraProps as TFieldSelectRenderProps['extraProps'],
  };

  return fieldRenderProps;
}
