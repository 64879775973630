import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { Box, Skeleton } from '@mui/material';

import {
  GroupVacationsEntry,
  GroupVacationsList,
  fetchGetGroupVacations,
} from '@octopus/api';
import { DataGrid, GridColDef, useDataGrid } from '@octopus/ui/data-grid';

import { DataFetching } from '../../../modules/dataFetching';

export default function GroupVacationsTable({
  organizationId,
}: {
  organizationId: string | undefined;
}) {
  const navigateTo = useNavigate();
  const queryParams = {
    refetchOnWindowFocus: false,
    enabled: !!organizationId,
  };

  const dataGridProps = useDataGrid({});
  const { sortingProps, paginationProps } = dataGridProps;

  const listGroupVacations = () =>
    fetchGetGroupVacations({ pathParams: { organizationId } });

  const listGroupVacationsQuery = useQuery({
    queryKey: ['fetchGetGroupVacations', organizationId],
    queryFn: listGroupVacations,
    ...queryParams,
  });

  return (
    <DataFetching
      fetchResult={listGroupVacationsQuery}
      Loading={() => {
        return (
          <Box display="flex" flexDirection="column" gap="8px" pt={1}>
            <Skeleton variant="rounded" height={300} width="100%" />
          </Box>
        );
      }}
      Data={({ data }: { data: GroupVacationsList }) => {
        return (
          <Box mt={2}>
            {data ? (
              <DataGrid
                paginationProps={paginationProps}
                sortingProps={sortingProps}
                totalRowCount={data.length || 0}
                getRowId={(row) => row.id}
                onRowClick={({ row }) => {
                  if (row.status === 'created') {
                    navigateTo(`/vacations-group/${row.id}`);
                  }
                }}
                rows={data}
                columns={columns}
                emptyMessage={'Nenhuma férias coletiva encontrada.'}
              />
            ) : null}
          </Box>
        );
      }}
    />
  );
}

const PeriodColumn: GridColDef = {
  field: 'period',
  headerName: 'Período',
  valueGetter: ({ row }) => {
    const { startDate, endDate } = row;
    const start = dayjs(startDate).format('DD/MM/YYYY');
    const end = dayjs(endDate).format('DD/MM/YYYY');
    return `${start} - ${end}`;
  },
  renderCell: ({ value }) => {
    return value;
  },
};

const StatusColumn: GridColDef = {
  field: 'status',
  headerName: 'Status',
  valueGetter: ({ row }) => {
    return row.status;
  },
  renderCell: ({ value }) => {
    const status = value as GroupVacationsEntry['status'];
    const statusMap = {
      creating: 'Criando',
      created: 'Criada',
      error: 'Erro',
    };

    return statusMap[status];
  },
};

const columns = [PeriodColumn, StatusColumn];
