const lancamentosDeEncargos = {
  fgts: {
    id: 'encargo.fgts',
    descricao: 'Encargos - FGTS',
  },
  grrf: {
    id: 'encargo.grrf',
    descricao: 'Encargos - FGTS GRRF',
  },
  inssPatronal: {
    id: 'encargo.inssPatronal',
    descricao: 'Encargos - INSS Patronal',
  },
  inssOutrasEntidades: {
    id: 'encargo.inssOutrasEntidades',
    descricao: 'Encargos - INSS Outras Entidades',
  },
  inssRat: {
    id: 'encargo.inssRat',
    descricao: 'Encargos - INSS RAT',
  },
};

const lancamentosDerivados = {
  liquidoDeRescisao: {
    id: 'derivados.liquidoDeRescisao',
    descricao: 'Líquido de Rescisão',
  },
};

const lancamentosParaRelatorios = {
  mediaDecimoTerceiroDominioValor: {
    id: 'relatorios.medias.decimoTerceiro.dominio.valor',
    descricao: 'Média de Valor (Dominio) para 13º',
  },
  mediaDecimoTerceiroDominioHoras: {
    id: 'relatorios.medias.decimoTerceiro.dominio.horas',
    descricao: 'Média de Horas (Dominio) para 13º',
  },
};

export {
  lancamentosDeEncargos,
  lancamentosDerivados,
  lancamentosParaRelatorios,
};
