import React, { useEffect, useState } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import { getFieldOnlyProps } from '../../parseField/getFieldOnlyProps';
import { TFieldRenderProps } from '../../parseField/types';

import { ErrorLabel } from './ErrorLabel';
import { FieldHint } from './FieldHint';
import { Label } from './Label';

type Props = {
  field: TFieldRenderProps;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  focused?: boolean;
};

export function FieldWrapper(props: Props) {
  const [showHint, setShowHint] = useState(false);
  const { field, children, focused } = props;

  const hasError = field.errors?.length > 0;
  const errorMessage = hasError ? field.errors[0] : '';

  const display = field.hidden ? 'none' : 'flex';
  const width = field.hidden ? '0' : 'auto';
  const fieldProps = getFieldOnlyProps(field);
  const { hint } = fieldProps?.props || {};
  const hasHint = hint != null && (hint.title != null || hint.body != null);

  useEffect(() => {
    setShowHint(focused);
  }, [focused]);

  return (
    <FieldHint
      title={hint?.title}
      body={hint?.body}
      showHint={hasHint && showHint}
    >
      <Box
        {...field.props}
        display={display}
        flexDirection="column"
        width={width}
        sx={{ ...props.sx }}
        data-testid={`field-${fieldProps?.props?.name}`}
      >
        <Label field={field} />
        {children}
        <ErrorLabel
          errorMessage={errorMessage}
          dataTestId={`error-${fieldProps?.props?.name}`}
        />
      </Box>
    </FieldHint>
  );
}
