import { Mapper } from './mapper';

export const Parentesco = Mapper.from([
  [1, 'Cônjuge'],
  [
    2,
    'Companheiro(a) com o(a) qual tenha filho ou viva há mais de 5 (cinco) anos ou possua declaração de união estável',
  ],
  [3, 'Filho(a) ou enteado(a)'],
  [
    4,
    'Filho(a) ou enteado(a), universitário(a) ou cursando escola técnica de 2º grau',
  ],
  [
    6,
    'Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, do(a) qual detenha a guarda judicial',
  ],
  [
    7,
    'Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, universitário(a) ou cursando escola técnica de 2° grau, do(a) qual detenha a guarda judicial',
  ],
  [9, 'Pais, avós e bisavós'],
  [10, 'Menor pobre do qual detenha a guarda judicial'],
  [11, 'A pessoa absolutamente incapaz, da qual seja tutor ou curador'],
  [12, 'Ex-cônjuge'],
  [99, 'Agregado/Outros'],
]);
