import { ReactElement } from 'react';

import { Box, Button, Divider, Typography } from '@mui/material';

import { RecordEntryProps } from './RecordEntry';
import { RecordEntryGroup, RecordEntryGroupProps } from './RecordEntryGroup';

export type RecordProps = {
  title: string;
  children:
    | ReactElement<RecordEntryProps | RecordEntryGroupProps>
    | ReactElement<RecordEntryProps>[]
    | ReactElement<RecordEntryGroupProps>[]
    | ReactElement[]
    | undefined;
  hide?: boolean;
  edit?: RecordEditProps;
  extraActions?: ReactElement;
};

export type RecordEditProps = {
  isEditable: boolean | undefined;
  onEdit: () => void;
  onCancel: (callback?: () => void) => void;
  onSave: (callback?: () => void) => void;
  isEditing?: boolean;
};

export function Record(props: RecordProps) {
  const { title, children, edit, hide = false, extraActions } = props;
  const { isEditing = false } = edit || {};

  if (!children || hide) {
    return null;
  }

  const isRecordEntryGroupArray = (
    children:
      | ReactElement<RecordEntryProps | RecordEntryGroupProps>
      | ReactElement<RecordEntryProps>[]
      | ReactElement<RecordEntryGroupProps>[],
  ) => {
    if (Array.isArray(children)) {
      return children
        .map((child) => {
          if (typeof child.type !== 'string') {
            return child.type === RecordEntryGroup;
          }
          return false;
        })
        .every((value) => value);
    }
    return false;
  };
  const renderEntries = (
    children:
      | ReactElement<RecordEntryProps | RecordEntryGroupProps>
      | ReactElement<RecordEntryProps>[],
  ) => (
    <Box display="flex" flexDirection="column" gap={isEditing ? 0.5 : 1.5}>
      {children}
    </Box>
  );
  const renderEntryGroups = (
    children: ReactElement<RecordEntryGroupProps>[],
  ) => (
    <Box display="flex" flexDirection="column">
      {children.reduce<Array<ReactElement>>((acc, child, index) => {
        if (index < children.length - 1) {
          return [...acc, child, <Divider key={index} sx={{ my: 2 }} />];
        }
        return [...acc, child];
      }, [] as Array<ReactElement>)}
    </Box>
  );
  return (
    <Box
      display="flex"
      flexDirection="column"
      boxSizing="border-box"
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.07)',
        borderRadius: '12px',
        width: '100%',
        px: 4,
        pt: 3,
        pb: 4,
        backgroundColor: 'background.paper',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" color="text.primary">
          {title}
        </Typography>
        <Box display="flex" gap={4}>
          {edit?.isEditable && !isEditing && (
            <Typography
              variant="caption"
              fontWeight="700"
              color="primary.main"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                edit.onEdit();
              }}
            >
              Editar
            </Typography>
          )}
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
      {isRecordEntryGroupArray(children)
        ? renderEntryGroups(children as ReactElement<RecordEntryGroupProps>[])
        : renderEntries(
            children as
              | ReactElement<RecordEntryProps | RecordEntryGroupProps>
              | ReactElement<RecordEntryProps>[],
          )}
      {isEditing && (
        <Box display="flex" justifyContent="space-between" flexGrow={1} pt={2}>
          <Box display="flex" alignItems="center">
            {extraActions}
          </Box>
          <Box display="flex" gap={1} width="252px">
            <Button
              size="large"
              color="secondary"
              onClick={() => edit.onCancel()}
              fullWidth
            >
              Cancelar
            </Button>
            <Button
              size="large"
              color="primaryAlt"
              onClick={() => edit.onSave()}
              fullWidth
            >
              Salvar
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
