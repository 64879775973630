import * as React from 'react';

import { Box, Drawer } from '@mui/material';
import { CSSObject, Theme } from '@mui/material/styles';

import { SidebarProps } from '../types';

import { SidebarAppSelector } from './appSelector';
import { SidebarFooter } from './footer';
import { SidebarHeader } from './header';

export function Sidebar({
  username,
  pictureUrl,
  apps,
  membership,
  membershipOptions,
  changeMembership,
  logout,
}: SidebarProps) {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openedMixin = (theme: Theme): CSSObject => ({
    width: '240px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    boxShadow: theme.palette.shadows.light,
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    width: '56px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  });

  return (
    <Box>
      <Drawer
        variant="permanent"
        open={open}
        onMouseOver={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
        sx={(theme) => ({
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
          }),
          ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
          }),
        })}
      >
        <Box
          display="flex"
          flexGrow="true"
          flexDirection="column"
          justifyContent="flex-start"
          height="100%"
          data-testid="sidebar"
        >
          <SidebarHeader
            open={open}
            membership={membership}
            membershipOptions={membershipOptions}
            changeMembership={changeMembership}
          />
          <SidebarAppSelector apps={apps} open={open} />
          <SidebarFooter
            username={username}
            pictureUrl={pictureUrl}
            open={open}
            logout={logout}
          />
        </Box>
      </Drawer>
    </Box>
  );
}
