import { BiMap } from '@rimbu/core';
import { capitalize } from 'lodash';

import { MapEntries, Mapper } from './mapper';
import { Paises } from './paises';

//https://pt.wikipedia.org/wiki/Wikip%C3%A9dia:Lista_de_predefini%C3%A7%C3%B5es/Nacionalidade
const entries: MapEntries = [
  ['Afeganistão', 'afegã(o)'],
  ['África do Sul', 'sul-africano(a)'],
  ['Albânia', 'albanês(esa)'],
  ['Alemanha', 'alemã(ão)'],
  ['Andorra', 'andorrano(a)'],
  ['Angola', 'angolano(a)'],
  ['Anguila', 'anguilano(a)'],
  ['Antígua e Barbuda', 'antiguano(a)'],
  ['Antilhas Holandesas', 'antilhano(a)'],
  ['Arábia Saudita', 'árabe-saudita'],
  ['Argélia', 'argelino(a)'],
  ['Argentina', 'argentino(a)'],
  ['Armênia', 'arménio(a)'],
  ['Aruba', 'arubano(a)'],
  ['Austrália', 'australiano(a)'],
  ['Áustria', 'austríaco(a)'],
  ['Azerbaijão', 'azeri'],
  ['Bahamas', 'baamiano(a)'],
  ['Bahrein', 'barenita'],
  ['Bangladesh', 'bangladechiano(a)'],
  ['Barbados', 'barbadense'],
  ['Bélgica', 'belga'],
  ['Belize', 'belizenho(a)'],
  ['Benim', 'benineseo(a)'],
  ['Bermudas', 'bermudense'],
  ['Bielorrússia', 'bielorrusso(a)'],
  ['Bolívia', 'boliviano(a)'],
  ['Bósnia e Herzegovina', 'bósnio(a)'],
  ['Botswana', 'botsuano(a)'],
  ['Brasil', 'brasileiro(a)'],
  ['Brunei', 'bruneíno(a)'],
  ['Bulgária', 'búlgaro(a)'],
  ['Burquina Fasso', 'burquino(a)'],
  ['Burundi', 'burundiano(a)'],
  ['Butão', 'butanês(esa)'],
  ['Cabo Verde', 'cabo-verdiano(a)'],
  ['Camarões', 'camaronês(esa)'],
  ['Camboja', 'cambojano(a)'],
  ['Canadá', 'canadiano(a)'],
  ['Cazaquistão', 'cazaque'],
  ['Chade', 'chadiano(a)'],
  ['Chile', 'chileno(a)'],
  ['China', 'chinês(esa)'],
  ['Chipre', 'cipriota'],
  ['Colômbia', 'colombiano(a)'],
  ['Comores', 'comoriano(a)'],
  ['República do Congo', 'congolês(a)'],
  ['Coreia do Norte', 'norte-coreano(a)'],
  ['Coreia do Sul', 'sul-coreano(a)'],
  ['Costa do Marfim', 'marfinense'],
  ['Costa Rica', 'costarriquenho(a)'],
  ['Croácia', 'croata'],
  ['Cuba', 'cubano(a)'],
  ['Curaçau', 'curaçauense'],
  ['Dinamarca', 'dinamarquês(esa)'],
  ['Djibouti', 'jibutiano(a)'],
  ['Dominica', 'dominiquês(esa)'],
  ['Egito', 'egípcio(a)'],
  ['El Salvador', 'salvadorenho(a)'],
  ['Emirados Árabes Unidos', 'emiradense'],
  ['Equador', 'equatoriano(a)'],
  ['Eritreia', 'eritreu(ia)'],
  ['Escócia', 'escocês(esa)'],
  ['Eslováquia', 'eslovaco(a)'],
  ['Eslovênia', 'esloveno(a)'],
  ['Espanha', 'espanhol(a)'],
  ['Estados Federados da Micronésia', 'micronésio(a)'],
  ['Estados Unidos', 'norte-americano(a)'],
  ['Estónia', 'estoniano(a)'],
  ['Etiópia', 'etíope'],
  ['Fiji', 'fijiano(a)'],
  ['Filipinas', 'filipino'],
  ['Finlândia', 'finlandês(esa)'],
  ['França', 'francês(esa)'],
  ['Gabão', 'gabonês(a)'],
  ['Gâmbia', 'gambiano(a)'],
  ['Gana', 'ganês(a)'],
  ['Geórgia', 'georgiano(a)'],
  ['Granada', 'granadino(a)'],
  ['Grécia', 'grego(a)'],
  ['Guadalupe', 'guadalupense'],
  ['Guam', 'guamês(a)'],
  ['Guatemala', 'guatemalteco(a)'],
  ['Guiana', 'guianês(esa)'],
  ['Guiana Francesa', 'guianense'],
  ['Guiné', 'guineano(a)'],
  ['Guiné Equatorial', 'guinéu-equatoriano(a)'],
  ['Guiné-Bissau', 'guineense'],
  ['Haiti', 'haitiano(a)'],
  ['Honduras', 'hondurenho(a)'],
  ['Hong Kong', 'honconguês(esa)'],
  ['Hungria', 'húngaro(a)'],
  ['Iêmen', 'iemenita'],
  ['Ilhas Caimã', 'caimanês(a)'],
  ['Ilhas Cook', 'cookense'],
  ['Ilhas Feroé', 'faroense'],
  ['Ilhas Salomão', 'salomonense'],
  ['Ilhas Virgens Americanas', 'virginense'],
  ['Ilhas Virgens Britânicas', 'virginense'],
  ['Índia', 'indiano(a)'],
  ['Indonésia', 'indonésia(o)'],
  ['Inglaterra', 'inglês(esa)'],
  ['Irão', 'iraniano(a)'],
  ['Iraque', 'iraquiano(a)'],
  ['Irlanda', 'irlandês(a)'],
  ['Irlanda do Norte', 'norte-irlandês(esa)'],
  ['Islândia', 'islandês(esa)'],
  ['Israel', 'israelense'],
  ['Itália', 'italiano(a)'],
  ['Jamaica', 'jamaicano(a)'],
  ['Japão', 'japonês(esa)'],
  ['Jordânia', 'jordano(a)'],
  ['Kiribati', 'quiribatiano(a)'],
  ['Kosovo', 'kosovar'],
  ['Kuwait', 'kuwaitiano(a)'],
  ['Laos', 'laociano(a)'],
  ['Lesoto', 'lesotiano(a)'],
  ['Letônia', 'letã(o)'],
  ['Líbano', 'libanês(a)'],
  ['Libéria', 'liberiano(a)'],
  ['Líbia', 'líbio(a)'],
  ['Liechtenstein', 'listenstainiano(a)'],
  ['Lituânia', 'lituano(a)'],
  ['Luxemburgo', 'luxemburguês(a)'],
  ['Macau', 'macaense'],
  ['Macedônia do Norte', 'macedônico(a)'],
  ['Madagáscar', 'malgaxe'],
  ['Malásia', 'malaio(a)'],
  ['Malawi', 'malauiano(a)'],
  ['Maldivas', 'maldivo(a)'],
  ['Mali', 'malinês'],
  ['Malta', 'maltês(a)'],
  ['Marrocos', 'marroquino(a)'],
  ['Martinica', 'martinicano(a)'],
  ['Ilhas Maurícias', 'mauriciano(a)'],
  ['Mauritânia', 'mauritano(a)'],
  ['México', 'mexicano(a)'],
  ['Myanmar', 'birmanês(a)'],
  ['Moçambique', 'moçambicano(a)'],
  ['Moldávia', 'moldavo(a)'],
  ['Mónaco', 'monegasco(a)'],
  ['Mongólia', 'mongol'],
  ['Montenegro', 'montenegrino(a)'],
  ['Monserrate', 'monserratense'],
  ['Namíbia', 'namibiano(a)'],
  ['Nauru', 'nauruano(a)'],
  ['Nepal', 'nepalês(a)'],
  ['Nicarágua', 'nicaraguense'],
  ['Níger', 'nigerino(a)'],
  ['Nigéria', 'nigeriano(a)'],
  ['Noruega', 'norueguês(esa)'],
  ['Nova Caledónia', 'neocaledónio(a)'],
  ['Nova Zelândia', 'neozelandês(esa)'],
  ['Omã', 'omanense'],
  ['País de Gales', 'galês(esa)'],
  ['Países Baixos', 'neerlandês(a)'],
  ['Palau', 'palauano(a)'],
  ['Palestina', 'palestiniano(a)'],
  ['Panamá', 'panamenho(a)'],
  ['Papua-Nova Guiné', 'papua'],
  ['Paquistão', 'paquistanês(a)'],
  ['Paraguai', 'paraguaio(a)'],
  ['Peru', 'peruano(a)'],
  ['Polinésia Francesa', 'polinésio(a)'],
  ['Polónia', 'polaco(a)'],
  ['Porto Rico', 'porto-riquenho(a)'],
  ['Portugal', 'português(esa)'],
  ['Catar', 'catariano(a)'],
  ['Quênia', 'queniano(a)'],
  ['Quirguistão', 'quirguiz'],
  ['Reino Unido', 'britânico(a)'],
  ['República Centro-Africana', 'centro-africano(a)'],
  ['Chéquia', 'tcheco(a)'],
  ['Taiwan', 'taiwanês(a)'],
  ['República Democrática do Congo', 'congolês(a)'],
  ['República Dominicana', 'dominicano(a)'],
  ['Roménia', 'romeno(a)'],
  ['Ruanda', 'ruandês(a)'],
  ['Rússia', 'russo(a)'],
  ['São Martinho', 'são-martinhense'],
  ['Samoa', 'samoano(a)'],
  ['Samoa Americana', 'samoense'],
  ['Santa Lúcia', 'santa-luciense'],
  ['São Cristóvão e Neves', 'são-cristovense'],
  ['San Marino', 'são-marinhense'],
  ['São Tomé e Príncipe', 'são-tomense'],
  ['São Vicente e Granadinas', 'são-vicentino(a)'],
  ['Senegal', 'senegalês(esa)'],
  ['Serra Leoa', 'serra-leonês(a)'],
  ['Sérvia', 'sérvio(a)'],
  ['Seicheles', 'seichelense'],
  ['Singapura', 'singapurense'],
  ['Síria', 'sírio(a)'],
  ['Somália', 'somali'],
  ['Sri Lanka', 'cingalês(a)'],
  ['Essuatíni', 'essuatiniano(a)'],
  ['Sudão', 'sudanês(a)'],
  ['Suécia', 'sueco(a)'],
  ['Suíça', 'suíço(a)'],
  ['Suriname', 'surinamês(a)'],
  ['Tajiquistão', 'tajique'],
  ['Tailândia', 'tailandês(a)'],
  ['Taipé Chinesa', 'taiwanês(a)'],
  ['Tanzânia', 'tanzaniano(a)'],
  ['Timor-Leste', 'timorense'],
  ['Togo', 'togolês(a)'],
  ['Tonga', 'tonganês(esa)'],
  ['Trinidad e Tobago', 'trinitário(a)-tobagense'],
  ['Tunísia', 'tunisiano(a)'],
  ['Turcas e Caicos', 'turquense'],
  ['Turquemenistão', 'turquemeno(a)'],
  ['Turquia', 'turco(a)'],
  ['Tuvalu', 'tuvaluano(a)'],
  ['Ucrânia', 'ucraniano(a)'],
  ['Uganda', 'ugandense'],
  ['Uruguai', 'uruguaio(a)'],
  ['Uzbequistão', 'usbeque'],
  ['Vanuatu', 'vanuatuense'],
  ['Vaticano', 'vaticano(a)'],
  ['Venezuela', 'venezuelano(a)'],
  ['Vietname', 'vietnamita'],
  ['Zâmbia', 'zambiano(a)'],
  ['Zimbabwe', 'zimbabuano(a)'],
];

class NacionalidadesMapper extends Mapper {
  override getByCode(code: string | number | undefined): string | undefined {
    if (code === undefined) {
      return undefined;
    }
    if (typeof code === 'string') {
      try {
        code = parseInt(code, 10);
      } catch (err) {
        console.warn(
          `Failed to parse code when retrieving nationality: ${code}`,
        );
        return undefined;
      }
    }
    const country = Paises.getByCode(code);
    if (country === undefined) {
      return undefined;
    }
    const nacionalidade = this.map.getValue(country);
    if (nacionalidade) {
      return capitalize(nacionalidade);
    }
    return capitalize(country);
  }

  override findByName(name: string): string | number | undefined {
    const country = this.map.getKey(name.toLowerCase());
    if (country === undefined) {
      return undefined;
    }
    return Paises.findByName(country.toString());
  }
}

const builder = BiMap.builder<number | string, string>();
builder.addEntries(entries);

export const Nacionalidades = new NacionalidadesMapper(builder.build());
