import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Container, Typography } from '@mui/material';

import {
  useGetAdmissionDraftByUser,
  useGetOrganizationEntry,
} from '@octopus/api';
import { WorkerCategory } from '@octopus/contract-types';
import { getAdmissionTitle } from '@octopus/onboarding-types';

import LogoBackground from '../../../../assets/LogoBackground.gif';
import TakoLogo from '../../../../assets/takoLogo.svg';
import TakoSymbol from '../../../../assets/takoSymbol.svg';
import { LoadingScene } from '../../../admission/components/AdmissionDraftLoadingScene';

type Props = {
  organizationId: string;
  userId: string;
};

export function SignupSuccess({ organizationId, userId }: Props) {
  const [draftId, setDraftId] = useState<string>(null);
  const [companyName, setCompanyName] = useState<string>();

  const admissionDraftQuery = useGetAdmissionDraftByUser(
    {
      pathParams: {
        organizationId,
        userId,
      },
    },
    {
      enabled: organizationId != null && userId != null && draftId == null,
    },
  );

  const {
    data: draft,
    status: draftStatus,
    isLoading,
    isFetching,
  } = admissionDraftQuery;

  useEffect(() => {
    if (draftStatus === 'error') {
      throw new Error(
        'Erro ao carregar dados da admissão. Tente novamente ou entre em contato com o departamento pessoal.',
      );
    }
    if (draft?.draftId != null && draftId == null) {
      setDraftId(draft.draftId);
    }
  }, [draft, draftId, draftStatus]);

  const organizationQuery = useGetOrganizationEntry(
    {
      pathParams: {
        organizationId,
      },
    },
    { enabled: organizationId != null && companyName == null, retry: 2 },
  );

  const { data: organization, status: organizationError } = organizationQuery;
  useEffect(() => {
    if (organizationError === 'error') {
      throw new Error(
        'Erro ao carregar dados da admissão. Tente novamente ou entre em contato com o departamento pessoal.',
      );
    }
    if (organization?.name != null && companyName == null) {
      setCompanyName(organization.name);
    }
  }, [organization, companyName, organizationError]);

  if (!draftId || !companyName || isLoading || isFetching) {
    return <LoadingScene />;
  }

  const admissionTitle = getAdmissionTitle(
    draft.workerCategory as WorkerCategory,
  );

  return (
    <Box display="flex" height="100%">
      <Container
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          textAlign: 'center',
          height: '80%',
          justifyContent: 'stretch',
          margin: 'auto',
          flexGrow: 1,
          [theme.breakpoints.up('md')]: {
            padding: 13,
          },
          [theme.breakpoints.down('md')]: {
            padding: 2,
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            flexGrow: 2,
            justifyContent: 'flex-end',
          }}
        >
          <img src={TakoLogo} alt="Tako" height={34} />
          <Typography variant="body2">
            Seu cadastro foi efetivado na Tako, clique no botão abaixo para
            iniciar a sua {admissionTitle} em {companyName}
          </Typography>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Button
            fullWidth
            color="primaryAlt"
            component={Link}
            to={`/admissions/new/${draftId}`}
            sx={{ p: 1.5 }}
            data-testid="back"
          >
            <Typography variant="body1" color="secondary" fontWeight="700">
              Começar minha {admissionTitle}
            </Typography>
          </Button>
        </Box>
      </Container>
      <Box
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
        padding={5}
        boxSizing="border-box"
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
          [theme.breakpoints.up('md')]: {
            display: 'flex',
          },
        })}
      >
        <Box
          height="100%"
          width="100%"
          borderRadius={5}
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
          sx={(theme) => ({
            boxShadow: theme.palette.shadows.light,
            backgroundColor: 'black',
            backgroundImage: `url(${LogoBackground})`,
            backgroundSize: 'cover',
            filter: 'brightness(240%)',
          })}
        >
          <img width="290px" height="290px" src={TakoSymbol} alt="Tako" />
        </Box>
      </Box>
    </Box>
  );
}
