import React from 'react';

import {
  AdmissionDraftInputFormSteps,
  AdmissionDraftStatus,
  AdmissionType,
} from '@octopus/api';
import { IFormDefinition } from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { CompanyContext } from '../../../../../modules/types';
import { NewAdmissionSummary } from '../../summary/NewAdmissionSummary';
import { getProfissionalFields } from '../fieldDefinitions';
import { getCriacaoUsuarioFields } from '../fieldDefinitions/criacaoUsuario';
import { getModalidadeContratoFields } from '../fieldDefinitions/modalidadeContrato';
import { getDadosEmpresaFields } from '../fieldDefinitions/pj/dadosEmpresa';
import { getHonorariosFields } from '../fieldDefinitions/pj/honorarios';
import { getPjFields } from '../fieldDefinitions/pj/pj';
import {
  AdmissionFormState,
  AdmissionFormSteps,
  FormStepDefinition,
} from '../types';

import * as common from './common';
import { IFormSteps } from './types';

const getAdminSteps = async ({
  organizationId,
  companyId,
  draftStatus,
  formState,
  companyContext,
  formSteps,
  isWorkerExperience,
  pjEnabled,
}: {
  organizationId: string;
  companyId: string;
  draftStatus: AdmissionDraftStatus;
  formState: AdmissionFormState;
  companyContext: CompanyContext;
  formSteps: AdmissionDraftInputFormSteps;
  isWorkerExperience: boolean;
  probationPeriodEnabled: boolean;
  pjEnabled: boolean;
}): Promise<AdmissionFormSteps> => {
  if (
    !companyContext ||
    !companyContext.costCenters ||
    !companyContext.jobTitles ||
    !companyContext.legalEntities
  ) {
    return {};
  }

  const { admissionType } = formState;

  const modalidade_contrato = getModalidadeContratoFields({
    companyContext,
    formState,
    estagiarioEnabled: false,
    pjEnabled,
    modalidadeFormSteps: formSteps[admissionDraftFormSteps.modalidade_contrato],
  });

  const criacao_usuario = getCriacaoUsuarioFields({
    formState,
    criacaoUsuarioFormSteps: formSteps[admissionDraftFormSteps.criacao_usuario],
    pjEnabled,
  });

  const profissional = await getProfissionalFields({
    companyContext,
    formState,
    organizationId,
    companyId,
    profissionalFormSteps: formSteps[admissionDraftFormSteps.profissional],
    hideCargoConfianca: true,
    isPj: true,
  });

  const pj_info = getPjFields({
    formState,
    pjFormSteps: formSteps[admissionDraftFormSteps.pj_info],
  });

  const remuneracao = getHonorariosFields({
    formState,
    remuneracaoFormSteps: formSteps[admissionDraftFormSteps.remuneracao],
  });

  const envio_convite = getSummaryStep({
    draftStatus,
    admissionType,
    stepName: 'envio_convite',
    formSteps,
    isWorkerExperience,
  });

  const { custom_fields_admin } = await common.getAdminSteps({
    organizationId,
    formState,
    formSteps,
  });

  return {
    modalidade_contrato,
    criacao_usuario,
    profissional,
    pj_info,
    remuneracao,
    ...(custom_fields_admin && { custom_fields_admin }),
    ...(envio_convite && { envio_convite }),
  };
};

export const getWorkerSteps = async ({
  organizationId,
  formState,
  formSteps,
  finalizaAdmissao,
}: {
  organizationId: string;
  formState: AdmissionFormState;
  formSteps: AdmissionDraftInputFormSteps;
  finalizaAdmissao: FormStepDefinition;
}): Promise<AdmissionFormSteps> => {
  const {
    dados_pessoais,
    endereco_e_contatos,
    dependentes,
    dados_bancarios,
    documentos,
    finaliza_admissao,
    custom_fields_worker,
  } = await common.getWorkerSteps({
    organizationId,
    formState,
    formSteps,
    finalizaAdmissao,
    isPj: true,
  });

  const dados_empresa = getDadosEmpresaFields({
    formState,
    empresaFormSteps: formSteps[admissionDraftFormSteps.dados_empresa],
  });

  return {
    dados_pessoais,
    dados_empresa,
    endereco_e_contatos,
    dependentes,
    dados_bancarios,
    documentos,
    ...(custom_fields_worker && { custom_fields_worker }),
    ...(finaliza_admissao && { finaliza_admissao }),
  };
};

const getSummaryStep = ({
  draftStatus,
  admissionType,
  stepName,
  formSteps,
  isWorkerExperience,
}: {
  draftStatus: AdmissionDraftStatus;
  admissionType: AdmissionType;
  stepName: string;
  formSteps: AdmissionDraftInputFormSteps;
  isWorkerExperience: boolean;
}): FormStepDefinition => {
  if (
    (admissionType === 'admin_fills' && stepName !== 'finaliza_admissao') ||
    (admissionType === 'send_invite' && stepName !== 'envio_convite') ||
    isWorkerExperience
  ) {
    return null;
  }

  const cta =
    admissionType === 'admin_fills'
      ? draftStatus !== 'admission_submitted'
        ? 'Enviar admissão para revisão'
        : 'Concluir admissão'
      : draftStatus !== 'draft_created'
        ? 'Reenviar convite'
        : 'Salvar candidato(a) e enviar convite';

  return {
    definition: [] as IFormDefinition,
    options: {
      id: stepName as keyof typeof admissionDraftFormSteps,
      title: 'Resumo e finalização',
      hideTitle: true,
      completed: formSteps[stepName]?.completed,
      reviewed: formSteps[stepName]?.reviewed,

      getCustomComponent: ({
        updateDisabled,
        formState,
        stepDefinition,
        onSubmit,
        isLoading,
      }: {
        updateDisabled: boolean;
        formState: AdmissionFormState;
        stepDefinition: FormStepDefinition;
        onSubmit: ({ extraData }: { extraData?: Record<string, any> }) => void;
        isLoading: boolean;
      }): React.ReactElement => {
        if (!formState || !stepDefinition) {
          return null;
        }

        return (
          <NewAdmissionSummary
            submitLabel={cta}
            formState={formState}
            onSubmit={onSubmit}
            isLoading={isLoading}
            disabled={updateDisabled}
          />
        );
      },
    },
  };
};

export const pj = {
  getAdminSteps,
  getWorkerSteps,
  getSummaryStep,
} as IFormSteps;
