import { Mapper } from './mapper';

export const TipoJornada = Mapper.from([
  [
    2,
    'Jornada 12 x 36 (12 horas de trabalho seguidas de 36 horas ininterruptas de descanso)',
  ],
  [3, 'Jornada com horário diário fixo e folga variável'],
  [4, 'Jornada com horário diário fixo e folga fixa (no domingo)'],
  [5, 'Jornada com horário diário fixo e folga fixa (exceto no domingo)'],
  [
    6,
    'Jornada com horário diário fixo e folga fixa (em outro dia da semana), com folga adicional periódica no domingo',
  ],
  [7, 'Turno ininterrupto de revezamento'],
  [9, 'Demais tipos de jornada'],
]);
