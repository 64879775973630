import { useEffect } from 'react';

import { Cancel, CheckCircle } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import {
  ApproveAllPayrollsError,
  PayrollTypes,
  useApprovePayroll,
} from '@octopus/api';
import { OctopusLoading } from '@octopus/ui/design-system';

import { useSnackbar } from '../../../../modules/hooks/useSnackbar';

export function ApprovalConfirmationModal({
  organizationId,
  companyId,
  payrollId,
  close,
}: {
  organizationId: string;
  companyId: string;
  payrollId: string;
  type: PayrollTypes;
  close: () => void;
}) {
  const { mutate, isLoading, isError, isSuccess, error } = useApprovePayroll();
  const approvePayroll = () => {
    mutate({
      pathParams: {
        organizationId: organizationId,
        companyId: companyId,
        payrollId: payrollId,
      },
    });
  };
  const showSnackbar = useSnackbar().showSnackbar;

  useEffect(() => {
    if (isSuccess) {
      showSnackbar({
        isOpen: true,
        Message: 'Pagamento aprovado com sucesso',
        StartAdornment: <CheckCircle />,
        autoHideDuration: 5000,
        hasCloseAction: true,
      });
      close();
    }
  }, [isSuccess]);

  if (isLoading) {
    return <LoadingScene />;
  }

  if (isError) {
    return <ErrorScene error={error} close={close} />;
  }

  if (isSuccess) {
    return null;
  }

  return <InitialScene approve={approvePayroll} close={close} />;
}

function ErrorScene({
  error,
  close,
}: {
  error: ApproveAllPayrollsError;
  close: () => void;
}) {
  return (
    <>
      <DialogContent>
        <Box display="flex" alignItems="center" gap={1.8} pb={3}>
          <Cancel fontSize="huge" color="error" />
          <Typography variant="h1">Erro na aprovação</Typography>
        </Box>
        <Typography variant="body1">
          Não foi possível aprovar a folha, certifique-se de que não existem
          pendências e tente novamente.
        </Typography>
        {error && <pre>{JSON.stringify(error.payload, null, 2)}</pre>}
      </DialogContent>

      <DialogActions>
        <Button size="large" sx={{ minWidth: '140px' }} onClick={close}>
          Ok
        </Button>
      </DialogActions>
    </>
  );
}

function LoadingScene() {
  return (
    <DialogContent>
      <Box
        py={10}
        px={16}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box width="100px">
          <OctopusLoading />
        </Box>
        <Box pt={2}>
          <Typography variant="h4" textAlign="center">
            Aprovando pagamentos, aguarde alguns segundos...
          </Typography>
        </Box>
      </Box>
    </DialogContent>
  );
}

function InitialScene({
  approve,
  close,
}: {
  approve: () => void;
  close: () => void;
}) {
  return (
    <>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          alignItems="flex-start"
          pr={20}
          mb={0}
        >
          <Box gap={3}>
            <Typography variant="h5" fontSize="24px" fontWeight={700}>
              Aprovar pagamento
            </Typography>

            <Box gap={3} mt={3}>
              <Typography fontSize="16px" fontWeight={500}>
                Ao confirmar, o que acontece em seguida:
              </Typography>

              <Box display="flex" flexDirection="column">
                <Box
                  component="ul"
                  pl={2}
                  gap={2}
                  display="flex"
                  flexDirection="column"
                >
                  <li>
                    <Typography
                      fontWeight={500}
                      variant="body1"
                      fontSize="16px"
                    >
                      Geração de recibos
                    </Typography>
                    <Typography
                      fontWeight={500}
                      variant="caption"
                      fontSize="12px"
                    >
                      Os recibos serão gerados e ficaram disponíveis para envio
                    </Typography>
                  </li>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'background.paper',
            color: 'text.primary',
            width: '140px',
            height: '40px',
            px: 4,
            ':hover': {
              backgroundColor: 'strokes.light',
            },
          }}
          size="large"
          onClick={() => {
            close();
          }}
          data-testid="cancel-approve-payroll-button"
        >
          Cancelar
        </Button>
        <Button
          color="primaryAlt"
          variant="contained"
          size="large"
          sx={{
            width: '140px',
            height: '40px',
          }}
          onClick={approve}
          data-testid="confirm-approve-payroll-button"
        >
          Confirmar
        </Button>
      </DialogActions>
    </>
  );
}
