import { useEffect, useState } from 'react';

import { EditOutlined } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Avatar,
  Badge,
  Box,
  CardContent,
  Dialog,
  IconButton,
  Typography,
} from '@mui/material';

import {
  AdmissionDraftEntry,
  fetchGetAdmissionDraft,
  fetchUploadAdmissionProfilePicture,
} from '@octopus/api';

import { PictureInputModal } from '../../../modules/components/PictureInputModal';
import TakoProgress from '../../../modules/components/TakoProgress';
import { SnackbarType } from '../../../modules/hooks/snackbarContext';
import { useSnackbar } from '../../../modules/hooks/useSnackbar';
import { pollUntil } from '../../../utils';

type Props = {
  organizationId: string;
  draftId: string;
  title: string;
  onPictureUploaded?: (pictureId: string) => void;
};

export const UploadProfilePicture = ({
  organizationId,
  draftId,
  title,
  onPictureUploaded,
}: Props) => {
  const { showSnackbar } = useSnackbar();
  const [profilePictureUrl, setProfilePictureUrl] = useState<string>();
  const [showEditPictureModal, setShowEditPictureModal] = useState(false);
  const [showPictureUploadLoadingModal, setShowPictureUploadLoadingModal] =
    useState(false);
  const [uploadProgress, setUploadProgress] = useState<{
    current: number;
    total: number;
  } | null>(null);

  useEffect(() => {
    const fetchInitialDraft = async () => {
      const draft = await fetchGetAdmissionDraft({
        pathParams: {
          organizationId,
          draftId,
        },
      });
      setProfilePictureUrl(draft.profilePicture?.pictureUrl);
      if (draft.profilePicture?.pictureId) {
        onPictureUploaded?.(draft.profilePicture.pictureId);
      }
    };
    fetchInitialDraft();
  }, [organizationId, draftId, onPictureUploaded]);

  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ mt: 2.5 }}>
        <Box
          onClick={() => setShowEditPictureModal(true)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            px: 3,
            py: 3.5,
            borderRadius: 1.5,
            border: '1px solid var(--strokes-light, rgba(0, 0, 0, 0.08))',
          }}
        >
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <IconButton
                sx={{
                  bgcolor: 'white',
                  width: 28,
                  height: 28,
                  padding: 0,
                  marginLeft: -6,
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                  '&:hover': {
                    bgcolor: 'lightgrey',
                  },
                }}
              >
                <EditOutlined sx={{ fontSize: 16 }} /> {}
              </IconButton>
            }
          >
            <Avatar
              src={profilePictureUrl}
              sx={{
                bgcolor: '#f5eec9',
                color: 'rgba(147, 80, 11, 0.2)',
                width: 78,
                height: 78,
                marginRight: 3,
                border: '0.5px solid #C4C4C4',
              }}
            />
          </Badge>
          <CardContent sx={{ flexGrow: 1, padding: 0 }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                lineHeight: 1.5,
              }}
            >
              {title}
            </Typography>
          </CardContent>
          <IconButton></IconButton>
        </Box>
      </Box>
      <PictureInputModal
        open={showEditPictureModal}
        onCancel={() => setShowEditPictureModal(false)}
        onSubmit={async (file) => {
          setShowEditPictureModal(false);
          await handlePictureUpload({
            organizationId,
            draftId,
            file,
            initProgress: (total: number) =>
              setUploadProgress({ total, current: 0 }),
            incrementProgress: () =>
              setUploadProgress((data) => ({
                ...data,
                current: data.current + 1,
              })),
            showLoading: (show: boolean) =>
              setShowPictureUploadLoadingModal(show),
            showSnackbar,
            setProfilePictureUrl,
            onPictureUploaded,
          });
        }}
      />
      <Dialog open={showPictureUploadLoadingModal} maxWidth={false}>
        <TakoProgress
          width={600}
          height={500}
          progress={{
            current: uploadProgress?.current,
            total: uploadProgress?.total,
          }}
          label={{
            phrases: [
              'Processando foto com cuidado...',
              'Aprimorando pixels para melhor visualização...',
              'Fazendo ajustes finais...',
              'Carregando foto, quase pronto!',
            ],
            cycleTimeInMs: 4000,
            completedPhrase: 'Foto atualizada!',
          }}
        />
      </Dialog>
    </Box>
  );
};

async function handlePictureUpload({
  organizationId,
  draftId,
  file,
  initProgress,
  incrementProgress,
  showLoading,
  showSnackbar,
  setProfilePictureUrl,
  onPictureUploaded,
}: {
  organizationId: string;
  draftId: string;
  file: File;
  initProgress: (totalSteps: number) => void;
  incrementProgress: () => void;
  showLoading: (show: boolean) => void;
  showSnackbar: (type: SnackbarType) => void;
  setProfilePictureUrl: (url: string) => void;
  onPictureUploaded?: (pictureId: string) => void;
}) {
  initProgress(4);
  showLoading(true);
  try {
    const { pictureId, uploadUrl, uploadHeaders } =
      await fetchUploadAdmissionProfilePicture({
        pathParams: {
          organizationId,
          draftId,
        },
        body: {
          type: 'image/jpeg',
          length: file.size,
        },
      });
    incrementProgress();
    await fetch(uploadUrl, {
      method: 'PUT',
      headers: uploadHeaders,
      body: file,
    });
    incrementProgress();
    await pollUntil<AdmissionDraftEntry>({
      action: () =>
        fetchGetAdmissionDraft({
          pathParams: {
            organizationId,
            draftId,
          },
        }),
      assertion: (entry) => entry?.profilePicture?.pictureId === pictureId,
      intervalMillis: 500,
      initialWaitMillis: 500,
      timeoutSeconds: 15,
    });
    incrementProgress();
    const draft = await fetchGetAdmissionDraft({
      pathParams: {
        organizationId,
        draftId,
      },
    });
    if (draft.profilePicture?.pictureUrl) {
      setProfilePictureUrl(draft.profilePicture.pictureUrl);
      onPictureUploaded?.(draft.profilePicture.pictureId);
    }
    incrementProgress();
    setTimeout(() => {
      showLoading(false);
      showSnackbar({
        isOpen: true,
        variant: 'default',
        Message: 'Foto atualizada',
        StartAdornment: <CheckCircleIcon />,
      });
    }, 400);
  } catch (error) {
    console.error(error);
    showLoading(false);
    showSnackbar({
      isOpen: true,
      Message: 'Erro ao fazer upload da foto.',
      variant: 'error',
      autoHideDuration: 3000,
    });
  }
}
