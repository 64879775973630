import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Submission } from '@conform-to/react';
import { ChronoUnit, LocalDate } from '@js-joda/core';
import { useQueries } from '@tanstack/react-query';
import { Schema } from 'zod';

import {
  Check,
  CheckCircle,
  Close,
  CloseOutlined,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import {
  ContractEntry,
  VacationsAccrualPeriodEntry,
  VacationsScheduleReviewInput,
  VacationsScheduleSummary,
  fetchGetContract,
  fetchGetContractAccrualPeriods,
  fetchGetContractVacationsSchedule,
  fetchPostReviewVacationsSchedule,
} from '@octopus/api';
import {
  getAdmissionDate,
  getDepartment,
  getName,
  getRoleName,
  getSalary,
  getWorkerId,
} from '@octopus/contract-types';
import { formatDateBR, formatMoney } from '@octopus/formatters';
import { Button as DSButton } from '@octopus/ui/design-system';
import { vacationsScheduleStatuses } from '@octopus/vacations-types';

import TabLabel from '../../modules/components/TabLabel';
import { useSnackbar } from '../../modules/hooks/useSnackbar';

import { RejectModal } from './[contractId]/new/modal/ScheduleRejectModal';
import { getAvailablesVacations } from './[contractId]/new/utils';
import { ExpiredVacationsIcon } from './utils/columns';
import { pollingSearch } from './utils/polling';
import {
  DeclinedVacationStatus,
  ScheduledVacationsStatus,
  VacationsDetailsTabs,
  declinedStatus,
  scheduledStatus,
  vacationsDetailsTabsConfig,
} from './utils/types';

type VacationsDetailsProps = {
  open: boolean;
  onClose: () => void;
  row: VacationsScheduleSummary;
  controls: Controls;
  detailsTab: VacationsDetailsTabs;
};

export const VacationsDrawerDetails = ({
  open,
  onClose,
  controls,
  row,
  detailsTab,
}: VacationsDetailsProps) => {
  const [tab, setTab] = useState(detailsTab);
  useEffect(() => setTab(detailsTab), [detailsTab]);
  const pathParams = {
    organizationId: row?.organizationId,
    contractId: row?.contractId,
  };
  const fetchContractAccrualPeriods = () =>
    fetchGetContractAccrualPeriods({ pathParams });
  const fetchContractVacationsSchedule = () =>
    fetchGetContractVacationsSchedule({ pathParams });
  const fetchContract = () => fetchGetContract({ pathParams });
  const queryParams = { enabled: !!row, refetchOnWindowFocus: false };
  const queryKey = (q: string) => [q, row?.organizationId, row?.contractId];
  const results = useQueries({
    queries: [
      {
        queryKey: queryKey('contractAccrualPeriods'),
        queryFn: fetchContractAccrualPeriods,
        ...queryParams,
      },
      {
        queryKey: queryKey('contractVacationsSchedule'),
        queryFn: fetchContractVacationsSchedule,
        ...queryParams,
      },
      {
        queryKey: queryKey('contract'),
        queryFn: fetchContract,
        ...queryParams,
      },
    ],
  });

  const [
    { data: contractAccrualPeriods, refetch: refetchContractAccrualPeriods },
    { data: vacationsSchedule, refetch: refetchVacationsSchedule },
    { data: contract },
  ] = results;
  const isLoading = results.some((result) => result.isLoading);

  const refetchData = () =>
    Promise.all([refetchContractAccrualPeriods(), refetchVacationsSchedule()]);

  const onReview = () => refetchData().then(() => onClose());

  const availableVacations = getAvailablesVacations(
    contractAccrualPeriods?.accrualPeriods,
  );
  const requestedVacations = vacationsSchedule?.data.filter(
    ({ status }) => status === vacationsScheduleStatuses.waitingApproval,
  );
  const scheduledVacations = vacationsSchedule?.data.filter(
    ({ status, endDate }) =>
      LocalDate.parse(endDate).isAfter(LocalDate.now()) &&
      scheduledStatus.includes(status as ScheduledVacationsStatus),
  );
  const historyVacations = vacationsSchedule?.data.filter(
    ({ status, endDate }) =>
      LocalDate.parse(endDate).isBefore(LocalDate.now()) &&
      scheduledStatus.includes(status as ScheduledVacationsStatus),
  );
  const rejectedOrCanceledVacations = vacationsSchedule?.data.filter(
    ({ status }) => declinedStatus.includes(status as DeclinedVacationStatus),
  );

  vacationsDetailsTabsConfig.requests.count = requestedVacations?.length;
  vacationsDetailsTabsConfig.scheduled.count = scheduledVacations?.length;
  vacationsDetailsTabsConfig.available.count = availableVacations?.filter(
    ({ daysAvailable }) => daysAvailable,
  )?.length;
  vacationsDetailsTabsConfig.history.count =
    historyVacations?.length + rejectedOrCanceledVacations?.length;

  return (
    <Drawer elevation={2} anchor="right" open={open} onClose={onClose}>
      {isLoading ? (
        <VacationsDrawerDetailsSkeleton />
      ) : (
        <Box px={5}>
          <Box>
            <VacationsHeader controls={controls} contract={contract} />
          </Box>
          <Box width={536}>
            <Box>
              <Tabs
                value={tab}
                onChange={(_, newTab) => setTab(newTab)}
                textColor="inherit"
                data-testid="vacations-status-tabs"
                TabIndicatorProps={{
                  sx: {
                    bgcolor: 'primary.main',
                  },
                }}
              >
                {Object.entries(vacationsDetailsTabsConfig).map(
                  ([key, tabConfig]) => (
                    <Tab
                      key={key}
                      value={key}
                      icon={
                        <TabLabel
                          variant="body2"
                          isSelected={key === tab}
                          color="primary"
                          label={tabConfig.label}
                          count={
                            tabConfig?.count > 0 ? tabConfig.count : undefined
                          }
                        />
                      }
                    />
                  ),
                )}
              </Tabs>
            </Box>
            <Box mt={3}>
              {tab === VacationsDetailsTabs.available && (
                <AvailableDays
                  availableVacations={availableVacations}
                  contractId={row.contractId}
                />
              )}
              {tab === VacationsDetailsTabs.requests && (
                <Requests requests={requestedVacations} onReview={onReview} />
              )}
              {tab === VacationsDetailsTabs.scheduled && (
                <Scheduled scheduleds={scheduledVacations} />
              )}
              {tab === VacationsDetailsTabs.history && (
                <History
                  history={historyVacations}
                  rejectedOrCanceledVacations={rejectedOrCanceledVacations}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Drawer>
  );
};

const AvailableDays = ({
  availableVacations,
  contractId,
}: {
  availableVacations: VacationsAccrualPeriodEntry[];
  contractId: string;
}) => {
  console.log({ availableVacations });
  const navigate = useNavigate();
  return (
    <Box>
      <Box px={0.5}>
        {availableVacations.length > 0 &&
          availableVacations?.map(
            (
              {
                concessionPeriod,
                startDate,
                endDate,
                daysAvailable,
                daysAcquired,
                maximumAvailableDaysForWorker,
                lost,
              },
              idx,
            ) => {
              const endOfConcessionPeriod = LocalDate.parse(
                concessionPeriod?.endDate,
              );
              const daysUntilAvailable = LocalDate.now().until(
                LocalDate.parse(endDate),
                ChronoUnit.DAYS,
              );
              const daysUntilExpiration = LocalDate.now().until(
                endOfConcessionPeriod,
                ChronoUnit.DAYS,
              );
              const hasAvailableVacations = daysAvailable > 0;
              const canScheduleVacations =
                !lost?.reason && (daysAvailable > 0 || daysAcquired > 0);

              const expired = endOfConcessionPeriod.isBefore(LocalDate.now());
              const statusMap: {
                [key: string]: {
                  text: string | React.ReactNode;
                  variant: VacationInfoTagsVariants;
                  days: number;
                };
              } = {
                daysUntilAvailable: {
                  text: `Disponível em ${daysUntilAvailable} dias`,
                  variant: 'default',
                  days: maximumAvailableDaysForWorker,
                },
                daysUntilExpiration: {
                  text: `Vence em ${daysUntilExpiration} dias`,
                  variant: 'warning',
                  days: daysAvailable,
                },
                expired: {
                  text: (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="start"
                      sx={{ fontWeight: 500 }}
                      mt={0.5}
                    >
                      <Box display="flex" py={0.5}>
                        <ExpiredVacationsIcon />
                        {`A data limite para concessão venceu em ${formatDateBR(concessionPeriod.endDate)},
                      portanto configurará pagamento de férias em dobro.`}
                      </Box>
                    </Box>
                  ),
                  variant: 'error',
                  days: daysAvailable,
                },
              };
              const { text, days, variant } = expired
                ? statusMap.expired
                : hasAvailableVacations
                  ? statusMap.daysUntilExpiration
                  : statusMap.daysUntilAvailable;

              const Alert = () => (
                <VacationInfoTags variant={variant} text={text} />
              );
              return (
                <VacationsDetailsCard key={startDate}>
                  <Box display="flex" flexDirection="column" width="100%">
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Box>
                        <Typography variant="h4">{`${days} dias`}</Typography>
                        <Typography
                          fontSize={12}
                          variant="body1"
                          component="div"
                        >
                          {`Aquisitivo: ${formatDateBR(startDate)} a ${formatDateBR(endDate)}`}
                        </Typography>
                        <Typography
                          fontSize={12}
                          variant="body1"
                          component="div"
                        >
                          {`Concessivo: ${formatDateBR(concessionPeriod.startDate)} a ${formatDateBR(concessionPeriod.endDate)}`}
                        </Typography>
                        {!expired && (
                          <Box mt={0.5}>
                            <Alert />
                          </Box>
                        )}
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        {canScheduleVacations && idx === 0 && (
                          <Button
                            variant="outlined"
                            sx={(theme) => ({
                              border: `1px solid ${theme.palette.strokes.light}`,
                              paddingX: theme.spacing(1.5),
                              paddingY: theme.spacing(1),
                              color: theme.palette.text.secondary,
                              ':hover': {
                                border: `1px solid ${theme.palette.strokes.light}`,
                              },
                            })}
                            onClick={() =>
                              navigate(
                                `/vacations/${contractId}/new?startDate=${concessionPeriod?.startDate}`,
                              )
                            }
                          >
                            {'Agendar'}
                          </Button>
                        )}
                      </Box>
                    </Box>
                    <Box mt={1} display="flex">
                      {expired && <Alert />}
                    </Box>
                  </Box>
                </VacationsDetailsCard>
              );
            },
          )}
      </Box>
    </Box>
  );
};

const Requests = ({
  requests,
  onReview,
}: {
  requests: VacationsScheduleSummary[];
  onReview: () => Promise<void>;
}) => {
  return (
    <Box>
      {requests.length > 0 ? (
        requests.map((summary: VacationsScheduleSummary) => {
          return (
            <Box mb={3}>
              <VacationsSummaryCard
                key={summary.contractId}
                summary={summary}
                onReview={onReview}
              />
            </Box>
          );
        })
      ) : (
        <EmptyState
          message={vacationsDetailsTabsConfig.requests.emptyMessage}
        />
      )}
    </Box>
  );
};

const Scheduled = ({
  scheduleds,
}: {
  scheduleds: VacationsScheduleSummary[];
}) => {
  return (
    <Box>
      {scheduleds.length > 0 ? (
        scheduleds.map((summary: VacationsScheduleSummary) => {
          return (
            <VacationsSummaryCard key={summary.contractId} summary={summary} />
          );
        })
      ) : (
        <EmptyState
          message={vacationsDetailsTabsConfig.scheduled.emptyMessage}
        />
      )}
    </Box>
  );
};

const History = ({
  history,
  rejectedOrCanceledVacations,
}: {
  history: VacationsScheduleSummary[];
  rejectedOrCanceledVacations: VacationsScheduleSummary[];
}) => {
  const [expandButtonState, setExpandButtonState] = useState(false);

  const handleExpandButtonClick = () => {
    setExpandButtonState(!expandButtonState);
  };
  return (
    <Box>
      {history.length === 0 && rejectedOrCanceledVacations.length === 0 && (
        <EmptyState message={vacationsDetailsTabsConfig.history.emptyMessage} />
      )}

      {history.map((summary: VacationsScheduleSummary) => {
        return (
          <VacationsHistoryCard key={summary.contractId} summary={summary} />
        );
      })}

      {rejectedOrCanceledVacations.length > 0 && (
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mb={1}
            px={0.5}
            onClick={handleExpandButtonClick}
            style={{ cursor: 'pointer' }}
          >
            <Typography
              variant="subtitle1"
              fontSize="14px"
              fontWeight={700}
              alignContent="center"
            >
              {'Recusadas e canceladas'}
            </Typography>

            <IconButton>
              {expandButtonState ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse in={expandButtonState} timeout="auto" unmountOnExit>
            {rejectedOrCanceledVacations.map(
              (summary: VacationsScheduleSummary) => {
                return (
                  <VacationsHistoryCard key={summary.id} summary={summary} />
                );
              },
            )}
          </Collapse>
        </Box>
      )}
    </Box>
  );
};

type Controls = {
  onDismiss: () => void;
  hasNext: boolean;
  onNext: () => void;
  onPrevious: () => void;
  hasPrevious: boolean;
};

function VacationsHeader({
  controls: { onDismiss, hasNext, onNext, hasPrevious, onPrevious },
  contract,
}: {
  controls?: Controls;
  contract: ContractEntry;
}) {
  const name = getName(contract);
  const firstLine = [
    getRoleName(contract),
    getDepartment(contract),
    `${formatMoney(getSalary(contract))} /mês`,
  ];
  const secondLine = [
    `Data de início em ${formatDateBR(getAdmissionDate(contract))}`,
    `Matrícula ${getWorkerId(contract)}`,
  ];

  const InfoItem = ({ idx, value }: { idx: number; value: string }) => (
    <>
      {idx !== 0 && (
        <Typography
          mx={0.75}
          sx={(theme) => ({ color: theme.palette.strokes.heavy })}
          variant="caption"
        >
          |
        </Typography>
      )}
      <Typography color="textSecondary" variant="caption">
        {value}
      </Typography>
    </>
  );

  return (
    <Box pt={5} pb={2}>
      <Box gap={2} display="flex" flexDirection="row" alignItems="center">
        <Typography
          color="textSecondary"
          variant="body2"
          sx={{ width: '100%' }}
        ></Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <IconButton
            onClick={onPrevious}
            disabled={!hasPrevious}
            sx={(theme) => ({
              py: 0,
              px: 0.5,
              height: '32px',
              backgroundColor: theme.palette.background.paper,
              borderRadius: '8px 0 0 8px',
              border: `1px solid ${theme.palette.strokes.light}`,
              borderRight: hasNext
                ? `0.5px solid ${theme.palette.strokes.light}`
                : undefined,
            })}
          >
            <ExpandLess />
          </IconButton>
          <IconButton
            disabled={!hasNext}
            onClick={onNext}
            sx={(theme) => ({
              py: 0,
              px: 0.5,
              height: '32px',
              backgroundColor: theme.palette.background.paper,
              borderRadius: '0 8px 8px 0',
              border: `1px solid ${theme.palette.strokes.light}`,
              borderLeft: `0.5px solid ${theme.palette.strokes.light}`,
            })}
          >
            <ExpandMore />
          </IconButton>
        </Box>
        <IconButton
          sx={{
            py: 0.5,
            px: 0.5,
            width: '32px',
            height: '32px',
            borderRadius: '4px',
          }}
          onClick={onDismiss}
        >
          <Close sx={{ fontSize: '24px' }} />
        </IconButton>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row">
          <Typography variant="h3">{name}</Typography>
        </Box>
        <Box mt={1} display="flex" flexDirection="row">
          {firstLine.map((value, idx) => (
            <InfoItem key={value} value={value} idx={idx} />
          ))}
        </Box>
        <Box mt={0.5} display="flex" flexDirection="row">
          {secondLine.map((value, idx) => (
            <InfoItem key={value} value={value} idx={idx} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

const VacationsDetailsCard = ({
  children,
}: {
  children: React.ReactNode;
  size?: 'regular' | 'large';
}) => {
  return (
    <Box
      px={3}
      py={2.5}
      borderRadius="12px"
      mb={1}
      sx={(theme) => ({
        border: `1px solid ${theme.palette.strokes.light}`,
      })}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      {children}
    </Box>
  );
};

type VacationInfoTagsVariants =
  | 'warning'
  | 'success'
  | 'info'
  | 'default'
  | 'error';

const VacationInfoTags = ({
  text,
  variant,
}: {
  text: string | React.ReactNode;
  variant: VacationInfoTagsVariants;
}) => {
  const statusMap = {
    warning: {
      backgroundColor: '#FEF7F1',
      color: '#93500B',
    },
    success: {
      backgroundColor: '#F4FBF6',
      color: '#066F4F',
    },
    info: {
      backgroundColor: '#F0F6FF',
      color: '#0058DB',
    },
    default: {
      backgroundColor: '#F7F7F8',
      color: '#616161',
    },
    error: {
      backgroundColor: '#FDF2F4',
      color: '#BC1A41',
    },
  };

  const { color, backgroundColor } = statusMap[variant];
  return (
    <Typography
      sx={{
        backgroundColor,
        color,
      }}
      px={1}
      py={0.5}
      borderRadius={0.5}
      fontSize={12}
      fontWeight={500}
      variant="body1"
      width="fit-content"
    >
      {text}
    </Typography>
  );
};

const VacationsSummaryCard = ({
  summary,
  onReview,
}: {
  summary: VacationsScheduleSummary;
  onReview?: () => Promise<void>;
}) => {
  const {
    daysTaken,
    startDate,
    endDate,
    accrualPeriod,
    approvalDeadline,
    status,
    organizationId,
    contractId,
    sequence,
    id,
  } = summary;
  const [isOpen, setIsOpen] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showSnackbar } = useSnackbar();
  const waitingApproval = status === vacationsScheduleStatuses.waitingApproval;
  const scheduled = scheduledStatus.includes(status as any);
  const inProgress =
    scheduled &&
    LocalDate.parse(startDate).isBefore(LocalDate.now()) &&
    LocalDate.parse(endDate).isAfter(LocalDate.now());

  const hasDoubleVacation = LocalDate.parse(endDate).isAfter(
    LocalDate.parse(accrualPeriod.concessionPeriod.endDate).minusMonths(1),
  );

  const pathParams = {
    sequence: `${sequence}`,
    organizationId,
    contractId,
  };

  const fetchReviewVacationsSchedule = ({
    sequence,
    review,
    reason,
  }: {
    sequence: string;
    reason?: string;
  } & VacationsScheduleReviewInput) =>
    fetchPostReviewVacationsSchedule({
      pathParams: { sequence, ...pathParams },
      body: {
        review,
        reason,
      },
    });

  const onStartReview = () => {
    setIsSubmitting(true);
  };

  const onFinishReview = () => {
    setIsSubmitting(false);
    setIsOpen(false);
  };

  const waitingStatusChange = (status: string) => {
    return () =>
      pollingSearch({
        organizationId,
        contractId,
        id,
        vacationsScheduleStatusesList: [status],
      });
  };

  const reproveVacation = (
    _: any,
    formData: Submission<Schema, string[], Schema>,
  ) => {
    onStartReview();
    fetchReviewVacationsSchedule({
      review: 'reject',
      sequence: `${sequence}`,
      reason: formData.payload.rejectReason as string,
    })
      .then(waitingStatusChange(vacationsScheduleStatuses.rejected))
      .then(() => onReview())
      .then(() => onFinishReview())
      .catch(() => {
        setIsSubmitting(false);
        showSnackbar({
          isOpen: true,
          variant: 'error',
          Message: 'Erro ao reprovar férias',
          StartAdornment: <Close />,
          hasCloseAction: true,
        });
      });
  };

  const approveVacation = () => {
    onStartReview();
    fetchReviewVacationsSchedule({
      review: 'approve',
      sequence: `${sequence}`,
    })
      .then(waitingStatusChange(vacationsScheduleStatuses.approved))
      .then(() => {
        showSnackbar({
          isOpen: true,
          Message: 'Férias aprovadas com sucesso',
          StartAdornment: <CheckCircle />,
          hasCloseAction: true,
        });
      })
      .then(() => onReview())
      .then(() => onFinishReview())
      .catch(() => {
        showSnackbar({
          isOpen: true,
          variant: 'error',
          Message: 'Erro ao aprovar férias',
          StartAdornment: <Close />,
          hasCloseAction: true,
        });
        setIsSubmitting(false);
      });
  };

  const statusMap: {
    [key: string]: {
      text: string;
      variant: VacationInfoTagsVariants;
    };
  } = {
    created: {
      text: `Enviada`,
      variant: 'info',
    },
    waitingApproval: {
      text: `Aprovar até ${formatDateBR(approvalDeadline)}`,
      variant: 'warning',
    },
    scheduled: {
      text: 'Agendada',
      variant: 'success',
    },
    inProgress: {
      text: 'Em gozo',
      variant: 'info',
    },
  };

  const { text, variant } = waitingApproval
    ? statusMap.waitingApproval
    : inProgress
      ? statusMap.inProgress
      : statusMap.scheduled;

  return (
    <VacationsDetailsCard>
      <RejectModal
        open={isOpen === 'reprove'}
        onCancel={() => setIsOpen(false)}
        onConfirm={reproveVacation}
        isSubmitting={isSubmitting}
      />
      <Box display="flex" flexDirection="column" width="100%">
        <Box mb={1}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" flexDirection="column">
              <Typography mb={0.5} variant="h4">
                {`${daysTaken} dias`}
              </Typography>
              <Typography
                mb={0.5}
                fontSize={12}
                variant="body1"
                component="div"
              >
                {`De ${formatDateBR(startDate)} a ${formatDateBR(endDate)}`}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" gap={1}>
              <VacationInfoTags text={text} variant={variant} />
              {hasDoubleVacation && (
                <VacationInfoTags text={'Pagamento em dobro'} variant="error" />
              )}
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box my={2} display="flex" justifyContent="space-between">
          <Box width="60%">
            <Typography fontSize={12} variant="body1" component="div" mb={0.5}>
              {`Abono de 10 dias`}
            </Typography>
            <Typography fontSize={12} variant="body1" component="div">
              {`Adiantamento da parcela do 13º`}
            </Typography>
          </Box>
          <Box width="40%">
            <Typography fontSize={12} variant="body1" component="div" mb={0.5}>
              {summary.hasDaysSold ? 'Sim' : 'Não'}
            </Typography>
            <Typography fontSize={12} variant="body1" component="div">
              {summary.thirteenthAdvance ? 'Sim' : 'Não'}
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Box mt={2} display="flex" justifyContent="space-between">
          <Box width="60%">
            <Typography fontSize={12} variant="body1" component="div" mb={0.5}>
              {`Periodo aquisitivo`}
            </Typography>
            {accrualPeriod?.concessionPeriod && (
              <Typography fontSize={12} variant="body1" component="div">
                {`Periodo concessivo`}
              </Typography>
            )}
          </Box>
          <Box width="40%">
            <Typography fontSize={12} variant="body1" component="div" mb={0.5}>
              {`${formatDateBR(accrualPeriod.startDate)} a ${formatDateBR(accrualPeriod.endDate)}`}
            </Typography>
            {accrualPeriod?.concessionPeriod && (
              <Typography fontSize={12} variant="body1" component="div">
                {`${formatDateBR(accrualPeriod?.concessionPeriod?.startDate)} a ${formatDateBR(accrualPeriod?.concessionPeriod?.endDate)}`}
              </Typography>
            )}
          </Box>
        </Box>

        {waitingApproval && (
          <Box mt={2} display="flex" justifyContent="end">
            <DSButton
              variantLayout="tiny"
              variantSemantic="secondary"
              onClick={() => setIsOpen('reprove')}
              disabled={isSubmitting}
            >
              {'Recusar'}
              <CloseOutlined
                sx={{ ml: '4px', width: '16px', height: '16px' }}
              />
            </DSButton>
            <DSButton
              variantLayout="tiny"
              sx={{ ml: 1, minWidth: '100px' }}
              onClick={() => approveVacation()}
              isLoading={isSubmitting}
            >
              {'Agendar'}
              <Check sx={{ ml: '4px', width: '16px', height: '16px' }} />
            </DSButton>
          </Box>
        )}
      </Box>
    </VacationsDetailsCard>
  );
};

const VacationsHistoryCard = ({
  summary,
}: {
  summary: VacationsScheduleSummary;
}) => {
  const {
    daysTaken,
    startDate,
    endDate,
    hasDaysSold,
    thirteenthAdvance,
    accrualPeriod,
    status,
  } = summary;
  const rejectedOrCanceledVacations = declinedStatus.includes(
    status as DeclinedVacationStatus,
  );
  return (
    <VacationsDetailsCard>
      <Box display="flex" flexDirection="column" width="100%">
        <Box mb={0.5} display="flex">
          <Typography mb={0.5} variant="h4">
            {`${daysTaken} dias,`}
          </Typography>
          <Typography ml={0.5} variant="h4" fontWeight={400}>
            {`de ${formatDateBR(startDate)} a ${formatDateBR(endDate)}`}
          </Typography>
        </Box>

        <Typography fontSize={12} variant="body1" component="div">
          {`Periodo aquisitivo: ${formatDateBR(accrualPeriod.startDate)} a ${formatDateBR(accrualPeriod.endDate)}`}
        </Typography>

        <Box mt={1} width="fit-content">
          {hasDaysSold && (
            <VacationInfoTags text="10 dias abonados" variant="success" />
          )}
          {thirteenthAdvance && (
            <VacationInfoTags text="Com adiantamento 13º" variant="success" />
          )}
          {rejectedOrCanceledVacations && (
            <VacationInfoTags text="Recusada" variant="error" />
          )}
        </Box>
      </Box>
    </VacationsDetailsCard>
  );
};

const VacationsDrawerDetailsSkeleton = () => {
  return (
    <Box px={5} mr="15px">
      {/* Header Skeleton */}
      <Box pt={5} pb={2}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={2}
          mt={2}
        >
          <Box>
            <Skeleton variant="text" width="200px" height={25} />
          </Box>
        </Box>
        <Box mt={1} display="flex" flexDirection="row" alignItems="center">
          <Box>
            <Skeleton variant="text" width="200px" height={20} />
            <Skeleton variant="text" width="200px" height={20} />
          </Box>
        </Box>
      </Box>

      {/* Tabs Skeleton */}
      <Box width={496}>
        <Tabs value={0}>
          {Array.from({ length: 4 }).map((_, index) => (
            <Tab
              key={index}
              label={<Skeleton variant="text" width={90} height={30} />}
            />
          ))}
        </Tabs>
      </Box>

      {/* Content Skeleton */}
      <Box mt={3}>
        {Array.from({ length: 3 }).map((_, index) => (
          <VacationsDetailsCard key={index}>
            <Box display="flex" flexDirection="column" width="420px">
              <Skeleton
                variant="text"
                width={120}
                height={29}
                sx={{ mb: 0.5 }}
              />
              <Skeleton
                variant="rectangular"
                width={250}
                height={15}
                sx={{ mb: 0.5 }}
              />
              <Skeleton variant="rectangular" width={250} height={15} />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={0.5}
              >
                <Skeleton variant="text" width={100} height={29} />
              </Box>
            </Box>
          </VacationsDetailsCard>
        ))}
      </Box>
    </Box>
  );
};

const EmptyState = ({ message }: { message: string }) => (
  <Box mt={10} display="flex" justifyContent="center">
    <Typography
      fontStyle="italic"
      color="#616161"
      variant="caption"
      fontWeight={500}
    >
      {message}
    </Typography>
  </Box>
);
