import React from 'react';

import {
  AdmissionDraftInputFormSteps,
  AdmissionDraftStatus,
  AdmissionType,
} from '@octopus/api';
import { IFormDefinition } from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { CompanyContext } from '../../../../../modules/types';
import { NewAdmissionSummary } from '../../summary/NewAdmissionSummary';
import {
  getDuracaoFields,
  getProfissionalFields,
  getRegimeFields,
} from '../fieldDefinitions';
import { getBolsaAuxilioFields } from '../fieldDefinitions/cltEstagiario/bolsaAuxilio';
import { getEstagioFields } from '../fieldDefinitions/cltEstagiario/estagio';
import { getCriacaoUsuarioFields } from '../fieldDefinitions/criacaoUsuario';
import { getJornadaFields } from '../fieldDefinitions/jornada';
import { getModalidadeContratoFields } from '../fieldDefinitions/modalidadeContrato';
import {
  AdmissionFormState,
  AdmissionFormSteps,
  FormStepDefinition,
} from '../types';

import * as common from './common';
import { IFormSteps } from './types';

const getAdminSteps = async ({
  organizationId,
  companyId,
  draftStatus,
  formState,
  companyContext,
  formSteps,
  isWorkerExperience,
  estagiarioEnabled,
  pjEnabled,
}: {
  organizationId: string;
  companyId: string;
  draftStatus: AdmissionDraftStatus;
  formState: AdmissionFormState;
  companyContext: CompanyContext;
  formSteps: AdmissionDraftInputFormSteps;
  isWorkerExperience: boolean;
  probationPeriodEnabled: boolean;
  estagiarioEnabled: boolean;
  pjEnabled: boolean;
}): Promise<AdmissionFormSteps> => {
  if (
    !companyContext ||
    !companyContext.costCenters ||
    !companyContext.jobTitles ||
    !companyContext.legalEntities
  ) {
    return {};
  }

  const { admissionType, tpContr, admissionDate } = formState;

  const modalidade_contrato = getModalidadeContratoFields({
    companyContext,
    formState,
    estagiarioEnabled,
    pjEnabled,
    modalidadeFormSteps: formSteps[admissionDraftFormSteps.modalidade_contrato],
  });

  const criacao_usuario = getCriacaoUsuarioFields({
    formState,
    criacaoUsuarioFormSteps: formSteps[admissionDraftFormSteps.criacao_usuario],
    pjEnabled,
  });

  const profissional = await getProfissionalFields({
    companyContext,
    organizationId,
    companyId,
    formState,
    profissionalFormSteps: formSteps[admissionDraftFormSteps.profissional],
    hideCargoConfianca: true,
  });

  const regime_trabalho = getRegimeFields({
    formState,
    regimeFormSteps: formSteps[admissionDraftFormSteps.regime_trabalho],
    hideRegimeTrabFields: true,
  });

  const estagio_info = getEstagioFields({
    formState,
    estagioFormSteps: formSteps[admissionDraftFormSteps.estagio_info],
  });

  const duracao = getDuracaoFields({
    tpContr,
    admissionDate,
    duracaoFormSteps: formSteps[admissionDraftFormSteps.duracao],
    probationPeriodEnabled: false,
  });

  const jornada = getJornadaFields({
    formState,
    jornadaFormSteps: formSteps[admissionDraftFormSteps.jornada],
    hideJornadaFields: true,
  });

  const remuneracao = getBolsaAuxilioFields({
    formState,
    remuneracaoFormSteps: formSteps[admissionDraftFormSteps.remuneracao],
  });

  const envio_convite = getSummaryStep({
    draftStatus,
    admissionType,
    stepName: 'envio_convite',
    formSteps,
    isWorkerExperience,
  });

  const { custom_fields_admin } = await common.getAdminSteps({
    organizationId,
    formState,
    formSteps,
  });

  return {
    modalidade_contrato,
    criacao_usuario,
    profissional,
    regime_trabalho,
    estagio_info,
    duracao,
    jornada,
    remuneracao,
    ...(custom_fields_admin && { custom_fields_admin }),
    ...(envio_convite && { envio_convite }),
  };
};

export const getWorkerSteps = ({
  organizationId,
  formState,
  formSteps,
  finalizaAdmissao,
}: {
  organizationId: string;
  formState: AdmissionFormState;
  formSteps: AdmissionDraftInputFormSteps;
  finalizaAdmissao: FormStepDefinition;
}): Promise<AdmissionFormSteps> => {
  return common.getWorkerSteps({
    organizationId,
    formState,
    formSteps,
    finalizaAdmissao,
    isPj: false,
  });
};

const getSummaryStep = ({
  draftStatus,
  admissionType,
  stepName,
  formSteps,
  isWorkerExperience,
}: {
  draftStatus: AdmissionDraftStatus;
  admissionType: AdmissionType;
  stepName: string;
  formSteps: AdmissionDraftInputFormSteps;
  isWorkerExperience: boolean;
}): FormStepDefinition => {
  if (
    (admissionType === 'admin_fills' && stepName !== 'finaliza_admissao') ||
    (admissionType === 'send_invite' && stepName !== 'envio_convite') ||
    isWorkerExperience
  ) {
    return null;
  }

  const cta =
    admissionType === 'admin_fills'
      ? draftStatus !== 'admission_submitted'
        ? 'Enviar admissão para revisão'
        : 'Concluir admissão'
      : draftStatus !== 'draft_created'
        ? 'Reenviar convite'
        : 'Salvar candidato(a) e enviar convite';

  return {
    definition: [] as IFormDefinition,
    options: {
      id: stepName as keyof typeof admissionDraftFormSteps,
      title: 'Resumo e finalização',
      hideTitle: true,
      completed: formSteps[stepName]?.completed,
      reviewed: formSteps[stepName]?.reviewed,

      getCustomComponent: ({
        updateDisabled,
        formState,
        stepDefinition,
        onSubmit,
        isLoading,
      }: {
        updateDisabled: boolean;
        formState: AdmissionFormState;
        stepDefinition: FormStepDefinition;
        onSubmit: ({ extraData }: { extraData?: Record<string, any> }) => void;
        isLoading: boolean;
      }): React.ReactElement => {
        if (!formState || !stepDefinition) {
          return null;
        }

        return (
          <NewAdmissionSummary
            submitLabel={cta}
            formState={formState}
            onSubmit={onSubmit}
            isLoading={isLoading}
            disabled={updateDisabled}
          />
        );
      },
    },
  };
};

export const cltEstagiario = {
  getAdminSteps,
  getWorkerSteps,
  getSummaryStep,
} as IFormSteps;
