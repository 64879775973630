import { BiMap } from '@rimbu/core';

/**
 * We have to use an array of tuples when building the BiMap
 * to support numbers as keys, because javascript objects will silently convert
 * number keys to string.
 */
export type MapEntries = [number | string, string][];

export class Mapper {
  protected readonly map: BiMap<number | string, string>;

  constructor(map: BiMap<number | string, string>) {
    this.map = map;
  }

  getByCode(code: number | string | undefined): string | undefined {
    if (code === undefined) {
      return undefined;
    }
    return this.map.getValue(code);
  }

  findByName(name: string): string | number | undefined {
    return this.map.getKey(name);
  }

  values(): string[] {
    return this.map.streamValues().toArray();
  }

  codes(): (string | number)[] {
    return this.map.streamKeys().toArray();
  }

  entries(): (readonly [string | number, string])[] {
    return this.map.stream().toArray();
  }

  static from(entries: MapEntries) {
    const builder = BiMap.builder<number | string, string>();
    builder.addEntries(entries);
    return new Mapper(builder.build());
  }
}
