import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import {
  Estados,
  MunicipiosByEstado,
  TipoLogradouro,
} from '@octopus/esocial/mapper';
import {
  IFormDefinition,
  NEW_UI_TYPE,
  UI_TYPE,
  cepSchema,
  cnpjSchema,
  nonEmptyString,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { getOptionsFromList, getOptionsFromMapper } from '../../inputUtils';
import { AdmissionFormState, FormStepDefinition } from '../../types';
import { estadoMunicipioSchema } from '../enderecoEContatosSchema';

const getFieldDefinition = ({
  formState,
}: {
  formState: AdmissionFormState;
}): IFormDefinition => {
  const { razaoSocial, cnpjEmpresa, cnae, enquadramentoTributario, empresa } =
    formState;
  const { cep, tpLograd, dscLograd, nrLograd, complemento, bairro, uf } =
    empresa?.endereco ?? {};

  return [
    {
      label: 'Razão social',
      type: z.string().min(1).max(100),
      name: 'razaoSocial',
      placeholder: 'Razão social da empresa',
      uiType: UI_TYPE.TEXT,
      value: razaoSocial,
    },
    {
      label: 'CNPJ',
      type: cnpjSchema,
      mask: 'cnpj',
      name: 'cnpjEmpresa',
      uiType: UI_TYPE.TEXT,
      placeholder: '00.000.000/0000-00',
      value: cnpjEmpresa,
    },
    {
      label: 'CNAE',
      type: z.string().length(7),
      name: 'cnae',
      uiType: UI_TYPE.TEXT,
      placeholder: 'CNAE',
      value: cnae,
    },
    {
      label: 'Enquadramento tributário',
      type: nonEmptyString,
      name: 'enquadramentoTributario',
      uiType: UI_TYPE.SELECT,
      options: getOptionsFromList({
        list: { simples: 'Simples', lucroPresumido: 'Lucro presumido' },
        currentValue: enquadramentoTributario,
      }),
    },
    {
      label: 'CEP',
      type: cepSchema,
      name: 'cep',
      uiType: UI_TYPE.TEXT,
      placeholder: '00000-000',
      value: cep || '',
      mask: 'cep',
    },
    {
      label: 'Logradouro',
      type: nonEmptyString,
      name: 'tpLograd',
      uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
      placeholder: 'Digite para buscar. Ex: Rua, Avenida...',
      options: getOptionsFromMapper({
        mapper: TipoLogradouro,
        currentValue: tpLograd,
      }),
    },
    {
      label: '',
      type: nonEmptyString,
      name: 'dscLograd',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Ex: dos bonifácios',
      value: dscLograd || '',
      sx: { marginTop: '-24px' },
    },
    {
      label: 'Número',
      type: nonEmptyString,
      name: 'nrLograd',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Ex: 123',
      value: nrLograd || '',
    },
    {
      label: 'Complemento',
      type: z.string().optional(),
      name: 'complemento',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Ex: apto 31',
      value: complemento || '',
    },
    {
      label: 'Bairro',
      type: nonEmptyString,
      name: 'bairro',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Ex: Vila formosa',
      value: bairro || '',
    },
    {
      label: 'Estado',
      type: estadoMunicipioSchema,
      name: 'uf',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Digite para buscar o estado',
      fieldsOptions: getOptionsFromMapper({
        mapper: Estados,
        currentValue: uf?.defaultValue,
      }).map((option) => ({
        ...option,
        fields: [
          {
            label: 'Cidade / Município',
            type: nonEmptyString,
            name: `codMunic`,
            uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
            placeholder: 'Digite para buscar a cidade',
            options: getOptionsFromMapper({
              mapper: MunicipiosByEstado[option.value],
              currentValue: uf?.defaultCodMunic,
            }),
          },
          {
            label: '',
            hidden: true,
            type: z.string().optional(),
            name: `uf_${option.value}`,
            value: '-',
            uiType: UI_TYPE.TEXT,
          },
        ],
      })),
    },
  ];
};

export const getDadosEmpresaFields = ({
  formState,
  empresaFormSteps,
}: {
  formState: AdmissionFormState;
  empresaFormSteps: AdmissionDraftInputFormStep;
}): FormStepDefinition => ({
  definition: getFieldDefinition({
    formState,
  }),
  options: {
    id: admissionDraftFormSteps.dados_empresa,
    title: 'Informações sobre a empresa',
    completed: empresaFormSteps?.completed,
    reviewed: empresaFormSteps?.reviewed,
  },
});
