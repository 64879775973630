import { ReactElement } from 'react';

import { Box, Divider, Typography } from '@mui/material';

import { RecordEntryProps } from './RecordEntry';

export type RecordEntryGroupProps = {
  title?: string;
  children:
    | ReactElement<RecordEntryProps | Element>
    | ReactElement<RecordEntryProps | Element>[]
    | undefined;
  isEditing?: boolean;
  withDivider?: boolean;
};

export function RecordEntryGroup({
  title,
  children,
  isEditing = false,
  withDivider = false,
}: RecordEntryGroupProps) {
  if (!children) {
    return null;
  }
  return (
    <Box display="flex" flexDirection="column" gap={isEditing ? 0.5 : 1.5}>
      {title && (
        <Typography
          variant="body2"
          fontWeight="700"
          color="text.primary"
          pb={0.75}
          pt={1}
        >
          {title}
        </Typography>
      )}
      {children}
      {withDivider && (
        <Box pb={4} pt={isEditing ? 3.5 : 2.5}>
          <Divider />
        </Box>
      )}
    </Box>
  );
}
