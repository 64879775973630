import React, { RefObject } from 'react';

import { Box } from '@mui/material';

import { ContractEntry, CustomFieldEntry } from '@octopus/api';

import { CustomFieldsRecord } from '../../modules/components/people/CustomFieldsRecord';
import { QueryResult } from '../../modules/types';

export type CustomFieldsDetailsProps = {
  contractQuery: QueryResult<ContractEntry>;
  customFieldsQuery: QueryResult<CustomFieldEntry[]>;
  customFieldsDetailsRef?: RefObject<Element>;
  showExpandIcon?: boolean;
};

export function CustomFieldsDetails({
  contractQuery,
  customFieldsQuery,
  customFieldsDetailsRef,
  showExpandIcon,
}: CustomFieldsDetailsProps) {
  if (
    customFieldsQuery.isLoading ||
    customFieldsQuery.isError ||
    customFieldsQuery.data?.length === 0
  ) {
    return null;
  }
  return (
    <Box ref={customFieldsDetailsRef} data-testid="custom-fields-details">
      <CustomFieldsRecord
        data={{ customFields: contractQuery.data.customFields }}
        fieldDefs={customFieldsQuery.data}
        showExpandIcon={showExpandIcon}
      />
    </Box>
  );
}
