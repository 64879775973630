import { RefObject } from 'react';

import { ContractEntry, CustomFieldEntry } from '@octopus/api';

import { QueryResult } from '../../../modules/types';

export type ContractDetailsProps = {
  historyEnabled: boolean;
  isEditable: boolean;
  showTitle?: boolean;
  refs?: {
    personalDetailsRef: RefObject<Element>;
    contractDetailsRef: RefObject<Element>;
    customFieldsDetailsRef: RefObject<Element>;
    paymentDetailsRef: RefObject<Element>;
  };
  contractQuery: QueryResult<ContractEntry>;
  customFieldsQuery: QueryResult<CustomFieldEntry[]>;
};

export type ContractEditError = {
  message: string;
  fields?: {
    [path: string]: string;
  };
};

export type PageRefs = {
  pageRef: RefObject<Element>;
  personalDetailsRef: RefObject<Element>;
  contractDetailsRef: RefObject<Element>;
  customFieldsDetailsRef: RefObject<Element>;
  paymentDetailsRef: RefObject<Element>;
};

export const pageContents = {
  details: 'details',
  reports: 'reports',
} as const;

export type PageContent = keyof typeof pageContents;
