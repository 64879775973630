import React, { useEffect, useState } from 'react';

import { IconX } from '@tabler/icons-react';

import {
  Box,
  Button,
  Checkbox,
  Drawer,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import {
  ContractEntry,
  ContractSummary,
  SearchInput,
  useSearchAllContracts,
} from '@octopus/api';
import { contractStatuses, contractTypes } from '@octopus/contract-types';
import {
  DataGrid,
  GridValueGetterParams,
  SearchBar,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { BackButton } from '../../BackButton';
import { ExpandableTypography } from '../../ExpandableTypography';
import { PageAlert } from '../../PageAlert';
import UserAvatar from '../../UserAvatar';

import { AddReportsDialog } from './AddReportsDialog';
import { AddReportEntry } from './types';

export type AddReportsDrawerProps = {
  newManagerContract: ContractEntry;
  open: boolean;
  onClose: () => void;
  onSuccess: (showSnackbar: boolean) => void;
};

export function AddReportsDrawer({
  newManagerContract,
  open,
  onClose,
  onSuccess,
}: AddReportsDrawerProps) {
  const { organizationId, contractId } = newManagerContract;

  const [reportsToAdd, setReportsToAdd] = useState<AddReportEntry[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogKey, setDialogKey] = useState<number | null>(null);

  const addReport = (reportContract: AddReportEntry) =>
    setReportsToAdd((curr) => [...curr, reportContract]);
  const cancelAddReport = (contractId: string) =>
    setReportsToAdd((curr) => [
      ...curr.filter((c) => c.reportContractId !== contractId),
    ]);

  return (
    <>
      <Drawer open={open} onClose={onClose} anchor="right">
        <AddReportsContent
          organizationId={organizationId}
          contractId={contractId}
          onClose={onClose}
          selectedContracts={reportsToAdd}
          addReport={addReport}
          cancelAddReport={cancelAddReport}
          submit={() => {
            setDialogKey(Date.now());
            setIsDialogOpen(true);
          }}
        />
      </Drawer>
      <AddReportsDialog
        key={dialogKey}
        open={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onSuccess={(showSnackbar: boolean) => {
          onSuccess(showSnackbar);
          setIsDialogOpen(false);
        }}
        newManagerContract={newManagerContract}
        reportsToAdd={reportsToAdd}
      />
    </>
  );
}

function AddReportsContent({
  organizationId,
  contractId,
  onClose,
  selectedContracts,
  addReport,
  cancelAddReport,
  submit,
}: {
  organizationId: string;
  contractId: string;
  onClose: () => void;
  selectedContracts: AddReportEntry[];
  addReport: (reportContract: AddReportEntry) => void;
  cancelAddReport: (contractId: string) => void;
  submit: () => void;
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState<ReportsTab>(reportsTabs.all);

  return (
    <Box
      display="flex"
      flexDirection="column"
      pt={5}
      pb={2}
      px={5}
      height="100%"
      width="700px"
      boxSizing="border-box"
      gap={3}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontWeight="700">
          Adicionar liderado
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={0.5}
          borderRadius={1}
          onClick={onClose}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'background.default',
            },
            '&:active': {
              backgroundColor: 'strokes.heavy',
            },
          }}
        >
          <IconX size={24} />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={1.5}
        sx={{
          flexGrow: 0,
        }}
      >
        <SearchBar
          searchProps={{ searchTerm, setSearchTerm }}
          sx={{ width: '320px' }}
          placeholder="Procurar"
        />
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value)}>
            <Tab label="Todos" value={reportsTabs.all} />
            <Tab label="Colaboradores" value={reportsTabs.employees} />
            <Tab
              label="Prestadores de serviço"
              value={reportsTabs.contractors}
            />
          </Tabs>
        </Box>
      </Box>
      <Box overflow="hidden" flexGrow={1} pb={9}>
        <AddReportsTable
          organizationId={organizationId}
          contractId={contractId}
          searchTerm={searchTerm}
          activeTab={activeTab}
          selectedContracts={selectedContracts}
          addReport={addReport}
          cancelAddReport={cancelAddReport}
        />
      </Box>
      <Box
        py={1}
        px={3}
        gap={3}
        boxSizing="border-box"
        display="flex"
        alignItems="center"
        justifyContent="end"
        sx={(theme) => ({
          background: 'rgba(247, 247, 247, 0.90)',
          borderTop: `1px solid ${theme.palette.strokes.light}`,
          'backdrop-filter': 'blur(4px)',
          position: 'fixed',
          bottom: 0,
          right: 0,
          width: '700px',
        })}
      >
        {selectedContracts.length > 0 && (
          <Typography variant="caption" color="text.secondary">
            {selectedContracts.length} pessoa
            {selectedContracts.length > 1 && 's'} selecionada
            {selectedContracts.length > 1 && 's'}
          </Typography>
        )}
        <Box display="flex" gap={1}>
          <Button color="secondary" size="large" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            color="primaryAlt"
            size="large"
            onClick={submit}
            disabled={selectedContracts.length === 0}
          >
            Adicionar
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

const reportsTabs = {
  all: 'all',
  employees: 'employees',
  contractors: 'contractors',
} as const;

type ReportsTab = keyof typeof reportsTabs;

function AddReportsTable({
  organizationId,
  contractId,
  searchTerm,
  activeTab,
  selectedContracts,
  addReport,
  cancelAddReport,
}: {
  organizationId: string;
  contractId: string;
  searchTerm: string;
  activeTab: ReportsTab;
  selectedContracts: Readonly<AddReportEntry[]>;
  addReport: (reportContract: AddReportEntry) => void;
  cancelAddReport: (contractId: string) => void;
}) {
  const { mutate, isLoading, data, isError, error } = useSearchAllContracts();
  const { sortingProps, paginationProps } = useDataGrid({
    pagination: {
      initialRowsPerPageOptionIndex: 2,
    },
  });

  useEffect(() => {
    paginationProps.handleChangePage(null, 0);
  }, [paginationProps, searchTerm]);

  useEffect(() => {
    if (organizationId && contractId) {
      mutate({
        pathParams: {
          organizationId,
        },
        body: {
          ...(searchTerm ? { query: searchTerm } : {}),
          filtering: {
            elements: {
              contractId: [{ not: contractId }],
              manager: [{ not: contractId }],
              status: [{ not: contractStatuses.terminated }],
              ...(activeTab !== reportsTabs.all
                ? {
                    contractType:
                      activeTab === reportsTabs.employees
                        ? [contractTypes.brClt]
                        : [contractTypes.brPj],
                  }
                : {}),
            },
          },
          sorting: {
            field: 'name',
            order: 'asc',
          },
          pagination: {
            page: paginationProps.page,
            size: paginationProps.rowsPerPage,
          },
        } as SearchInput,
      });
    }
  }, [
    organizationId,
    contractId,
    searchTerm,
    activeTab,
    paginationProps.page,
    paginationProps.rowsPerPage,
    mutate,
  ]);

  if (isLoading || !data) {
    return <Skeleton variant="rounded" height="100%" />;
  }

  if (isError) {
    console.error(
      `Failed to load contracts to add as reports: ${JSON.stringify(error)}`,
    );
    return (
      <>
        <BackButton destination="/people" />
        <PageAlert
          message="Erro ao carregar dados de contratos"
          severity="error"
          showRetryMessage={true}
        />
      </>
    );
  }

  return (
    <Box
      sx={{
        scrollbarWidth: 'thin',
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        overflowY: 'auto',
        width: 'calc(100% - 5px)',
        pr: 0.5,
        justifyContent: 'center',
      }}
    >
      <DataGrid
        sortingProps={sortingProps}
        paginationProps={paginationProps}
        totalRowCount={data.total || 0}
        getRowId={(row) => row.contractId}
        rows={data.data}
        columns={prepareColumns({
          selectedContracts,
          addReport,
          cancelAddReport,
        })}
        emptyMessage="Nenhum colaborador encontrado."
      />
    </Box>
  );
}

function prepareColumns({
  selectedContracts,
  addReport,
  cancelAddReport,
}: {
  selectedContracts: Readonly<AddReportEntry[]>;
  addReport: (reportContract: AddReportEntry) => void;
  cancelAddReport: (reportContractId: string) => void;
}): GridColDef<ContractSummary>[] {
  return [
    {
      field: 'name',
      headerName: 'Nome',
      renderHeader: (params) => {
        return <Box ml={2}>{params.field}</Box>;
      },
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.name;
      },
      renderCell: ({ value, row }) => {
        return (
          <Stack direction="row" spacing={0}>
            <Checkbox
              checked={
                !!selectedContracts.find(
                  ({ reportContractId }) => reportContractId === row.contractId,
                )
              }
              onChange={(_, checked) =>
                checked
                  ? addReport({
                      reportContractId: row.contractId,
                      reportName: row.name,
                      reportProfileUrl: row.pictureUrl,
                      oldManagerContractId: row.manager,
                    })
                  : cancelAddReport(row.contractId)
              }
              sx={{}}
            />
            <UserAvatar
              name={value}
              pictureUrl={row.pictureUrl}
              expandNameOnHover={true}
              sx={{
                '--UserAvatar-name-max-width': '12.5em',
              }}
              avatarProps={{
                ml: 0.25,
              }}
            />
          </Stack>
        );
      },
    },
    {
      field: 'titleName',
      headerName: 'Cargo',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.titleName;
      },
      renderCell: ({ value }) => {
        return <ExpandableTypography>{value}</ExpandableTypography>;
      },
    },
  ];
}
