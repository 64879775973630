import { ReactElement, useState } from 'react';

import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import { Box, Typography } from '@mui/material';

import { RecordProps } from './Record';

export type RecordGroupProps = {
  title: string;
  showExpandIcon?: boolean;
  children: ReactElement<RecordProps> | ReactElement<RecordProps>[];
};

export function RecordGroup({
  title,
  showExpandIcon,
  children,
}: RecordGroupProps) {
  const [expanded, setExpanded] = useState(true);
  return (
    <Box
      display="flex"
      flexDirection="column"
      data-testid="person-personal-details"
      gap={1.5}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        {...(showExpandIcon
          ? {
              onClick: () => setExpanded((state) => !state),
              sx: { cursor: 'pointer' },
            }
          : {})}
      >
        <Typography variant="h3" fontWeight="700" px={1}>
          {title}
        </Typography>
        {showExpandIcon && (
          <ExpandLessOutlinedIcon
            sx={{
              height: '24px',
              width: '24px',
              transition: 'all 0.5s',
              ...(!expanded && { transform: 'scaleY(-1)' }),
            }}
          />
        )}
      </Box>
      {expanded && children}
    </Box>
  );
}
