import React from 'react';

import { BeachAccessOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Box, Container } from '@mui/system';

import { Button } from '@octopus/ui/design-system';

import { BackButton } from '../../../modules/components/BackButton';

import VacationsGroupScheduleTable from './ScheduleTable';

type VacationsGroupScheduleProps = {
  organizationId: string;
};

export const VacationsGroupSchedules = ({
  organizationId,
}: VacationsGroupScheduleProps) => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        py: 9,
        px: 10,
      }}
    >
      <Box>
        <BackButton />
      </Box>
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          data-testid="group-vacations-header"
          gap={1}
          mb={5}
        >
          <BeachAccessOutlined
            sx={{ height: '40px', width: '40px', marginRight: 1.5 }}
          />
          <Typography variant="h1">Férias Coletivas</Typography>
          <Box ml="auto">
            <Button to="/vacations-group/new">Nova</Button>
          </Box>
        </Box>

        <Box>
          <VacationsGroupScheduleTable organizationId={organizationId} />
        </Box>
      </Container>
    </Box>
  );
};
