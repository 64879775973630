import type { Rubricas } from './rubricas';

/*******************************************************************************
 * Ao atualizar essa tabela, é preciso enviar as mudanças para o eSocial.
 * É preciso fazer isso para cada company em que somos primários no eSocial.
 * Existe um tako-cli sendRubricsToESocial para isso.
 *******************************************************************************/
const rubricasProprietarias = {
  padrao2930a: {
    codigo: 'padrao2930a',
    nome: 'Desconto do Saldo Devedor',
    natureza: '2930',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente ao saldo devedor de um recibo de pagamento passado.',
    nota: '',

    incideNoCustoTotal: true,
  },
  padrao5501a: {
    codigo: 'padrao5501a',
    nome: 'Adiantamento de salário',
    natureza: '5501',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Valor referente ao pagamento de adiantamento/antecipação de salário, quando pagamento é no mesmo mês do pagamento do salário.',
    nota: '',
  },
  padrao5165a: {
    codigo: 'padrao5165a',
    nome: 'Desconto Plano Odontológico',
    natureza: '9219',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente à participação do empregado relativo à assistência odontológica proveniente de convênio feito para esse fim.',
    nota: '',
  },
  padrao0000a: {
    codigo: 'padrao0000a',
    nome: 'Desconto de dias afastados sem remuneração',
    natureza: '',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao: 'Valor referente aos dias afastados sem remuneração.',
    nota: '',
  },
  padrao0000b: {
    codigo: 'padrao0000b',
    nome: 'Provento de dias afastados sem remuneração',
    natureza: '',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao: 'Valor referente aos dias afastados sem remuneração.',
    nota: '',
  },
  padrao1050a: {
    codigo: 'padrao1050a',
    nome: 'Remuneração de faltas justificadas com desobrigação de contribuição previdenciária',
    natureza: '1050',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Remuneração de dias nos quais o trabalhador esteja afastado do trabalho sem prejuízo de sua remuneração. Primeiros 15 dias que antecedem afastamento previdenciário.',
    nota: '',
  },
  padrao1350a: {
    codigo: 'padrao1350a',
    nome: 'Estagiários - Recesso Remunerado',
    natureza: '1350',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '11',
    codIncFGTS: '00',
    descricao: 'Valores pagos ao estagiário a título de recesso remunerado.',
    nota: '',
  },
  padrao9299a: {
    codigo: 'padrao9299a',
    nome: 'Outros descontos',
    natureza: '9299',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao: 'Outros descontos não previstos nos demais itens.',
    nota: '',
  },
  padrao1002a: {
    codigo: 'padrao1002a',
    nome: 'DSR - Descanso Semanal Remunerado - sobre horas interjornada',
    natureza: '1002',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '00',
    descricao:
      'Valor referente ao reflexo das horas interjornada devidas no mês sobre o DSR.',
    nota: '',
  },
  padrao1002b: {
    codigo: 'padrao1002b',
    nome: 'DSR - Descanso Semanal Remunerado - sobre horas intrajornada',
    natureza: '1002',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente ao reflexo das horas intrajornada devidas no mês sobre o DSR.',
    nota: '',
  },
  padrao9208a: {
    codigo: 'padrao9208a',
    nome: 'Desconto de banco de horas',
    natureza: '9208',
    tipo: '2',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Desconto referente às horas negativas no fechamento de banco de horas.',
    nota: '',

    incideNoCustoTotal: true,
  },
  padrao1050b: {
    codigo: 'padrao1050b',
    nome: 'Remuneração de horas de atestado',
    natureza: '1050',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Remuneração de horas de falta justificadas com atestado médico.',
    nota: '',
  },
  padrao9222a: {
    codigo: 'padrao9222a',
    nome: 'Imposto Sobre Serviço - Municipal',
    natureza: '9222',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao: 'Imposto municipal sobre prestação de serviços',
    nota: '',
  },
  padrao1000a: {
    codigo: 'padrao1000a',
    nome: 'Licença Remunerada',
    natureza: '1000',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente aos dias de licença remunerada concedidos ao empregado.',
    nota: '',
  },
  padrao4050a: {
    codigo: 'padrao4050a',
    nome: 'Prorrogação licença maternidade (Empresa Cidadã)',
    natureza: '4050',
    tipo: '1',
    codIncCP: '15',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente à prorrogação da licença maternidade concedida pela Empresa Cidadã.',
    nota: '',
  },
  padrao4051a: {
    codigo: 'padrao4051a',
    nome: 'Prorrogação licença maternidade (Empresa Cidadã) - 13º Salário',
    natureza: '4051',
    tipo: '1',
    codIncCP: '16',
    codIncIRRF: '12',
    codIncFGTS: '12',
    descricao:
      'Valor correspondente ao salário de contribuição da empregada de Empresa Cidadã - no mês de dezembro - cujo pagamento ocorrerá até o dia 20 de dezembro.',
    nota: '',
  },
} as const satisfies Rubricas;

const rubricasESocial = {
  eSocial1000: {
    codigo: 'eSocial1000',
    nome: 'Salário',
    natureza: '1000',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente ao salário mensal do empregado, ou dos dias trabalhados no mês em caso de contratação por hora, dia ou semana.',
    nota: '',
  },
  eSocial1010: {
    codigo: 'eSocial1010',
    nome: 'Salário in natura - pagos em bens ou serviços',
    natureza: '1010',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor do salário in natura, também conhecido por salário utilidade, que correspondente ao valor pago ao trabalhador em bens ou serviços.',
    nota: '',
  },
  eSocial1020: {
    codigo: 'eSocial1020',
    nome: 'Comissões, percentagens ou produção',
    natureza: '1207',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor pago ao trabalhador pela contraprestação de serviço, em decorrência de uma produção alcançada, normalmente baseada em um percentual sobre as vendas totais desse trabalhador.',
    nota: '',
  },
  eSocial1030: {
    codigo: 'eSocial1030',
    nome: 'Sobreaviso',
    natureza: '1011',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor pago ao empregado, correspondente a um percentual da sua hora normal de trabalho, estipulado em lei ou instrumento coletivo.',
    nota: '',
  },
  eSocial1040: {
    codigo: 'eSocial1040',
    nome: 'Adiantamento de salário',
    natureza: '5501',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '11',
    codIncFGTS: '00',
    descricao:
      'Valor referente ao pagamento de adiantamento/antecipação de salário.',
    nota: '',
  },
  eSocial1050: {
    codigo: 'eSocial1050',
    nome: 'Remuneração de faltas justificadas',
    natureza: '1050',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Remuneração de dias nos quais o trabalhador esteja afastado do trabalho sem prejuízo de sua remuneração',
    nota: '',
  },
  eSocial1060: {
    codigo: 'eSocial1060',
    nome: 'Remuneração do dirigente sindical',
    natureza: '1230',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Remuneração paga ao trabalhador afastado, durante o exercício da atividade sindical',
    nota: '',
  },
  eSocial1098: {
    codigo: 'eSocial1098',
    nome: 'Remuneração de autônomo',
    natureza: '1000',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '00',
    descricao: 'Valor referente à remuneração de trabalhador autônomo.',
    nota: '',
  },
  eSocial1099: {
    codigo: 'eSocial1099',
    nome: 'Remuneração mensal',
    natureza: '1000',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor total da remuneração já considerando todos os vencimentos e deduções, exceto 13° salário, utilizado na Folha/Recebimentos e Pagamentos vigente no eSocial até 04/2016.',
    nota: '',
  },
  eSocial1100: {
    codigo: 'eSocial1100',
    nome: 'Horas extras',
    natureza: '1003',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente às horas trabalhadas além da jornada contratada acrescidas de percentual, nunca inferior a 50%.',
    nota: '',
  },
  eSocial1101: {
    codigo: 'eSocial1101',
    nome: 'Horas extras noturnas',
    natureza: '1003',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente às horas extras trabalhadas em período noturno acrescidas de percentual, nunca inferior a 50%.',
    nota: '',
  },
  eSocial1102: {
    codigo: 'eSocial1102',
    nome: 'Horas extras em dobro',
    natureza: '1003',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente ao dobro das horas extras, em razão de trabalho em DSR realizado além da jornada diária normal.',
    nota: '',
  },
  eSocial1103: {
    codigo: 'eSocial1103',
    nome: 'Horas extras noturnas em dobro',
    natureza: '1003',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente ao dobro das horas extras noturnas, em razão de trabalho em DSR realizado além da jornada diária normal.',
    nota: '',
  },
  eSocial1110: {
    codigo: 'eSocial1110',
    nome: 'Horas extras sobre comissões',
    natureza: '1003',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor correspondente ao percentual nunca inferior a 50%, estipulado em acordo ou convenção coletiva, incidente sobre o valor da hora de trabalho do empregado sobre comissões.',
    nota: '',
  },
  eSocial1120: {
    codigo: 'eSocial1120',
    nome: 'Horas extras - Banco de Horas',
    natureza: '1004',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente às horas extras, inicialmente destinadas para o banco de horas, e que não foram compensadas.',
    nota: '',
  },
  eSocial1130: {
    codigo: 'eSocial1130',
    nome: 'Adicional noturno',
    natureza: '1205',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente ao adicional por trabalho realizado em período noturno, nunca inferior a 20% do valor da hora normal para trabalhador urbano/doméstico e a 25% para trabalhador rural.',
    nota: '',
  },
  eSocial1131: {
    codigo: 'eSocial1131',
    nome: 'Adicional noturno em dobro',
    natureza: '1205',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente ao dobro do adicional noturno, em razão de trabalho em período noturno realizado em DSR.',
    nota: '',
  },
  eSocial1140: {
    codigo: 'eSocial1140',
    nome: 'Intervalo para repouso e alimentação, quando não concedido integralmente',
    natureza: '1006',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '00',
    descricao:
      'Valor referente à indenização do intervalo para repouso e alimentação, quando não concedido integralmente, acrescida de percentual nunca inferior a 50%.',
    nota: '',
  },
  eSocial1150: {
    codigo: 'eSocial1150',
    nome: 'Intervalo entre duas jornadas de trabalho, quando não concedido integralmente',
    natureza: '1006',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '00',
    descricao:
      'Valor referente à indenização das horas não concedidas do intervalo mínimo entre duas jornadas de trabalho, acrescida de percentual nunca inferior a 50%.',
    nota: '',
  },
  eSocial1200: {
    codigo: 'eSocial1200',
    nome: 'DSR - Descanso Semanal Remunerado',
    natureza: '1002',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente ao salário dos dias de descanso semanal e feriados do mês pago ao empregado contratado por hora, dia ou semana, ou referente ao DSR devido na rescisão quando da última semana integralmente trabalhada pelo empregado contratado por mês ou quinzena.',
    nota: '',
  },
  eSocial1210: {
    codigo: 'eSocial1210',
    nome: 'DSR - Descanso Semanal Remunerado - sobre Horas Extras',
    natureza: '1002',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente ao reflexo das horas extras devidas no mês sobre o DSR.',
    nota: '',
  },
  eSocial1211: {
    codigo: 'eSocial1211',
    nome: 'DSR - Descanso Semanal Remunerado - sobre Adicional Noturno',
    natureza: '1002',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente ao reflexo do adicional noturno devido no mês sobre o DSR.',
    nota: '',
  },
  eSocial1212: {
    codigo: 'eSocial1212',
    nome: 'DSR - Descanso Semanal Remunerado - sobre Adicional de Viagem do empregado doméstico',
    natureza: '1002',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente ao reflexo do adicional de viagem do empregado doméstico devido no mês sobre o DSR.',
    nota: '',
  },
  eSocial1213: {
    codigo: 'eSocial1213',
    nome: 'DSR - Descanso Semanal Remunerado - sobre comissões, percentagens',
    natureza: '1002',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor correspondente a um dia de trabalho do empregado, incidente sobre a média das verbas de natureza variável, especificamente comissões e percentagens',
    nota: '',
  },
  eSocial1220: {
    codigo: 'eSocial1220',
    nome: 'Descanso semanal e/ou feriado trabalhados',
    natureza: '1002',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente ao dobro do salário, relativo ao trabalho não compensado prestado em dias de descanso semanal e feriados.',
    nota: '',
  },
  eSocial1300: {
    codigo: 'eSocial1300',
    nome: 'Adicional de insalubridade',
    natureza: '1202',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor correspondente ao adicional por serviços prestados em ambientes insalubres.',
    nota: '',
  },
  eSocial1310: {
    codigo: 'eSocial1310',
    nome: 'Adicional de periculosidade',
    natureza: '1203',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor correspondente ao adicional por serviços prestados em condições perigosas.',
    nota: '',
  },
  eSocial1320: {
    codigo: 'eSocial1320',
    nome: 'Adicional por tempo de serviço',
    natureza: '1206',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente ao adicional pago ao trabalhador em virtude do tempo de serviço para o mesmo empregador - convenção coletiva / estatuto da empresa (anuênio, quinquênio etc.).',
    nota: '',
  },
  eSocial1330: {
    codigo: 'eSocial1330',
    nome: 'Adicional de transferência',
    natureza: '1204',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor pago ao empregado em razão de alteração do local de trabalho que resulte em mudança de residência',
    nota: '',
  },
  eSocial1340: {
    codigo: 'eSocial1340',
    nome: 'Adicional de viagem do empregado doméstico',
    natureza: '1204',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente ao adicional de viagem do empregado doméstico, nunca inferior a 25% do valor da hora normal.',
    nota: '',
  },
  eSocial1350: {
    codigo: 'eSocial1350',
    nome: 'Outros adicionais',
    natureza: '1299',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente a outros adicionais não previstos nos itens anteriores.',
    nota: '',
  },
  eSocial1400: {
    codigo: 'eSocial1400',
    nome: 'Quebra de caixa',
    natureza: '1225',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor pago para cobrir os riscos assumidos pelo empregado que trabalha com manuseio de valores, para compensar eventuais descontos ou diferenças de numerários.',
    nota: '',
  },
  eSocial1410: {
    codigo: 'eSocial1410',
    nome: 'Gratificações',
    natureza: '1211',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao: 'Valor referente a gratificações pagas ao trabalhador.',
    nota: '',
  },
  eSocial1420: {
    codigo: 'eSocial1420',
    nome: 'Gratificação de função',
    natureza: '1201',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor pago ao empregado por ocupar cargo ou função comissionada',
    nota: '',
  },
  eSocial1430: {
    codigo: 'eSocial1430',
    nome: 'Prêmios',
    natureza: '2501',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '11',
    codIncFGTS: '00',
    descricao: 'Valor referente a prêmios diversos pagos ao trabalhador.',
    nota: '',
  },
  eSocial1440: {
    codigo: 'eSocial1440',
    nome: 'Gratificação por acordo ou convenção coletiva',
    natureza: '1210',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao: 'Verba estabelecida em acordo ou convenção coletiva de trabalho',
    nota: '',
  },
  eSocial1500: {
    codigo: 'eSocial1500',
    nome: 'Gorjetas',
    natureza: '1209',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente às gorjetas pagas por clientes diretamente ao empregado, ou repassadas pelo empregador.',
    nota: '',
  },
  eSocial1510: {
    codigo: 'eSocial1510',
    nome: 'Direitos intelectuais',
    natureza: '2510',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '11',
    codIncFGTS: '00',
    descricao:
      'Valor pago ao empregado em razão de sua participação em produção científica, intelectual ou artística.',
    nota: '',
  },
  eSocial1520: {
    codigo: 'eSocial1520',
    nome: 'Gueltas',
    natureza: '1208',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor pago pelo fabricante (ou prestador de serviço) ao empregado vendedor (de outra empresa) como incentivo pela venda de produto, marca ou serviço.',
    nota: '',
  },
  eSocial1550: {
    codigo: 'eSocial1550',
    nome: 'Vale-Transporte - Adiantamento pago em dinheiro',
    natureza: '1810',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Valor pago antecipadamente ao empregado, em dinheiro, para custeio das passagens decorrentes do deslocamento residência-trabalho e vice-versa.',
    nota: '',
  },
  eSocial1561: {
    codigo: 'eSocial1561',
    nome: 'Alimentação - Pecúnia',
    natureza: '1800',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao: 'Alimentação - Pecúnia',
    nota: '',
  },
  eSocial1562: {
    codigo: 'eSocial1562',
    nome: 'Alimentação - Ticket ou cartão - Integrantes do PAT',
    natureza: '1806',
    tipo: '3',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao: 'Alimentação - Ticket ou cartão - Integrantes do PAT',
    nota: '',
  },
  eSocial1563: {
    codigo: 'eSocial1563',
    nome: 'Alimentação - Ticket ou cartão - Não integrante do PAT',
    natureza: '1807',
    tipo: '3',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '11',
    descricao: 'Alimentação - Ticket ou cartão - Não integrante do PAT',
    nota: '',
  },
  eSocial1564: {
    codigo: 'eSocial1564',
    nome: 'Alimentação - Cesta básica ou refeição - Integrantes do PAT',
    natureza: '1808',
    tipo: '3',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao: 'Alimentação - Cesta básica ou refeição - Integrantes do PAT',
    nota: '',
  },
  eSocial1565: {
    codigo: 'eSocial1565',
    nome: 'Alimentação - Cesta básica ou refeição - Não integrante do PAT',
    natureza: '1809',
    tipo: '3',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '11',
    descricao: 'Alimentação - Cesta básica ou refeição - Não integrante do PAT',
    nota: '',
  },
  eSocial1570: {
    codigo: 'eSocial1570',
    nome: 'Vestuário e equipamentos',
    natureza: '2903',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Valor correspondente a vestuários, equipamentos e outros acessórios fornecidos ao trabalhador e utilizados no local de trabalho para prestação dos respectivos serviços',
    nota: '',
  },
  eSocial1600: {
    codigo: 'eSocial1600',
    nome: 'Abonos',
    natureza: '1401',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '11',
    codIncFGTS: '00',
    descricao:
      'Valor referente ao abono concedido de forma espontânea pelo empregador ou em virtude de acordo ou convenção coletiva, norma, etc.',
    nota: '',
  },
  eSocial1610: {
    codigo: 'eSocial1610',
    nome: 'Abono PIS/PASEP',
    natureza: '1402',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Valor do abono e/ou rendimento do PIS / PASEP repassado pela empresa',
    nota: '',
  },
  eSocial1620: {
    codigo: 'eSocial1620',
    nome: 'Abono legal',
    natureza: '1403',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '11',
    codIncFGTS: '00',
    descricao:
      'Valor referente às importâncias relativas a título de ganhos eventuais e a abonos expressamente desvinculados do salário, por força da lei.',
    nota: '',
  },
  eSocial1630: {
    codigo: 'eSocial1630',
    nome: 'Auxílio babá',
    natureza: '1404',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Valor relativo a reembolso de despesas com babá, limitado ao menor salário de contribuição mensal e condicionado à comprovação na CTPS da empregada, do pagamento da remuneração e do recolhimento da contribuição previdenciária, observado o limite de 6 anos de idade da criança. Caso haja previsão em ACT/CCT, este limite de idade poderá ser maior.',
    nota: '',
  },
  eSocial1640: {
    codigo: 'eSocial1640',
    nome: 'Assistência médica',
    natureza: '1405',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Valor pago diretamente ao trabalhador a título de assistência médica ou odontológica, inclusive o reembolso de despesas com medicamentos, óculos, aparelhos ortopédicos, despesas médico-hospitalares e outras similares',
    nota: '',
  },
  eSocial1650: {
    codigo: 'eSocial1650',
    nome: 'Auxílio-creche',
    natureza: '1406',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'O reembolso creche pago em conformidade com a legislação trabalhista, observado o limite máximo de 6 (seis) anos de idade da criança, quando devidamente comprovadas as despesas realizadas. Caso haja previsão em acordo coletivo da categoria, este limite de idade poderá ser maior.',
    nota: '',
  },
  eSocial1700: {
    codigo: 'eSocial1700',
    nome: 'Salário maternidade',
    natureza: '4050',
    tipo: '1',
    codIncCP: '21',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor correspondente ao salário de contribuição mensal da empregada, pago em virtude da licença remunerada por aborto ou maternidade, pelo prazo previsto em lei.',
    nota: '',
  },
  eSocial1701: {
    codigo: 'eSocial1701',
    nome: 'Salário maternidade (pago pelo INSS)',
    natureza: '9930',
    tipo: '3',
    codIncCP: '25',
    codIncIRRF: '9',
    codIncFGTS: '11',
    descricao:
      'Valor referente à remuneração mensal da empregada, afastada em virtude de licença-maternidade.',
    nota: '',
  },
  eSocial1710: {
    codigo: 'eSocial1710',
    nome: 'Salário maternidade - 13º Salário',
    natureza: '4051',
    tipo: '1',
    codIncCP: '22',
    codIncIRRF: '12',
    codIncFGTS: '12',
    descricao:
      'Valor correspondente ao salário de contribuição da empregada - no mês de dezembro - cujo pagamento ocorrerá até o dia 20 de dezembro.',
    nota: '',
  },
  eSocial1711: {
    codigo: 'eSocial1711',
    nome: 'Salário maternidade - 13º Salário (pago pelo INSS)',
    natureza: '9931',
    tipo: '3',
    codIncCP: '26',
    codIncIRRF: '9',
    codIncFGTS: '12',
    descricao:
      'Valor referente à remuneração mensal da empregada, afastada em virtude de licença-maternidade, proporcional aos meses de afastamento no ano.',
    nota: '',
  },
  eSocial1720: {
    codigo: 'eSocial1720',
    nome: 'Salário-família',
    natureza: '1409',
    tipo: '1',
    codIncCP: '51',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Valor referente ao benefício previdenciário devido em virtude da faixa salarial e do número de filhos ou equiparados menores de 14 anos, ou inválidos de qualquer idade.',
    nota: '',
  },
  eSocial1721: {
    codigo: 'eSocial1721',
    nome: 'Diferença de Salário-família retroativo',
    natureza: '1409',
    tipo: '1',
    codIncCP: '51',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Valor referente à diferença de Salário-família relativa a mês(es) anterior(es), paga retroativamente.',
    nota: '',
  },
  eSocial1730: {
    codigo: 'eSocial1730',
    nome: 'Salário-família - Complemento',
    natureza: '1009',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor excedente ao valor do salário-família fixado pela Previdência Social, pago pelo empregador.',
    nota: '',
  },
  eSocial1740: {
    codigo: 'eSocial1740',
    nome: 'Salário base afastamento acidentário',
    natureza: '9932',
    tipo: '3',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '11',
    descricao:
      'Valor referente à remuneração mensal do empregado, afastado em virtude de auxílio-doença por acidente do trabalho ou doença ocupacional.',
    nota: '',
  },
  eSocial1745: {
    codigo: 'eSocial1745',
    nome: 'Salário base afastamento acidentário - 13º Salário',
    natureza: '9932',
    tipo: '3',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '12',
    descricao:
      'Valor referente à remuneração mensal do empregado, afastado em virtude de auxílio-doença por acidente do trabalho ou doença ocupacional, proporcional aos meses de afastamento no ano.',
    nota: '',
  },
  eSocial1750: {
    codigo: 'eSocial1750',
    nome: 'Salário base do serviço militar obrigatório',
    natureza: '9905',
    tipo: '3',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '11',
    descricao:
      'Valor referente à remuneração mensal do empregado, afastado para prestação de serviço militar obrigatório.',
    nota: '',
  },
  eSocial1755: {
    codigo: 'eSocial1755',
    nome: 'Salário base do serviço militar obrigatório - 13º Salário',
    natureza: '9905',
    tipo: '3',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '12',
    descricao:
      'Valor referente à remuneração mensal do empregado, afastado para prestação de serviço militar obrigatório, proporcional aos meses de afastamento no ano.',
    nota: '',
  },
  eSocial1800: {
    codigo: 'eSocial1800',
    nome: '13º salário - Adiantamento',
    natureza: '5504',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '12',
    descricao:
      'Valor referente ao adiantamento do 13º salário, cujo pagamento deve ocorrer entre fevereiro e novembro.',
    nota: '',
  },
  eSocial1810: {
    codigo: 'eSocial1810',
    nome: '13º salário',
    natureza: '5001',
    tipo: '1',
    codIncCP: '12',
    codIncIRRF: '12',
    codIncFGTS: '12',
    descricao:
      'Valor referente à gratificação salarial, equivalente à remuneração do mês de dezembro, cujo pagamento deve ocorrer até 20 de dezembro.',
    nota: '',
  },
  eSocial1820: {
    codigo: 'eSocial1820',
    nome: 'Diferença de reflexo da remuneração variável no 13º salário',
    natureza: '5005',
    tipo: '1',
    codIncCP: '12',
    codIncIRRF: '12',
    codIncFGTS: '12',
    descricao:
      'Valor referente ao 13° salário complementar, devido a diferenças apuradas no mês de dezembro e não consideradas na folha de 13º salário, cujo pagamento deve ocorrer até 10 de janeiro do ano seguinte.',
    nota: '',
  },
  eSocial1830: {
    codigo: 'eSocial1830',
    nome: '13º salário - Parcela final paga em dezembro',
    natureza: '5504',
    tipo: '3',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '12',
    descricao:
      'Valor restante de 13º salário, subtraído os adiantamentos, pago em dezembro ao empregado para fins de recolhimento do FGTS.',
    nota: '',
  },
  eSocial1840: {
    codigo: 'eSocial1840',
    nome: 'Insuficiência de saldo',
    natureza: '2930',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Valor referente ao saldo devedor do recibo de pagamento ou do termo de rescisão, a fim de que o valor líquido não fique negativo.',
    nota: '',
  },
  eSocial1900: {
    codigo: 'eSocial1900',
    nome: 'Adiantamento de férias',
    natureza: '1020',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '13',
    codIncFGTS: '00',
    descricao:
      'Valor referente ao adiantamento da remuneração de férias, desconsiderado o terço constitucional.',
    nota: 'Observar que o calculo do IRRF sobre as férias é feito em calculo em separado. Artigo 29 da IN RFB 1500/2014.',
  },
  eSocial1901: {
    codigo: 'eSocial1901',
    nome: 'Adiantamento do adicional de 1/3 sobre férias',
    natureza: '1020',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '13',
    codIncFGTS: '00',
    descricao:
      'Valor referente ao adiantamento da remuneração do terço constitucional de férias.',
    nota: 'Observar que o calculo do IRRF sobre as férias é feito em calculo em separado. Artigo 29 da IN RFB 1500/2014.',
  },
  eSocial1910: {
    codigo: 'eSocial1910',
    nome: 'Férias - Gozadas no mês',
    natureza: '1020',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '13',
    codIncFGTS: '11',
    descricao:
      'Valor referente à remuneração a que o empregado faz jus na época da concessão de férias, desconsiderado o terço constitucional, proporcional aos dias de férias no mês.',
    nota: '',
  },
  eSocial1920: {
    codigo: 'eSocial1920',
    nome: 'Férias - Adicional 1/3 sobre férias gozadas no mês',
    natureza: '1020',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '13',
    codIncFGTS: '11',
    descricao:
      'Valor referente ao terço constitucional de férias gozadas no mês.',
    nota: '',
  },
  eSocial1930: {
    codigo: 'eSocial1930',
    nome: 'Férias - Abono pecuniário de férias',
    natureza: '1023',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '75',
    codIncFGTS: '00',
    descricao:
      'Valor referente à conversão em dinheiro de 1/3 dos dias de férias a que o empregado possui direito.',
    nota: 'Ato Declaratório PGFN nº 6, de 16 de novembro de 2006.',
  },
  eSocial1940: {
    codigo: 'eSocial1940',
    nome: 'Férias - Adicional 1/3 sobre abono pecuniário',
    natureza: '1023',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '13',
    codIncFGTS: '00',
    descricao:
      'Valor referente ao terço constitucional de férias, incidente sobre o abono pecuniário de férias no mês.',
    nota: 'Ato Declaratório PGFN nº 6, de 16 de novembro de 2006.',
  },
  eSocial1950: {
    codigo: 'eSocial1950',
    nome: 'Férias - O dobro na vigência do contrato',
    natureza: '1024',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '13',
    codIncFGTS: '00',
    descricao:
      'Valor referente à dobra da remuneração de férias gozadas no mês, por terem sido concedidas após o prazo legal, inclusive o terço constitucional.',
    nota: '',
  },
  eSocial1955: {
    codigo: 'eSocial1955',
    nome: 'Abono pecuniário de férias - O dobro na vigência do contrato',
    natureza: '1024',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '75',
    codIncFGTS: '00',
    descricao:
      'Valor referente à dobra do abono pecuniário de férias, por ter sido concedido após o prazo legal, inclusive o terço constitucional.',
    nota: '',
  },
  eSocial1975: {
    codigo: 'eSocial1975',
    nome: 'Diárias de viagem',
    natureza: '1650',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '72',
    codIncFGTS: '00',
    descricao: 'Valor pago ao empregado a título de diária de viagem.',
    nota: '',
  },
  eSocial1980: {
    codigo: 'eSocial1980',
    nome: 'Ajuda de custo de transferência (Parcela única)',
    natureza: '1602',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '73',
    codIncFGTS: '00',
    descricao:
      'Valor pago ao trabalhador em parcela única, em razão de transferência de seu local trabalho (art. 470 CLT).',
    nota: '',
  },
  eSocial1981: {
    codigo: 'eSocial1981',
    nome: 'Ajuda de custo',
    natureza: '1603',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '73',
    codIncFGTS: '00',
    descricao: 'Ajuda de custo paga ao trabalhador',
    nota: '',
  },
  eSocial1982: {
    codigo: 'eSocial1982',
    nome: 'Ressarcimento de despesas pelo uso de veículo próprio',
    natureza: '1620',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Ressarcimento de despesas ao trabalhador, pela utilização de veículo de sua propriedade',
    nota: '',
  },
  eSocial1983: {
    codigo: 'eSocial1983',
    nome: 'Ressarcimento de despesas de viagem, exceto despesas com veículos',
    natureza: '1621',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Ressarcimento de despesas pagas com recursos do trabalhador em viagens a trabalho',
    nota: '',
  },
  eSocial1984: {
    codigo: 'eSocial1984',
    nome: 'Ressarcimento de provisão',
    natureza: '1623',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Ressarcimento de desconto efetuado em recibos de férias relativo a provisão de contribuição previdenciária',
    nota: '',
  },
  eSocial1985: {
    codigo: 'eSocial1985',
    nome: 'Ressarcimento de outras despesas',
    natureza: '1629',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Ressarcimento de outras despesas pagas pelo trabalhador, não previstas nos itens anteriores',
    nota: '',
  },
  eSocial2000: {
    codigo: 'eSocial2000',
    nome: 'Ajuda Compensatória - MP 1.045/2021',
    natureza: '1619',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Ajuda compensatória paga pelo empregador ao empregado durante período de suspensão do contrato de trabalho ou redução proporcional de salário e jornada.',
    nota: '',
  },
  eSocial2030: {
    codigo: 'eSocial2030',
    nome: 'PLR - Participação nos lucros e resultados',
    natureza: '1300',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '14',
    codIncFGTS: '00',
    descricao:
      'Valor paga ao empregado relativo a participação nos lucros e resultados da empresa',
    nota: '',
  },
  eSocial2050: {
    codigo: 'eSocial2050',
    nome: 'Arredondamentos',
    natureza: '2999',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Valor lançado em folha de pagamento, não superior a 99 centavos, relativo a arrendamentos',
    nota: '',
  },
  eSocial2100: {
    codigo: 'eSocial2100',
    nome: 'Estagiários - Bolsa',
    natureza: '1350',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '11',
    codIncFGTS: '00',
    descricao:
      'Valor pago ao estagiário em atividades práticas de complementação do currículo escolar, inclusive os valores pagos a título de recesso remunerado. Lei nº 11.788 de 25/09/2008.',
    nota: '',
  },
  eSocial2110: {
    codigo: 'eSocial2110',
    nome: 'Numerário - Remuneração do contribuinte individual',
    natureza: '3501',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '00',
    descricao:
      'Valor referente ao pagamento dos serviços prestados à empresa pelo contribuinte individual',
    nota: '',
  },
  eSocial2120: {
    codigo: 'eSocial2120',
    nome: 'Honorários',
    natureza: '3501',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '00',
    descricao:
      'Valor pago ao profissional liberal que presta serviços diretamente à empresa.',
    nota: '',
  },
  eSocial2130: {
    codigo: 'eSocial2130',
    nome: 'Retiradas (pró-labore) de proprietários ou sócios',
    natureza: '3508',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '00',
    descricao:
      'Valor referente ao Pró-labore ou retirada (remuneração) paga a proprietários ou sócios da empresa.',
    nota: '',
  },
  eSocial2131: {
    codigo: 'eSocial2131',
    nome: 'Retiradas (pró-labore) de diretores (não empregados) optantes pelo FGTS',
    natureza: '3506',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente ao Pró-labore ou retirada (remuneração) paga a diretores não empregados.',
    nota: '',
  },
  eSocial3000: {
    codigo: 'eSocial3000',
    nome: 'Saldo de salários',
    natureza: '6000',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente aos dias trabalhados no mês da rescisão contratual, desconsideradas as faltas e o respectivo DSR.',
    nota: '',
  },
  eSocial3010: {
    codigo: 'eSocial3010',
    nome: '13º salário sobre o aviso prévio indenizado',
    natureza: '6001',
    tipo: '1',
    codIncCP: '12',
    codIncIRRF: '74',
    codIncFGTS: '21',
    descricao:
      'Valor referente ao 13º salário incidente sobre o aviso prévio indenizado, por ocasião da rescisão do contrato de trabalho.',
    nota: '',
  },
  eSocial3020: {
    codigo: 'eSocial3020',
    nome: '13º salário proporcional',
    natureza: '6002',
    tipo: '1',
    codIncCP: '12',
    codIncIRRF: '12',
    codIncFGTS: '12',
    descricao:
      'Valor referente ao 13º salário proporcional, por ocasião da rescisão do contrato de trabalho.',
    nota: '',
  },
  eSocial3030: {
    codigo: 'eSocial3030',
    nome: 'Aviso prévio indenizado',
    natureza: '6003',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '74',
    codIncFGTS: '21',
    descricao:
      'Valor referente à remuneração do aviso prévio indenizado, calculado de acordo com o tempo de serviço do empregado.',
    nota: '',

    // Customizado por nós
    incideNoCustoTotal: true,
  },
  eSocial3040: {
    codigo: 'eSocial3040',
    nome: 'Férias vencidas - em dobro - na rescisão',
    natureza: '6004',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '74',
    codIncFGTS: '00',
    descricao:
      'Valor referente à remuneração dobrada de férias, em virtude da não concessão no prazo legal, por ocasião da rescisão do contrato de trabalho.',
    nota: 'Ato Declaratório PGFN nº 14, de 2 de dezembro de 2008.',
  },
  eSocial3050: {
    codigo: 'eSocial3050',
    nome: 'Férias proporcionais',
    natureza: '6006',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '74',
    codIncFGTS: '00',
    descricao:
      'Valor referente a férias proporcionais, por ocasião da rescisão do contrato de trabalho.',
    nota: 'Ato Declaratório PGFN nº 5, de 16 de novembro de 2006.',
  },
  eSocial3060: {
    codigo: 'eSocial3060',
    nome: 'Férias vencidas',
    natureza: '6007',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '74',
    codIncFGTS: '00',
    descricao:
      'Valor referente a férias vencidas, mas dentro do período concessivo, por ocasião da rescisão do contrato de trabalho.',
    nota: 'Parecer PGFN CAT nº 2.956, de 2009, de 7 de abril de 2010, item 11. Citado no Parecer PGFN CAT nº 614, de 2010.',
  },
  eSocial3065: {
    codigo: 'eSocial3065',
    nome: 'Férias sobre o aviso prévio indenizado',
    natureza: '6006',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '74',
    codIncFGTS: '00',
    descricao:
      'Valor referente a férias incidentes sobre o aviso prévio indenizado, por ocasião da rescisão do contrato de trabalho.',
    nota: '',
  },
  eSocial3070: {
    codigo: 'eSocial3070',
    nome: 'Adicional de 1/3 sobre férias',
    natureza: '6007',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '74',
    codIncFGTS: '00',
    descricao:
      'Valor referente ao terço constitucional de férias vencidas, inclusive em dobro, e/ou proporcionais, inclusive sobre o aviso prévio indenizado.',
    nota: 'XI - adicional de 1/3 (um terço) previsto no inciso XVII do art. 7º da Constituição Federal, quando agregado a pagamento de férias - simples ou proporcionais - vencidas e não gozadas, convertidas em pecúnia, em razão de rescisão do contrato de trabalho (Ato Declaratório PGFN nº 6, de 1º de dezembro de 2008).',
  },
  eSocial3080: {
    codigo: 'eSocial3080',
    nome: 'Indenização do art. 9º da Lei 7.238/1984',
    natureza: '6102',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '74',
    codIncFGTS: '00',
    descricao:
      'Valor pago ao empregado a título de indenização quando a dispensa ocorrer sem justa causa dentro dos trinta dias que antecedem a data base.',
    nota: '',
  },
  eSocial3090: {
    codigo: 'eSocial3090',
    nome: 'Indenização do art. 14 da lei nº 5.889, de 8 de junho de 1973',
    natureza: '6103',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '74',
    codIncFGTS: '00',
    descricao:
      'Valor pago ao safrista, a título de indenização do tempo de serviço, importância correspondente a 1/12 (um doze avos) do salário mensal, por mês de serviço ou fração superior a 14 (quatorze) dias.',
    nota: '',
  },
  eSocial3100: {
    codigo: 'eSocial3100',
    nome: 'Indenização pela extinção antecipada do contrato a termo',
    natureza: '6104',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '74',
    codIncFGTS: '00',
    descricao:
      'Valor referente à indenização correspondente à metade da remuneração devida ao empregado até o termino do contrato por prazo determinado em caso de rescisão antecipada, por iniciativa do empregador.',
    nota: '',
  },
  eSocial3110: {
    codigo: 'eSocial3110',
    nome: 'Multa por atraso no pagamento da rescisão (art. 477, § 8º, da CLT)',
    natureza: '6106',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '74',
    codIncFGTS: '00',
    descricao:
      'Valor referente à indenização por atraso no pagamento de rescisão do contrato de trabalho.',
    nota: '',
  },
  eSocial3120: {
    codigo: 'eSocial3120',
    nome: 'Indenização por quebra de estabilidade',
    natureza: '6107',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '74',
    codIncFGTS: '00',
    descricao:
      'Valor referente à indenização por desligamento durante período de estabilidade legal, ou estabilidade derivada de acordo ou convenção coletiva de trabalho.',
    nota: '',
  },
  eSocial3200: {
    codigo: 'eSocial3200',
    nome: 'Indenização rescisória - Programa Emergencial de Manutenção do Emprego e da Renda',
    natureza: '6119',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Indenização pela dispensa sem justa causa que ocorrer durante o período de garantia provisória no emprego de que trata o Programa Emergencial de Manutenção do Emprego e da Renda.',
    nota: '',
  },
  eSocial3500: {
    codigo: 'eSocial3500',
    nome: 'Retroativo - Diferença de remuneração mensal',
    natureza: '1000',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Valor referente à diferença de remuneração mensal do empregado (exceto 13º salário e férias), relativa a mês(es) anterior(es), paga retroativamente.',
    nota: '',
  },
  eSocial3501: {
    codigo: 'eSocial3501',
    nome: 'Retroativo - Diferença de 13º salário',
    natureza: '5001',
    tipo: '1',
    codIncCP: '12',
    codIncIRRF: '12',
    codIncFGTS: '12',
    descricao:
      'Valor referente à diferença de 13º salário do empregado, relativa a ano atual ou anterior, paga retroativamente.',
    nota: '',
  },
  eSocial3502: {
    codigo: 'eSocial3502',
    nome: 'Retroativo - Diferença de férias gozadas',
    natureza: '1020',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '13',
    codIncFGTS: '11',
    descricao:
      'Valor referente à diferença de férias do empregado, desconsiderado o terço constitucional, relativa a mês(es) atual ou anterior(es), paga retroativamente.',
    nota: '',
  },
  eSocial3503: {
    codigo: 'eSocial3503',
    nome: 'Retroativo - Diferença de verbas indenizatórias',
    natureza: '6129',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Valor referente à diferença de verbas indenizatórias (sem natureza de remuneração), relativa a mês(es) anterior(es), paga retroativamente.',
    nota: '',
  },
  eSocial3504: {
    codigo: 'eSocial3504',
    nome: 'Retroativo - Diferença de salário maternidade (pago pelo INSS)',
    natureza: '9930',
    tipo: '3',
    codIncCP: '25',
    codIncIRRF: '9',
    codIncFGTS: '11',
    descricao:
      'Valor referente à diferença de remuneração mensal da empregada afastada em virtude de licença-maternidade, relativa a mês(es) anterior(es).',
    nota: '',
  },
  eSocial3505: {
    codigo: 'eSocial3505',
    nome: 'Retroativo - Diferença de salário maternidade - 13º salário (pago pelo INSS)',
    natureza: '9931',
    tipo: '3',
    codIncCP: '26',
    codIncIRRF: '9',
    codIncFGTS: '12',
    descricao:
      'Valor referente à diferença de remuneração mensal da empregada afastada em virtude de licença-maternidade, proporcional aos meses de afastamento no ano atual ou anterior.',
    nota: '',
  },
  eSocial3506: {
    codigo: 'eSocial3506',
    nome: 'Retroativo - Diferença de auxílio-doença acidentário (pago pelo INSS)',
    natureza: '9932',
    tipo: '3',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '11',
    descricao:
      'Valor referente à diferença de remuneração mensal do empregado afastado em virtude de auxílio-doença por acidente do trabalho ou doença ocupacional, relativa a mês(es) anterior(es).',
    nota: '',
  },
  eSocial3507: {
    codigo: 'eSocial3507',
    nome: 'Retroativo - Diferença de salário base do serviço militar obrigatório',
    natureza: '9905',
    tipo: '3',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '11',
    descricao:
      'Valor referente à diferença de remuneração mensal do empregado afastado para prestação de serviço militar obrigatório, relativa a mês(es) anterior(es).',
    nota: '',
  },
  eSocial3508: {
    codigo: 'eSocial3508',
    nome: 'Diferença de férias gozadas',
    natureza: '1020',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '13',
    codIncFGTS: '11',
    descricao:
      'Valor referente à diferença de férias, relativa a mês(es) atual ou anterior(es), paga retroativamente.',
    nota: '',
  },
  eSocial3509: {
    codigo: 'eSocial3509',
    nome: 'Diferença do adicional de 1/3 sobre férias gozadas',
    natureza: '1020',
    tipo: '1',
    codIncCP: '11',
    codIncIRRF: '13',
    codIncFGTS: '11',
    descricao:
      'Valor referente à diferença do terço constitucional de férias, relativa a mês(es) atual ou anterior(es), paga retroativamente.',
    nota: '',
  },
  eSocial3510: {
    codigo: 'eSocial3510',
    nome: 'Diferença de abono pecuniário de férias',
    natureza: '1023',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '75',
    codIncFGTS: '00',
    descricao:
      'Valor referente à diferença de abono pecuniário de férias, desconsiderado o terço constitucional, relativa a mês(es) atual ou anterior(es), paga retroativamente.',
    nota: 'Ato Declaratório PGFN nº 6, de 16 de novembro de 2006.',
  },
  eSocial3511: {
    codigo: 'eSocial3511',
    nome: 'Diferença do adicional de 1/3 sobre abono pecuniário',
    natureza: '1023',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '75',
    codIncFGTS: '00',
    descricao:
      'Valor referente à diferença do terço constitucional de férias incidente sobre o abono pecuniário de férias, relativa a mês(es) atual ou anterior(es), paga retroativamente.',
    nota: 'Ato Declaratório PGFN nº 6, de 16 de novembro de 2006.',
  },
  eSocial3512: {
    codigo: 'eSocial3512',
    nome: 'Diferença da dobra de férias na vigência do contrato',
    natureza: '1024',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '13',
    codIncFGTS: '00',
    descricao:
      'Valor referente à diferença da dobra da remuneração de férias gozadas, inclusive o terço constitucional, relativa a mês(es) atual ou anterior(es), paga retroativamente.',
    nota: '',
  },
  eSocial3513: {
    codigo: 'eSocial3513',
    nome: 'Diferença da dobra de abono pecuniário de férias',
    natureza: '1024',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '75',
    codIncFGTS: '00',
    descricao:
      'Valor referente à diferença da dobra do abono pecuniário de férias, inclusive o terço constitucional, relativa a mês(es) atual ou anterior(es), paga retroativamente.',
    nota: '',
  },
  eSocial5000: {
    codigo: 'eSocial5000',
    nome: 'Desconto do aviso prévio não cumprido',
    natureza: '6901',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente ao pedido de demissão do empregado sem cumprimento do aviso prévio.',
    nota: '',
  },
  eSocial5010: {
    codigo: 'eSocial5010',
    nome: 'Multa da extinção antecipada do contrato a termo',
    natureza: '6904',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente à rescisão antecipada do contrato de trabalho por prazo determinado, por iniciativa do empregado.',
    nota: '',
  },
  eSocial5020: {
    codigo: 'eSocial5020',
    nome: 'Desconto do adiantamento de salário',
    natureza: '9200',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '11',
    codIncFGTS: '00',
    descricao:
      'Valor referente ao desconto a título de adiantamento dos salários.',
    nota: '',
  },
  eSocial5029: {
    codigo: 'eSocial5029',
    nome: 'Desconto de adiantamento de férias e adicional de 1/3 no mês',
    natureza: '9221',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '13',
    codIncFGTS: '00',
    descricao:
      'Desconto referente ao pagamento das férias ocorrido no mês anterior, inclusive terço constitucional, proporcional aos dias de férias no mês.',
    nota: '',

    // Customizado por nós
    incideNoCustoTotal: true,
  },
  eSocial5030: {
    codigo: 'eSocial5030',
    nome: 'Desconto de adiantamento de férias e adicional de 1/3 no mês',
    natureza: '9221',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '13',
    codIncFGTS: '00',
    descricao:
      'Desconto referente ao pagamento das férias ocorrido no mês anterior, inclusive terço constitucional, proporcional aos dias de férias no mês.',
    nota: '',
  },
  eSocial5031: {
    codigo: 'eSocial5031',
    nome: 'Desconto de pagamento de férias gozadas e abono pecuniário',
    natureza: '9221',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente ao pagamento de férias gozadas e abono pecuniário, inclusive respectivos terços constitucionais.',
    nota: '',
  },
  eSocial5032: {
    codigo: 'eSocial5032',
    nome: 'Desconto de pagamento de férias gozadas/vendidas',
    natureza: '9221',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente ao pagamento de férias gozadas e abono pecuniário, inclusive respectivos terços constitucionais, em caso de antecipação de férias em período aquisitivo em curso.',
    nota: '',
  },
  eSocial5040: {
    codigo: 'eSocial5040',
    nome: '13º salário - Desconto do adiantamento',
    natureza: '9214',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '12',
    descricao:
      'Desconto referente à antecipação do 13º salário, realizada entre os meses de fevereiro e novembro.',
    nota: '',
  },
  eSocial5041: {
    codigo: 'eSocial5041',
    nome: '13º salário - Desconto do pagamento',
    natureza: '9214',
    tipo: '2',
    codIncCP: '12',
    codIncIRRF: '12',
    codIncFGTS: '12',
    descricao:
      'Desconto referente ao pagamento do 13º salário, realizado até o dia 20 de dezembro.',
    nota: '',
  },
  eSocial5045: {
    codigo: 'eSocial5045',
    nome: '13º salário - Desconto remuneração zerada',
    natureza: '9214',
    tipo: '2',
    codIncCP: '12',
    codIncIRRF: '12',
    codIncFGTS: '12',
    descricao:
      'Valor do desconto do 13º salário para fins de remuneração zerada, quando o valor do 13º salário for igual a 0,01.',
    nota: '',
  },
  eSocial5048: {
    codigo: 'eSocial5048',
    nome: '13º salário complementar - Desconto',
    natureza: '5005',
    tipo: '2',
    codIncCP: '12',
    codIncIRRF: '12',
    codIncFGTS: '12',
    descricao:
      'Desconto referente ao 13° salário complementar, devido a diferenças apuradas no mês de dezembro e não consideradas na folha de 13º salário.',
    nota: '',
  },
  eSocial5050: {
    codigo: 'eSocial5050',
    nome: 'Atrasos',
    natureza: '9208',
    tipo: '2',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Desconto referente às horas/minutos de atraso do empregado no mês.',
    nota: '',

    // Customizado por nós
    incideNoCustoTotal: true,
  },
  eSocial5060: {
    codigo: 'eSocial5060',
    nome: 'Faltas',
    natureza: '9207',
    tipo: '2',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao: 'Desconto referente aos dias de falta do empregado no mês.',
    nota: '',
  },
  eSocial5070: {
    codigo: 'eSocial5070',
    nome: 'DSR sobre faltas e atrasos',
    natureza: '9210',
    tipo: '2',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Desconto referente ao salário dos dias de descanso semanal e feriados do mês quando, sem justo motivo, a jornada da semana anterior não for integralmente cumprida.',
    nota: '',
  },
  eSocial5080: {
    codigo: 'eSocial5080',
    nome: 'Estagiários - Faltas e atrasos',
    natureza: '9209',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '11',
    codIncFGTS: '00',
    descricao:
      'Valor descontado correspondente a faltas, atrasos no início da jornada ou à saída antecipada do estagiário.',
    nota: '',
  },
  eSocial5090: {
    codigo: 'eSocial5090',
    nome: 'Vale-Transporte - Desconto',
    natureza: '9216',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente à participação do empregado no custeio do vale-transporte (até 6% do salário contratual, limitado ao valor do benefício pago).',
    nota: '',
  },
  eSocial5095: {
    codigo: 'eSocial5095',
    nome: 'Vale-Transporte - Devolução de valor pago a maior',
    natureza: '9216',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Restituição de vale-transporte em virtude de concessão do benefício em valor maior que o devido.',
    nota: '',
  },
  eSocial5098: {
    codigo: 'eSocial5098',
    nome: 'Desconto do adiantamento de salário',
    natureza: '9200',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao: 'Desconto referente a adiantamento salarial.',
    nota: '',
  },
  eSocial5101: {
    codigo: 'eSocial5101',
    nome: 'Desconto - Alimentação - Pecúnia',
    natureza: '9240',
    tipo: '2',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao: 'Desconto de alimentação - Pecúnia',
    nota: '',
  },
  eSocial5102: {
    codigo: 'eSocial5102',
    nome: 'Desconto - Alimentação - Ticket ou cartão - Integrantes do PAT',
    natureza: '9241',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto de alimentação - Ticket ou cartão - Integrantes do PAT',
    nota: '',
  },
  eSocial5103: {
    codigo: 'eSocial5103',
    nome: 'Desconto - Alimentação - Ticket ou cartão - Não integrante do PAT',
    natureza: '9242',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '11',
    descricao:
      'Desconto de alimentação - Ticket ou cartão - Não integrante do PAT',
    nota: '',
  },
  eSocial5104: {
    codigo: 'eSocial5104',
    nome: 'Desconto - Alimentação - Cesta básica ou refeição - Integrantes do PAT',
    natureza: '9243',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto de alimentação - Cesta básica ou refeição - Integrantes do PAT',
    nota: '',
  },
  eSocial5105: {
    codigo: 'eSocial5105',
    nome: 'Desconto - Alimentação - Cesta básica ou refeição - Não integrante do PAT',
    natureza: '9244',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '11',
    descricao:
      'Desconto de alimentação - Cesta básica ou refeição - Não integrante do PAT',
    nota: '',
  },
  eSocial5110: {
    codigo: 'eSocial5110',
    nome: 'Pensão Alimentícia - Mensal',
    natureza: '9213',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '51',
    codIncFGTS: '00',
    descricao: 'Desconto referente ao valor destinado à pensão alimentícia.',
    nota: '',
  },
  eSocial5111: {
    codigo: 'eSocial5111',
    nome: 'Pensão Alimentícia - 13° salário',
    natureza: '9213',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '52',
    codIncFGTS: '00',
    descricao:
      'Desconto referente ao valor destinado à pensão alimentícia sobre o 13º salário.',
    nota: '',
  },
  eSocial5112: {
    codigo: 'eSocial5112',
    nome: 'Pensão Alimentícia - Férias',
    natureza: '9213',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '53',
    codIncFGTS: '00',
    descricao:
      'Desconto referente ao valor destinado à pensão alimentícia sobre as férias.',
    nota: '',
  },
  eSocial5113: {
    codigo: 'eSocial5113',
    nome: 'Pensão Alimentícia - PLR',
    natureza: '9213',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '54',
    codIncFGTS: '00',
    descricao:
      'Desconto referente ao valor destinado à pensão alimentícia sobre o PLR.',
    nota: '',
  },
  eSocial5117: {
    codigo: 'eSocial5117',
    nome: 'Arredondamentos (desconto)',
    natureza: '2999',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Valor lançado em folha de pagamento, não superior a 99 centavos, relativo a desconto de arredondamento aplicado em mês anterior',
    nota: '',
  },
  eSocial5120: {
    codigo: 'eSocial5120',
    nome: 'Contribuição sindical laboral',
    natureza: '9230',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente a um dia de trabalho, a título de contribuição sindical laboral.',
    nota: '',
  },
  eSocial5121: {
    codigo: 'eSocial5121',
    nome: 'Contribuição sindical - Associativa',
    natureza: '9231',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente à mensalidade do empregado associado ao sindicato.',
    nota: '',
  },
  eSocial5122: {
    codigo: 'eSocial5122',
    nome: 'Contribuição sindical - Assistencial',
    natureza: '9232',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente à contribuição destinada ao custeio das atividades assistenciais do sindicato.',
    nota: '',
  },
  eSocial5123: {
    codigo: 'eSocial5123',
    nome: 'Contribuição sindical - Confederativa',
    natureza: '9233',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente à contribuição destinada ao custeio do sistema confederativo.',
    nota: '',
  },
  eSocial5140: {
    codigo: 'eSocial5140',
    nome: 'Danos causados pelo empregado - Desconto',
    natureza: '9270',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente ao reparo de danos e prejuízos causados pelo empregado.',
    nota: '',
  },
  eSocial5150: {
    codigo: 'eSocial5150',
    nome: 'Previdência privada - Desconto',
    natureza: '9223',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente à participação do empregado no custeio do plano de previdência privada.',
    nota: '',
  },
  eSocial5160: {
    codigo: 'eSocial5160',
    nome: 'Seguro de Vida - Desconto',
    natureza: '9250',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente à participação do empregado no custeio do seguro de vida.',
    nota: '',
  },
  eSocial5161: {
    codigo: 'eSocial5161',
    nome: 'Empréstimos consignados - desconto',
    natureza: '9254',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto de trabalhadores a título de empréstimos consignados, para repasse a instituição financeira consignatária',
    nota: '',
  },
  eSocial5163: {
    codigo: 'eSocial5163',
    nome: 'Convênios',
    natureza: '9258',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto relativos a convênios diversos com empresas para fornecimento de produtos ou serviços ao empregado, sem pagamento imediato, mas com posterior desconto em folha de pagamento como farmácias, supermercados, etc.',
    nota: '',
  },
  eSocial5164: {
    codigo: 'eSocial5164',
    nome: 'Fies - Desconto',
    natureza: '9260',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente à amortização de financiamento do Fundo de Financiamento Estudantil (Fies), para repasse à instituição consignatária',
    nota: '',
  },
  eSocial5165: {
    codigo: 'eSocial5165',
    nome: 'Assistência Médica - Desconto',
    natureza: '9219',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente à participação do empregado relativo à assistência prestada por serviço médico ou odontológico proveniente de convênio feito para esse fim.',
    nota: '',
  },
  eSocial5166: {
    codigo: 'eSocial5166',
    nome: 'Assistência Médica e/ou Odontológica - Desconto (empregado doméstico)',
    natureza: '9299',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente à participação do empregado doméstico no custeio dos planos de assistência médico-hospitalar e/ou odontológica.',
    nota: '',
  },
  eSocial5170: {
    codigo: 'eSocial5170',
    nome: 'Habitação (empregado doméstico)',
    natureza: '9299',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente à participação do empregado doméstico no custeio da habitação, desde que a moradia seja em local diverso da prestação de serviço.',
    nota: '',
  },
  eSocial5180: {
    codigo: 'eSocial5180',
    nome: 'Contribuição previdenciária do empregado (INSS)',
    natureza: '9201',
    tipo: '2',
    codIncCP: '31',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente à contribuição previdenciária do empregado sobre o salário e férias gozadas.',
    nota: '',
  },
  eSocial5181: {
    codigo: 'eSocial5181',
    nome: 'Contribuição previdenciária do empregado sobre 13º Salário (INSS)',
    natureza: '9201',
    tipo: '2',
    codIncCP: '32',
    codIncIRRF: '42',
    codIncFGTS: '00',
    descricao:
      'Desconto referente à contribuição previdenciária do empregado sobre o 13º salário.',
    nota: '',
  },
  eSocial5182: {
    codigo: 'eSocial5182',
    nome: 'Provisão de INSS - Férias',
    natureza: '9205',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '43',
    codIncFGTS: '00',
    descricao:
      'Desconto referente à contribuição previdenciária calculada sobre as férias gozadas.',
    nota: '',

    // Customizado por nós
    incideNoCustoTotal: true,
  },
  eSocial5183: {
    codigo: 'eSocial5183',
    nome: 'Compensação de provisão de INSS no mês - Férias',
    natureza: '1099',
    tipo: '1',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Valor referente à compensação da contribuição previdenciária já descontada no recibo de férias, proporcional aos dias de férias no mês.',
    nota: '',
  },
  eSocial5186: {
    codigo: 'eSocial5186',
    nome: 'INSS - SENAT (Descontado Transportador PF)',
    natureza: '9201',
    tipo: '2',
    codIncCP: '34',
    codIncIRRF: '41',
    codIncFGTS: '00',
    descricao: 'Desconto da parcela de contribuição do Transportador Autônomo.',
    nota: '',
  },
  eSocial5188: {
    codigo: 'eSocial5188',
    nome: 'INSS - SEST (Descontado Transportador PF)',
    natureza: '9201',
    tipo: '2',
    codIncCP: '35',
    codIncIRRF: '41',
    codIncFGTS: '00',
    descricao: 'Desconto da parcela de contribuição do Transportador Autônomo.',
    nota: '',
  },
  eSocial5190: {
    codigo: 'eSocial5190',
    nome: 'Imposto de renda retido na fonte - Mensal',
    natureza: '9203',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '31',
    codIncFGTS: '00',
    descricao:
      'Desconto referente ao Imposto de Renda sobre as verbas remuneratórias, exceto férias e 13º salário.',
    nota: '',
  },
  eSocial5191: {
    codigo: 'eSocial5191',
    nome: 'Imposto de renda retido na fonte - Férias',
    natureza: '9203',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '33',
    codIncFGTS: '00',
    descricao:
      'Desconto referente ao Imposto de Renda sobre as verbas de férias.',
    nota: '',
  },
  eSocial5192: {
    codigo: 'eSocial5192',
    nome: 'Imposto de renda retido na fonte - 13° salário',
    natureza: '9203',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '32',
    codIncFGTS: '00',
    descricao:
      'Desconto referente ao Imposto de Renda sobre a remuneração do 13º salário.',
    nota: '',
  },
  eSocial5193: {
    codigo: 'eSocial5193',
    nome: 'Imposto de renda retido na fonte - PLR',
    natureza: '9203',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '34',
    codIncFGTS: '00',
    descricao:
      'Desconto do Imposto de renda sobre a Participação em Lucros ou Resultados.',
    nota: '',
  },
  eSocial5199: {
    codigo: 'eSocial5199',
    nome: 'Decisão judicial - Valor líquido de TRCT quitado',
    natureza: '9299',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Desconto referente ao valor líquido do termo de rescisão quitado em decisão judicial.',
    nota: '',
  },
  eSocial5500: {
    codigo: 'eSocial5500',
    nome: 'Retroativo - Diferença de descontos indenizatórios',
    natureza: '9299',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Valor referente à diferença de deduções indenizatórias (sem natureza de remuneração), relativa a mês(es) anterior(es), descontada retroativamente.',
    nota: '',
  },
  eSocial5550: {
    codigo: 'eSocial5550',
    nome: 'Devolução de remuneração mensal',
    natureza: '9290',
    tipo: '2',
    codIncCP: '11',
    codIncIRRF: '11',
    codIncFGTS: '11',
    descricao:
      'Devolução de remuneração mensal do empregado (exceto 13º salário e férias), paga indevidamente, relativa a mês(es) anterior(es).',
    nota: '',
  },
  eSocial5551: {
    codigo: 'eSocial5551',
    nome: 'Devolução de 13º salário - Ano corrente',
    natureza: '9290',
    tipo: '2',
    codIncCP: '12',
    codIncIRRF: '12',
    codIncFGTS: '12',
    descricao:
      'Devolução de 13º salário do empregado, pago indevidamente, relativa ao ano atual.',
    nota: '',
  },
  eSocial5552: {
    codigo: 'eSocial5552',
    nome: 'Devolução de férias gozadas',
    natureza: '9290',
    tipo: '2',
    codIncCP: '11',
    codIncIRRF: '13',
    codIncFGTS: '11',
    descricao:
      'Devolução de férias do empregado acrescidas do terço constitucional, pagas indevidamente, relativa a mês(es) atual ou anterior(es).',
    nota: '',
  },
  eSocial5553: {
    codigo: 'eSocial5553',
    nome: 'Devolução de verbas indenizatórias',
    natureza: '9299',
    tipo: '2',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Devolução de verbas indenizatórias (sem natureza de remuneração), pagas indevidamente, relativa a mês(es) atual ou anterior(es).',
    nota: '',
  },
  eSocial5554: {
    codigo: 'eSocial5554',
    nome: 'Devolução de 13º salário - Anos anteriores',
    natureza: '9290',
    tipo: '2',
    codIncCP: '12',
    codIncIRRF: '12',
    codIncFGTS: '12',
    descricao:
      'Devolução de 13º salário do empregado, pago indevidamente, relativa a ano anterior.',
    nota: '',
  },
  eSocial9500: {
    codigo: 'eSocial9500',
    nome: 'Dependente - Remuneração Mensal',
    natureza: '9989',
    tipo: '4',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Valor a SER calculado na apuração do IRRF (quant. Dep IRRF X valor dedução por dependente Tabela Receita Federal)',
    nota: '',
  },
  eSocial9501: {
    codigo: 'eSocial9501',
    nome: 'Dependente - 13° salário',
    natureza: '9989',
    tipo: '4',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Valor a SER calculado na apuração do IRRF (quant. Dep IRRF X valor dedução por dependente Tabela Receita Federal)',
    nota: '',
  },
  eSocial9502: {
    codigo: 'eSocial9502',
    nome: 'Dependente - Férias',
    natureza: '9989',
    tipo: '4',
    codIncCP: '00',
    codIncIRRF: '9',
    codIncFGTS: '00',
    descricao:
      'Valor a SER calculado na apuração do IRRF (quant. Dep IRRF X valor dedução por dependente Tabela Receita Federal)',
    nota: '',
  },
  eSocial9510: {
    codigo: 'eSocial9510',
    nome: 'PSO - Mensal',
    natureza: '9989',
    tipo: '4',
    codIncCP: '00',
    codIncIRRF: '41',
    codIncFGTS: '00',
    descricao:
      'Valor referente à PSO (Previdência Social Oficial) a ser deduzido para apuração da base de IR mensal.',
    nota: '',
  },
  eSocial9511: {
    codigo: 'eSocial9511',
    nome: 'PSO - Férias',
    natureza: '9989',
    tipo: '4',
    codIncCP: '00',
    codIncIRRF: '43',
    codIncFGTS: '00',
    descricao:
      'Valor referente à PSO (Previdência Social Oficial) a ser deduzido para apuração da base de IR sobre férias.',
    nota: '',
  },
} as const satisfies Rubricas;

const idsDasTabelasPadroes = {
  eSocial: '1',
  proprietaria: 'octopus',
} as const;

export { rubricasESocial, rubricasProprietarias, idsDasTabelasPadroes };
