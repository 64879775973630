import { ReactElement } from 'react';

import { IconCameraPlus } from '@tabler/icons-react';

import { Box, Skeleton, Typography, useTheme } from '@mui/material';

import { getAvatarColor } from '../../../utils';
import { BouncingBackground } from '../BouncingBackground';
import UserAvatar from '../UserAvatar';

export type PersonInfoCardProps = {
  name: string;
  pictureUrl: string | undefined;
  onClick?: () => void;
  children?: ReactElement;
};

export function PersonInfoCard({
  name,
  pictureUrl,
  onClick,
  children,
}: PersonInfoCardProps) {
  const theme = useTheme();
  const color = getAvatarColor(name, theme);
  return (
    <BouncingBackground color={color}>
      <Box
        display="flex"
        flexDirection="column"
        pr={5}
        pt={10}
        gap={1}
        sx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            pl: 4.25,
          },
          [theme.breakpoints.down('sm')]: {
            pl: 3.5,
          },
        })}
        data-testid="person-info-card"
      >
        <UserAvatar
          onClick={onClick}
          name={name}
          pictureUrl={pictureUrl}
          showFullName={false}
          size={100}
          avatarProps={{
            border: '1.5px solid rgba(0, 0, 0, 0.07)',
            ml: 0,
            my: 0,
          }}
          avatarTextProps={{ fontWeight: '500', fontSize: '40px' }}
          AvatarHoverIcon={IconCameraPlus}
        />
        <Typography variant="h2" fontWeight="700" mt={3} mb={0.5}>
          {name}
        </Typography>
        {children}
      </Box>
    </BouncingBackground>
  );
}

export function PersonInfoCardSkeleton() {
  return (
    <BouncingBackground color="#808080">
      <Box
        display="flex"
        flexDirection="column"
        pr={5}
        pl={4.25}
        pt={10}
        gap={1}
      >
        <Skeleton
          variant="rounded"
          sx={{
            borderRadius: '100%',
            minHeight: '100px',
            width: '100px',
            border: '1.5px solid #FFFFFF',
            boxSizing: 'border-box',
          }}
        />
        <Skeleton
          variant="rounded"
          width="100%"
          height="40px"
          sx={{
            mt: 3,
          }}
        />
        <Skeleton variant="rounded" width="100%" height="60px" />
      </Box>
    </BouncingBackground>
  );
}
