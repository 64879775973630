import { MaskitoMask } from '@maskito/core';

/**
 * This is a mask parser that turns a string definition into a Maskito Mask
 * array.
 *
 * There are a few special tokens that represent regex expressions:
 *
 * - #: numeric character
 * - $: alphabetic character
 * - %: alphanumeric character
 * - &: whitespace
 *
 * Other characters will be interpreted literally.
 *
 * See examples on how maskito masks works on {@link https://maskito.dev/recipes/card}
 *
 * * At the moment there isn't a way of representing complex, multi-format masks.
 */
export function parseMask(
  maskString: string | undefined,
): MaskitoMask | undefined {
  if (!maskString) {
    return undefined;
  }
  const mask = [];
  for (let i = 0; i < maskString.length; i++) {
    const char = maskString.charAt(i);
    switch (char) {
      case '#':
        mask.push(/\d/);
        break;
      case '$':
        mask.push(/[a-zA-Z]/);
        break;
      case '%':
        mask.push(/[a-zA-Z0-9]/);
        break;
      case '&':
        mask.push(/\s/);
        break;
      default:
        mask.push(char);
    }
  }
  return mask;
}
