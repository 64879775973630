import SvgIcon from '@mui/material/SvgIcon';

export default function FilterIcon() {
  return (
    <SvgIcon>
      {/* credit: cog icon from https://heroicons.com */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M3.33398 3.3335H16.6673V5.1435C16.6672 5.58549 16.4916 6.00934 16.179 6.32183L12.5007 10.0002V15.8335L7.50065 17.5002V10.4168L3.76732 6.31016C3.48852 6.00343 3.33403 5.60383 3.33398 5.18933V3.3335Z"
          stroke="#616161"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
