import { SvgIconComponent } from '@mui/icons-material';
import { createSvgIcon } from '@mui/material';

export function AutonomoReceiptIcon(): SvgIconComponent {
  return createSvgIcon(
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="receipt2">
        <path
          id="Vector"
          d="M9.33398 5.33333H7.66732C7.4021 5.33333 7.14775 5.43869 6.96021 5.62623C6.77267 5.81376 6.66732 6.06812 6.66732 6.33333C6.66732 6.59855 6.77267 6.8529 6.96021 7.04044C7.14775 7.22798 7.4021 7.33333 7.66732 7.33333H8.33398C8.5992 7.33333 8.85356 7.43869 9.04109 7.62623C9.22863 7.81376 9.33398 8.06812 9.33398 8.33333C9.33398 8.59855 9.22863 8.8529 9.04109 9.04044C8.85356 9.22798 8.5992 9.33333 8.33398 9.33333H6.66732M8.00065 9.33333V10.3333M8.00065 4.33333V5.33333M3.33398 14V3.33333C3.33398 2.97971 3.47446 2.64057 3.72451 2.39052C3.97456 2.14048 4.3137 2 4.66732 2H11.334C11.6876 2 12.0267 2.14048 12.2768 2.39052C12.5268 2.64057 12.6673 2.97971 12.6673 3.33333V14L10.6673 12.6667L9.33398 14L8.00065 12.6667L6.66732 14L5.33398 12.6667L3.33398 14Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>,
    'AutonomoIcon',
  );
}

export function TrashIcon(): SvgIconComponent {
  return createSvgIcon(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.33301 5.83333H16.6663M8.33301 9.16667V14.1667M11.6663 9.16667V14.1667M4.16634 5.83333L4.99967 15.8333C4.99967 16.2754 5.17527 16.6993 5.48783 17.0118C5.80039 17.3244 6.22431 17.5 6.66634 17.5H13.333C13.775 17.5 14.199 17.3244 14.5115 17.0118C14.8241 16.6993 14.9997 16.2754 14.9997 15.8333L15.833 5.83333M7.49967 5.83333V3.33333C7.49967 3.11232 7.58747 2.90036 7.74375 2.74408C7.90003 2.5878 8.11199 2.5 8.33301 2.5H11.6663C11.8874 2.5 12.0993 2.5878 12.2556 2.74408C12.4119 2.90036 12.4997 3.11232 12.4997 3.33333V5.83333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
    'trashIcon',
  );
}
