import React from 'react';

import {
  Box,
  Divider,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

type Props = {
  title: string | React.ReactElement;
  body: string;
  showHint: boolean;
  children: React.ReactElement;
};

export function FieldHint(props: Props) {
  const { children, showHint } = props;
  const tooltipBody = FieldHintBody(props);
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeDevice = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Tooltip
      placement={isLargeDevice ? 'right' : 'bottom-end'}
      title={tooltipBody}
      open={showHint && !isSmallDevice}
      arrow
      sx={{ width: 'auto' }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 16],
              },
            },
          ],
        },
      }}
    >
      {children}
    </Tooltip>
  );
}

function FieldHintBody(props: Props) {
  const { title, body } = props;
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        [theme.breakpoints.up('lg')]: {
          px: 2.5,
          py: 0.5,
        },
        [theme.breakpoints.down('lg')]: {
          px: 0.5,
          py: 2.5,
        },
      })}
    >
      <Typography variant="h4">{title}</Typography>
      <Divider light />
      <Typography variant="body1" whiteSpace="preserve">
        {body}
      </Typography>
    </Box>
  );
}
