import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import { RenovacaoContrato } from '@octopus/contract-types';
import {
  IFormDefinition,
  UI_TYPE,
  dateYearMonthSchema,
  optionalDateYearMonthSchema,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { getOptionsFromMapper } from '../../inputUtils';
import { AdmissionFormState, FormStepDefinition } from '../../types';

const getFieldDefinition = ({
  formState,
}: {
  formState: AdmissionFormState;
}): IFormDefinition => {
  const { dataInicio, dataAssinatura, renovacao } = formState;

  return [
    {
      label: 'Data de início do contrato',
      type: dateYearMonthSchema,
      name: 'dataInicio',
      uiType: UI_TYPE.DATE_PICKER,
      placeholder: '00/00/0000',
      value: dataInicio,
    },
    {
      label: 'Data de assinatura do contrato',
      type: optionalDateYearMonthSchema,
      name: 'dataAssinatura',
      uiType: UI_TYPE.DATE_PICKER,
      placeholder: '00/00/0000',
      value: dataAssinatura,
    },
    {
      label: 'Renovação',
      type: z.string().optional(),
      name: 'renovacao',
      uiType: UI_TYPE.SELECT,
      options: getOptionsFromMapper({
        mapper: RenovacaoContrato,
        currentValue: renovacao,
      }),
    },
  ];
};

export const getPjFields = ({
  formState,
  pjFormSteps,
}: {
  formState: AdmissionFormState;
  pjFormSteps: AdmissionDraftInputFormStep;
}): FormStepDefinition => ({
  definition: getFieldDefinition({
    formState,
  }),
  options: {
    id: admissionDraftFormSteps.pj_info,
    title: 'Informações sobre o contrato',
    completed: pjFormSteps?.completed,
    reviewed: pjFormSteps?.reviewed,
  },
});
