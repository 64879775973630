import { isEqual } from 'lodash';

export function getDiffs<T extends Record<string, unknown>>(
  original: Record<string, unknown> | undefined,
  updates: Record<string, unknown> | undefined,
  opts?: {
    setEmptyToNull?: boolean;
  },
): T {
  if (!original) {
    return (updates ?? {}) as T;
  }
  if (!updates) {
    return {} as T;
  }
  return Object.entries(updates).reduce((diffs, [key, value]) => {
    const originalValue = original[key];
    if (!originalValue) {
      if (opts?.setEmptyToNull && value === '') {
        return {
          ...diffs,
          [key]: null,
        };
      }
      return { ...diffs, [key]: value };
    }
    if (isEqual(originalValue, value)) {
      return diffs;
    }
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      const objDiffs = getDiffs(
        originalValue as Record<string, unknown>,
        value as Record<string, unknown>,
        opts,
      );
      if (Object.keys(objDiffs).length > 0) {
        return {
          ...diffs,
          [key]: objDiffs,
        };
      }
    }
    if (opts?.setEmptyToNull && value === '') {
      return {
        ...diffs,
        [key]: null,
      };
    }
    return {
      ...diffs,
      [key]: value,
    };
  }, {}) as T;
}
