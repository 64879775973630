import {
  Box,
  Divider,
  List,
  ListItemButton,
  Popover,
  SvgIcon,
  Typography,
} from '@mui/material';

type menuAction = {
  label: string;
  onClick?: () => void;
  icon?: typeof SvgIcon;
  disabled?: boolean;
  tooltip?: string;
  divider?: boolean;
};

export default function EnableMoreActionsPopover({
  moreActionsProps,
  actions,
}: {
  moreActionsProps: {
    enableMoreActionsPopoverOpen: boolean;
    setEnableMoreActionsPopoverOpen: (value: boolean) => void;
    popoverAnchorEl: HTMLButtonElement | null;
    setPopOverAnchorEl: (value: HTMLButtonElement | null) => void;
  };
  actions: menuAction[];
}) {
  return (
    <Popover
      open={moreActionsProps.enableMoreActionsPopoverOpen}
      anchorEl={moreActionsProps.popoverAnchorEl}
      onClose={() => moreActionsProps.setEnableMoreActionsPopoverOpen(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      elevation={1}
      sx={{
        mt: 1,
      }}
      data-testid="filters-popover"
    >
      <List sx={{ pt: 0.5, px: 0.5 }}>
        {actions.map(({ label, onClick, icon, divider, disabled }) =>
          divider ? (
            <Divider key="divider" />
          ) : (
            <ListItemButton
              sx={{ width: '100%', gap: 1.5 }}
              key={label}
              onClick={(event) => {
                event.stopPropagation();
                if (disabled) {
                  return;
                }
                onClick();
                moreActionsProps.setEnableMoreActionsPopoverOpen(false);
              }}
            >
              {icon ? (
                <Box
                  component={icon}
                  sx={{
                    p: 0,
                    height: '20px',
                    width: '20px',
                    color: disabled ? '#BABABF' : 'primary',
                  }}
                />
              ) : undefined}

              <Typography
                sx={(theme) => ({
                  width: '100%',
                  color: disabled
                    ? theme.palette.strokes.heavy
                    : theme.palette.text.primary,
                })}
              >
                {label}
              </Typography>
            </ListItemButton>
          ),
        )}
      </List>
    </Popover>
  );
}
